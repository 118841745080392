import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import moment from 'moment'

// 角色列表
export const getSystemRoleData = async (params : any) => {
  let data: any
  await http.post(HUrl.ROLE_SYSTEM_DATA, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 角色详情
export const getInfo = async (params : any) => {
  let data: any
  await http.get(HUrl.ROLE_SYSTEM_INFO, params).then((result : any) => {
    result.data.cret = moment(result.data.cret).format('YYYY-MM-DD HH:mm:ss')

    let updt = moment(result.data.updt).format('YYYY-MM-DD HH:mm:ss')
    if (updt.indexOf('0001-01-01') != -1) {
      result.data.updt = ''
    } else {
      result.data.updt = updt
    }
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 新增角色
export const addSystemRole = async (params : any) => {
  let data: any
  await http.addPost(HUrl.ROLE_SYSTEM_ADD, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 修改角色
export const editSystemRole = async (params : any) => {
  let data: any
  await http.editPost(HUrl.ROLE_SYSTEM_EDIT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除角色
export const delSystemRole = async (params : any) => {
  let data: any
  await http.delPost(HUrl.ROLE_SYSTEM_DEL, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
