/**
 * 字典
 * @since 2023-6-15
*/
import { storeToRefs } from 'pinia'
import { dictionarySelect } from '@/store' // 引入setting permission的store
import { basicDataSelect } from '@/service/modules/sysmanage/generalData'
const initStore = () => {
  const settStore = dictionarySelect()
  // 设置为响应数据
  const refSettStore = storeToRefs(dictionarySelect())
  return { settStore, refSettStore }
}

// 修改数据字典
// export function setDictionaryList(val: any) {
//   initStore().settStore.setData(val)
// }

// 获取数据字典
export function getDictionaryList(code?: string, updateCache = false) {
  const initStoreVal = initStore()
  const { getData } = initStoreVal.refSettStore
  if (code) {
    if (!getData.value[code] || !getData.value[code].state || updateCache) {
      // 重新获取数据
      basicDataSelect({ bdtc: code }, true)
    }
  }
  return getData.value
}
