// 部门接口
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
const url = HUrl.BASE_PREFIX_INIT_ADMIN
// 设置模版信息
export const setTemplate = async (params: any) => {
  let data: any
  await http.post(url + '/department/initTemplate', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询部门列表数据-分页
export const getDepartmentInfo = async (params: any) => {
  let data: any
  // await http.post(HUrl.DEPARTMENT_INFO, params).then((result: any) => {
  await http.post(url + '/department/queryPageList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 部门提交
export const departmentInfoSubmit = async (params: any) => {
  let data: any
  // await http.post(HUrl.DEPARTMENT_INFO_SUBMIT, params).then((result: any) => {
  await http.post(url + '/department/submit', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 部门草稿
export const departmentSaveDraft = async (params?: any) => {
  let data: any
  // await http.post(HUrl.DEPARTMENT_SAVE_DRAFT, params).then((result: any) => {
  await http.post(url + '/department/save', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 删除单个部门信息列表数据
export const delDepartmentInfo = async (params: any) => {
  let data: any
  // await http.delPost(HUrl.DEPARTMENT_INFO_DEL, params).then((result: any) => {
  await http.delPost(url + '/department/deleteList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 部门负责人信息
export const leaderInfo = async (params: any) => {
  let data: any
  // await http.post(HUrl.DEPARTMENT_LEADER_INFO, params).then((result: any) => {
  await http.post(url + '/employee/querySingle', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询部门信息
export const GetInfoDepartmentInfo = async (params: any) => {
  let data: any
  // await http.post(HUrl.DEPARTMENT_INFO_GETINFO, params).then((result: any) => {
  await http.post(url + '/department/querySingle', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 上传多个文件
// export const PictureDepartmentInfo = async (params: any) => {
//   let data: any
//   await http.post(HUrl.DEPARTMENT_INFO_PICTURE, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }
// 此接口导入部门Execl文件信息
export const departmentImport = async (params: any) => {
  let data: any
  // await http.postFiles(HUrl.DEPARTMENT_IMPORT, {}, params).then((result: any) => {
  await http.postFiles(url + '/department/import', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 下载部门导入模板
export const departmentDownloadtemp = async (params?: any) => {
  let data: any
  // await http.getFiles(HUrl.DEPARTMENT_DOWNLOADTEMP, params).then((result: any) => {
  await http.getFiles(url + '/department/export/template', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 此接口导出部门Execl信息
export const departmentExport = async (params?: any) => {
  let data: any
  // await http.getFilesExt(HUrl.DEPARTMENT_EXPORT, params).then((result: any) => {
  await http.javaDl(url + '/department/export', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 获取部门下拉组件数据 /job/queryList /department/queryList
export const getDeptData = async (params?: any) => {
  let data :any
  // await http.post(HUrl.DEPARTMENT_INFO_SELECT, params).then((result:any) => {
  await http.post(url + '/department/queryList', params).then((result:any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 询部门下拉树组件数据
export const TreeDepartmentInfo = async (params?: any) => {
  let data: any
  // await http.post(HUrl.DEPARTMENT_INFO_TREE, params).then((result: any) => {
  await http.post(url + '/department/queryTree', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 部门离职汇总
export const EmployeeStat = async (params: any) => {
  let data: any
  // await http.post(HUrl.DEPARTMENT_EMPLOYEE_STAT, params).then((result: any) => {
  await http.post(url + '/department/statistics', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
