import moment from 'moment'
import { commonFunc } from '..'
/**
 * 共用函数
 * @author jihua.huang
 * @since 2022-03-24
*/

/**
 * 转换为字符串（false null function undefined NaN 0 空对象 空数组 统一转为空字符串）
 * @param value any
 * @return String 转换后的字符串
 */
export const toStr = (value: any) => {
  let str = ''
  if (typeof value === 'undefined') {
    str = ''
  } else if (!value || value === null || typeof value === 'function' || value.isNaN || JSON.stringify(value) === '{}') {
    str = ''
  } else {
    str = value.toString()
  }
  return str
}

/**
 * 转换开关值（true null function undefined NaN  空对象 空数组 统一转为开）
 * @param value any
 * @return boolen 转换后的trun/false
 */
export const hasOnOff = (value: any) => {
  let ret = false
  if (typeof value === 'undefined') {
    ret = true
  } else {
    if (typeof value === 'string') {
      ret = !!parseInt(value)
    } else if (typeof value === 'number') {
      ret = !!value
    } else {
      ret = true
    }
  }
  return ret
}

/**
 * 拼接成字符串
 * @param str string
 * @param str... string
 * @return string str
 */
export const concatStr = (str: string, str1: string) => {
  return str + str1
}

export function isValidKey(key: string | number | symbol, object: object): key is keyof typeof object {
  return key in object
}

/**
 * 格式化unix时间戳为年月日格式（2022-03-18 10:08:00）
 * @param time number
 * @param format string 格式 默认：yyyy-MM-dd h:m:s
 * @return string str
 */
export const formatDate = (time = 0, format = 'yyyy-MM-dd hh:mm:ss') => {
  let date: any
  if (time > 0) {
    let timeStr = time.toString()
    if (timeStr.length > 10) {
      date = new Date(time)
    } else {
      // 如果是毫秒则不需要*1000，如果是秒，则需要*1000
      date = new Date(time * 1000)
    }
  } else {
    date = new Date()
  }

  if (!format) return date.toLocaleString()
  let dateMap: any = {
    y: date.getFullYear(),
    M: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    m: date.getMinutes(),
    s: date.getSeconds(),
    S: date.getMilliseconds()
  }
  return format.replace(/(y+)|(M+)|(d+)|(h+)|(m+)|(s+)|(S+)/g, (a) => _add0(dateMap[a[0]], a.length))
}

/**
 * 国际化时间处理为本地时间
 * @param timestamp 时间
 * @param format 格式
 * @returns 返回本地时间
 */
export function toLocalTime(timestamp: string | Date, format = 'YYYY-MM-DD HH:mm:ss') {
  if (!timestamp) {
    return ''
  }
  let date = moment(timestamp).format('YYYY-MM-DD')
  if (date.indexOf('0001-01-01') != -1 || timestamp === '0001-01-01T00:00:00Z') {
    return ''
  }
  // if(date.indexOf("2000-01-01")!=-1)
  // {
  //   return "";
  // }
  return moment(timestamp).format(format)
}

/**
 * 根据类型种的key属性
 * @param key key所对应的检索值
 * @param arr 检索的数组
 * @returns 返回检索对象
 */
export function getObjByKey(key: string, arr: any) {
  let obj = arr[0]
  if (obj.hasOwnProperty('key')) {
    for (let index in arr) {
      if (arr[index].key == key) {
        return arr[index]
      }
    }
    return { key: '', value: key }
  } else {
    return { key: '', value: key }
  }
}

function _add0(time: any, len: number) {
  let timeStr = time.toString()
  let l = timeStr.length
  return l < len ? '0'.repeat(len - l) + time : time
}

/**
 * 判断字符串是否在item中
 * @param item object/string item项
 * @param need string 字符串
 * @return number 1：弱，2：中，3：强
 */
export const isIndexOf = (item: any, need: any) => {
  if (typeof item === 'undefined' || item === null || typeof need === 'undefined' || typeof need === 'undefined') return false
  if (Object.keys(item).length >= 1 && need) {
    if (item.indexOf(need) !== -1) {
      return true
    }
  }
  return false
}

/**
 * 字符串的IP地址转为整型的IP（127.0.0.1 -> 2130706433 ）
 * @param ipAddress string 字符串的IP地址
 * @return number 整型IP地址
 */
export const ip2long = (ipAddress: string) => {
  let output: any
  if (ipAddress.match(/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/)) {
    let parts: any = ipAddress.split('.')
    output = (parts[0] * Math.pow(256, 3)) +
      (parts[1] * Math.pow(256, 2)) +
      (parts[2] * Math.pow(256, 1)) +
      (parts[3] * Math.pow(256, 0))
  }
  return output << 0
}

/**
 * 整型的IP转为字符串的IP地址（2130706433 -> 127.0.0.1）
 * @param longAddress number 整型IP地址
 * @return string 字符串的IP地址
 */
export const long2ip = (longAddress: number) => {
  longAddress = longAddress >>> 0
  let output

  if (!isNaN(longAddress) && (longAddress >= 0 || longAddress <= 4294967295)) {
    output = Math.floor(longAddress / Math.pow(256, 3)) + '.' +
      Math.floor((longAddress % Math.pow(256, 3)) / Math.pow(256, 2)) + '.' +
      Math.floor(((longAddress % Math.pow(256, 3)) % Math.pow(256, 2)) /
        Math.pow(256, 1)) + '.' +
      Math.floor((((longAddress % Math.pow(256, 3)) % Math.pow(256, 2)) %
        Math.pow(256, 1)) / Math.pow(256, 0))
  }
  return output
}

const S4 = function () {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
}

export function guid() {
  return (S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4())
}

/**
 * 获取URL查询参数值
 * @param url string
 * @return string
 */
export function getAllUrlParams(url: string) {
  let queryString = url ? url.split('?')[1] : window.location.search.slice(1)
  let obj: any = {}
  if (queryString) {
    queryString = queryString.split('#')[0]
    let arr = queryString.split('&')
    for (let i = 0; i < arr.length; i++) {
      let a = arr[i].split('=')
      let paramName = a[0]
      let paramValue = typeof (a[1]) === 'undefined' ? true : a[1]
      if (paramName.match(/\[(\d+)?\]$/)) {
        let key = paramName.replace(/\[(\d+)?\]/, '')
        if (!obj[key]) obj[key] = []
        if (paramName.match(/\[\d+\]$/)) {
          let index = /\[(\d+)\]/.exec(paramName)![1]
          obj[key][index] = paramValue
        } else {
          obj[key].push(paramValue)
        }
      } else {
        if (!obj[paramName]) {
          obj[paramName] = paramValue
        } else if (obj[paramName] && typeof obj[paramName] === 'string') {
          obj[paramName] = [obj[paramName]]
          obj[paramName].push(paramValue)
        } else {
          obj[paramName].push(paramValue)
        }
      }
    }
  }
  return obj
}

/**
 * 首字母大写
 * @param str
 * @returns
 */
export const titleCase = (str: string) => {
  const newStr = str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase()
  return newStr
}
/**
 * 首字母小写
 * @param str
 * @returns
 */
export const initialLowercase = (str: string) => {
  const newStr = str.slice(0, 1).toLocaleLowerCase() + str.slice(1)
  return newStr
}

/**
 * 驼峰转横杠
 * @param str
 * @returns aaa-bbb-ccc
 */
export const humpToUnderline = (str: string) => {
  let newStr = str.replace(/([A-Z])/g, '-$1').toLowerCase()
  if (newStr[0] === '-') {
    newStr = newStr.slice(1, newStr.length)
  }
  return newStr
}

/**
 * 时间大小判断
 * @param time 时间
 * @param judge 大于 小于 等于 默认大于
 * @returns boolean
 */
export const timeResolutionSize = (start: string | Date, end: string | Date, judge: '<' | '>' | '=' = '>') => {
  if (!start || !end) {
    return ''
  }
  const startTime = moment(start)
  const endTime = moment(end)
  if (judge === '=') {
    return startTime.isSame(endTime)
  } else if (judge === '<') {
    return !(endTime.isBefore(startTime))
  } else if (judge === '>') {
    return endTime.isBefore(startTime)
  }
}

/**
 * 四舍五入 传入数字和需要保留几位小数
 * @param num 数字字符串
 * @param digit 传入需要保留几位小数
 * @returns
 */
export const halfAdjust = (num: string, digit: number) => {
  if (num && num.includes('.')) {
    let numArr = num.split('.')
    if (numArr[1].length > digit) {
      let digitNum = '1'
      for (let i = 0; i < digit; i++) {
        digitNum += '0'
      }
      let multNum = Number(digitNum)
      let numb = Number(num)

      return (Math.round(numb * multNum) / multNum) + ''
    } else {
      return num
    }
  } else {
    return num
  }
}

function isObject(value: object) {
  return typeof value === 'object' && value !== null
}

/**
 * 判断两个对象是否完全相等
 * @param obj1
 * @param obj2
 * @returns boolean
 */
export const isEqual = (obj1: object, obj2: object) => {
  // 两个数据有任何一个不是对象或者或数组，都不需要深度比较，直接全等比较即可
  // 此时该判断为true 的条件一般就是 值类型了，函数一般不会参与equal判断
  if (!isObject(obj1) || !isObject(obj2)) return obj1 === obj2
  // 传入的参数都是同一个对象或者数组
  if (obj1 === obj2) return true

  const keyArr1 = Object.keys(obj1)
  const keyArr2 = Object.keys(obj2)
  // key 的长度都不一致，直接false
  if (keyArr1.length !== keyArr2.length) return false
  // 选一个作为基准，依次取出对应key，one by one 递归比较
  // 什么时候递归要注意，当你进入的判断和你之前写的逻辑一致时，参考深拷贝
  keyArr1.forEach(e => {
    // 每一对的属性值判断要接住
    // 否则直接 return 的话会出现一个属性值对比结果为true的话，整个函数返回就是true
    const partRes = isEqual(obj1[e], obj2[e])
    // 有false 的倒是可以一遇到就返回
    if (!partRes) return false
  })
  // 前面判断都走完的话，到了这一步，传入的俩参数也就全相等了
  return true
}

/**
 * 时间格式化成秒
 * @param time 时间
 * @param unit 时间单位
 * @returns 秒
 */
export const timeConversion = (time: string | number, unit: string) => {
  // 分钟
  if (unit === 'Minute' || unit === 'minutes') {
    time = commonFunc.countFn(time, '60', '*')
  } else if (unit === 'Hour' || unit === 'hours') {
    // 小时
    time = commonFunc.countFn(time, '3600', '*')
  } else if (unit === 'Day' || unit === 'days') {
    // 天
    time = commonFunc.countFn(commonFunc.countFn(time, '24', '*'), '3600', '*')
  } else if (unit === 'Week' || unit === 'weeks') {
    // 周
    time = commonFunc.countFn('7', commonFunc.countFn(commonFunc.countFn(time, '24', '*'), '3600', '*'), '*')
  } else if (unit === 'Month' || unit === 'months') {
    // 月
    time = commonFunc.countFn('30', commonFunc.countFn(commonFunc.countFn(time, '24', '*'), '3600', '*'), '*')
  } else if (unit === 'Year' || unit === 'years') {
    // 年
    time = commonFunc.countFn('365', commonFunc.countFn(commonFunc.countFn(time, '24', '*'), '3600', '*'), '*')
  }
  return Number(time)
}

/**
 * utf-8 转中文
 * @param strUtf8
 * @returns
 */
export const utf8ToChinese = (strUtf8: string) => {
  let i, len, ch
  const arr = []
  len = strUtf8.length
  for (i = 0; i < len; i++) {
    ch = strUtf8.charCodeAt(i)
    if ((ch & 0xff80) == 0) {
      arr.push(strUtf8.charAt(i))
    } else if ((ch & 0xfc00) == 0xd800) {
      i++
      let rs = (ch & 0x3ff) << 10
      ch = strUtf8.charCodeAt(i)
      rs |= ch & 0x3ff
      rs += 0x10000
      arr.push(String.fromCharCode(rs >> 18, rs >> 12 & 0x3f, rs >> 6 & 0x3f, rs & 0x3f))
    } else {
      arr.push(String.fromCharCode(ch >> 12 & 0x0f, ch >> 6 & 0x3f, ch & 0x3f))
    }
  }
  return arr.join('')
}

// export const secondsGoTime = (time: string | number) => {
//   time = String(time)
//   if (time) {
//     // 转分钟 // 转换为式分秒
//     let h: number = parseInt(time / 60 / 60 % 24)
//     h = h < 10 ? '0' + h : h
//     let m = parseInt(time / 60 % 60)
//     m = m < 10 ? '0' + m : m
//     let s = parseInt(time % 60)
//     s = s < 10 ? '0' + s : s
//     // 作为返回值返回
//     return [h, m, s]
//   } else {
//     return time
//   }
// }

type TimeType = 'YYYY-MM-HH DD:mm:ss' | 'YYYY-MM-HH' | 'HH-DD-mm'

/**
 * 把秒转换成对应格式的时间
 * @param seconds 秒
 * @param type 时间格式
 * @returns
 */
export function secondsGoTime(seconds: number | string, type: TimeType = 'YYYY-MM-HH DD:mm:ss') {
  seconds = Number(seconds)
  const oneYearInSeconds = 31536000 // 365天
  const oneMonthInSeconds = 2592000 // 30 天
  const oneDayInSeconds = 86400 // 1天
  const oneHourInSeconds = 3600 // 小时
  const oneMinuteInSeconds = 60 // 分钟

  let years = Math.floor(seconds / oneYearInSeconds)
  let months = Math.floor((seconds % oneYearInSeconds) / oneMonthInSeconds)
  let days = 0
  let hours = 0
  let minutes = 0
  let remainingSeconds = 0
  if (type !== 'HH-DD-mm') {
    days = Math.floor(((seconds % oneYearInSeconds) % oneMonthInSeconds) / oneDayInSeconds)
    hours = Math.floor((((seconds % oneYearInSeconds) % oneMonthInSeconds) % oneDayInSeconds) / oneHourInSeconds)
    minutes = Math.floor(((((seconds % oneYearInSeconds) % oneMonthInSeconds) % oneDayInSeconds) % oneHourInSeconds) / oneMinuteInSeconds)
    remainingSeconds = (((seconds % oneYearInSeconds) % oneMonthInSeconds) % oneDayInSeconds) % oneHourInSeconds % oneMinuteInSeconds
  } else {
    days = Math.floor(seconds / oneDayInSeconds)
    hours = Math.floor((seconds % oneDayInSeconds) / oneHourInSeconds)
    minutes = Math.floor(((seconds % oneDayInSeconds) % oneHourInSeconds) / oneMinuteInSeconds)
    remainingSeconds = seconds % oneMinuteInSeconds
  }

  // 使用padStart确保每个值始终为两位数
  const yDisplay = years // .toString().padStart(2, '0')
  const mDisplay = months // .toString().padStart(2, '0')
  const dDisplay = days // .toString().padStart(2, '0')
  const hDisplay = hours // .toString().padStart(2, '0')
  const miDisplay = minutes // .toString().padStart(2, '0')
  const sDisplay = remainingSeconds // .toString().padStart(2, '0')

  if (type === 'HH-DD-mm') {
    if (dDisplay) {
      return `${dDisplay}天${hDisplay}小时${miDisplay}分钟${sDisplay}秒`
    } else if (hDisplay) {
      return `${hDisplay}小时${miDisplay}分钟${sDisplay}秒`
    } else if (miDisplay) {
      return `${miDisplay}分钟${sDisplay}秒`
    } else {
      return `${sDisplay}秒`
    }
  }

  if (type === 'YYYY-MM-HH') {
    if (yDisplay) {
      return `${yDisplay}年${mDisplay}个月${dDisplay}天`
    } else if (mDisplay) {
      return `${mDisplay}个月${dDisplay}天`
    } else {
      return `${dDisplay}天`
    }
  }

  if (type === 'YYYY-MM-HH DD:mm:ss') {
    if (yDisplay) {
      return `${yDisplay}年${mDisplay}个月${dDisplay}天 ${hDisplay}小时${miDisplay}分钟${sDisplay}秒`
    } else if (mDisplay) {
      return `${mDisplay}个月${dDisplay}天 ${hDisplay}小时${miDisplay}分钟${sDisplay}秒`
    } else if (dDisplay) {
      return `${dDisplay}天 ${hDisplay}小时${miDisplay}分钟${sDisplay}秒`
    } else if (hDisplay) {
      return `${hDisplay}小时${miDisplay}分钟${sDisplay}秒`
    } else if (miDisplay) {
      return `${miDisplay}分钟${sDisplay}秒`
    } else {
      return `${sDisplay}秒`
    }
  }
}

/**
 * 防抖
 * @param fn delay
 * @use debounce(fn, 500)
 */
export function debounce(fn: () => void, delay: number) {
  let timer: NodeJS.Timeout | null = null
  return function () {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      typeof fn === 'function' && fn()
      timer = null
    }, delay)
  }
}

/**
 * 节流
 * @param fn delay
 * @use throttle(fn, 500)
 */
export function throttle(fn: () => void, delay: number) {
  let timer: NodeJS.Timeout | null = null
  return function () {
    if (timer) {
      return
    }
    timer = setTimeout(() => {
      typeof fn === 'function' && fn()
      timer = null
    }, delay)
  }
}

/**
 * 数字转金额
 * @param num
 * @returns 转换后的金额
 */
export const numberToAmount = (num: string | number) => {
  // 只展示两位小数
  let str = commonFunc.halfAdjust(String(num), 2)

  // 切割小数点
  let arr = str.split('.')

  // 添加千位符
  let regExp = arr[0].replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')
  // 拼接数据
  str = arr[1] === undefined ? regExp : `${regExp}.${arr[1]}`

  return str
}

type TreeType = {
  children: TreeType[]
}

/**
 * 树状结构转扁平数据
 * @param tree 树状结构
 * @returns
 */
export const flattenTree = (tree: TreeType[]) => {
  const result: any = tree.reduce((callbackfn: any[], item: TreeType) => {
    const { children, ...res } = item
    const arr = (children && children.length > 0) ? flattenTree(children) : []
    return callbackfn.concat(res, ...arr)
  }, [])
  return result
}

/**
 * 清除数据里面的注释 空格 换行
 * @param str 数据
 * @returns 格式化后的数据
 */
export const clearComment = (str: string) => {
  let reg = /("([^\\\"]*(\\.)?)*")|('([^\\\']*(\\.)?)*')|(\/{2,}.*?(\r|\n))|(\/\*(\n|.)*?\*\/)/g // 正则表达式
  const newStr = str.replace(reg, function(word) { // 去除注释后的文本
    return /^\/{2,}/.test(word) || /^\/\*/.test(word) ? '' : word
  })

  // 清空空格和换行
  return newStr.replace(/("(?:\\"|[^"])*")|\s/g, '$1')
}
