import { http } from '@/service' // 引入网络请求http
import { Tany } from 'types/commonresource'

const url = 'api/production' // 路径前缀 + 微服务

/**
 * 检测bom重复
 */
export const bomInfoCheck = async (params: Tany) => {
  let data: Tany
  await http.post(url + '/bomInfo/check', params).then((result: Tany) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取表格分页数据
export const getPageList = async (params: Tany) => {
  let data: Tany
  await http.post(url + '/bomInfo/queryPageList', params).then((result: Tany) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取单个详情
export const getInfo = async (params: Tany) => {
  let data: Tany
  await http.post(url + '/bomInfo/querySingle', params).then((result: Tany) => {
    if (result.code === 1 && result.data) {
      for (const key in result.data) {
        if (result.data[key] === null) {
          result.data[key] = ''
        }
      }
    }
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 保存草稿
export const saveDraft = async (params: Tany) => {
  let data: Tany
  await http.post(url + '/bomInfo/save', params).then((result: Tany) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 提交
export const submit = async (params: Tany) => {
  let data: Tany
  await http.post(url + '/bomInfo/submit', params).then((result: Tany) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 删除
export const del = async (params: Tany) => {
  let data: Tany
  await http.delPost(url + '/bomInfo/deleteList', params).then((result: Tany) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 偷偷的删除-不带提示
export const stealthilyDel = async (params: Tany) => {
  let data: Tany
  await http.post(url + '/bomInfo/deleteList', params).then((result: Tany) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导入
export const importFiles = async (params: Tany) => {
  let data: Tany
  await http.postFiles(url + '/bomInfo/import', {}, params).then((result: Tany) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出
export const exportFiles = async (params?: Tany) => {
  let data: Tany
  await http.getFilesExt(url + '/bomInfo/export', params).then((result: Tany) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出模板
export const exportFilesTmpl = async (params?: Tany) => {
  let data: Tany
  await http.getFiles(url + '/bomInfo/template', params).then((result: Tany) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 反审
export const deApproveApi = async (params: Tany) => {
  let data: Tany
  await http.post(url + '/bomInfo/counterReview', params).then((result: Tany) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 撤回
export const withdrawApi = async (params: Tany) => {
  let data: Tany
  await http.post(url + '/bomInfo/revoke', params).then((result: Tany) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// -------------------------------- BOM明细 --------------------------------------
// 批量删除
export const bomDetailsDeleteList = async (params: Tany) => {
  let data: Tany
  await http.delPost(url + '/bomDetails/deleteList', params).then((result: Tany) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出
export const bomDetailsExport = async (params: Tany) => {
  let data: Tany
  await http.getFilesExt(url + '/bomDetails/export', params).then((result: Tany) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导入
export const bomDetailsImport = async (params: Tany) => {
  let data: Tany
  await http.postFiles(url + '/bomDetails/import', {}, params).then((result: Tany) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 列表查询
export const bomDetailsQueryList = async (params: Tany) => {
  let data: Tany
  await http.post(url + '/bomDetails/queryList', params).then((result: Tany) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 分页查询
export const bomDetailsQueryPageList = async (params: Tany) => {
  let data: Tany
  await http.post(url + '/bomDetails/queryPageList', params).then((result: Tany) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 单个查询
export const bomDetailsQuerySingle = async (params: Tany) => {
  let data: Tany
  await http.post(url + '/bomDetails/querySingle', params).then((result: Tany) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 保存或修改
export const bomDetailsSave = async (params: Tany) => {
  let data: Tany
  await http.post(url + '/bomDetails/save', params).then((result: Tany) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 模板下载
export const bomDetailsTemplate = async (params: Tany) => {
  let data: Tany
  await http.getFilesExt(url + '/bomDetails/template', params).then((result: Tany) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
