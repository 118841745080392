import { settingStore } from '@/store'
import { ElMessage } from 'element-plus'
import { setting } from '@/utils'
// 挂载 ElMessage 给 CefSharp 使用 已挂载
export const cefSharpMessage = () => {
  document.addEventListener('cefSharpMessage', (e: any) => {
    console.log('ElMessage调用', e)
    ElMessage({
      type: e?.detail?.type,
      message: e.detail?.message || ''
    })
  })
}

// 挂载 切换语言 给 CefSharp 使用 已挂载
export const changeLang = () => {
  document.addEventListener('changeLang', (e: any) => {
    setting.setLang(e.detail)
  })
}

// 挂载 帮助点击 给 CefSharp 使用 已挂载
export const openHelper = () => {
  document.addEventListener('openHelper', () => {
    console.log('帮助点击')
  })
}

//  挂载 主题开关 到document 已挂载
export const setDrawer = () => {
  document.addEventListener('setDrawer', () => {
    const store = settingStore()
    const drawer = store.getDrawer
    store.setDrawer(!drawer)
  })
}

// 挂载 快捷键方法 到document 已挂载
export const shortcutKeyEvent = (fn: (value: string) => void) => {
  document.addEventListener('shortcutKeyEvent', (e: any) => {
    if (e.detail) {
      fn(e.detail.toLowerCase())
    }
  })
}

// 挂载 BUG反馈弹窗
export const bugFeedback = (feedBackRef:any) => {
  document.addEventListener('bugFeedback', () => {
    console.log('触发bugFeedback！')
    console.log('feedBackRef', feedBackRef)
    feedBackRef.value.dialogVisible = true
  })
}

// 挂载 刷新列表
export const refreshList = (name: string, fn: () => void) => {
  document.addEventListener(name, () => {
    fn()
  })
}

// 挂载 方法给scfSharp
export const mountMethod = (name: string, fn: () => void) => {
  document.addEventListener(name, () => {
    fn()
  })
}
