/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import { storeToRefs } from 'pinia'
import { dictionarySelect, settingStore } from '@/store'
import { billNo,digittoChinese } from '@/utils'
import { readBaseData } from '@/utils/cefSharpFun/index'
// 查询基础数据类型
export const getBasicDataType = async (params: any) => {
  let data: any
  await http
    .get(HUrl.GET_BASIC_DATA_TYPE, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 查询基础数据类型分页
export const getBasicDataTypePage = async (params: any) => {
  let data: any
  await http
    .post(HUrl.GET_BASIC_DATA_TYPE_PAGE, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 新增基础数据类型
export const addBasicDataType = async (params: any) => {
  let data: any
  await http
    .addPost(HUrl.ADD_BASIC_DATA_TYPE, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 修改基础数据类型
export const editBasicDataType = async (params: any) => {
  let data: any
  await http
    .editPost(HUrl.EDIT_BASIC_DATA_TYPE, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 删除基础数据类型
export const delBasicDataType = async (params: any) => {
  let data: any
  await http
    .delPost(HUrl.DEL_BASIC_DATA_TYPE, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 查询基础数据
export const getBasicData = async (params: any) => {
  let data: any
  await http
    .post(HUrl.GET_BASIC_DATA, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
// 查询基础数据-base
export const getBasicDataBase = async (params: any) => {
  let data: any
  await http
    .post(HUrl.BASE_PREFIX_SYS + '/BasicDataBase/Info', params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
// 查询基础数据分页
export const getBasicDataPage = async (params: any) => {
  let data: any
  const { prefer } = storeToRefs(settingStore())
  let paramName:any = digittoChinese.getParams(prefer.value.lang)
  let newParams = {...params,language:prefer.value.lang}
  let pageIndex = newParams.pageIndex
  let pageSize = newParams.pageSize
  let sort = newParams.sort
  delete newParams.pageIndex
  delete newParams.pageSize
  delete newParams.sort
  let endParams = {data:newParams,pageIndex,pageSize,sort}
  await http
    .post(HUrl.GET_BASIC_DATA_PAGE, endParams)
    .then((result: any) => {
      result.data.items.forEach((item:any)=>{
        item.badt = item[paramName]
      })
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 新增基础数据
export const addBasicData = async (params: any) => {
  let data: any
  await http
    .addPost(HUrl.ADD_BASIC_DATA, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
// 新增基础数据
export const addBasicDataBasa = async (params: any) => {
  let data: any
  await http
    .addPost(HUrl.BASE_PREFIX_SYS + '/BasicDataBase/Add', params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
// 批量添加数据
export const batchAddBasicData = async (params: any, badv: string) => {
  let data: any
  const all = params.map(async (item: any) => {
    if (!item.badv) {
      await billNo
        .getBillNo(settingStore().userInfo.companyId, badv)
        .then((result) => {
          item.badv = result
        }) + ''
      return http.post(HUrl.BASE_PREFIX_INIT_START_RESOUCE + '/basicData/addBasic', [item])
      // return http.post(HUrl.BASE_PREFIX_SYS + '/BasicData/Add', item)
    } else {
      // return http.post(HUrl.BASE_PREFIX_SYS + '/BasicData/Add', item)
      return http.post(HUrl.BASE_PREFIX_INIT_START_RESOUCE + '/basicData/addBasic', [item])
    }
  })
  const pall = Promise.all(all)
  await pall.then(() => {
    data = { code: 1 }
  })
  return data
}

// 修改基础数据
export const editBasicData = async (params: any) => {
  let data: any
  await http
    .editPost(HUrl.EDIT_BASIC_DATA, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 修改基础数据
export const batchEditBasicData = async (params: any) => {
  let data: any
  await http
    .editPost(HUrl.BATCHEDIT_BASIC_DATA, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 修改基础数据-base（系统数据字典）
export const editBasicDataBase = async (params: any) => {
  let data: any
  await http
    .editPost(HUrl.BASE_PREFIX_SYS + '/BasicDataBase/Edit', params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
// 修改基础数据
export const editBasicDataNoTips = async (params: any) => {
  let data: any
  await http
    .post(HUrl.EDIT_BASIC_DATA, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 删除基础数据
export const delBasicData = async (params: any) => {
  let data: any
  await http
    .delPost(HUrl.DEL_BASIC_DATA, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
// 删除基础数据
export const delBasicDataBase = async (params: any) => {
  let data: any
  await http
    .delPost(HUrl.BASE_PREFIX_SYS + '/BasicDataBase/Del', params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
/**
 * 通用基础数据
 * @param params
 * @returns
 */
export const basicDataSelect = (
  params: {
    bdtc: string;
    text?: string;
  },
  updateCache = false
) => {
  return new Promise((resolve: (value: any) => void) => {
    const refDictionarySelect = storeToRefs(dictionarySelect())
    const bdtc = params.bdtc
    // 有缓存取缓存里面的数据
    if (
      !updateCache &&
      bdtc &&
      refDictionarySelect.data.value[bdtc] &&
      refDictionarySelect.data.value[bdtc]?.state
    ) {
      resolve({
        code: 1,
        data: refDictionarySelect.data.value[bdtc].list,
        message: ''
      })
    } else {
      // 没有缓存 发起请求
      // 判断是否是客户端
      const { getData } = refDictionarySelect
      if (getData.value[bdtc]) {
        getData.value[bdtc].state = true
      } else {
        getData.value[bdtc] = {
          state: true,
          list: []
        }
      }

      readBaseData(params.bdtc, updateCache).then((result: any) => {
        if (result) {
          result.forEach((item: any) => {
            item.key = item.val
            item.value = item.txt
          })
          getData.value[bdtc].list = result
          resolve({
            code: 1,
            data: result
          })
        } else {
          const { prefer } = storeToRefs(settingStore())
          let paramName:any = digittoChinese.getParams(prefer.value.lang)
          http
            // .post(HUrl.BASE_PREFIX_SYS + '/BasicData/select', params)
            .post(HUrl.BASE_PREFIX_INIT_START_RESOUCE + '/basicData/queryBasicList', {...params,language:prefer.value.lang})
            .then((result: any) => {
              if (result.code === 1) {

                result.data.forEach((item: any) => {
                  item.val = item.badv
                  item.txt = item[paramName]
                  item.key = item.val
                  item.value = item.txt
                })
                // 把数据缓存
                getData.value[bdtc].list = result.data
                resolve(result)
              }
            })
            .catch((err: any) => {
              return http.catch(err)
            })
        }
      })
    }
  })
}

export const basicDataSetState = async (params: any) => {
  let data: any
  await http
    .post(HUrl.BASIC_DATA_SET_STATE, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
// 计量单位分页
export const basicDataCUGetPage = async (params: any) => {
  let data: any
  await http
    .post(HUrl.BASIC_DATA_CUGET_PAGE, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
// 新增计量单位
export const basicDataCUSave = async (params: any) => {
  let data: any
  await http
    .post(HUrl.BASIC_DATA_CUSAVE, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
export const basicDataCUPatchSave = async (params: any) => {
  let data: any
  await http
    .addPost(HUrl.BASE_PREFIX_SYS + '/BasicData/AddMultiCU', params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
// 修改计量单位数据
export const basicDataCuedit = async (params: any) => {
  let data: any
  await http
    .post(HUrl.BASIC_DATA_CUEDIT, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
// 删除计量单位数据
export const basicDataCudel = async (params: any) => {
  let data: any
  await http
    .delPost(HUrl.BASIC_DATA_CUDEL, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
// 查询计量单位单条数据
export const basicDataCugetInfo = async (params: any) => {
  let data: any
  await http
    .post(HUrl.BASIC_DATA_CUGET_INFO, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 计量单位下拉
export const basicDataCUSelect = async (params?: any) => {
  let data: any
  await http
    .post(HUrl.BASIC_DATA_CUSELECT, params)
    .then((result: any) => {
      if (result.code == 1) {
        result.data.forEach((item: { [x: string]: unknown }) => {
          item.key = item.val
          item.value = item.txt
        })
      }
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

/**
 * 下载模板
 */

// 下载字典模板
export const basicDataExport = async (params?: any) => {
  let data: any
  await http
    .getFilesExt(HUrl.BASIC_DATA_EXPORT, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
/**
 * 导入模板信息
 */
export const basicDataImport = async (params: any) => {
  let data: any
  await http
    .postFiles(HUrl.BASIC_DATA_IMPORT, {}, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
/**
 * 导入语种资源
 */
export const languageImport = async (params: any) => {
  let data: any
  await http
    .postFiles(HUrl.LANGUAGE_IMPORT, {}, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
/**
 * 弹窗---导入 导入基础数据Execl文件信息
 */
export const dialogImport = async (params: any) => {
  let data: any
  await http
    .postFiles(HUrl.BASIC_DATA_IMPORT_EXCEL, {}, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

/**
 * 下载execl模板
 */
// 下载字典模板
export const dialogBasicDataDownTemp = async (params?: any) => {
  let data: any
  await http
    .postFiles(HUrl.BASIC_DATA_DOWN_EXCEL, {}, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

/**
 * 导出excel数据
 */
export const dialogBasicDataExportTemp = async (params?: any) => {
  if (params.typeName) {
    delete params.typeName
  }
  let data: any
  await http
    .postFiles(HUrl.BASIC_DATA_EXPORT_EXCEL, {}, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

export const secTreeByParents = async (params: any) => {
  let data: any
  await http
    .get(HUrl.HTTP_BASE_URL + '/material/category/secTreeByParents', params)
    .then((result: any) => {
      if (result.code === 200) {
        result.code = 1
        let list = result.data.map((item: { children: any }) => {
          delete item.children
          return item
        })
        // list.sort((a: { id: number }, b: { id: number }) => {
        //   return a.id - b.id
        // })
        result.data.totalCount = list.length
        const startPage = (params.pageIndex - 1) * params.pageSize
        const endPage = startPage + params.pageSize
        console.log(startPage, endPage)
        result.data.items = list.slice(startPage, endPage)
      }
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })

  return data
}

export const secTreeByParentsLevel1 = async (params: any) => {
  let data: any
  await http
    .get(HUrl.HTTP_BASE_URL + '/material/category/secTreeByParents', {
      parentId: 0,
      keyword: ''
    })
    .then((result: any) => {
      if (result.code === 200) {
        result.code = 1
        let obj = result.data.find((item: { children: any; id: number }) => {
          return item.id == params.parentId
        })
        if (obj) {
          const list = obj.children.map(
            (item: { children: any; id: number }) => {
              delete item.children
              return item
            }
          )
          list.sort((a: { id: number }, b: { id: number }) => {
            return a.id - b.id
          })
          result.data.totalCount = list.length
          const startPage = (params.pageIndex - 1) * params.pageSize
          const endPage = startPage + params.pageSize
          result.data.items = list.slice(startPage, endPage)
        }
      }
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

export const getMaccPage = async (params: any) => {
  let data: any
  let obj = {
    parentId: 0,
    keyword: ''
  }
  await http
    .get(HUrl.HTTP_BASE_URL + '/material/category/secTreeByParents', { ...obj })
    .then((result: any) => {
      if (result.code === 200) {
        result.code = 1
        let resItem = result.data.find(
          (item: { id: any }) => item.id == params.id
        )
        if (resItem) {
          result.data.items = resItem.children.map(
            (item: { children: any }) => {
              delete item.children
              return item
            }
          )
        }
      }
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })

  return data
}

// 分类分层 保存更新
export const saveOrUpdate = async (params: any) => {
  let data: any
  await http
    // .addPost(HUrl.HTTP_BASE_URL + '/material/category/saveOrUpdate', params)
    .addPost(HUrl.HTTP_BASE_URL + '/material/category/updateBatch', params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
export const savePatchOrUpdate = async (params: any) => {
  let data: any
  await http
    .addPost(HUrl.HTTP_BASE_URL + '/material/category/saveBatch', params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
export const batchDeleteMatc = async (params: any) => {
  let data: any
  await http
    .delPost(HUrl.HTTP_BASE_URL + '/material/category/deleteBatch', params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
// 分类分层 批量新增
export const addSomeSaveBatch = async (params: any) => {
  let data: any
  await http
    .post(HUrl.HTTP_BASE_URL + '/material/category/addBatch', params)
    // .post(HUrl.HTTP_BASE_URL + '/material/category/saveBatch', params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
/**
 * 新增级联字典
 */
export const addCascad = async (params: any) => {
  let data: any
  await http
    .addPost(
      HUrl.BASE_PREFIX_INIT_START_RESOUCE + '/basicData/addCascad',
      params
    )
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
/**
 * 删除级联数据字典
 */
export const deleteCascad = async (params: any) => {
  let data: any
  await http
    .delPost(
      HUrl.BASE_PREFIX_INIT_START_RESOUCE + '/basicData/deleteCascad',
      params
    )
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

/**
 * 查询级联数据字典分页
 */
export const queryCascadPageList = async (params: any) => {
  if (!params.data) {
    return
  } else {
    for (const key in params.data) {
      if (Object.prototype.hasOwnProperty.call(params.data, key)) {
        const element = params.data[key]
        if (!element) {
          delete params.data[key]
        }
      }
    }
  }
  let data: any
  await http.post(HUrl.BASE_PREFIX_INIT_START_RESOUCE + '/basicData/queryCascadPageList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询级联数据字典下拉
export const queryCascadList = async (params: any) => {
  let data: any
  await http
    .post(
      HUrl.BASE_PREFIX_INIT_START_RESOUCE + '/basicData/queryCascadList',
      params
    )
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
