// 图片损坏
export default (app: any) => {
  app.directive('imgerror', (el: any, binding: any) => {
    el.onerror = function () {
      if (binding?.value) {
        // 文件限制格式
        // const fileLayout = ['bmp', 'jpg', 'png', 'tif', 'gif', 'pcx']
        // 截取文件后缀
        const index = binding.value.lastIndexOf('.')
        // 获取后缀
        const ext = binding.value.substr(index + 1)

        // if (fileLayout.indexOf(ext.toLowerCase()) === -1) {
        // 不是上面格式的图片

        // 判断本地是否有默认图片
        const fileType = ['AI', 'AVI', 'BMP', 'DOC', 'DOCX', 'EPS', 'EXE', 'FLV', 'GIF', 'JPEG', 'JPG', 'KEY', 'MOV', 'MP3', 'MP4', 'PDF', 'PNG', 'PPT', 'PPTX', 'PSD', 'RAR', 'RM', 'RP', 'SVG', 'SWF', 'TAR', 'TIF', 'TXT', 'WPS', 'XLSX', 'XMIND', 'XSL', 'ZIP']
        console.log(fileType.indexOf(ext.toLowerCase()))

        if (fileType.indexOf(ext.toUpperCase()) !== -1) {
          el.src = require(`@/assets/images/fileIcon/${ext.toUpperCase()}.png`)
        } else {
          el.src = require('@/assets/images/company/defaultFile.png')
        }
        // } else {
        //   // 是上面格式的图片
        //   el.src = binding?.value
        // }
      } else {
        el.src = require('@/assets/images/company/defaultFile.png') // binding.value 指令的值 也就是我们的defaultImg
      }
    }
  })
}
