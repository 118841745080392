// 获取hooks
import basicInfoHooks from '@/views/business/purchase/apply/hooks/basicInfo' // 基本信息
import applyListInfoHooks from '@/views/business/purchase/apply/hooks/applyListInfo' // 基本信息
import { getPageList } from '@/service/modules/purchase/applyList'
import { getInfo } from '@/service/modules/purchase/purchaseApply'

const formJson = () => {
  const { formJson } = basicInfoHooks({})
  const { columnList } = applyListInfoHooks()

  return [
    {
      title: { lank: 'purchase.basicInformation', default: '基本信息' }, // 基本和商务信息
      type: 'form', // 表单
      json: formJson,
      getInfo
    },
    {
      title: { lank: 'purchase.applyFormAttachment', default: '申请单附件' },
      type: 'files',
      code: 'PurchaseApply',
      templetCode: 'Apply'
    },
    {
      title: { lank: 'purchase.applyListInfo', default: '申请单信息' },
      type: 'table', // 表单
      formid: 'applyId', //  pid="pid"
      id: 'id', // rowId="ipid"
      columnList: columnList,
      getInfo: getPageList
    }
  ]
}
export default formJson
