import { computed } from 'vue'
import type { ColumnType } from 'types/table'
import { commonFunc } from '@/utils'

import BasicInfoHooks from '@/views/business/purchase/inquiry/hooks/basicInfo'
import InquiryListHooks from '@/views/business/purchase/inquiry/hooks/inquiryListHooks'

import { getInfo } from '@/service/modules/purchase/inquiry'
import { getPageList } from '@/service/modules/purchase/inquiryMatlList'

const formJson = () => {
  const materFn = commonFunc.backDefMaterNoComponents(
    { prop: 'unit' },
    {
      // 产品线： productLine
      // 产品分类： materialClassification
      // 产品料号：materialCode
      // 物料名称：fullNameOfMaterial
      // 规格型号：specification
      // 计量单位: unit
      productLine: {
        label: '产品线',
        title: 'purchase.productLine'
      },
      materialClassification: {
        label: '产品分类',
        title: 'purchase.productSort'
      },
      materialCode: {
        label: '物料料号',
        title: 'purchase.purchasePriceMaintenanceMaterialCode'
      },
      fullNameOfMaterial: {
        label: '物料名称',
        title: 'purchase.purchasePriceMaintenanceFullNameOfMaterial',
        disabled: true
      },
      specification: {
        label: '规格型号',
        title: 'purchase.SpecificationsAndModels',
        disabled: true
      },
      unit: {
        label: '计量单位',
        title: 'engineering.unit'
      }
    }
  )

  const basicInfoRender = BasicInfoHooks()
  let inquiryListRender = InquiryListHooks(materFn.materColumn)

  // 设置部分字段的配置
  const nameType = ['物料名称']
  inquiryListRender = inquiryListRender.map((item: ColumnType) => {
    if (nameType.includes(item.label)) {
      item.formatter = ({ fullNameOfMaterial }: {fullNameOfMaterial: string}) => {
        return fullNameOfMaterial
      }
    }
    return item
  })

  const res = computed(() => {
    return { ...basicInfoRender.companyInfo }
  })

  return [
    {
      title: { lank: 'purchase.basicInfo', default: '基本信息' },
      type: 'form',
      json: res.value,
      getInfo: getInfo,
      slot: {}
    },
    {
      title: { lank: 'sales.rfqMaterial', default: '询价物料' },
      type: 'table',
      formid: 'maintenanceId',
      id: 'id',
      columnList: inquiryListRender,
      getInfo: getPageList,
      slot: {}
    },
    {
      title: { lank: 'purchase.rfqFile', default: '询价单附件' },
      type: 'files',
      code: 'Inquiry',
      templetCode: 'Inquiry'
    }
  ]
}

export default formJson
