/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import { fileTemplate } from 'types/uploadFile'

type dataType = {
  code: number
  message: string
  data: fileTemplate
}

// 首页筛选
export const getPage = async(params:any) => {
  let data:any
  await http.post(HUrl.TEMPLATE_GETPAGE, params).then((result:any) => {
    data = result
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// 首页删除
export const del = async(params:any) => {
  let data:any
  await http.post(HUrl.TEMPLATE_DEL, params).then((result:any) => {
    data = result
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}

//  模板新增
export const infoTempSave = async(params:any) => {
  let data:any
  await http.post(HUrl.TEMPLATE_SAVE, params).then((result:any) => {
    data = result
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
//  资料模板的树形列表
export const treeDataList = async(params:any) => {
  let data:any
  await http.post(HUrl.MENU_TREEDATALIST, params).then((result:any) => {
    data = result
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}

// 通过模板页面编码查询模板
export const tempDataList = async(params:any) => {
  let data: any
  await http.post(HUrl.INFOTEMP_TEMPDATALIST, params).then((result: any) => {
    data = result
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}

// 根据id查询资料模板
export const getSingleData = async(params:any) => {
  let data = {} as dataType
  await http.post(HUrl.INFOTEMP_GETINFO, params).then((result: any) => {
    data = result
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// 查询菜单资料模板列表
export const menuinfotempList = async(params:any) => {
  let data: any
  await http.post(HUrl.MENUINFOTEMP_DATALIST, params).then((result: any) => {
    data = result
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// 获取历史版本
export const HistoryList = async(params:any) => {
  let data: any
  await http.post(HUrl.INFOTEMP_HISTORYLIST, params).then((result: any) => {
    data = result
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// 删除资料模板
// 查询菜单资料模板列表
export const menuInfoTempDel = async(params:any) => {
  let data: any // HistoryList
  await http.post(HUrl.MENU_INFO_TEMP_DEL, params).then((result: any) => {
    data = result
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
