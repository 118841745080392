import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import moment from 'moment'

// 下拉
export const Select = async (params?: any) => {
  let data: any
  await http.post(HUrl.DBSERVER_SELECT, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

