import { defineStore } from 'pinia'
import { SDivisional, SEmployee } from '@/service'
/**
 * personSelectData:{
 * personIdKey/选中的人员id:{
 *  emid/部门id: true
 * }
 * }
 * 人员在不同的部门可能有重复 所以一个人员可能对应多个部门
 */
export const personSelect = defineStore({
  id: 'personSelect',
  state: () => ({
    personData: {}, // 存储人员的数据
    personSelectData: {} as any, // 人员的选中数据
    departmentCheck: {} // 存储每一个部门中人员的选中情况 部门id:[人员id]
  }),
  getters: {

  },
  actions: {
    // 根据公司id与部门id获取人员
    getPerson(companyId:any, deid:any) {
      return new Promise((resolve:any) => {
        let personData = this.personData as any
        if (personData[deid]) {
          // 如果能拿到数据 说明有缓存数据 就从缓存中拿 否则发送请求
          resolve(personData[deid])
          return
        }
        let params = {
          'pageIndex': 1,
          'pageSize': 9999,
          'keyword': '',
          'coid': companyId,
          'deid': deid
        }
        SEmployee.initInterface(params).then(result => {
          if (result.code == 1) {
            const backData = JSON.parse(JSON.stringify(result.data.items))
            const personObj = {} as any
            if (backData && backData.length) {
              backData.forEach((element:any) => {
                element.check = false
                personObj[element.emid] = element
              })
              // 存储人员数据的长度
              personObj.length = backData.length
              console.log('personObj', personObj)

              personData[deid] = personObj
              resolve(personObj)
            }
          }
        })
      })
    },
    // 人员的选中事件 传入人员id 与部门id
    personSelect(pId:any, deId?:any) {
      if (deId) {
        if (typeof deId === 'string') {
          deId = Number(deId) ? Number(deId) : deId
        }
      }
      let personCheck = false
      // 如果传入的参数没有部门id 就记录删除人员的对应部门
      let delDeidArr = []
      let personSelectData = this.personSelectData[pId] as any
      // 如果在人员的选中数据中找到了选中数据
      if (personSelectData) {
        if (deId) {
          // 如果在选中人员中找到了对应的部门id 就说明当前人员是选中的 就需要删除id
          let selectDeid = personSelectData.indexOf(deId)
          if (selectDeid !== -1) {
            personSelectData.splice(selectDeid, 1)
            if (!personSelectData.length) delete this.personSelectData[pId]
            personCheck = false
          } else {
          // 如果没有找到 就说明是未选中的 就把当前部门id push进对应的人员选中数据中
            personSelectData.push(deId)
            personCheck = true
          }
        } else {
          // 如果没有部门id并且找到了这个人员 就删除这个人员 --- 展示选中人员名称的地方的删除事件
          delDeidArr = JSON.parse(JSON.stringify(this.personSelectData[pId]))
          delete this.personSelectData[pId]
          personCheck = false
        }
      } else {
        if (deId) {
          // 如果没有选中数据 就新增一条人员数据
          this.personSelectData[pId] = []
          this.personSelectData[pId].push(deId)
          personCheck = true
        }
      }
      // 部门的选中情况
      if (!deId) {
        // 如果没有部门id 有人员id 就删除所有门中的当前人员
        if (delDeidArr && delDeidArr.length) {
          delDeidArr.forEach((delDeid:any) => {
            let deCheckDel = this.departmentCheck[delDeid]
            let hasPer = deCheckDel.indexOf(pId)
            if (hasPer !== -1) {
              this.departmentCheck[delDeid].splice(hasPer, 1)
            }
          })
        }
        return
      }
      const deCheck = this.departmentCheck[deId]
      const hasPeo = deCheck ? deCheck.indexOf(pId) : -1
      // 如果当前是选中状态 就将人员的id push进部门数据中 否则从部门选中数据中删除
      if (personCheck) {
        if (deCheck) {
          if (hasPeo === -1) {
            deCheck.push(pId)
          }
        } else {
          // 如果该部门没有选中数据 就新增一条
          this.departmentCheck[deId] = []
          this.departmentCheck[deId].push(pId)
        }
      } else {
        if (deCheck) {
          if (hasPeo !== -1) {
            deCheck.splice(hasPeo, 1)
          }
        }
      }
      return personCheck
    },
    // 部门人员的全选和取消选中的方法
    percheckAll(deId:any, check:boolean) {
      let person = JSON.parse(JSON.stringify((this.personData[deId])))
      if (person.length) delete person.length
      let departmentCheckD = JSON.parse(JSON.stringify(this.departmentCheck))
      let personSelectDataD = JSON.parse(JSON.stringify(this.personSelectData))
      if (!person) return
      for (const key in person) {
        let item = person[key]
        if (check) {
          // 全选事件
          if (departmentCheckD[deId]) {
            if (!(departmentCheckD[deId].includes(item.emid))) {
              departmentCheckD[deId].push(item.emid)
            }
          } else {
            departmentCheckD[deId] = []
            departmentCheckD[deId].push(item.emid)
          }
          let perD = personSelectDataD[item.emid]
          if (perD && perD.length) {
            if (!perD.includes(deId)) personSelectDataD[item.emid].push(deId)
          } else {
            personSelectDataD[item.emid] = []
            personSelectDataD[item.emid].push(deId)
          }
        } else {
          delete departmentCheckD[deId]
          if (personSelectDataD[item.emid] && personSelectDataD[item.emid].length) {
            if (personSelectDataD[item.emid].includes(deId)) {
              let inIndex = personSelectDataD[item.emid].indexOf(deId)
              personSelectDataD[item.emid].splice(inIndex, 1)
              if (!personSelectDataD[item.emid].length) delete personSelectDataD[item.emid]
            }
          }
        }
      }
      this.departmentCheck = departmentCheckD
      this.personSelectData = personSelectDataD
    },
    clearCheck() {
      this.personData = {}
      this.personSelectData = {}
      this.departmentCheck = {}
    }
  },

  // 开启数据缓存  -- 需要在store/index.js中import piniaPluginPersist 插件
  persist: {
    enabled: true, // 开启持久化
    strategies: [
      {

      }
    ]
  }
})
