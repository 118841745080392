import { computed, defineAsyncComponent, ref } from 'vue'
import companyInfoHooksIndex from '@/views/business/sales/salesOrder/hooks/companyInfo'
import supplierInfoHooksIndex from '@/views/business/sales/salesOrder/hooks/customerInfo'
import orderInfoHooksIndex from '@/views/business/sales/salesOrder/hooks/orderInfo'
import orderDetailHooksIndex from '@/views/business/sales/salesOrder/hooks/orderDetail'
import payTaskHooksIndex from '@/views/business/sales/salesOrder/hooks/payTask'
import { getPageList as getPayTaskList } from '@/service/modules/sales/orderPayTask'

import { getInfo } from '@/service/modules/sales/salesOrder'
// import { getPageList } from '@/service/modules/sales/salesOrder'
import { getPageList } from '@/service/modules/sales/saleOrderList'
const formJson = () => {
  const companyInfoHooks = companyInfoHooksIndex()
  const supplierInfoHooks = supplierInfoHooksIndex()
  const orderInfoHooks = orderInfoHooksIndex()
  const payTaskHooks = payTaskHooksIndex()
  const res = computed(() => {
    return { ...companyInfoHooks.formJson, ...supplierInfoHooks.formJson, ...orderInfoHooks.orderInfo.value, ...orderInfoHooks.orderAmountInfo.value }
  })

  const orderDetailHooks = orderDetailHooksIndex(ref(''), ref(''))

  return [
    {
      title: { lank: 'administration.basicInformation', default: '基本信息' },
      type: 'form',
      json: res.value,
      getInfo: getInfo,
      slot: {
        currency: defineAsyncComponent(() => import('./componentsSlot/currency.vue'))
      }
    },
    {
      title: { lank: 'purchase.orderDetail', default: '订单详情' },
      type: 'table',
      formid: 'orderId',
      id: 'id',
      columnList: orderDetailHooks.columnList,
      getInfo: getPageList
    },
    {
      title: { lank: 'purchase.orderFiles', default: '订单附件' },
      type: 'files',
      code: 'PurchaseOrder',
      templetCode: 'PurchaseOrder'
    },
    {
      title: { lank: 'sales.derivativePayTask', default: '衍生付款任务' },
      type: 'table',
      formid: 'orderId',
      id: 'id',
      columnList: payTaskHooks.columnList,
      slot: {
        cycleType: defineAsyncComponent(() => import('./componentsSlot/cycleType.vue'))
      },
      getInfo: getPayTaskList
    }
  ]
}

export default formJson
