// 待入库页面接口

import { http } from '@/service'
import { HUrl } from '@/config'
import { commonFunc } from '@/utils'
// 根据code或id查询入库单详情 入库
const url = HUrl.BASE_PREFIX_FINISHEDWAREHOUSING // 路径前缀 + 微服务
export const queryStockIn = async(params:object = {}) => {
  let data:any
  await http.post(url + '/queryStockIn', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// 查询待入库记录-平铺
export const queryWaitingStorageList = async(params:object = {}) => {
  let data:any
  await http.post(url + '/queryWaitingStorageList', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// 待入库记录-导出-平铺
export const waitingStorageExport = async(params:object = {}) => {
  let data:any
  await http.getFilesExt(url + '/waitingStorageExport', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// 待入库记录-导出-收起
export const storageExport = async(params:object = {}) => {
  let data:any
  await http.getFilesExt(url + '/storageExport', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// storageDetailsExport 入库记录-导出-平铺
export const storageDetailsExport = async(params:object = {}) => {
  let data:any
  await http.getFilesExt(url + '/storageDetailsExport', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// stockInExport 入库记录-导出-收起
export const stockInExport = async(params:object = {}) => {
  let data:any
  await http.getFilesExt(url + '/stockInExport', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}

// 查询待入库记录-收起
export const queryStorageList = async(params:object = {}) => {
  let data:any
  await http.post(url + '/queryStorageList', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}

// 获取入库单ID 存草稿
export const getId = async(params:object = {}) => {
  let data:any
  await http.post(url + '/getId', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// 新增入库单行（草稿）
export const addRow = async(params:object = {}) => {
  let data:any
  await http.post(url + '/addRow', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// 新增入库单(提交/草稿)
export const addStockIn = async(params:object = {}) => {
  let data:any
  await http.post(url + '/addStockIn', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// 删除入库单行
export const deleteStorageDetails = async(params:object = {}) => {
  let data:any
  await http.delPost(url + '/deleteStorageDetails', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// 删除入库单
export const deleteStockIn = async(params:object = {}) => {
  let data:any
  await http.delPost(url + '/deleteStockIn', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// 更新入库单行
export const updateRow = async(params:object = {}) => {
  let data:any
  await http.post(url + '/updateRow', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// 导出excel入库单行
export const rowExport = async(params:object = {}) => {
  let data:any
  await http.getFiles(url + '/rowExport', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// 导出excel模板入库单行
export const rowTemplate = async(params:object = {}) => {
  let data:any
  await http.getFiles(url + '/rowTemplate', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// 导入excel入库单行
export const rowImport = async(file: any) => {
  let data:any
  await http.postFiles(url + '/rowImport', {}, file).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// 正式提交与保存草稿接口
export const addBill = async(params:object = {}) => {
  let data:any
  await http.post(url + '/addBill', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
export const addBillDraft = async(params:object = {}) => {
  let data:any
  await http.post(url + '/addBillDraft', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
export const sourceQueryPageList = async(params:object = {}) => {
  let data:any
  await http.post(url + '/sourceOrderList/queryPageList', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}

// --------------------------
// storageSourceDocList 查询入库单关联源单
export const storageSourceDocList = (params:object = {}) => {
  let data:any
  return new Promise((resolve:any) => {
    http.post(url + '/storageSourceDocList', params).then((res:any) => {
      if (res && res.code === 1) {
        const back = JSON.parse(JSON.stringify(res))
        let filterData = {
          typeOfMaterial: 'materialClass',
          rejectionRate: (key:string) => {
            if (key) {
              return Number(key) * 100 + '%'
            }
            return key
          }
        }
        commonFunc.keyToVal(back.data.items, filterData).then((backData) => {
          back.data.items = backData
          data = back
          resolve(data)
        })
      }
    }).catch((err:any) => {
      return http.catch(err)
    })
  })
}

// 入库记录list 平铺
export const queryStorageDetailsList = async (params: object = {}) => {
  let data: any
  await http.post(url + '/queryStorageDetailsList', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// 入库记录list 收起
export const queryStockInList = async(params:object = {}) => {
  let data:any
  await http.post(url + '/queryStockInList', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// 查询入库单货品信息
export const queryProductInformationList = async(params:object = {}) => {
  let data:any
  await http.post(url + '/queryProductInformationList', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// 查询仓库库存
export const queryInventoryList = async(params:object = {}) => {
  let data:any
  await http.post(url + '/queryInventoryList', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// /warehousing/counterReview
// 反审
export const putInStorageReview = async (params : any) => {
  let data: any
  await http.post(url + '/counterReview', params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// /warehousing/revoke
// 撤回
export const putInStorageRevoke = async (params : any) => {
  let data: any
  await http.post(url + '/revoke', params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
