import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import { apiType } from 'types/commonresource'

const url = HUrl.BASE_PREFIX_INIT_WORKFLOW // 路径前缀 + 微服务

// 查询单个流程
export const deploymentQueryProcess = async (params: object) => {
  let data = {} as apiType
  await http.post(url + '/deployment/queryProcess', params).then((result: apiType) => {
    data = result
  })
  return data
}

// 查询流程分页
export const getPageList = async (params: object) => {
  let data = {} as apiType
  await http.post(url + '/deployment/queryProcessPageList', params).then((result: apiType) => {
    data = result
  })
  return data
}

//  查询流程历史版本分页
export const queryProcessHisPageList = async (params: object) => {
  let data = {} as apiType
  await http.post(url + '/deployment/queryProcessHisPageList', params).then((result: apiType) => {
    data = result
  })
  return data
}

// 新增流程
export const deploymentAddProcess = async (params: object) => {
  let data = {} as apiType
  await http.post(url + '/deployment/addProcess', params).then((result: apiType) => {
    data = result
  })
  return data
}

// 更新流程
export const deploymentUpdateProcess = async (params: object) => {
  let data = {} as apiType
  await http.post(url + '/deployment/updateProcess', params).then((result: apiType) => {
    data = result
  })
  return data
}

// 删除流程
export const deploymentDeleteProcess = async (params: object) => {
  let data = {} as apiType
  await http.delPost(url + '/deployment/deleteProcess', params).then((result: apiType) => {
    data = result
  })
  return data
}

// 激活流程
export const deploymentActivation = async (params: object) => {
  let data = {} as apiType
  await http.post(url + '/deployment/activation', params).then((result: apiType) => {
    data = result
  })
  return data
}

// 部署流程XML
export const deploymentDeploy = async (params: object) => {
  let data = {} as apiType
  await http.post(url + '/deployment/deploy', params).then((result: apiType) => {
    data = result
  })
  return data
}

// 挂起流程
export const deploymentSuspend = async (params: object) => {
  let data = {} as apiType
  await http.post(url + '/deployment/suspend', params).then((result: apiType) => {
    data = result
  })
  return data
}

// 切换流程版本
export const deploymentSwitchVersion = async (params: object) => {
  let data = {} as apiType
  await http.post(url + '/deployment/switchVersion', params).then((result: apiType) => {
    data = result
  })
  return data
}

// 查询流程模板分页
export const queryTemplatePageList = async (params: object) => {
  let data = {} as apiType
  await http.post(url + '/deployment/queryTemplatePageList', params).then((result: apiType) => {
    data = result
  })
  return data
}

// 新增流程模板
export const addTemplate = async (params: object) => {
  let data = {} as apiType
  await http.post(url + '/deployment/addTemplate', params).then((result: apiType) => {
    data = result
  })
  return data
}
// 更新流程模板
export const updateTemplate = async (params: object) => {
  let data = {} as apiType
  await http.post(url + '/deployment/updateTemplate', params).then((result: apiType) => {
    data = result
  })
  return data
}
// 删除流程模板
export const deleteTemplate = async (params: object) => {
  let data = {} as apiType
  await http.delPost(url + '/deployment/deleteTemplate', params).then((result: apiType) => {
    data = result
  })
  return data
}
// 校验待办
export const checkTodo = async (params: object) => {
  const data = await http.post(url + '/workflow/checkTodo', params)
  return data
}
// 校验待办
export const checkTodoList = async (params: object) => {
  const data = await http.post(url + '/workflow/checkTodoList', params)
  return data
}
// 完成任务节点
export const completeTask = async (params: object) => {
  const data = await http.post(url + '/workflow/completeTask', params)
  return data
}
// 完成一组任务
export const completeTaskList = async (params: object) => {
  const data = await http.post(url + '/workflow/completeTaskList', params)
  return data
}

// 查询工作流日志
export const workflowSog = async (params: object) => {
  const data = await http.post(url + '/workflow/log', params)
  return data
}

// 启动一个工作流
export const workflowStart = async (params: object) => {
  const data = await http.post(url + '/workflow/start', params)
  return data
}
// 校验反审
export const checkRollbackApprove = async (params: object) => {
  const data = await http.post(url + '/workflow/checkRollbackApprove', params)
  return data
}
// 校验撒消
export const checkCancel = async (params: object) => {
  const data = await http.post(url + '/workflow/checkCancel', params)
  return data
}

