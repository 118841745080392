import { computed, defineAsyncComponent } from 'vue'

import basicInfoHooks from '@/views/business/production/picking/hooks/basicInfo'
import productionMaterialRequisitionListHooks from '@/views/business/production/picking/hooks/productionMaterialRequisitionList'
import { getInfo } from '@/service/modules/production/picking'

const formJson = () => {
  const basicInfo = basicInfoHooks()
  const res = computed(() => {
    return { ...basicInfo.formJson }
  })

  const productionMaterialRequisitionList = productionMaterialRequisitionListHooks()
  return [
    {
      title: { lank: 'production.basicInfo', default: '基本信息' },
      type: 'form',
      json: res.value,
      getInfo: getInfo
    },
    {
      title: {
        lank: 'production.productionMaterialRequisitionList',
        default: '领取物料列表'
      },
      type: 'table',
      formid: 'id',
      id: 'id',
      columnList: productionMaterialRequisitionList.columnList,
      getInfo: getInfo
    }
  ]
}

export default formJson
