import { computed, defineAsyncComponent } from 'vue'

import basicInfoHooks from '@/views/business/production/bom/hooks/basicInfo'
import productionBomDetailsHooks from '@/views/business/production/bom/hooks/productionBomDetailsHooks'
import productionBomMaterialHooks from '@/views/business/production/bom/hooks/productionBomMaterialHooks'
import { queryPageList } from '@/service/modules/production/productionBomMaterial'
import { getInfo, bomDetailsQueryPageList } from '@/service/modules/production/bom'

const formJson = () => {
  const basicInfo : any = basicInfoHooks({})
  basicInfo.formJson.value.unit.slot = 'unit'
  basicInfo.formJson.value.companyName.slot = 'companyName'
  basicInfo.formJson.value.status.slot = 'status'
  basicInfo.formJson.value.companyId.slot = 'companyId'
  delete basicInfo.formJson.value.useQuantity
  const res = computed(() => {
    return {
      ...basicInfo.formJson.value
    }
  })

  const productionBomDetails = productionBomDetailsHooks()
  const productionBomMaterial = productionBomMaterialHooks()
  return [
    {
      title: { lank: 'production.essentialInfo', default: '订单需求信息' },
      type: 'form',
      json: res.value,
      slot: {
        ...(() => {
          let obj = {}
          for (const key in res.value) {
            const item = res.value[key]
            if (item.slot) {
              obj[key] = defineAsyncComponent(() => import('./componentsSlot/bomInfo.vue'))
            }
          }
          return obj
        })()
      },
      getInfo: getInfo
    },
    {
      title: { lank: 'production.bomDetailed', default: 'BOM明细' },
      type: 'table',
      json: res,
      formid: 'bomInfoId',
      id: 'id',
      columnList: productionBomDetails.columnList.value,
      getInfo: bomDetailsQueryPageList
    },
    {
      title: { lank: 'production.materialDetailed', default: '物料明细' },
      type: 'table',
      json: res,
      formid: 'bomInfoId',
      id: 'id',
      columnList: productionBomMaterial.columnList.value,
      getInfo: queryPageList
    }
  ]
}

export default formJson
