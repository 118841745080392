import { defineStore } from "pinia"
import store from "../init"
const useTaskStore = defineStore({
  id: "useTaskStore",
  state: () => ({
    customs:''
  }),
  getters: {
    getCustoms: (state) => {
      let list = state.customs.split(',')
      console.log(list)
      return list
    },
  },
  actions: {
    setCustoms(customs: any) {
      this.customs = customs.join(',')
    },
  },

  // 开启数据缓存  -- 需要在store/index.js中import piniaPluginPersist 插件
  persist: {
    enabled: true, // 开启持久化
    strategies: [
      {
        storage: localStorage, // 可以为localStorage或者sessionStorage
        // 可以通过paths指定需要持久化的值，其他没有指定的则不会持久化
        paths: [
            'customs'
        ]
      }
    ]
  }
})

export default useTaskStore
