/* eslint-disable @typescript-eslint/no-explicit-any */
// 物料编码接口
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

const url = HUrl.BASE_PREFIX_INIT_START_RESOUCE
// 设置模版信息
export const setTemplate = async (params: any) => {
  let data: any
  await http.post(url + '/billcode/drule/initTemplate', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询模版信息
export const tmpQuerySingle = async (params: any) => {
  let data: any
  await http.post(url + '/template/querySingle', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 设置模版信息
export const submitTemplate = async (params: any) => {
  let data: any
  await http.post(url + '/template/submit', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
//  获取公司单据编码
export const getBillNo = async (params : any) => {
  let data: any
  await http.post(HUrl.GET_BILL_NO, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询编码规则
export const getDocumentRulesDetail = async (params : any) => {
  let data: any
  await http.post(HUrl.DOCUMENT_RULES_DETAIL, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询编码规则分页列表
export const getDocumentRulesPage = async (params : any) => {
  let data: any
  await http.post(HUrl.DOCUMENT_RULES_PAGE, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 新增编码规则
export const getDocumentRulesSave = async (params : any) => {
  let data: any
  await http.addPost(HUrl.DOCUMENT_RULES_SAVE, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 修改编码规则
export const getDocumentRulesEdit = async (params : any) => {
  let data: any
  await http.editPost(HUrl.DOCUMENT_RULES_EDIT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 删除编码规则
export const getDocumentRulesDel = async (params : any) => {
  let data: any
  await http.delPost(HUrl.DOCUMENT_RULES_DEL, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询物料规则特性子项分页列表
export const getMaterialRulesFeaturesItem = async (params : any) => {
  let data: any
  await http.post(HUrl.MATERIAL_RULES_FEATURES_ITEM, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 新增编物料规则特性子项
export const materialRulesFeaturesItemAdd = async (params : any) => {
  let data: any
  await http.addPost(HUrl.MATERIAL_RULES_FEATURES_ITEM_ADD, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 修改物料规则特性子项
export const materialRulesFeaturesItemEdit = async (params : any) => {
  let data: any
  await http.editPost(HUrl.MATERIAL_RULES_FEATURES_ITEM_EDIT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除物料规则特性子项
export const materialRulesFeaturesItemDel = async (params : any) => {
  let data: any
  await http.delPost(HUrl.MATERIAL_RULES_FEATURES_ITEM_DEL, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
