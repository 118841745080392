import { number } from 'echarts'
import { defineStore } from 'pinia'
import store from '../init'

export const useWarehouseStore = defineStore({
  id: 'warehouseStore',
  state: () => ({
    // 仓库列表选中的一项
    warehouseListItem: {},
    // 当前仓库信息
    warehouseInfo: {
      'id': '84a8738811bf42cb9a3b38b5772fcd74',
      'updateUser': '',
      'updateTime': '2000-01-02 02:00:00',
      'createUser': '89bd7defc54f453390b3a398b92a8be8',
      'warehouseCreateTime': '2022-11-14 17:22:38',
      'companyId': '381de13915b543e1a706168780a7e909',
      'warehouseCode': '101010101010',
      'warehouseType': '1',
      'categoryId': 'e5325e1123104c1fa1f6a78e3ca4954b',
      'warehouseStatus': '1',
      'materialOwnership': '1',
      'ownerId': '',
      'warehouseName': '智能仓库31',
      'storageTemperature': '10℃-20℃',
      'storeHumidity': '80%-90%',
      'warehouseAddress': '仓库地址1',
      'warehouseLocation': '仓库门牌号',
      'lastUseTime': '2022-11-09 00:00:00',
      'lastCountingTime': '2022-11-09 00:00:00',
      'remark': '备注',
      'numberPartition': null,
      'numberStorageLocation': null,
      'numberLibraryNo': null,
      'supervisor': [
        '3b6e5d05a0f84b769434990ad54e79ff',
        '5e31d12e8a634e81a99cc16f54eb9400'
      ],
      'director': [
        '5e31d12e8a634e81a99cc16f54eb94e0',
        '6e70e867d241440ba0814c2bd26e4c37'
      ]
    }

  }),
  getters: {
    getWarehouseInfo: (state) => {
      return state.warehouseInfo
      // return (key:string) => state[key]
    }
  },
  actions: {
    setWarehouseInfo(data: any) {
      // this.warehouseInfo = data // 仓库信息暂时写死----仓库未对接非简写字段接口
    }
  },
  persist: {
    enabled: true, // 开启持久化
    strategies: [
      {
        // key: 'token', //自定义Key，也可以去掉，使用默认值为参数id的值
        storage: localStorage, // 可以为localStorage或者sessionStorage
        // 可以通过paths指定需要持久化的值，其他没有指定的则不会持久化
        paths: ['warehouseListItem', 'warehouseInfo']
      }
    ]
  }
})

interface unitType {
  cret: string
  creu: string
  cuid: number
  cutc: string
  rema: string
  unic: string
  unin: string
  updt: string
  updu: string
}
export const useUnit = defineStore({
  id: 'unit',
  state: () => ({
    unit: [] as unitType[]
  }),
  actions: {
    setUnit(val: unitType[]) {
      this.unit = val
    }
  }
})

