/* eslint-disable @typescript-eslint/no-explicit-any */
// 员工接口
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import { apiType } from 'types/commonresource'
const url = HUrl.BASE_PREFIX_INIT_ADMIN

// 个人信息
export const getEmployeeInfo = async (params: any) => {
  let data: any
  await http.post(url + '/employee/employeeInfo', params)
    .then((result: any) => {
      data = result
    }).catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 查询员工列表数据
export const initInterface = async (params: any) => {
  let data: any
  await http.post(HUrl.EMPLOYEE_INFORMATION, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 员工草稿信息
export const employeeSaveDraft = async (params: any) => {
  let data = {} as apiType
  await http.post(HUrl.EMPLOYEE_INFORMATION_SAVE_DRAFT, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 保存员工信息
export const employeeSubmit = async (params: any) => {
  let data = {} as apiType
  await http.post(HUrl.EMPLOYEE_INFORMATION_SUBMIT, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 删除单个员工信息
export const delInterface = async (params: any) => {
  let data: any
  await http.delPost(HUrl.EMPLOYEE_INFORMATION_DEL, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询员工详情
export const getInfoInterface = async (params: any) => {
  let data: any
  await http.post(HUrl.EMPLOYEE_INFORMATION_GETIFO, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询员工详情
export const querySingle = async (params: any) => {
  let data: any
  await http.post(url + '/employee/querySingle', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询员工详情和账户
export const getEmployeeAcc = async (params: any) => {
  let data: any
  await http.post(url + '/employee/getEmployeeAcc', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 获取员工下拉组件数据-1
export const selectInfoInterface = async (params?: any) => {
  if (params.type) {
    // 查询参数type修改为scene - 付工
    params.scene = params.type
    delete params.type
  }
  let data: any
  if (params && params.type) {
    params.scene = params.type
  }
  await http.post(HUrl.EMPLOYEE_INFORMATION_SELECT, params).then((result: any) => {
    const res = result.data.map((item: any) => {
      item.text = item.txt // 添加工号字段
      return { ...item }
    })
    result.data = res
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 获取全部员工数据
export const employeeDictionary = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_COMPANY + '/Employee/EmployeeDictionary', params).then((result: any) => {
    const res = result.data.map((item: any) => {
      item.txt = item.empn
      item.text = item.empn
      item.value = item.empn
      item.key = item.emid
      item.val = item.emid
      return { ...item }
    })
    result.data = res
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 导出员工信息
export const exportInfoInterface = async (params?: any) => {
  let data: any
  await http.getFilesExt(HUrl.EMPLOYEE_INFORMATION_EXPORT, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 下载员工导入模板
export const employeeInformationDownloadtemp = async (params?: any) => {
  let data: any
  await http.getFiles(HUrl.EMPLOYEE_INFORMATION_DOWNLOADTEMP, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 导入员工信息
export const employeeInformationImport = async (params?: any) => {
  let data: any
  await http.postFiles(HUrl.EMPLOYEE_INFORMATION_IMPORT, {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 保险
// 查询员工保险信息详情
export const getInsuranceInfo = async (params: any) => {
  let data: any
  await http.post(HUrl.EMPLOYEE_GET_INSURANCE_INFO, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询员工保险信息分页列表
export const getInsurancePage = async (params: any) => {
  let data: any
  await http.post(HUrl.EMPLOYEE_GET_INSURANCE_PAGE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 新增员工保险信息
export const saveInsurance = async (params: any) => {
  let data: any
  await http.post(HUrl.EMPLOYEE_SAVE_INSURANCE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 修改员工保险信息
export const editInsurance = async (params: any) => {
  let data: any
  await http.post(HUrl.EMPLOYEE_EDIT_INSURANCE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除员工保险信息
export const delInsurance = async (params: any) => {
  let data: any
  await http.delPost(HUrl.EMPLOYEE_DEL_INSURANCE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 下载员工保险导入模板
export const downloadInsuranceTemp = async (params?: any) => {
  let data: any
  await http.getFiles(HUrl.EMPLOYEE_DOWNLOAD_INSURANCE_TEMP, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 导入员工保险信息
export const importInsurance = async (params: any) => {
  let data: any
  await http.postFiles(HUrl.EMPLOYEE_IMPORT_INSURANCE, {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 导出员工保险信息
export const exportInsurance = async (params?: any) => {
  let data: any
  await http.getFilesExt(HUrl.EMPLOYEE_EXPORT_INSURANCE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询员工紧急联系人信息
export const getEmerContactInfo = async (params: any) => {
  let data: any
  await http.post(HUrl.EMPLOYEE_GET_EMER_CONTACT_INFO, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询员工紧急联系人信息分
export const getEmerContactPage = async (params: any) => {
  let data: any
  await http.post(HUrl.EMPLOYEE_GET_EMER_CONTACT_PAGE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 新增员工紧急联系人信息
export const saveEmerContact = async (params: any) => {
  let data: any
  await http.post(HUrl.EMPLOYEE_SAVE_EMER_CONTACT, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 修改员工紧急联系人信息
export const editEmerContact = async (params: any) => {
  let data: any
  await http.post(HUrl.EMPLOYEE_EDIT_EMER_CONTACT, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除员工紧急联系人
export const delEmerContact = async (params: any) => {
  let data: any
  await http.delPost(HUrl.EMPLOYEE_DEL_EMER_CONTACT, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 下载员工紧急联系人导入模
export const downloadEmerContactTemp = async (params?: any) => {
  let data: any
  await http.getFiles(HUrl.EMPLOYEE_DOWNLOAD_EMER_CONTACT_TEMP, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 导入员工紧急联系人信息
export const importEmerContact = async (params: any) => {
  let data: any
  await http.postFiles(HUrl.EMPLOYEE_IMPORT_EMER_CONTACT, {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 导出员工紧急联系人信息
export const exportEmerContact = async (params?: any) => {
  let data: any
  await http.getFilesExt(HUrl.EMPLOYEE_EXPORT_EMER_CONTACT, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询员工工资账户信息
export const getPayRollAccInfo = async (params: any) => {
  let data: any
  await http.post(HUrl.EMPLOYEE_GET_PAY_ROLL_ACC_INFO, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询员工紧急联系人信息分页
export const getPayRollAccPage = async (params: any) => {
  let data: any
  await http.post(HUrl.EMPLOYEE_GET_PAY_ROLL_ACC_PAGE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 新增员工工资账户信息
export const savePayRollAcc = async (params: any) => {
  let data: any
  await http.addPost(HUrl.EMPLOYEE_SAVE_PAY_ROLL_ACC, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 修改员工工资账户信息
export const editPayRollAcc = async (params: any) => {
  let data: any
  await http.editPost(HUrl.EMPLOYEE_EDIT_PAY_ROLL_ACC, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 员工工资账户设置状态
export const payRollAccSetState = async (params: any) => {
  let data: any
  await http.post(HUrl.EMPLOYEE_PAY_ROLL_ACC_SET_STATE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除员工工资账户
export const delPayRollAcc = async (params: any) => {
  let data: any
  await http.delPost(HUrl.EMPLOYEE_DEL_PAY_ROLL_ACC, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 下载员工工资账户导入模板
export const downloadPayRollAccTemp = async (params?: any) => {
  let data: any
  await http.getFiles(HUrl.EMPLOYEE_DOWNLOAD_PAY_ROLL_ACC_TEMP, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导入员工工资账户信息
export const importPayRollAcc = async (params: any) => {
  let data: any
  await http.postFiles(HUrl.EMPLOYEE_IMPORT_PAY_ROLL_ACC, {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导出员工工资账户信息
export const exportPayRollAcc = async (params?: any) => {
  let data: any
  await http.getFilesExt(HUrl.EMPLOYEE_EXPORT_PAY_ROLL_ACC, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 获取员工汇总
export const getListSummary = async (params?: any) => {
  let data: any
  await http.post(HUrl.GET_LIST_SUMMARY, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

