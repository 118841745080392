import { createRouter, createWebHashHistory } from 'vue-router'
import { colList } from '@/store/modules/colList'

import { routes, existWhite, whiteNameList } from './modules/routes'
import { ElMessage } from 'element-plus'
import { cache, setting, pullDownData } from '@/utils'
import { G } from '@/config'
import moment from 'moment'
import { componentRouter } from '@/router/modules/components'
import i18n from '@/i18n'
import { relogin } from '@/utils/cefSharpFun/index'

type routeType = {
  pname: string,
  name: string,
  issh: string,
  path: string,
  meta: { title: string, highlight: string },
  type: string,
  pathname: string,
  children: routeType[]
}

let router = createRouter({
  history: createWebHashHistory(),
  routes
})

// 清空路由的方法
export const resetRouter = () => {
  // 获取所有路由
  router.getRoutes().forEach((route) => {
    const { name } = route // 获取路由name 没有name的不做操作
    if (name && !whiteNameList.includes(name as string)) { // 路由不属于白名单,则删除
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export default router

// 菜单数据格式转换
const getRouts = (data: routeType[], pathname: string) => {
  const parentKey: routeType[] = []
  data.forEach((item: routeType) => {
    if (pathname != null) {
      item.pathname = pathname
    }
    if (item.path && item.path.length > 0) {
      parentKey.push(item)
    }
    if (item.children && item.children.length > 0) {
      const temp = getRouts(item.children, item.name)
      if (temp.length) {
        parentKey.push(...temp)
      }
    }
  })
  return parentKey
}

/**
 * 递归获取上级菜单
 * @param obj 平铺的菜单
 * @param name 需要匹配的父级name
 * @returns
 */
const getPathName: (obj: routeType[], name: string) => string | null = (obj: routeType[], name: string) => {
  const index = obj.findIndex(item => item.name === name)
  if (index !== -1) {
    if (obj[index].pname) {
      const pname = getPathName(obj, obj[index].pname)
      return pname
    } else {
      return obj[index].name
    }
  }
  return null
}

// 递归获取 父级路径实现表格高亮
const getHighlight = (obj: routeType[], name: string) => {
  const res = obj.filter((item) => {
    return item.name === name
  })
  return res[0].path
}

// JS判断数据格式是否为json
const isJson = function (str: string) {
  if (typeof str == 'string') {
    try {
      let obj = JSON.parse(str)
      if (typeof obj == 'object' && obj) {
        return true
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }
}

// 导航路由守卫
router.beforeEach((to, from, next) => {
  try {
    if (to.query && to.query.name) {
      // const { t } = useI18n()
      const t = i18n.global.t
      let title = ''
      let defaultTitle = to.query.title as string
      if (defaultTitle) {
        title = t(`menu.${to.query.name}.key`, defaultTitle)
      }
      document.title = '管管系统 - ' + title
    } else {
      const title = to.meta && to.meta.title
      if (title) {
        document.title = '管管系统 - ' + title
      }
    }

    // 路由在白名单里面
    if (existWhite(to.path)) {
      // 登录页
      if (to.path === '/login') {
        const oldToken: string = cache.getLocalStorage(G.AUTHORIZATION_TOKEN)
        if (oldToken) {
          const tokenObj = JSON.parse(oldToken)
          // 有token
          if (tokenObj?.token) {
            next(from.path || '/')
          } else {
            // 没有token或token格式不正确 清空 放行
            cache.setLocalStorage('token', '')
            next()
          }
        } else {
          // 登录页没token直接放行
          next()
        }
      } else {
        // 白名单直接放行
        next()
      }
    } else {
      const oldToken: string = cache.getLocalStorage(G.AUTHORIZATION_TOKEN)

      // 判断token格式是否正确
      const res = isJson(oldToken)

      if (!oldToken || !res) {
        console.log('判断token格式是否正确')
        relogin(() => {
          // 清除数据
          cache.setLocalStorage('userInfo', '')
          cache.setLocalStorage('menu', '')
          cache.setLocalStorage('loginCMC', '')
          cache.setLocalStorage('token', '')
          next(G.LOGIN_URL)
        })
        return
      }
      let token: string = JSON.parse(oldToken).token
      let date: string = JSON.parse(oldToken).date
      let time: number = JSON.parse(oldToken).time

      // 如果token或userInfo为空、null、{}则跳转到指定login页面进行登录
      if (!token) {
        // 保存我们所在的位置，以便以后再来

        setting.setOldPath(from.fullPath)

        // 清除数据
        cache.setLocalStorage('userInfo', '')
        cache.setLocalStorage('menu', '')
        cache.setLocalStorage('loginCMC', '')
        next(G.LOGIN_URL)
      } else {
        // 判断上次登录时间
        if (moment(new Date()).diff(moment(date), 'days') > +time) {
          // 登录超时
          cache.setLocalStorage('userInfo', '')
          cache.setLocalStorage('menu', '')
          cache.setLocalStorage('loginCMC', '')
          cache.setLocalStorage('token', '')
          setting.setOldPath(to.fullPath)
          ElMessage.error('登录超时')
          next(G.LOGIN_URL)
        } else {
          setting.setOldPath(from.fullPath)
          const colListStore = colList()

          // 路由跳转调用获取版本接口
          colListStore.getSystemVersion(to?.name)
          // .then(() => {
          pullDownData.setData()
          // setting.setMenuList()
          setting.setMenuList().then(result => {
            // console.log('菜单源数据', result)

            // 转换菜单数据格式 一维数组
            const arr = getRouts(result, '')
            console.log('一维数组菜单', arr)

            // 判断是否有菜单
            if (arr && arr.length > 0) {
              arr.forEach((item: routeType) => {
                // item.issh 为0 不显示的菜单不添加路由
                if (componentRouter[item.name] && item.issh) {
                  // if (componentRouter[item.name]) {
                  const routerData = {
                    name: item.name,
                    path: item.path,
                    meta: item.meta,
                    issh: item.issh,
                    component: componentRouter[item.name],
                    redirect: ''
                  }
                  if (componentRouter[item.name]?.name === 'Layout') {
                    routerData.redirect = '/home'
                  }
                  if (item.pname === 'Buglist') {
                    console.log('BuglistBuglistBuglist')
                  }
                  // 判断父级
                  if (item.pname) {
                    // 判断是否是 20(列表页) 25(表单页) 需要寻找父级模块
                    if ([25, 20].includes(+item.type)) {
                      // 表单页面父级显示提问  获取上一级的父级
                      const pname = getPathName(arr, item.pname)
                      if (pname) {
                        // 表单页面获取父级高亮
                        if (+item.type === 25) {
                          const highlight = getHighlight(arr, item.pname)
                          routerData.meta.highlight = highlight
                        }

                        router.addRoute(pname, routerData)
                      }
                    } else {
                      // 添加到路由
                      router.addRoute(item.pname, routerData)
                    }
                  } else {
                    router.addRoute(routerData)
                  }
                } else if (!componentRouter[item.name]) {
                  console.log('有资源未配置页面', item)
                }
              })
              next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
            } else {
              // 没有路由跳转404
              if (to.matched.length === 0) {
                ElMessage.error('找不到路由-NotFound Router...')
                next(G.NOTFOUND_URL)
              } else {
                next()
              }
            }
          })
          // })
        }
      }
    }
  } catch (error) {
    throw error
  }
})

// 路由跳转后钩子函数中 - 执行进度条加载结束
router.afterEach((to, from, next) => {
  //
})

