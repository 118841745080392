/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import { apiType } from 'types/commonresource'
const url = HUrl.BASE_PREFIX_SALE // 路径前缀 + 微服务

// 获取表格分页数据
export const getPageList = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/deliverAdvice/queryRetractPage', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 分页平铺查询
export const queryTilePageList = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/deliverAdvice/queryTilePageList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取单个详情
export const getInfo = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/deliverAdvice/querySingle', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 统计源单
export const getSourceCount = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/deliverAdvice/getSourceCount', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取实时汇率
export const getReceRateApi = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/deliverAdvice/getReceRateApi', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 保存草稿
export const saveDraft = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/deliverAdvice/save', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 提交
export const submit = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/deliverAdvice/submit', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 删除 delete
export const del = async (params: any) => {
  let data = {} as apiType
  await http.delPost(url + '/deliverAdvice/deleteList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导入
export const importFiles = async (query:any, params: any) => {
  let data = {} as apiType
  await http.postFiles(url + '/deliverAdvice/import', query, params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出
export const exportFiles = async (params?: any) => {
  let data = {} as apiType
  await http.javaDl(url + '/deliverAdvice/export', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出模板
export const exportFilesTmpl = async (params?: any) => {
  let data = {} as apiType
  await http.getFiles(url + '/deliverAdvice/template', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

/** 审批流程——撤回 */
export const revoke = async (params: any) => {
  let data: any
  await http.post(url + '/deliverAdvice/revoke', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

/** 审批流程——反审 */
export const counterReview = async (params: any) => {
  let data: any
  await http.post(url + '/deliverAdvice/counterReview', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
/** 子表 */
// 分页查询
export const getPageListDetail = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/deliverAdviceList/queryPageList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 保存
export const saveDetail = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/deliverAdviceList/save', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 删除
export const delDetail = async (params: any) => {
  let data = {} as apiType
  await http.delPost(url + '/deliverAdviceList/deleteList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导入
export const importFilesDetail = async (params: any) => {
  let data = {} as apiType
  await http.postFiles(url + '/deliverAdviceList/import', {}, params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出
export const exportFilesDetail = async (params?: any) => {
  let data = {} as string
  await http.getFilesExt(url + '/deliverAdviceList/export', params).then((result: string) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出模板
export const exportFilesTmplDetail = async (params?: any) => {
  let data = {} as string
  await http.getFiles(url + '/deliverAdviceList/template', params).then((result: string) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

