/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

const url = HUrl.BASE_PREFIX_WAREHOUSING // 路径前缀 + 微服务

// 审批
export const approve = async (params: any) => {
  let data = {} as any
  await http.post(url + '/shelfBoundWarehouse/approve', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 撤销
export const cancel = async (params: any) => {
  let data = {} as any
  await http.post(url + '/shelfBoundWarehouse/cancel', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 获取下拉
export const queryList = async (params?: any) => {
  let data = {} as any
  await http.post(url + '/shelfBoundWarehouse/queryList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 获取表格分页数据
export const getPageList = async (params: any) => {
  let data = {} as any
  await http.post(url + '/shelfBoundWarehouse/queryPageList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取单个详情
export const getInfo = async (params: any) => {
  let data = {} as any
  await http.post(url + '/shelfBoundWarehouse/querySingle', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 保存草稿
export const saveDraft = async (params: any) => {
  let data = {} as any
  await http.post(url + '/shelfBoundWarehouse/save', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 提交
export const submit = async (params: any) => {
  let data = {} as any
  await http.post(url + '/shelfBoundWarehouse/submit', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 删除
export const del = async (params: any) => {
  let data = {} as any
  await http.delPost(url + '/shelfBoundWarehouse/deleteList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导入
export const importFiles = async (params: any) => {
  let data = {} as any
  await http.postFiles(url + '/shelfBoundWarehouse/import', {}, params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出
export const exportFiles = async (params?: any) => {
  let data = {} as any
  await http.getFilesExt(url + '/shelfBoundWarehouse/export', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出模板
export const exportFilesTmpl = async (params?: any) => {
  let data = {} as any
  await http.getFiles(url + '/shelfBoundWarehouse/template', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 解除绑定
export const unbind = async (params: any) => {
  let data = {} as any
  await http.post(url + '/shelfBoundWarehouse/unbind', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
