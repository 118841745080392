import moment from 'moment'
import cache from './cache'

type optionsType = {
  content: string,
  width: number,
  height: number,
  font: string,
  color: string,
  rotateDegree: number,
  x: number,
  y: number,
}

// 生成图片
function createImgBase(options: optionsType) {
  const canvas = document.createElement('canvas')
  const text = options.content
  // 因为要实现文字交错效果，所以这里生成两倍宽度的图片
  canvas.width = options.width * 2
  canvas.height = options.height
  const ctx = canvas.getContext('2d')
  if (ctx) {
    // X轴阴影距离，负值表示往上，正值表示往下
    // ctx.shadowOffsetX = 2;
    // // Y轴阴影距离，负值表示往左，正值表示往右
    // ctx.shadowOffsetY = 2;
    // // 阴影的模糊程度
    // ctx.shadowBlur = 2
    ctx.font = options.font
    ctx.fillStyle = options.color
    ctx.rotate(options.rotateDegree)
    ctx.textAlign = 'left'
    ctx.fillText(text, options.x, options.y)
  }
  return canvas.toDataURL('image/png')
}

export const getmark = () => {
  const setWatermark = (str: string) => {
    const id = '1.23452384164.123412416'

    if (document.getElementById(id) !== null) {
      document.body.removeChild(document.getElementById(id)!)
    }

    const option = {
      width: 360,
      height: 240,
      content: str,
      font: '14px SourceHanSansCN-Light',
      color: 'rgba(0, 0, 0, 0.2)',
      rotateDegree: (-16 * Math.PI) / 180
    }
    const dataUri1 = createImgBase({
      ...option,
      x: 100,
      y: 140
    })
    const dataUri2 = createImgBase({
      ...option,
      x: 400,
      y: 340
    })

    const div = document.createElement('div')

    div.id = id
    const styleStr = `
      position:fixed;
      top:0px;
      left:0px;
      width:100vw;
      height:100vh;
      z-index:99999;
      pointer-events:none;
      background-repeat:repeat;
      mix-blend-mode: multiply;
      background-image: url(${dataUri1}), url(${dataUri2});
      background-size: ${option.width * 2}px ${option.height}px;`
    div.setAttribute('style', styleStr)
    document.body.appendChild(div)

    const observer = new MutationObserver(() => {
      const tiem = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      let account = cache.getLocalStorage('userInfo')
      if (account) {
        account = JSON.parse(account).account
        watermark(`管管 ${account} ${tiem}`)
      }
    })

    observer.observe(div, {
      attributes: true
    })

    return id
  }

  // 该方法只允许调用一次
  const watermark = (str: string) => {
    let id = setWatermark(str)
    setInterval(() => {
      if (document.getElementById(id) === null) {
        id = setWatermark(str)
      }
    }, 500)
    window.onresize = () => {
      setWatermark(str)
    }
  }
  return { watermark }
}
