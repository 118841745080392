/**
 * 设置相关功能
 * @author jihua.huang
 * @since 2022-03-14
*/
import { G } from '@/config'
import { storeToRefs } from 'pinia'
import { settingStore } from '@/store' // 引入setting permission的store
import { SLanguage, Smenu } from '@/service'
import { ElLoading } from 'element-plus'
import { langType } from 'types/commonresource'
import { flattenTree } from './comm'
import { resetRouter } from '@/router'

/**
 * 初始化相关pinia store对象
 * @return {*}
 */

let loading: any = null

const startLoading = (value = '') => {
  if (value) {
    loading = ElLoading.service({
      target: value, // 局部刷新动画，填写class   .notice-tabs
      lock: true,
      fullscreen: false,
      text: '加载中...',
      background: 'rgba(0,0,0,0.5)'
    })
  } else {
    loading = ElLoading.service({
      lock: false,
      text: '拼命加载中...',
      background: 'rgba(255,255,255,0.5)'
    })
  }
  return loading
}
/**
* 数据停止加载动画效果
*/
const endLoading = () => {
  if (loading) {
    loading.close()
  }
}

const initStore = () => {
  const settStore = settingStore()
  // 设置为响应数据
  const refSettStore = storeToRefs(settingStore())
  return { settStore, refSettStore }
}

// 修改国际化数组
export function setLanguageList(val: any) {
  initStore().settStore.setLanguageList(val)
}

// 获取国际化数组
export function getLanguageList() {
  return new Promise((resolve: (value: langType[]) => void) => {
    const { getLanguageList } = initStore().refSettStore
    let languageList: any = []
    if ((getLanguageList.value && getLanguageList.value.length === 0) || !getLanguageList.value) {
      SLanguage.langSelect().then((result) => {
        if (result.code) {
          languageList = result.data
          resolve(languageList)
          initStore().settStore.setLanguageList(languageList)
        }
      })
    } else {
      languageList = getLanguageList.value
      resolve(languageList)
    }
  })
}

// 修改登录弹窗
export function setOldPath(val: string) {
  initStore().settStore.setOldPath(val)
}

// 获取登录弹窗
export function getOldPath() {
  const { getOldPath } = initStore().refSettStore
  return getOldPath
}

// 修改登录弹窗
export function setLoginPopWindow(val: boolean) {
  initStore().settStore.setLoginPopWindow(val)
}

// 获取登录弹窗
export function getLoginPopWindow() {
  const { getLoginPopWindow } = initStore().refSettStore
  return getLoginPopWindow
}
// 获取 是否弹窗
export function getIsReLogin() {
  const { getIsReLogin } = initStore().refSettStore
  return getIsReLogin
}
// 获取 是否弹窗
export function setIsReLogin(val: boolean) {
  initStore().settStore.setIsReLogin(val)
}

// 修改路由缓存数据
export function setCacheKeepAlive(val: any) {
  initStore().settStore.setCacheKeepAlive(val)
}

// 获取路由缓存数据
export function getCacheKeepAlive() {
  const { getCacheKeepAlive } = initStore().refSettStore
  return getCacheKeepAlive
}

// 获取表单放大标识
export function getAmplification() {
  const { amplification } = initStore().refSettStore
  return amplification
}

// 修改表单放大标识
export function setAmplification() {
  let collapse = initStore().settStore.amplification || ''
  initStore().settStore.setAmplification(!collapse)
}

// 获取局部刷新
export function getRenovate() {
  const { renovate } = initStore().refSettStore
  return renovate
}

// 修改局部刷新
export function setRenovate() {
  startLoading()
  setTimeout(() => {
    initStore().settStore.setRenovate(false)
    setTimeout(() => {
      initStore().settStore.setRenovate(true)
      setTimeout(() => {
        endLoading()
      }, 500)
    })
  }, 0)
}

// 修改局部刷新
export function renovateMain() {
  initStore().settStore.setRenovate(false)
  setTimeout(() => {
    initStore().settStore.setRenovate(true)
  })
}

// 全局刷新
export function setGlobalRefresh() {
  startLoading()
  setTimeout(() => {
    initStore().settStore.setGlobalRefresh(false)
    setTimeout(() => {
      initStore().settStore.setGlobalRefresh(true)
      setTimeout(() => {
        endLoading()
      }, 500)
    })
  }, 0)
}

// 二级菜单是否显示
export function getSecondaryMenuShow() {
  const { secondaryMenuShow } = initStore().refSettStore
  return secondaryMenuShow
}

// 修改二级菜单是否显示
export function setSecondaryMenuShow(val: boolean) {
  initStore().settStore.setSecondaryMenuShow(val)
}

// 二级菜单标识
export function getSecondaryMenu() {
  const { secondaryMenu } = initStore().refSettStore
  return secondaryMenu
}

// 二级菜单标识修改
export function setSecondaryMenu(path: string) {
  // let collapse = initStore().settStore.secondaryMenu || ''
  initStore().settStore.setSecondaryMenu(path)
}

/**
 * 设置左边菜单收起/展开
 * @returns bool
 */
export function setCollapse(val: number) {
  initStore().settStore.setCollapse(val)
}

export function setModelDecide(val: number) {
  initStore().settStore.setModelDecide(val)
}

// 二级菜单展开修改
export function setTwoCollapse(val?: boolean) {
  let twoCollapse = val || initStore().settStore.twoCollapse || false
  initStore().settStore.setTwoCollapse(!twoCollapse)
}

/**
 * 获取左边菜单的收起/展开 状态
 * @returns bool
 */
export function getCollapse() {
  const { collapse } = initStore().refSettStore
  return collapse
}

// 模式选中
export function getModelDecide() {
  const { modelDecide } = initStore().refSettStore
  return modelDecide
}

// 二级菜单的收起/展开 状态
export function getTwoCollapse() {
  const { twoCollapse } = initStore().refSettStore
  return twoCollapse
}

/**
 * 设置右边抽屉工具页面打开/关闭 状态
 * @returns bool
 */
export function setDrawer() {
  let drawer = initStore().settStore.drawer || false
  initStore().settStore.setDrawer(!drawer)
}

/**
 * 获取设置右边抽屉工具页面打开/关闭 状态
 * @returns bool
 */
export function getDrawer() {
  const { drawer } = initStore().refSettStore
  return drawer
}

/**
 * 设置账号的下拉菜单收起/展开
 * @returns bool
 */
export function setDropDown() {
  let dropDown = initStore().settStore.dropDown || false
  initStore().settStore.setDropDown(!dropDown)
}

/**
 * 获取账号的下拉菜单收起/展开 状态
 * @returns bool
 */
export function getDropDown() {
  const { dropDown } = initStore().refSettStore
  return dropDown
}

/**
 * 获取用户的基本信息
 * @returns object
 */
export function getUserInfo() {
  const { getUserInfo } = initStore().refSettStore
  // const info: any = getUserInfo().value
  return getUserInfo
}

/**
 * 设置默认激活的二级菜单path -key
 * @returns number
 */
export function setDefaultActive(path: string) {
  if (path) {
    initStore().settStore.setDefaultActive(path)
  }
  return true
}
/**
 * 设置默认激活的菜单path -key
 * @returns number
 */
export function setDefaultMenu(path: string) {
  if (path) {
    initStore().settStore.setDefaultMenu(path)
  }
  return true
}

// 设置头部是否显示
export function setHeaderShow(val: boolean) {
  initStore().settStore.setHeaderShow(val)
}

// 获取头部是否显示
export function getHeaderShow() {
  const { getHeaderShow } = initStore().refSettStore
  return getHeaderShow
}

/**
 * 获取默认激活的二级菜单path -key
 * @returns number
 */
export function getDefaultActive() {
  const { defaultActive } = initStore().refSettStore
  return defaultActive
}
/**
 * 获取默认激活的一级菜单path -key
 * @returns number
 */
export function getDefaultMenu() {
  const { defaultMenu } = initStore().refSettStore
  return defaultMenu
}

/**
 * 获取路由数据转换成面包屑数据
 * @returns number
 */
export const getBreadcrumb = (route: any) => {
  let list: any = []
  const matched: any = route.matched || []
  // 获取数组长度
  const length = Object.keys(matched).length
  if (length >= 1) {
    Object.values(matched).forEach((item: any) => {
      if (item.path && item.path !== '/') {
        let breadcrumb: any = {
          path: item.path,
          name: item.name,
          title: item.meta.title
        }
        list.push(breadcrumb)
      }
    })
  }
  return list
}

/**
 * 设置偏好设置信息
 * @returns number
 */
export function setPrefer(data: any) {
  // 如果数据值为空，则不处理
  if (Object.keys(data).length < 1) {
    return
  }

  // 获取store里的prefer数据
  const prefer = initStore().refSettStore.prefer
  let newData: any = {}
  // 存在数据的处理
  if (Object.keys(prefer.value).length >= 1) {
    // 合并对象
    newData = Object.assign(prefer.value, data)
  } else {
    newData = data
  }
  if (newData) {
    // 保存数据到store中
    initStore().settStore.setPrefer(newData)
  }
  return true
}

/**
 * 获取偏好设置信息
 * @returns number
 */
export const getPrefer = () => {
  const { getPrefer } = initStore().refSettStore
  // 如果不存在主题皮肤，则使用默认皮肤
  return getPrefer
}

/**
 * 获取偏好设置信息
 * @returns number
 */
export const getTheme = (val: any) => {
  if (typeof val === 'undefined' || !val) {
    // 如果不存在主题皮肤，则使用默认皮肤
    return G.THEME_DEFAULT
  } else {
    return val
  }
}
/**
 * 获取某个偏好设置的开关信息信息
 * @returns number
 */
export const getOnOff = (val: any) => {
  if (typeof val === 'undefined') {
    // 如果不存在key，则使用默认 1
    return 1
  } else {
    return parseInt(val) === 0 ? 0 : 1
  }
}

/**
 *
 * @param val 语言编码
 * @returns 设置语言
 */
export const setLang = (val: string) => {
  initStore().settStore.setLang(val).then(result => {
    // console.log(result)
  })
}

type menuType = {
  abbr: string,
  asse: string,
  children: menuType[],
  id: string,
  issh: number,
  meta: { title: string, icon: string, layout: boolean }
  name: string
  path: string,
  pathname: string,
  pname: string,
  type: string,
}

// 设置菜单列表
export const setMenuList = (strte?: boolean) => {
  return new Promise((resolve: (value: any) => void, reject) => {
    const { token } = initStore().refSettStore
    const { menuList, refreshRoute } = initStore().refSettStore
    console.log('-------------------1-----------')
    if ((menuList.value && menuList.value.length > 0 || !token.value) && !strte) {
      // 判断是否需要刷新路由
      if (refreshRoute.value) {
        // 清空所有路由
        resetRouter()
        // 下次不在需要刷新路由
        refreshRoute.value = false
        // 返回数据
        resolve(menuList.value)
      } else {
        resolve([])
      }
    } else {
      // 获取权限
      // Smenu.getPower().then(val => {
      //   // console.log(val)
      // })
      if (strte) {
        refreshRoute.value = true
      }

      // 获取菜单
      Smenu.getMenu().then((val: { data: menuType[] }) => {
        if (val) {
          initStore().settStore.setMenuList(val?.data)
          resolve(val?.data)
        }
      }).catch((error) => {
        reject(error)
      })
    }
  })
}

// 获取树状菜单
export const getMenuList = () => {
  const { menuList } = initStore().refSettStore
  return menuList
}

// 获取扁平菜单
export const getMenuPlaneList = () => {
  const { menuList } = initStore().refSettStore
  const res = flattenTree(menuList.value)
  //   console.log(res);

  return res
}
