/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

const url = HUrl.HTTP_BASE_URL_JAVACHANGE // 路径前缀 + 微服务
// 获取表格分页数据
export const getPageList = async (params: any) => {
  let data: any
  await http.post(url + '/warehousing/shelvesModelNum/queryPageList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 列表查询-下拉
export const getList = async (params: any) => {
  let data: any
  await http.post(url + '/warehousing/shelvesModelNum/queryList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// shelvesModelNum/submit
// 获取单个详情
export const getInfo = async (params: any) => {
  let data: any
  await http.post(url + '/warehousing/shelvesModelNum/querySingle', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 保存草稿
export const saveDraft = async (params: any) => {
  let data: any
  await http.post(url + '/warehousing/shelvesModelNum/save', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 提交
export const shelvesModelNumSubmit = async (params: any) => {
  let data: any
  await http.post(url + '/warehousing/shelvesModelNum/submit', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 删除
export const deleteList = async (params: any) => {
  let data: any
  await http.delPost(url + '/warehousing/shelvesModelNum/deleteList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导入
export const importFiles = async (params: any) => {
  let data: any
  await http.postFiles(url + '/warehousing/shelvesModelNum/import', {}, params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出
export const exportFiles = async (params?: any) => {
  let data: any
  await http.getFilesExt(url + '/warehousing/shelvesModelNum/export', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出模板
export const exportFilesTmpl = async (params?: any) => {
  let data: any
  await http.getFiles(url + '/warehousing/shelvesModelNum/template', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

export const saveRough = async (params?: any) => {
  let data: any
  await http.post(url + '/shelvesModelNum/save', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 获取设备下拉
export const shelfDeviceQueryList = async (params?: any) => {
  let data: any
  await http.post(url + '/warehousing/shelfDevice/queryList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
