import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

// 详情
export const getDocumentRulesDetail = async (params: object) => {
  const data = await http.post(HUrl.BASE_PREFIX_SYS + '/BillCodedRuleBase/Info', params)
  return data
}

// 删除
export const getDocumentRulesDel = async (params: object) => {
  const data = await http.post(HUrl.BASE_PREFIX_SYS + '/BillCodedRuleBase/Del', params)
  return data
}

// 分页列表
export const getDocumentRulesPage = async (params: object) => {
  const data = await http.post(HUrl.BASE_PREFIX_SYS + '/BillCodedRuleBase/PageList', params)
  return data
}

// 新增
export const getDocumentRulesSave = async (params: object) => {
  const data = await http.post(HUrl.BASE_PREFIX_SYS + '/BillCodedRuleBase/Add', params)
  return data
}

// 修改
export const getDocumentRulesEdit = async (params: object) => {
  const data = await http.post(HUrl.BASE_PREFIX_SYS + '/BillCodedRuleBase/Edit', params)
  return data
}
// 单据编码规则同步接口： 无参数
export const dataSyncBusiness = async (params?: object) => {
  const data = await http.post(HUrl.BASE_PREFIX_SYS + '/BillCodedRuleBase/DataSyncBusiness', params)
  return data
}
