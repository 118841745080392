/**
 * 封装下级库位相关功能等
 * @author
 * @date 2022-08-10
 */
// 引入文件
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

// 新增库号
export const addLibrary = async (params : any = {}) => {
  let data: any
  await http.post(HUrl.CHANGE_LIBRARY_ADD, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 库号分页
export const queryLibraryNoList = async (params : any = {}) => {
  let data: any
  await http.post(HUrl.CHANGE_LIBRARYNO_LIST, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 根据id查询库号详情
export const querySingleLibraryNo = async (params : any = {}) => {
  let data: any
  await http.post(HUrl.CHANGE_LIBRARYT_QUERYSINGLE, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 编辑接口
export const updateLibrary = async (params : any = {}) => {
  let data: any
  await http.post(HUrl.CHANGE_LIBRARYT_UPDATE, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除接口
export const deleteLibraryNo = async (params : any = {}) => {
  let data: any
  await http.delPost(HUrl.CHANGE_LIBRARYT_DELETE, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 获取库号货品
export const queryNotUsed:any = async (params : any = {}) => {
  let data: any
  await http.post(HUrl.CHANGE_QUERYNOTUSED, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
