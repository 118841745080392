import { defineStore } from 'pinia'

export const useCompanyStore = defineStore({
  id: 'companyStore',
  state: () => ({
    // 公司列表
    companyList: [],
    // 当前公司信息
    companyInfo: {},
    // 公司类型
    companyType: [],
    // 日志类型
    logType: [] as {key: string, value: string}[],
    // 组件共享当前公司新增/编辑数据
    currentCompanyName: '',
    currentCompanyId: '',
    currnetRentNo: '',
    // 统计表单录入进度
    count: {
      basicInfoFilled: 0,
      billInfoFilled: 0
    }

  }),
  getters: {
    getCompanyInfo: (state) => {
      return state.companyInfo
      // return (key:string) => state[key]
    }
  },
  actions: {
    setCompanyInfo(data: any) {
      this.companyInfo = data
    },
    setCurrentCompanyId(data: any) {
      this.currentCompanyId = data
    },
    setCurrentCompanyName(data: any) {
      this.currentCompanyName = data
    }
  },
  persist: {
    enabled: true, // 开启持久化
    strategies: [
      {
        // key: 'token', //自定义Key，也可以去掉，使用默认值为参数id的值
        storage: localStorage, // 可以为localStorage或者sessionStorage
        // 可以通过paths指定需要持久化的值，其他没有指定的则不会持久化
        paths: ['companyList', 'companyInfo', 'companyType', 'logType', 'count']
      }
    ]
  }
})

