import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
// 查询资源版本
export const resourcelistUpdate = async (params: any) => {
  let data: any
  await http.post(HUrl.RESOURCELISTUPDATE, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
