/**
 * 封装下级库位相关功能等
 * @author
 * @date 2022-08-10
 */
// 引入文件
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

/**
 * 仓库库位分区列表
 */
export const queryPartitionList:any = async (params : any = {}) => {
  let data: any
  await http.post(HUrl.CHANGE_PARTITION_LIST, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 新增库位
export const addLocation:any = async (params : any = {}) => {
  let data: any
  await http.post(HUrl.CHANGE_LOCATION_ADD, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 编辑接口
export const updateLocation:any = async (params : any = {}) => {
  let data: any
  await http.post(HUrl.CHANGE_LOCATION_UPDATE, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 根据id查询库位详情
export const querySingleLocation:any = async (params : any = {}) => {
  let data: any
  await http.post(HUrl.CHANGE_LOCATION_QUERYSINGLE, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除接口
export const deleteLocation:any = async (params : any = {}) => {
  let data: any
  await http.delPost(HUrl.CHANGE_LOCATION_DELETE, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 仓库库位
 * */
export const queryLocationList:any = async (params : any = {}) => {
  let data: any
  await http.post(HUrl.CHANGE_LOCATION_LIST, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 仓库下级查询上级信息
 * */
export const queryWarehouseFullDetails:any = async (params : any = {}) => {
  let data: any
  // warehouseType = "warehouse";
  // warehouseType = "partition";
  // warehouseType = "storageLocation";
  // warehouseType = "libraryNo";
  await http.post(HUrl.CHANGE_LOCATION_QUERYDETAILSESSION, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 库位下载excl模板
 * */
export const storageLocationTemplate:any = async () => {
  let data: any
  await http.getFiles(HUrl.CHANGE_LOCATION_STORAGELOCATIONTEMPLATE).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 库号下载excl模板
 * */
export const libraryNoTemplate:any = async () => {
  let data: any
  await http.getFiles(HUrl.CHANGE_LOCATION_LIBRARYNOTEMPLATE).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 库位导入excl
 * */
export const storageLocationImport:any = async (params:any = {}) => {
  let data: any
  await http.postFiles(HUrl.CHANGE_LOCATION_STORAGELOCATIONIMPORT, {}, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 库号导入excl
 * */
export const libraryNoImport:any = async (params:any = {}) => {
  let data: any
  await http.postFiles(HUrl.CHANGE_LOCATION_LIBRARYNOIMPORT, {}, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 库位导出excl
 * */
export const storageLocationExport:any = async (params:any = {}) => {
  let data: any
  await http.getFilesExt(HUrl.CHANGE_LOCATION_STORAGELOCATIONEXPORT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 库号导出excl
 * */
export const libraryNoExport:any = async (params:any = {}) => {
  let data: any
  await http.getFilesExt(HUrl.CHANGE_LOCATION_LIBRARYNOEXPORT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
