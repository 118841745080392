import { defineStore } from 'pinia'
/**
 * personSelectData:{
 * personIdKey/选中的人员id:{
 *  emid/部门id: true
 * }
 * }
 * 人员在不同的部门可能有重复 所以一个人员可能对应多个部门
 */
export const receiptData = defineStore({
  id: 'receiptData',
  state: () => ({
    receProductList: [] as any[]
  }),
  getters: {

  },
  actions: {
    setReceProductList(val:any[]) {
      this.receProductList = val
    }
  }
})
