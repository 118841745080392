import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

const url = HUrl.HTTP_BASE_URL_JAVACHANGE // 路径前缀 + 微服务
export type TInterFunc<P, D> = {(params: P): Promise<D>}
export type TData = { code: number, data: { [x: string]: number | string }}
export type TArrData = { code: number, data: { [x: string]: number | string }[]}
export type TPlanogramsItem = {
  type: number // 单双面 1：单面 2：双面
  size: number // 尺寸
  qty: number // 数量
  level: number // 层数
  cells: TCellItem[]
}
export type TCellItem = {
  isAlarm: boolean
  isFailure: boolean
  isSaved: boolean
  isStoped: boolean
  no: string
  status: number
}
export const asyncShelfStatis: TInterFunc<{ id: string }, TData > = async (params) => {
  let data = { code: 0, data: {}}
  await http.post(url + '/warehousing/shelf/statis', params).then((result) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 实时状态信息
export const asyncShelfLastInfo: TInterFunc<{ id: string }, TData > = async (params) => {
  let data = { code: 0, data: {}}
  await http.post(url + '/warehousing/shelf/lastInfo', params).then((result) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 货架行信息
export const asyncShelfRowInfo: TInterFunc<{ id: string }, {code: number, data:TPlanogramsItem[]} > = async (params) => {
  let data:{code: number, data:TPlanogramsItem[]} = { code: 0, data: [] }
  await http.post(url + '/warehousing/shelf/rowInfo', params).then((result) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 温度记录
export const temperatureList: TInterFunc<{id:string}, TArrData> = async (params) => {
  let data:TArrData = { code: 0, data: [] }
  await http.post(url + '/warehousing/shelfStatusGraph/temperatureList', params).then((result) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 湿度记录
export const humidityList: TInterFunc<{id:string}, TArrData> = async (params) => {
  let data:TArrData = { code: 0, data: [] }
  await http.post(url + '/warehousing/shelfStatusGraph/humidityList', params).then((result) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 报警
export const alarm: TInterFunc<{imei:string, cellNo:string}, any> = async (params) => {
  let data:TArrData = { code: 0, data: [] }
  await http.post(url + '/warehousing/shelfCell/alarm', params).then((result) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 申请
export const apply: TInterFunc<{imei:string, cellNo:string}, TArrData> = async (params) => {
  let data:TArrData = { code: 0, data: [] }
  await http.post(url + '/warehousing/shelfCell/apply', params).then((result) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 故障
export const fault: TInterFunc<{imei:string, cellNo:string}, TArrData> = async (params) => {
  let data:TArrData = { code: 0, data: [] }
  await http.post(url + '/warehousing/shelfCell/fault', params).then((result) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 停用
export const pause: TInterFunc<{imei:string, cellNo:string}, TArrData> = async (params) => {
  let data:TArrData = { code: 0, data: [] }
  await http.post(url + '/warehousing/shelfCell/pause', params).then((result) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 已用
export const used: TInterFunc<{imei:string, cellNo:string}, TArrData> = async (params) => {
  let data:TArrData = { code: 0, data: [] }
  await http.post(url + '/warehousing/shelfCell/used', params).then((result) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
export const waiting: TInterFunc<{imei:string, cellNo:string}, TArrData> = async (params) => {
  let data:TArrData = { code: 0, data: [] }
  await http.post(url + '/warehousing/shelfCell/waiting', params).then((result) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
