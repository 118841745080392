/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import { apiType } from 'types/commonresource'
const url = HUrl.BASE_PREFIX_INIT_ADMIN
// 账号列表
export const getPage = async (params?: any) => {
  let data: any
  // await http.post(HUrl.ACCOUNT_PAGE, params).then((result: any) => {
  // }
  await http.post(url + '/account/queryPageList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 账号详情
export const getInfo = async (params?: any) => {
  let data: any
  await http.post(HUrl.ACCOUNT_INFO, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 账号下拉
export const accountSelect = async (params?: any) => {
  let data: any
  await http.get(HUrl.ACCOUNT_SELECT, params).then((result: any) => {
    console.log('result', result)

    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 1.2.1.15查询用户员工列表汇总数据

export const listSummary = async (params?: any) => {
  let data: any
  await http.post(HUrl.ACCOUNT_LIST_SUMMARY, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

export const dormancy = async (params?: any) => {
  let data: any
  await http.post(HUrl.ACCOUNT_DORMANCY, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

export const resetPassWord = async (params?: any) => {
  let data: any
  await http.post(HUrl.RESET_PASSWORD, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 切换公司
export const toggleCompany = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/account/toggleCoid', params)
    .then((result: any) => {
      data = result
    }).catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 根据账号查询对应公司
export const getCompanyByAcc = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/account/companyByAcc', params)
    .then((result: any) => {
      data = result
    }).catch((err: any) => {
      return http.catch(err)
    })
  return data
}
// export const getInfo = async (params?: any) => {
//   let data: any;
//   await http.post(HUrl.ACCOUNT_GETINFO, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }
