// 列表汇总
/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

const url = HUrl.BASE_PREFIX_PURCHASE_V // 路径前缀 + 微服务
// 获取表格分页数据
export const getPageList = async (params: any) => {
  let data: any
  await http.post(url + '/inquire/queryPageList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取表格分页数据--平铺
export const queryPageListTiled = async (params: any) => {
  let data: any
  await http.post(url + '/inquire/queryPageListTiled', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取单个详情
export const getInfo = async (params: any) => {
  let data: any
  await http.post(url + '/inquire/querySingle', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 保存草稿
export const saveDraft = async (params: any) => {
  let data: any
  await http.post(url + '/inquire/save', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 提交
export const submit = async (params: any) => {
  let data: any
  await http.post(url + '/inquire/submit', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 删除
export const del = async (params: any) => {
  let data: any
  await http.delPost(url + '/inquire/deleteList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导入
export const importFiles = async (params: any) => {
  let data: any
  await http.postFiles(url + '/inquire/import', {}, params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出
export const exportFiles = async (params?: any) => {
  let data: any
  await http.getFilesExt(url + '/inquire/export', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出模板
export const exportFilesTmpl = async (params?: any) => {
  let data: any
  await http.getFiles(url + '/inquire/template', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 反审
export const counterReview = async (params?: any) => {
  let data: any
  await http.getFiles(url + '/inquire/counterReview', params).then((result: any) => {
    data = result
  }).catch((err) => {
    http.catch(err)
  })
  return data
}

// 撤回
export const revokeApi = async (params?: any) => {
  let data: any
  await http.getFiles(url + '/inquire/revoke', params).then((result: any) => {
    data = result
  }).catch((err) => {
    http.catch(err)
  })
  return data
}

