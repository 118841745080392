import { computed, ref } from 'vue'
// 获取hooks
import companyInfo from '@/views/business/finance/collectionPayment/payment/hooks/companyInfo' // 公司
import paymentInfo from '@/views/business/finance/collectionPayment/payment/hooks/paymentInfo' // 付款信息
import financeInfo from '@/views/business/finance/collectionPayment/payment/hooks/financeInfo' // 财务信息
import { addColumnList } from '@/views/business/finance/collectionPayment/payment/hooks/detailTableForm' // 金额明细
// 获取接口
import { getInfo, queryPageDetail } from '@/service/modules/finance/payment'

import type { objAny } from 'types/table'
import type { apiType } from 'types/commonresource'

const formJsonData = () => {
  // 基础表单hooks
  // const { formJson, collectionInfo } = basicInfoHooksIndex({ serviceCharge: ref(''), exchangeRate: ref('') }, ref([]), ref([]), ref([]), ref(false), ref(false)) // 基本信息

  const { formJson } = companyInfo()
  const { formJson: paymentJson, customerAmountJson, amountJson } = paymentInfo(ref(false), ref(false))
  const { formJson: financeJson, amountJson: financeAmoutn } = financeInfo()

  const res = computed(() => {
    return {
      ...formJson,
      ...paymentJson,
      ...customerAmountJson,
      ...amountJson,
      ...financeJson,
      ...financeAmoutn
    }
  })
  // // 表格hooks
  const mColumnList = addColumnList({ exchangeRate: ref(''), currency: ref('') })
  // if (formJson.value.sourceCode) {
  //   mColumnList = sourceColumnList({ exchangeRate: exchangeRate.value, currency: currency.value })
  // } else {
  //   mColumnList =
  // }
  return [
    {
      title: { lank: 'finance.essentialInfo', default: '基本信息' }, // 基本信息
      type: 'form', // 表单
      json: res.value,
      getInfo: (params: objAny) => {
        return new Promise((resolve: (value: apiType) => void) => {
          getInfo(params).then(result => {
            // 供应商id 重新赋值
            result.data.supplierCstmId = result.data.supplierId
            // 供应商简称
            result.data.supaCstmId = result.data.supa
            // 供应商编码
            result.data.supcCstmId = result.data.supc
            // 付款账户
            result.data.paymentAccountCstmId = result.data.paymentAccount
            // 付款开户行
            result.data.paymentBankCstmId = result.data.paymentBank

            // 收款账户
            result.data.collectionAccountCstmId = result.data.collectionAccount
            // 收款开户行
            result.data.collectionBankCstmId = result.data.collectionBank
            resolve(result)
          })
        })
      },
      slot: {
        // coma: defineAsyncComponent(() => import('./componentsSlot/coma.vue')),
        // shah: defineAsyncComponent(() => import('./componentsSlot/shah.vue')),
        // affi: defineAsyncComponent(() => import('./componentsSlot/affi.vue')),
        // buid: defineAsyncComponent(() => import('./componentsSlot/buid.vue'))
        // isMo: defineAsyncComponent(() => import('./componentsSolt/isMo.vue'))
      },
      formStyle: {
        pymtAccountType: [
          'grid-column: 1 / span 1;'
        ],
        skAccountType: [
          'grid-column: 1 / span 1;'
        ]
        // buid: [
        //   'grid-column: 1 / span 4;'
        // ],
        // shah: [
        //   'grid-column: 1 / span 4;'
        // ],
        // affi: [
        //   'grid-column: 1 / span 4;'
        // ]
      }
    },
    {
      title: { lank: 'finance.uploadAttachments', default: '上传附件' },
      type: 'files',
      code: 'Payment',
      templetCode: 'PayMentAppendix'
    },
    {
      title: { lank: 'finance.moneyDetail', default: '金额明细' },
      type: 'table', // 表格
      formid: 'paymentId',
      id: 'id',
      columnList: mColumnList,
      // slot: {
      //   isen: defineAsyncComponent(() => import('./componentsSlot/isen.vue'))
      // },
      getInfo: queryPageDetail
    }
  ]
}
export default formJsonData
