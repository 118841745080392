// import indexHooks from '@/views/business/sales/salesOrder/hooks/indexHooks'
import { getColumnList } from '@/views/business/engineer/materialdoc/hooks/index'
import { Materia } from '@/service'

const Material = () => {
  const columnList = getColumnList().value
  const printView = [
    {
      viewCode: 'Material.index',
      columnList: columnList,
      formJSOn: '',
      getData: {
        fnName: '',
        fn: async () => {
          const result = await Materia.materialGetPage({
            data: {},
            pageIndex: 1,
            pageSize: 9999
          })
          return result
        }
      }
    }
    // {
    //   viewCode: 'Material.index',
    //   columnList: columnList,
    //   getData: {
    //     fnName: '',
    //     fn: async () => {
    //       const result = await getPageList({
    //         pageIndex: 1,
    //         pageSize: 99
    //       })
    //       return result
    //     }
    //   }
    // }
  ]
  return printView
}

export default Material()
