import { defineStore } from 'pinia'
type TProductionOrderFormForm = { [x: string]: number | string | boolean }
type Tstate = {
  productionOrderForm:TProductionOrderFormForm
}
export const productionOrderStore = defineStore({
  id: 'ProductionOrderFormStore',
  state: ():Tstate => {
    // 物料数据字典
    return {
      productionOrderForm: {
        id: ''
      }
    }
  },
  getters: {
    getProductionOrderFormFormId: (state) => {
      return state.productionOrderForm.id
    },
    getProductionOrderFormForm: (state) => {
      return state.productionOrderForm
    }
  },
  actions: {
    setProductionOrderFormFormId(id: string) {
      this.productionOrderForm.maid = id
    },
    setProductionOrderFormForm(obj: TProductionOrderFormForm) {
      this.productionOrderForm = { ...this.productionOrderForm, ...obj }
    },
    clearProductionOrderFormForm() {
      this.productionOrderForm = {
        id: ''
      }
    }
  },
  persist: {
    enabled: true, // 开启持久化
    strategies: [
      {
        storage: localStorage, // 可以为localStorage或者sessionStorage
        paths: [
          'ProductionOrderFormStore', 'productionOrderForm'
        ]
      }
    ]
  }
})

export default productionOrderStore
