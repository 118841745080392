import { http } from '@/service' // 引入网络请求http
import { apiType } from 'types/commonresource'

const url = 'api/production' // 路径前缀 + 微服务

// 获取表格分页数据
export const getPageList = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/fewMaterial/queryPageList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取表格分页数据--平铺
export const queryTilePageList = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/fewMaterial/queryTilePageList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取表格分页数据--收起
export const queryRetractPage = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/fewMaterial/queryRetractPage', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取单个详情
export const getInfo = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/fewMaterial/querySingle', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 保存草稿
export const saveDraft = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/fewMaterial/save', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 保存草稿
export const submit = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/fewMaterial/submit', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 批量删除
export const del = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/fewMaterial/deleteList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导入
export const importFiles = async (params: any) => {
  let data = {} as apiType
  await http.postFiles(url + '/fewMaterial/import', {}, params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出
export const exportFiles = async (params?: any) => {
  let data = {} as apiType
  await http.javaDl(url + '/fewMaterial/export', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出模板
export const exportFilesTmpl = async (params?: any) => {
  let data = {} as apiType
  await http.javaDl(url + '/fewMaterial/template', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

export const revoke = async (params?: any) => {
  let data = {} as apiType
  await http.post(url + '/fewMaterial/revoke', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

export const counterReview = async (params?: any) => {
  let data = {} as apiType
  await http.post(url + '/fewMaterial/counterReview', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 根据参数以及类型获取相应的数据源
export const getTreeDataInfoByType = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/fewMaterial/getTreeDataInfoByType', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 根据参数获取需要处理的BOM明细
export const getBomDataByParam = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/fewMaterial/getBomDataByParam', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 欠料处理行处理

// 导出
export const itemExport = async (params?: any) => {
  let data = {} as any
  await http.javaDl(url + '/fewMaterialItem/export', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 分页查询
export const itemQueryPageList = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/fewMaterialItem/queryPageList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 保存草稿
export const itemSave = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/fewMaterialItem/save', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 批量删除
export const itemDeleteList = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/fewMaterialItem/deleteList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 欠料bom明细处理--申请/锁定
export const itemButtonHandle = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/fewMaterialItem/itemButtonHandle', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 被申请/锁定的数据
// 批量删除
export const removeRecordItemHandleByIds = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/fewMaterialItem/removeRecordItemHandleByIds', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 分页查询
export const queryRecordItemHandlePageList = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/fewMaterialItem/queryRecordItemHandlePageList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

