/* eslint-disable @typescript-eslint/no-explicit-any */

import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

const url = HUrl.BASE_PREFIX_PURCHASE_V // 路径前缀 + 微服务
// 获取表格分页数据
export const getPageList = async (params: any) => {
  let data: any
  await http.post(url + '/purchaseApply/queryPageList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取单个详情
export const getInfo = async (params: any) => {
  let data: any
  await http.post(url + '/purchaseApply/querySingle', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 保存草稿
export const saveDraft = async (params: any) => {
  let data: any
  await http.savePost(url + '/purchaseApply/save', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 提交
export const submit = async (params: any) => {
  let data: any
  await http.post(url + '/purchaseApply/submit', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 删除
export const del = async (params: any) => {
  let data: any
  await http.delPost(url + '/purchaseApply/deleteList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导入
export const importFiles = async (params: any) => {
  let data: any
  await http.postFiles(url + '/purchaseApply/import', {}, params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出
export const exportFiles = async (params?: any) => {
  let data: any
  await http.getFilesExt(url + '/purchaseApply/export', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出模板
export const exportFilesTmpl = async (params?: any) => {
  let data: any
  await http.getFiles(url + '/purchaseApply/template', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 审批流程——取消
export const cancel = async (params: any) => {
  const result = await http.post(url + '/purchaseApply/cancel', params)
  return result
}

// 审批流程——完成审批
export const complete = async (params: any) => {
  const result = await http.post(url + '/purchaseApply/complete', params)
  return result
}

// 审批流程——反审
export const counterReview = async (params: any) => {
  const result = await http.post(url + '/purchaseApply/counterReview', params)
  return result
}

// 审批流程——驳回
export const reject = async (params: any) => {
  const result = await http.post(url + '/purchaseApply/reject', params)
  return result
}

// 审批流程——撤回
export const revoke = async (params: any) => {
  const result = await http.post(url + '/purchaseApply/revoke', params)
  return result
}

