/* eslint-disable @typescript-eslint/no-explicit-any */
// 引入文件
import { http } from '@/service' // 引入网络请求http
/**
 * 保存草稿
 */
export const originInfoSave = async (params: any) => {
  let data: any
  await http.savePost('api/engineer/originInfo/save', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 查询分页
 */
export const originInfoQueryPage = async (params: any) => {
  let data: any
  await http.post('api/engineer/originInfo/queryPage', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}


/**
 * 通过Ids查询分页
 */
export const queryPageListByIds = async (params: any) => {
  let data: any
  await http.post('api/engineer/originInfo/queryPageListByIds', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}


/**
 * 通过参数查询Ids
 */
export const queryIds = async (params: any) => {
  let data: any
  await http.post('api/engineer/originInfo/queryIds', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 品牌列表
 */
export const originInfoSysTemBrandList = async (params: any) => {
  let data: any
  await http.post('api/engineer/originInfo/brandList', {}, params).then((result: any) => {
    if (result.data) {
      result.data = result.data.map((item: string) => ({ label: item, value: item }))
    }
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 分类查询接口 传level=1查询一级分类 parentId传一级id查二级分类
 */
export const originInfoCategoryList = async (params: any) => {
  let data: any
  await http.post('api/engineer/originInfo/categoryList', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 查询分类属性模版-id字段传二级分类id
 */
export const originInfoFieldTemplate = async (params: any) => {
  let data: any
  await http.post('api/engineer/originInfo/fieldTemplate', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 查询详情
 */
export const originInfoDetailInfo = async (params: any) => {
  let data: any
  await http.post('api/engineer/originInfo/detailInfo', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 查询详情-审批
 */
export const originInfoDetailInfoApps = async (params: any) => {
  let data: any
  const matcOptions = await originInfoCategoryList({ level: 1 })
  const maccOptions = await originInfoCategoryList({ level: 2 })
  await http.post('api/engineer/originInfo/detailInfo', {}, params).then((result: any) => {
    if (result.code === 1) {
      result.data.id = result.data.originCode
      const matcObj = matcOptions.data.find((item: { id: any }) => item.id === result.data.matc)
      const maccObj = maccOptions.data.find((item: { id: any }) => item.id === result.data.macc)
      if (matcObj) {
        result.data.matc = matcObj.label
      }
      if (maccObj) {
        result.data.macc = maccObj.label
      }
    }
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 删除
 */
export const originInfoDelete = async (params: any) => {
  let data: any
  await http.delPost('api/engineer/originInfo/delete', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * /engineer/originInfo/cancel 取消
 */
export const originInfoCancel = async (params: any) => {
  let data: any
  await http.post('api/engineer/originInfo/cancel', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * /engineer/originInfo/counterReview 反审
 */
export const originInfoCounterReview = async (params: any) => {
  let data: any
  await http.post('api/engineer/originInfo/counterReview', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 *  监听审批状态执行方法
 */
export const originInfoDoApprovestatusChange = async (params: any) => {
  let data: any
  await http.post('api/engineer/originInfo/doApprovestatusChange', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * /engineer/originInfo/queryPageList
 */
export const originInfoQueryPageList = async (params: any) => {
  let data: any
  await http.post('api/engineer/originInfo/queryPageList', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * /engineer/originInfo/register
 */
export const originInfoRegister = async (params: any) => {
  let data: any
  await http.post('api/engineer/originInfo/register', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * /engineer/originInfo/revoke 撤回流程
 */
export const originInfoRevoke = async (params: any) => {
  let data: any
  await http.post('api/engineer/originInfo/revoke', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * /engineer/originInfo/submit 提交
 */
export const originInfoSubmit = async (params: any) => {
  let data: any
  await http.post('api/engineer/originInfo/submit', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * /engineer/originInfo/updateBatch 批量修改
 */
export const originUpdateBatch = async (params: any) => {
  let data: any
  await http.post('api/engineer/originInfo/updateBatch', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 原厂替代
 */
export const originalSub = async (params: any) => {
  let data: any
  await http.post('api/engineer/originInfo/originalSub', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 替代查询
export const querySub = async (params: any) => {
  let data: any
  await http.post('api/engineer/originInfo/querySub', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 替代查询-删除
export const queryDel = async (params: any) => {
  let data: any
  await http.post('api/engineer/originInfo/querySub', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}