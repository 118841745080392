import { libraryNoChange, NextWarehourseChange, SWarehouse } from '@/service'
import { basicDataSelect } from '@/service/modules/sysmanage/generalData'

type Toption = {
  [x: string]: string
}
type TgetWarRel<T, D> = (waid: T, wpid: T, wlid: T, wnid: T) => Promise<{ [x: string]: { item: D, options: D[] } }>
/**
 * 查询仓库工具类
 * @param waid 所属仓库id
 * @param wpid 仓库分区id
 * @param wlid 仓库库位id
 * @param wnid 仓库库号id
 * @returns
 */
export const getWarehouseRelationships: TgetWarRel<string, Toption> = async (waid, wpid, wlid, wnid) => {
  const warehouseObj = {
    waidObj: { item: {}, options: [] },
    wpidObj: { item: {}, options: [] },
    wlidObj: { item: {}, options: [] },
    wnidObj: { item: {}, options: [] }
  }

  // 所属仓库 - 接口
  if (waid) {
    const result = await SWarehouse.getWarehouseList({ data: { warehouseStatus: '1' }})
    if (result.code === 1) {
      warehouseObj.waidObj.options = result.data.items
      const item = warehouseObj.waidObj.options.find((item: Toption) => item.id === waid)
      warehouseObj.waidObj.item = item || {}
    }
  } else {
    return warehouseObj
  }

  // 仓库分区 - 接口
  if (wpid) {
    const result = await SWarehouse.handlePartitionList({ data: { warehouseId: waid }})
    if (result.code === 1) {
      warehouseObj.wpidObj.options = result.data.items
      const item = warehouseObj.wpidObj.options.find((item: Toption) => item.id === wpid)
      warehouseObj.wpidObj.item = item || {}
    }
  } else {
    return warehouseObj
  }

  // 仓库库位 - 接口
  if (wlid) {
    const result = await NextWarehourseChange.queryLocationList({ data: { storageLocationId: wpid }})
    if (result.code === 1) {
      warehouseObj.wlidObj.options = result.data.items
      const item = warehouseObj.wlidObj.options.find((item: Toption) => item.id === wlid)
      warehouseObj.wlidObj.item = item || {}
    }
  } else {
    return warehouseObj
  }

  // 仓库库号 - 接口
  if (wnid) {
    const result = await libraryNoChange.queryLibraryNoList({ data: { storageLocationId: wlid }})
    if (result.code === 1) {
      warehouseObj.wnidObj.options = result.data.items
      const item = warehouseObj.wnidObj.options.find((item: Toption) => item.id === wnid)
      warehouseObj.wnidObj.item = item || {}
    }
  } else {
    return warehouseObj
  }
  return warehouseObj
}

/**
 * 查询数据字典工具类
 */
export const getBasicOption = async (bdtc:string) => {
  const resultAll = await basicDataSelect({ bdtc, text: '' }, false)
  console.log(resultAll)
}
// basicDataSelect
/**
 * 查询数据字典工具类
 */
export const getAPI = async (bdtc: string) => {
  const res = await basicDataSelect({ bdtc }, true)
  res.data = res.data.map((item:any)=>{
    item.txt = item.badtChs
    item.val = item.badv
    return item
  })
  return res
}
export const getOptions = (bdtc: string) => {
  return { API: getAPI(bdtc), optionsLabel: { label: 'txt', value: 'val' }}
}
/**
 *
 * @param prefixes 需要截取的前缀
 * @param suffixes 需要截取的后缀
 * @param str 截取前的字符串
 */
export const getCompanyAbbreviation = (prefixes: string[], suffixes: string[], str: string) => {
  let newStr = str
  const all = [...prefixes, ...suffixes]
  if (all.length > 0) {
    all.forEach((item) => {
      newStr = str.replace(item, '')
    })
  } else {
    newStr = newStr.replace(/(\S*[省|市|县|区|镇|乡|村])|([股份|有限|公司|股份制|责任])/g, '')
  }
  return newStr
}
