import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import moment from 'moment'

const url = HUrl.BASE_PREFIX_INIT_ADMIN

// 查询模版信息
export const tmpQuerySingle = async (params: any) => {
  let data: any
  await http.post(url + '/template/querySingle', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 设置模版信息
export const submitTemplate = async (params: any) => {
  let data: any
  await http.post(url + '/template/submit', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 设置模版信息
export const setTemplate = async (params: any) => {
  let data: any
  await http.post(url + '/job/initTemplate', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 获取职务分页信息
export const getPage = async (params: any) => {
  let data: any
  // await http.post(HUrl.ADMIN_JOB_PAGE, params).then((result: any) => {
  await http.post(url + '/job/queryPageList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 添加职称信息
export const getInfo = async (params: any) => {
  let data: any
  // await http.post(HUrl.ADMIN_JOB_INFO, params).then((result: any) => {
  await http.post(url + '/job/querySingle', params).then((result: any) => {
    result.data.cret = moment(result.data.cret).format('YYYY-MM-DD HH:mm:ss')

    let updt = moment(result.data.updt).format('YYYY-MM-DD HH:mm:ss')
    if (updt.indexOf('0001-01-01') != -1) {
      result.data.updt = ''
    } else {
      result.data.updt = updt
    }

    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 添加职称信息
export const add = async (params: any) => {
  let data: any
  // await http.post(HUrl.ADMIN_JOB_ADD, params).then((result: any) => {
  await http.post(url + '/job/submit', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 修改职称信息
export const edit = async (params: any) => {
  let data: any
  // await http.post(HUrl.ADMIN_JOB_EDIT, params).then((result: any) => {
  await http.post(url + '/job/submit', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 删除职称信息
export const del = async (params: any) => {
  let data: any
  // await http.delPost(HUrl.ADMIN_JOB_DEL, params).then((result: any) => {
  await http.delPost(url + '/job/deleteList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 职称下拉 /job/queryList
export const jobSelect = async (params?: any) => {
  let data: any
  // await http.post(HUrl.ADMIN_JOB_SELECT, params).then((result: any) => {
  // result.data.forEach((item:{val: string, txt: string, key: string, value: string, }) => {
  // item.key = item.val
  // item.value = item.txt
  // })
  // data = result
  await http.post(url + '/job/queryList', params).then((result: any) => {
    result.data.forEach((item:{ joid: string, jobn: string, val: string, txt: string, key: string, value: string, }) => {
      item.val = item.joid
      item.txt = item.jobn
      item.key = item.joid
      item.value = item.jobn
    })
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// /job/export,导出,POST,/job/export/template,导出模板,POST,/job/import,导入
// 下载职务导入模板
export const jobDownloadtemp = async (params?: any) => {
  let data: any
  // await http.getFiles(HUrl.ADMIN_JOB_DOWNLOADTEMP, params).then((result: any) => {
  await http.getFiles(url + '/job/export/template', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导入职务信息
export const jobImport = async (params: any) => {
  let data: any
  // await http.postFiles(HUrl.ADMIN_JOB_IMPORT, {}, params).then((result: any) => {
  await http.postFiles(url + '/job/import', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// FxRateCfg  汇率配置 #icon-xuqiuliebiao /finance/fxrate/fxratecfg
// 导出职务信息
export const jobExport = async (params?: any) => {
  let data: any
  // await http.getFilesExt(HUrl.ADMIN_JOB_EXPORT, params).then((result: any) => {
  await http.javaDl(url + '/job/export', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
