/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from '@/service' // 引入网络请求http

const url = 'api/production' // 路径前缀 + 微服务
// 删除
export const del = async (params: any) => {
  let data = {} as any
  await http.delPost(url + '/bomMaterial/deleteList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出
export const exportFiles = async (params?: any) => {
  let data = {} as any
  await http.getFilesExt(url + '/bomMaterial/export', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导入
export const importFiles = async (params: any) => {
  let data = {} as any
  await http.postFiles(url + '/bomMaterial/import', {}, params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 获取表格不分页数据
export const getPageList = async (params: any) => {
  let data = {} as any
  await http.post(url + '/bomMaterial/queryList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 获取表格分页数据
export const queryPageList = async (params: any) => {
  let data = {} as any
  await http.post(url + '/bomMaterial/queryPageList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 单个查询
export const querySingle = async (params: any) => {
  let data = {} as any
  await http.post(url + '/bomMaterial/querySingle', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 保存
export const save = async (params: any) => {
  let data = {} as any
  await http.post(url + '/bomMaterial/save', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出模板
export const exportFilesTmpl = async (params?: any) => {
  let data = {} as any
  await http.getFiles(url + '/bomMaterial/template', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
