import { SFolder } from '@/service'
import moment from 'moment'
export type TdurationType = 'week'|'month'|'quarter'|'year'
/**
 * 获取当前时间
 */
export const getCurrentTime = () => {
  const date = new Date()
  const year = date.getFullYear().toString()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')
  const seconds = date.getSeconds().toString().padStart(2, '0')
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}
/**
 *
 * @param id 表单id
 * @param brandImg foln：文件夹名称；fold：文件夹描述；filt：文件标题；file：文件
 * @returns
 */
export const uploadBrandImage = (id: string, brandImg: { foln: string; fold: string; filt: string; file: File }) => {
  return new Promise((resolve) => {
    if (!brandImg.file) {
      resolve(true)
    } else {
      const promise = new Promise((resolve) => {
        SFolder.folderList({ formId: id })
          .then((result) => {
            const item = result.data.filf.find((item: { foln: string }) => item.foln === brandImg.foln)
            if (item) {
              resolve({
                fmid: id,
                fold: item.foid,
                auid: item.fild[0].auid,
                filt: '原厂品牌', // 文件标题
                fisn: 0,
                isth: true
              })
            } else {
              let params1 = {
                fmid: id,
                paid: 0,
                fild: [{
                  islo: 0,
                  foln: brandImg.foln, // 文件夹名称
                  fold: brandImg.fold, // 文件夹描述
                  fosn: 1
                }]
              }
              SFolder.saveFolders(params1).then(res1 => {
                if (res1.code == 1) {
                  resolve({
                    fmid: id,
                    fold: res1.data.foid,
                    filt: brandImg.filt, // 文件标题
                    fisn: 0,
                    isth: true
                  })
                }
              })
            }
          })
      })
      promise.then(param => {
        const newFormData = new FormData()
        newFormData.append('File', brandImg.file as File)
        newFormData.append('fild', '')
        SFolder.uploadFile({ data: newFormData, params: JSON.parse(JSON.stringify(param)) })
      })
    }
  })
}

/**
 * 获取时间范围，周，月，季度，年
 */
export const getTimeSlot = (dateString:string|null, durationType:TdurationType) => {
  let date = new Date()
  if (dateString) {
    date = new Date(dateString)
  }
  const dateObj = {
    beginTime: moment(date).startOf(durationType).format('YYYY-MM-DD'),
    endTime: moment(date).endOf(durationType).format('YYYY-MM-DD'),
    durationType: durationType
  }
  return dateObj
}
