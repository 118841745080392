import { computed, ref } from 'vue'
// 获取hooks
import companyInfoHooks from '@/views/business/finance/payable/hooks/companyInfo' // 基本信息(公司和客户)
import processInfoHooks from '@/views/business/finance/payable/hooks/processInfo' // 基本信息(公司和客户)
import receiptInfoHooks from '@/views/business/finance/payable/hooks/receiptInfo' // 基本信息(公司和客户)
import payablesInfoHooks from '@/views/business/finance/payable/hooks/payablesInfo' // 基本信息(公司和客户)
import sourceOrderInfoHooks from '@/views/business/finance/payable/hooks/sourceOrderInfo' // 基本信息(公司和客户)
import { addColumnList, sourceColumnList } from '@/views/business/finance/payable/hooks/detailTableForm' // 金额明细
// 获取接口
import { getInfo, getPageListDetail, getStatistics } from '@/service/modules/finance/payable'
import { objAny } from 'types/table'
import { apiType } from 'types/commonresource'
import { formJsonType } from '@/views/business/finance/payable/hooks'
import { Supplier } from '@/service'
import { materialCategory } from '@/service/modules/engineering/materialCode'

const formJsonData = () => {
  // 基础表单hooks
  const d = {} as formJsonType

  const { formJson } = receiptInfoHooks(d, ref(false), [])
  const { formJsonTop, formJsonBot } = sourceOrderInfoHooks()

  const res = computed(() => {
    return {
      ...companyInfoHooks(d).formJson.value,
      ...processInfoHooks(d).formJson.value,
      ...formJsonTop.value,
      ...formJsonBot.value,
      ...formJson.value,
      ...payablesInfoHooks(d, ref([]), ref(false)).formJson.value
    }
  })
  // 表格hooks
  const exchangeRate = ref(res.value.exchangeRate)
  const currency = ref(res.value.currencyType)
  let mColumnList = []
  if (res.value.sourceOrderCode) {
    mColumnList = sourceColumnList({ exchangeRate: exchangeRate.value, currency: currency.value })
  } else {
    mColumnList = addColumnList({ exchangeRate: exchangeRate.value, currency: currency.value })
  }
  return [
    {
      title: { lank: 'finance.essentialInfo', default: '基本信息' }, // 基本信息
      type: 'form', // 表单
      json: res.value,
      getInfo: (params: objAny) => {
        return new Promise((resolve: (value: apiType) => void, reject) => {
          getInfo(params).then(async (res) => {
            let payment = { accn: '', bank: '', bana: '', bano: '' }
            const resCus = await Supplier.paymentInfoPageList({
              keyWord: '',
              pid: res.data.supplierName
            })
            if (resCus.code && resCus.data && resCus.data.items) {
              payment = resCus.data.items.find((v:{ suid:string}) => res.data.supplierName === v.suid)
            }
            const obj = {
              account: payment.accn,
              billingDetailsCategory: payment.bank
            }

            res.data = { ...res.data, ...obj }
            if (res.data.detailList && res.data.detailList.length) {
              const items = res.data.detailList.map((v:objAny) => {
                return {
                  orderId: res.data.sourceOrderId, //	string 源头单据id id
                  orderType: res.data.sourceOrderType, //	string 源头单据id id
                  orderCode: res.data.sourceOrderCode, //	string 源单单号 orderCode
                  id: v.id
                }
              })
              const d = items ? items[0] : {}
              let params = {
                rootNodeId: d?.orderId, //	string 源头单据id id
                sourceOrderType: d?.orderType, //	string 源头单据id id
                sourceBillCode: d?.orderCode, //	string 源单单号 orderCode
                sourceOrderListIds: items.map((d:any) => {
                  return d.id
                })
              }
              if (params.rootNodeId) {
                const sourceInfo = await getStatistics(params)
                res.data = { ...res.data, ...sourceInfo?.data }
              }
            }
            resolve(res)
          })
        })
      },
      slot: {
      },
      formStyle: {
        company: [
          'grid-column: 1 / span 4;'
        ]
      }
    },
    {
      title: { lank: 'finance.uploadAttachments', default: '上传附件' },
      type: 'files',
      code: 'Payable',
      templetCode: 'Payable'
    },
    {
      title: { lank: 'finance.orderDetail', default: '订单详情' },
      type: 'table', // 表格
      formid: 'payableId',
      id: 'id',
      columnList: mColumnList,
      getInfo: (params: objAny) => {
        return new Promise((resolve: (value: apiType) => void, reject) => {
          getPageListDetail({
            keyWord: '',
            data: {
              payableId: params.payableId
            },
            pageIndex: params.pageIndex,
            pageSize: params.pageSize,
            total: 0
          }).then(async (result) => {
            const res = await materialCategory({}) as any
            let materialList = [] as any
            if (res.code) {
              materialList = res.data
            }
            if (result.data && result.data.items) {
              result.data.items.forEach((v: any) => {
                // v.materialId = v.customerMaterial
                const material = materialList.find((item:any) => v.materialId === item.maid) as any
                if (material) {
                  const { gocc, macc, unid, spec, mata, matn } = material
                  v.unitOfMeasurement = unid
                  v.productLine = gocc
                  v.materialClassification = macc
                  v.materialName = mata
                  v.materialNo = matn
                  v.materialCode = matn
                  v.specification = spec
                }
              })
            }
            resolve(result)
          })
        })
      }
    }
  ]
}
export default formJsonData
