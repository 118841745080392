// 公司接口
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import { apiType } from 'types/commonresource'
import { objAny } from 'types/table'

type ApiType = apiType&{
  totalCount: number
}

// 查询操作日志列表分页
export const getOperationLog = async (params: objAny | objAny[]) => {
  let data = {} as apiType
  await http.post(HUrl.BASE_PREFIX_INIT_START_RESOUCE + '/log/queryPageList', params).then((result) => {
    data = result
  }).catch((err: Error) => {
    return http.catch(err)
  })
  return data
}

// 查询单数据项变更历史
export const changePageList = async (params: objAny | objAny[]) => {
  let data = {} as ApiType
  await http.post(HUrl.BASE_PREFIX_SYS + '/OperationLog/ChangePageList', params).then((result) => {
    data = result as ApiType
  }).catch((err: Error) => {
    return http.catch(err)
  })
  return data
}
