import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import { apiType } from 'types/commonresource'

const url = HUrl.BASE_PREFIX_SALE // 路径前缀 + 微服务

// 相虎接口--本地调试
// const url = '/tigerSale'

// 获取表格分页数据
export const getPageList = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/supplierPrice/queryPageList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取供应商维护信息 [根据供应商ID和物料ID]
export const getHistorySupplierPrice = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/supplierPrice/getHistorySupplierPrice', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取单个详情
export const getInfo = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/supplierPrice/querySingle', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 保存草稿
export const saveDraft = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/supplierPrice/save', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 提交
export const submit = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/supplierPrice/submit', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取最近7次 [根据供应商ID和物料ID]
export const getStatisticsData = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/supplierPrice/getStatisticsData', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 删除
export const del = async (params: any) => {
  let data = {} as apiType
  await http.delPost(url + '/supplierPrice/deleteList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导入
export const importFiles = async (params: any) => {
  let data = {} as apiType
  await http.postFiles(url + '/supplierPrice/import', {}, params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出
export const exportFiles = async (params?: any) => {
  let data = {} as apiType
  await http.getFilesExt(url + '/supplierPrice/export', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出物料历史修改数据
export const exportHistoryList = async (params?: any) => {
  let data = {} as apiType
  await http.getFilesExt(url + '/supplierPrice/exportHistorySupplierPrice', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出模板
export const exportFilesTmpl = async (params?: any) => {
  let data = {} as apiType
  await http.getFiles(url + '/supplierPrice/template', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// let dataparams: any[] = []
// 根据 料号 客户id 数量获取单价
export const getPrice = async (params?: any) => {
  // params.forEach((item: any) => {
  //   const index = dataparams.findIndex(val => item.materialId === val.materialId && item.quantity === val.quantity && item.saleCustomerId === val.saleCustomerId)
  //   if (index === -1) {
  //     item.price = Math.floor(Math.random() * 100)
  //     dataparams.push({ ...item })
  //   }
  // })
  // let data = []
  // if (params.length === 1) {
  //   const index = dataparams.findIndex(val => params[0].materialId === val.materialId && params[0].quantity === val.quantity)
  //   data = [dataparams[index]]
  // } else {
  //   data = [...dataparams]
  // }
  // console.log('data', data)

  // return {
  //   code: 1,
  //   data,
  //   message: ''
  // }
  let data = {} as apiType
  await http.post(url + '/supplierPrice/getPriceByCondtion', params).then((result) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
