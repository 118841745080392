import { defineStore } from 'pinia'
// const { missing } = useI18n()
import { SAuth } from '@/service'
// import { cache } from '@/utils'
import i18n from '@/i18n'
import { cloneDeep } from 'lodash'
import { ViewDataType } from 'types/table'

interface Iang {
  // 资源编码
  [key: string]: {
    // 字段名
    [key: string]: {
      alias: string
      desc: string
      key: string
    }
  }
}

// type i18nKey = 'ar' | 'de' | 'en' | 'es' | 'fa' | 'fr' | 'hi-in' | 'ja' | 'ko' | 'pt' | 'ru' | 'th' | 'vi' | 'zh-cn' | 'zh-tw'

interface StateType {
  // tableData: any
  // formData: any
  viewData: ViewDataType[]
  i18nUpdateData: {
    // 国际化语言
    [key: string]: Iang
  }
  systemVersion: {
    i18nVersion: string | number
    resourceVersion: string | number
  }
}

export const colList = defineStore({
  id: 'colList',
  state: (): StateType => ({
    // tableData: {}, // 存储表格数据
    // formData: {}, // 存储表单数据
    viewData: [], // 视图数据
    i18nUpdateData: {}, // i18n更新的数据
    systemVersion: {
      i18nVersion: '',
      resourceVersion: ''
    }// 存储资源版本
    // lang
  }),
  getters: {
    // 根据视图编码获取线上配置
    // PurchaseOrder
    // PurchaseOrder
    getViewList(state) {
      // 传入资源编码 和 视图编码
      return (sourceCode: string, viewCode: string) => {
        if (state.viewData.length <= 0) return null
        const index = state.viewData.findIndex(item => item.resCode === sourceCode)
        if (index !== -1) {
          const idx = state.viewData[index].viewRefColumn.findIndex(item => item.viewCode === viewCode)
          if (idx !== -1) {
            return state.viewData[index].viewRefColumn[idx]
          } else {
            return null
          }
        } else {
          return null
        }
      }
    }
  },
  actions: {
    // 1.视图编码，2.列名key,3.需要修改的字段{修改值的key： 修改的值}
    changeViewData(veiwCode: string, key: string, changeField: { key: string, width: number }) {
      for (let i = 0; i < this.viewData.length; i++) {
        if (this.viewData[i].resCode === key) {
          for (let j = 0; j < this.viewData[i].viewRefColumn.length; j++) {
            if (this.viewData[i].viewRefColumn[j].viewCode === veiwCode) {
              for (let k = 0; k < this.viewData[i].viewRefColumn[j].viewColumns.length; k++) {
                // const element = array[k];
                if (this.viewData[i].viewRefColumn[j].viewColumns[k].coln === changeField.key) {
                  this.viewData[i].viewRefColumn[j].viewColumns[k].widt = changeField.width
                }
              }
            }
          }
        }
      }
    },
    // 获取 资源数据
    getRequestList(name: string[]) {
      return new Promise((resolve: (value?: unknown) => void) => {
        // 根据资源编码获取 视图数据
        SAuth.getColumnList({ codes: name }).then(result => {
          if (result && result.code) {
            if (result.data) {
              // 储存 视图数据
              result.data.forEach((item: ViewDataType) => {
                const index = this.viewData.findIndex((val) => item.resCode === val.resCode)
                if (index !== -1) {
                  this.viewData[index].viewRefColumn = item.viewRefColumn
                } else {
                  this.viewData.push({ ...item })
                }
              })
            }
            resolve(result.data)
          }
        })
      })
    },
    // 获取i18n数据
    async getRequestI18n(i18nList: string[], i18nVersion: number) {
      if (i18nList && i18nList.length) {
        await SAuth.GetSourceByKey(i18nList).then(res => {
          if (res.code === 1) {
            // 存储多语言版本
            if (i18nVersion || i18nVersion === 0) this.systemVersion.i18nVersion = i18nVersion
            let lanI18n = res.data
            // 深拷贝本地国际化数据
            let i18nOld = cloneDeep(this.i18nUpdateData)
            for (const lanKey in lanI18n) {
              let i18nLocal = lanI18n[lanKey]
              // 如果本地还没有对应语言的key 就直接更新 否则只更新已修改的
              if (!i18nOld[lanKey]) {
                this.i18nUpdateData[lanKey] = i18nLocal
              } else {
                for (const key in i18nLocal) {
                  let curPageI18n = i18nLocal[key]
                  let oldCurr = i18nOld[lanKey][key]
                  this.i18nUpdateData[lanKey][key] = Object.assign(oldCurr, curPageI18n)
                }
              }

              // 更新数据到国际化插件
              this.updateI18n({ key: lanKey, value: lanI18n[lanKey] })
            }
          }
        })
      }
    },
    // 获取版本 路由跳转调用获取版本的接口
    async getSystemVersion(name?: string | null | undefined | symbol) {
      // 没有name表示空白页 不做操作
      if (!name) return true
      let localSystem = this.systemVersion
      // 版本
      let params = {
        'i18nVersion': localSystem.i18nVersion, // 国际化版本
        'resource': [name], // 资源名称
        'resourceVersion': localSystem.resourceVersion || (localSystem.resourceVersion === 0 ? localSystem.resourceVersion : '') // 资源版本
      }
      // 获取需要更新的版本
      const res = await SAuth.querySystemVersion(params).catch(() => {
        return
      })
      if (res?.code === 1) {
        // 如果资源版本需要更新就调用更新资源的接口
        if (res.data.resourceUpdate) {
          this.updateVersouse(res.data.resourceList, res.data.resourceVersion)
        }
        // 如果多语言的版本有更新 就调用多语言的接口
        if (res.data.i18nUpdate) {
          this.getRequestI18n(res.data.i18nList, res.data.i18nVersion)
        } else {
          // 如果多语言没有更新就从本地去获取
          this.updateI18nByLocal()
        }
        if (res.data.currentResourceUpdate && res.data.currentResourceList && res.data.currentResourceList.length) {
          // 同步调用
          await this.updateVersouse(res.data.currentResourceList, res.data.resourceVersion)
          return true
        }
      }
      return true
    },
    // 获取资源成功后更新资源版本
    async updateVersouse(resourseList: string[], version: number) {
      this.getRequestList(resourseList).then(() => {
        // 请求成功后更新版本
        this.systemVersion.resourceVersion = version
        return true
      })
    },
    // 从本地更新 国际化 到国际化插件
    updateI18nByLocal() {
      let i18nUpdate = this.i18nUpdateData
      for (const key in i18nUpdate) {
        this.updateI18n({ key, value: i18nUpdate[key] })
      }
    },
    // 更新国际化到国际化插件 传入需要更新的多语言数据
    updateI18n(obj: { key: string, value: Iang }) {
      // let lang:any = { warehouse: { affiliatedCompany: { key: '所属公司01', desc: '所属公司01', alias: '0' }}}
      // alias转为字符串
      for (const key in obj.value) {
        let curLangObj = obj.value[key]
        for (const keyIn in curLangObj) {
          curLangObj[keyIn].alias += ''
        }
      }
      i18n.global.mergeLocaleMessage(obj.key, obj.value)
    }
  },

  // 开启数据缓存  -- 需要在store/index.js中import piniaPluginPersist 插件
  persist: {
    enabled: true, // 开启持久化
    strategies: [
      {
        storage: localStorage, // 可以为localStorage或者sessionStorage
        // 可以通过paths指定需要持久化的值，其他没有指定的则不会持久化
        paths: [
          'viewData', 'i18nUpdateData', 'systemVersion'
        ]
      }
    ]
  }
})
