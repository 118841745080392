/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineStore } from 'pinia'

export const materStore = defineStore({
  id: 'materStore',
  state: () => {
    // 物料数据字典
    return {
      materDataDitc: []
    }
  },
  getters: {
    getDataDitc: (state) => {
      return state.materDataDitc
    }
  },
  actions: {
    setDataDitc(data: any) {
      if (this.materDataDitc.length === 0) {
        this.materDataDitc = data
      } else {
        data.forEach((item: any) => {
          let upItem: any = this.materDataDitc.find((ditc: any) => ditc.bdtc === item.bdtc)
          if (upItem) {
            upItem.dict = item.dict
          }
        })
      }
    }
  },
  persist: {
    enabled: true, // 开启持久化
    strategies: [
      {
        // key: 'token', //自定义Key，也可以去掉，使用默认值为参数id的值
        storage: localStorage, // 可以为localStorage或者sessionStorage
        // 可以通过paths指定需要持久化的值，其他没有指定的则不会持久化
        paths: ['materDataDitc']
      }
    ]
  }
})

