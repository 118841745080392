// 待入库页面接口

import { http } from '@/service'
import { HUrl } from '@/config'
// 查询库存列表-平铺(库存平铺)
export const queryPageList = async(params:object = {}) => {
  let data:any
  await http.post(HUrl.INVENTORY_QUERYPAGELIST, params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}

// 查询库存列表-收起（基于物料汇总）
export const queryPackPageList = async(params:object = {}) => {
  let data:any
  await http.post(HUrl.INVENTORY_QUERYPACKPAGELIST, params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}

// 查询库存列表-收起（基于批次汇总）
export const queryBatchPageList = async(params:object = {}) => {
  let data:any
  await http.post(HUrl.INVENTORY_QUERYBATCHPAGELIST, params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}

// /inventory/queryInventoryPage 物料库存查询(根据物料料号查询)
export const queryInventoryPage = async(params:object = {}) => {
  let data:any
  await http.post(HUrl.INVENTORY_QUERYINVENTORYPAGE, params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}

// /inventory/queryInventoryStatistics 物料库存统计信息查询(根据物料料号查询)
export const queryInventoryStatistics = async(params:object = {}) => {
  let data:any
  await http.post(HUrl.INVENTORY_QUERYINVENTORYSTATISTICS, params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}

// /inventory/queryMaterialOutboundPage 查询物料出库库存(根据物料料号查询)
export const queryMaterialOutboundPage = async(params:object = {}) => {
  let data:any
  await http.post(HUrl.INVENTORY_QUERYMATERIALOUTBOUNDPAGE, params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}

// /inventory/queryMaterialReceiptPage 查询物料入库库存(根据物料料号查询)
export const queryMaterialReceiptPage = async(params:object = {}) => {
  let data:any
  await http.post(HUrl.INVENTORY_QUERYMATERIALRECEIPTPAGE, params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}

// /inventory/queryOutboundStatistics 查询物料出库库存统计(根据物料料号查询)
export const queryOutboundStatistics = async(params:object = {}) => {
  let data:any
  await http.post(HUrl.INVENTORY_QUERYOUTBOUNDSTATISTICS, params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}

// /inventory/queryReceiptStatistics 查询物料入库库存统计(根据物料料号查询)
export const queryReceiptStatistics = async(params:object = {}) => {
  let data:any
  await http.post(HUrl.INVENTORY_QUERYRECEIPTSTATISTICS, params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}

// /inventory/inventoryExport 物料库存导出(必传参数：物料料号)
export const inventoryExportFiles = async (params?: any) => {
  let data: any
  await http.getFilesExt(HUrl.INVENTORY_INVENTORYEXPORT, params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// /inventory/materialOutboundExport 物料出库库存导出(必传参数：物料料号)
export const materialOutboundExportFiles = async (params?: any) => {
  let data: any
  await http.getFilesExt(HUrl.INVENTORY_MATERIALOUTBOUNDEXPORT, params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// /inventory/materialReceiptExport 物料入库库存导出(必传参数：物料料号)
export const materialReceiptExportFiles = async (params?: any) => {
  let data: any
  await http.getFilesExt(HUrl.INVENTORY_MATERIALRECEIPTEXPORT, params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

