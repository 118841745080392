import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import { apiType } from 'types/commonresource'

const url = HUrl.BASE_PREFIX_FINANCE // 路径前缀 + 微服务
// 获取表格分页数据
export const getPageList = async (params: any) => {
  let data: any
  await http.post(url + '/collection/queryPageList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取表格分页数据-平铺
export const queryPageListTile = async (params: any) => {
  let data: any
  await http.post(url + '/collection/queryPageListTile', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

/**
 * 原单统计
 code	string 单号
 rootNodeId	string 源头单据id
 sourceBillCode	string 源单单号
*/
export const getStatistics = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/collection/getSourceOrderStatistics', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 查询财务源单列表(参数type:单据类型)
export const getfinSourceOrderList = async (params: any) => {
  let data: any
  await http.post(url + '/finSourceOrder/queryPage', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 查询财务源单详情（参数orderCode:单据编码）
export const getfinSourceOrderDetail = async (params: any) => {
  let data: any
  await http.post(url + '/finSourceOrder/queryDetailsPage', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取单个详情
export const getInfo = async (params: any) => {
  let data: any
  await http.post(url + '/collection/querySingle', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 提交
export const submit = async (params: any) => {
  let data: any
  await http.post(url + '/collection/submit', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 删除
export const del = async (params: any) => {
  let data: any
  await http.delPost(url + '/collection/deleteList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导入
export const importFiles = async (query:any, params: any) => {
  let data: any
  await http.postFiles(url + '/collection/import', query, params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出
export const exportFiles = async (params?: any) => {
  let data: any
  await http.javaDl(url + '/collection/export', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出模板
export const exportFilesTmpl = async (params?: any) => {
  let data: any
  await http.getFiles(url + '/collection/template', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 保存
export const save = async (params: any) => {
  let data: any
  await http.post(url + '/collection/save', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 批量删除
export const deleteList = async (params: any) => {
  let data: any
  await http.post(url + '/collectionList/deleteList/ids', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 列表查询
export const queryList = async (params: any) => {
  let data: any
  await http.post(url + '/collectionList/queryList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 列表分页查询
export const queryPageDetail = async (params: any) => {
  let data: any
  await http.post(url + '/collectionList/queryPageList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 保存或修改
export const addOrSave = async (params: any) => {
  let data: any
  await http.post(url + '/collectionList/save', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 模板下载
export const template = async (params: any) => {
  let data: any
  await http.post(url + '/collectionList/template', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导入
export const importTemp = async (params: any) => {
  let data: any
  await http.post(url + '/collectionList/import', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出
export const exportTemp = async (params: any) => {
  let data: any
  await http.post(url + '/collectionList/export', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 审批流程——撤回
export const revoke = async (params: any) => {
  let data: any
  await http.post(url + '/collection/revoke', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 审批流程——反审
export const counterReview = async (params: any) => {
  let data: any
  await http.post(url + '/collection/counterReview', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
