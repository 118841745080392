/* eslint-disable @typescript-eslint/no-explicit-any */
// 引入文件
import { http, SGeneralData } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import { getWarehouseRelationships } from '@/utils/modules/dataFormatter'

/**
 * 列表
 * @param params object
 * @returns json object
 */
export const materialGetPage = async (params: any = {}) => {
  let data: any
  await http.post(HUrl.MATERIAL_GET_PAGE, params).then((result: any) => {
    if (result.data) {
      result.data.items.forEach((item: { [x: string]: number | string }, index: number) => {
        item.maid_rid = item.maid + '_' + index
        item.inventoryTotalAmount = item.inventoryTotalAmount || 0
      })
    }
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })

  return data
}
// 新增物料信息
export const materialSave = async (params: any = {}) => {
  let data: any
  await http.savePost(HUrl.MATERIAL_SAVE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })

  return data
}
// 新增物料草稿信息
export const saveDraft = async (params: any = {}) => {
  console.log('新增物料草稿信息')
  let data: any
  await http.post(HUrl.ENGINEERING + '/material/SaveDraft', params).then((result: any) => {
    data = JSON.parse(JSON.stringify(result))
    data.data = { id: '' }
    data.data.id = result.data
    console.log('物料草稿返回', data)
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
//
// 修改物料信息
export const materialEdit = async (params: any = {}) => {
  let data: any
  await http.editPost(HUrl.MATERIAL_EDIT, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })

  return data
}
// 删除物料信息
export const materialDel = async (params: any = {}, axiosType = 'delPost') => {
  let data: any
  await http[axiosType](HUrl.MATERIAL_DEL, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })

  return data
}
// 查询物料信息-根据id查
export const materialGetinfo = async (params: any = {}) => {
  let data: any
  await http.post(HUrl.MATERIAL_GETINFO, params).then((result: any) => {
    result.data.incc_code = result.data.incc
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })

  return data
}
type TmaterialApprInfo = {
  waid: string
  wpid: string
  wlid: string
  wnid: string
}
const getCurrencysType = async (updateCache = true) => {
  return SGeneralData.basicDataSelect({ bdtc: 'currencysType' }, updateCache).then((result) => {
    if (result.code === 1) {
      return result.data
    }
  })
}
// 查询物料信息-根据id查-审批专用
export const materialGetinfoApprove = async (params: any = {}) => {
  let data: any
  await http.post(HUrl.MATERIAL_GETINFO, params).then(async (result: any) => {
    for (const key in result.data) {
      if (result.data[key] === null) {
        result.data[key] = ''
      }
    }
    result.data.incc_code = result.data.incc
    const { waid, wpid, wlid, wnid } = { ...(result.data as TmaterialApprInfo) }
    let obj = await getWarehouseRelationships(waid, wpid, wlid, wnid)
    let curTypes = await getCurrencysType()
    result.data.waid = obj.waidObj.item.warehouseName
    result.data.wpid = obj.wpidObj.item.partitionName
    result.data.wlid = obj.wlidObj.item.locationName
    result.data.wnid = obj.wnidObj.item.libraryNameNumber
    result.data.accumulatedDepreciatedAmountUnit = (() => {
      let obj = curTypes.find((item: { val: string }) => item.val === result.data.accumulatedDepreciatedAmountUnit)
      return obj ? obj.txt : ''
    })()
    result.data.accumulatedDepreciatedAmount =
      `${result.data.accumulatedDepreciatedAmount}(${result.data.accumulatedDepreciatedAmountUnit})`
    console.log('审批查询结果', result)
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })

  return data
}
// 查询物料信息-根据物料编码查
export const InfoByCode = async (params: any = {}) => {
  let data: any
  await http.post(HUrl.ENGINEERING + '/material/InfoByCode', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// /engineer/
/**
 * 获取物料分类
 */
export const listByLevelAndParent = async (params: { level: number; parentId?: number }) => {
  let data: any
  let { level, parentId } = { ...params }
  await http.get('api/material/category/listByLevelAndParent', { level, parentId }).then((result: any) => {
    if (result.code === 200) {
      result.data.items = result.data
    }
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
export const getCategoryFeature = async (params: any) => {
  let data: any
  await http.get('api/material/feature/getCategoryFeature', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
export const queryPageList = async (params: any) => {
  let data: any
  if (params.total !== undefined) {
    delete params.total
  }
  await http.post('api/engineer/package/queryPageList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

export const fieldSaveOrUpdate = async (params: any) => {
  let data: any
  await http.post('api/material/field/saveOrUpdate', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

export const getFeatureSelectByCode = async (params: any) => {
  let data: any
  await http.get('api/material/feature/getFeatureSelectByCode', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

export const getSplitChar = async () => {
  let data: any
  await http.get('api/material/global/splitChar', {}).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
//  属性列表  查询分类下所有特性
export const getListSonCategory = async (
  parentId: number,
  keyword: number
) => {
  let data: any
  await http
    .post(HUrl.MATERIAL_LISTSONCATEGORY_DATA, {
      keyword: keyword,
      pageNo: 1,
      pageSize: 1,
      param: {
        parentId: parentId
      }
    })

    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
//  属性列表  查询分类特性列表
export const fieldList = async (params: any) => {
  console.log(params, '查询分类特性列表')
  let obj = {
    pageSize: params.pageSize,
    pageNo: params.pageIndex,
    keyword: params.keyWord,
    param: {
      featureId: params.data
    },
    orders: [{
      asc: false,
      column: 'update_time'
    }, {
      asc: true,
      column: 'id'
    }]
  }
  let data: any
  await http
    .post(HUrl.MATERIAL_FIELDLIST_DATA, obj)

    .then((result: any) => {
      data = {
        code: result.code === 200 ? 1 : 0,
        data: {
          items: result.records,
          totalCount: result.total
        }
      }
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

export const exportContact = async (params: any) => {
  let url = '/api/material/field/uploadField'
  let paramStrs = []
  for (const key in params) {
    paramStrs.push(`${key}=${params[key]}`)
  }
  url = url + '?' + paramStrs.join('&')
  console.log('拼接后的url', url)
  const dom = document.createElement('a')
  dom.setAttribute('href', url)
  dom.setAttribute('download', params.featureName + '.xlsx')
  dom.click()
}

export const downloadContact = async () => {
  let a = document.createElement('a')
  a.href = './fieldUploadTemplate.xlsx'
  a.download = '文件模板.xlsx'
  a.style.display = 'none'
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export const importContact = async () => {
  //
}

export const saveOrUpdate = async (params: any) => {
  let data: any
  await http.post('api/material/field/saveOrUpdateBatch', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// /engineer/package/saveOrUpdateBatch
export const getRelationFields = async (params: any) => {
  let data: any
  await http.post('api/material/field/getRelationFields', params).then((result: any) => {
    result.data.items = result.data.packaging
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  if (data.code === 200) {
    data.code = 1
  }
  return data
}

/******
 * 获取所有封装
 * ********/
export const getAllPack = async (params: any) => {
  let data: any
  await http.post('api/material/field/getRelationFields', params).then((result: any) => {
    result.data.items = result.data.packaging
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  if (data.code === 200) {
    data.code = 1
  }
  return data
}

export const delContact = async (params: []) => {
  let data: any
  await http
    .post(HUrl.MATERIAL_FIELDDELETE_DATA, params)

    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  if (data.code === 200) {
    data.code = 1
  }
  return data
}

export const codeTrans = async (params: any) => {
  let data: any
  await http.post('api/engineer/material/codeTrans', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
export const specTrans = async (params: any) => {
  let data: any
  await http.post('api/engineer/material/specTrans', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
export const exportMaterial = async (params: any) => {
  let data: any
  await http.getFilesExt('api/engineer/material/export', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
export const exportTemplateMaterial = async (params: any) => {
  let data: any
  await http.getFilesExt('api/engineer/material/exportTemplate', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

export const uploadMaterial = async (params: any) => {
  let data: any
  await http.postFiles('api/engineer/material/upload', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// /engineer/package/deleteList
export const deleteList = async (params: any) => {
  let data: any
  await http.post('api/engineer/package/deleteList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 取消
 */
export const cancel = async (params: any) => {
  let data: any
  await http.post('api/engineer/material/cancel', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 反审
 */
export const counterReview = async (params: any) => {
  let data: any
  await http.post('api/engineer/material/counterReview', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 撤回
 */
export const revoke = async (params: any) => {
  let data: any
  await http.post('api/engineer/material/revoke', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 物料归属所属公司
 */
export const materialOwners = async (params: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_SALE + '/Customer/MaterialOwners', {}, params).then((result) => {
    if (result.code === 1 && result.data) {
      result.data = fun(result.data)
    }
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
const fun = (list: { name: string }[]) => {
  return list.filter((item) => !!item.name)
}
/**
 * 查询原厂参数
 */
export const originInfoQueryPageList = async (params: any) => {
  if (!params.data.status) {
    params.data.status = '1'
  }
  let data: any
  await http.post('api/engineer/originInfo/queryPageList', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 品牌列表
 */
export const originInfoBrandList = async (params: any) => {
  let data: any
  await http.post('api/engineer/originInfo/brandList', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 品牌列表-详细信息
 */
export const originInfoDetails = async (params: any) => {
  let data: any
  await http.post('api/engineer/originInfo/detailInfo', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 提交到GG维护中心
 */
export const submitRegister = async (params: any) => {
  let data: any
  await http.post('/api/engineer/originInfo/register', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

export const getMatlInfoBySpec = async (params: any) => {
  let data: any
  await http.post('api/engineer/material/getMatlInfoBySpec', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// -***************************************************物料分类 - 全局特性 - 属性单位 **********************************************************************
/**
 * 获取单位组数据
 */
export const groupList = async (params: any) => {
  let data: any
  if (params) {
    delete params.pageIndex
    delete params.pageSize
  }
  await http.get('api/material/feature/unit/groupList', params).then((result: any) => {
    data = {
      ...result, data: {
        items: result.data
      }
    }
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 编辑单位组数据
 */
export const saveByGroup = async (params: any) => {
  let data: any
  await http.post('api/material/feature/unit/saveByGroup', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 删除单位组数据
 */
export const deleteByGroup = async (params: any) => {
  let data: any
  await http.get('api/material/feature/unit/deleteByGroup', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 获取单位分页数据
 */
export const pageList = async (params: any) => {
  let data: any
  await http.post('api/material/feature/unit/pageList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 删除单位数据
 */
export const deleteById = async (params: any) => {
  let data: any
  await http.get('api/material/feature/unit/deleteById', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 新增 单位组 单位
 */
export const unitSaveOrUpdate = async (params: any) => {
  let data: any
  await http.post('api/material/feature/unit/saveOrUpdate', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/*************************************************** ***********************************************************************/

export const queryCategoryByCode = async (params: any) => {
  let data: any
  await http.post('api/material/category/queryCategoryByCode', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
