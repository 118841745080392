/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

const url = HUrl.BASE_PRODUCTION // 路径前缀 + 微服务
// 获取表格分页数据
export const getPageList = async (params: any) => {
  const data = await http.post(url + '/picking/list/queryPageList', params).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 保存
export const save = async (params: any) => {
  const data = await http.post(url + '/picking/list/save', params).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 删除
export const del = async (params: any) => {
  const data = await http.delPost(url + '/picking/list/deleteList', params).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导入
export const importFiles = async (params: any) => {
  const data = await http.postFiles(url + '/picking/list/import', {}, params).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出
export const exportFiles = async (params?: any) => {
  const data = await http.getFilesExt(url + '/picking/list/export', params).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出模板
export const exportFilesTmpl = async (params?: any) => {
  const data = await http.getFiles(url + '/picking/list/template', params).catch((err) => {
    return http.catch(err)
  })
  return data
}
