import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import moment from 'moment'

// 查询账套列表分页
export const getAccountsetList = async (params : any) => {
  let data: any
  await http.post(HUrl.ACCOUNTSET_DATA, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 根据编码获取账套列表
export const getAccountConfigListByCode = async (params : any) => {
  let data: any
  await http.post(HUrl.GET_ACCOUNT_CONFIG_LIST_BY_CODE, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 新增账套
export const addAccountsetList = async (params : any) => {
  let data: any
  params.enab = params.enab ? 1 : 0
  await http.addPost(HUrl.ACCOUNTSET_ADD, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 编辑账套
export const editAccountsetList = async (params : any) => {
  let data: any
  params.enab = params.enab ? 1 : 0
  await http.editPost(HUrl.ACCOUNTSET_EDIT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除账套
export const delAccountsetList = async (params : any) => {
  let data: any
  await http.delPost(HUrl.ACCOUNTSET_DEL, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 单个详情
export const queryAccountConfig = async (params : any) => {
  let data: any
  await http.post(HUrl.QUERY_ACCOUNT_CONFIG, params).then((result : any) => {
    result.data.enab = result.data.enab == 1
    result.data.cret = moment(result.data.cret).format('YYYY-MM-DD HH:mm:ss')

    let updt = moment(result.data.updt).format('YYYY-MM-DD HH:mm:ss')
    if (updt.indexOf('0001-01-01') != -1) {
      result.data.updt = ''
    } else {
      result.data.updt = updt
    }

    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询单个应用日志
export const queryAppLogList = async (params : any) => {
  let data: any
  await http.post(HUrl.QUERY_APP_LOG_LIST, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 分页查询应用日志
export const queryAppLogPageList = async (params : any) => {
  let data: any
  await http.post(HUrl.QUERY_APP_LOG_PAGE_LIST, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 锁定初始化应用版本
export const lockAccountInitAppVersion = async (params : any) => {
  let data: any
  await http.post(HUrl.LOCK_ACCOUNT_INIT_APP_VERSION, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 锁定升级应用版本
export const lockAccountUpgradeAppVersion = async (params : any) => {
  let data: any
  await http.post(HUrl.LOCK_ACCOUNT_UPGRADE_APP_VERSION, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
