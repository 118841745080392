import { computed, defineAsyncComponent } from 'vue'
// 获取hooks
import basicInfofoHooksIndex from '@/views/business/purchase/brand/hooks/basicInfo' // 基本信息
import contactsHooksIndex from '@/views/business/purchase/brand/hooks/brandContact' // 联系人
import productHooksIndex from '@/views/business/purchase/brand/hooks/brandProductLine' // 收货地址
// 获取接口
import { getInfo } from '@/service/modules/purchase/brand'
import { getPageList } from '@/service/modules/purchase/brandContact'
import { getPageList as ProductPage } from '@/service/modules/purchase/brandProductLine'
import { objAny } from 'types/table'
import { apiType } from 'types/commonresource'

const formJson = () => {
  // 基础表单hooks
  const { formJson } = basicInfofoHooksIndex({ }) // 基本信息

  const res = computed(() => {
    return {
      ...formJson
    }
  })
  // 表格hooks
  const contactsHooks = contactsHooksIndex()
  const productHooks = productHooksIndex()

  return [
    {
      title: { lank: 'purchase.basicInformation', default: '基本信息' }, // 基本和商务信息
      type: 'form', // 表单
      json: res.value,
      getInfo: (params: objAny) => {
        return new Promise((resolve: (value: apiType) => void, reject) => {
          getInfo(params).then(async(result) => {
            console.log('ok||', result)
            resolve({ ...result })
          })
        })
      },
      // getInfo: getSupplierInfo,
      slot: {
        coma: defineAsyncComponent(() => import('./componentsSlot/coma.vue')),
        shah: defineAsyncComponent(() => import('./componentsSlot/shah.vue')),
        affi: defineAsyncComponent(() => import('./componentsSlot/affi.vue')),
        buid: defineAsyncComponent(() => import('./componentsSlot/buid.vue')),
        region: defineAsyncComponent(() => import('./componentsSlot/region.vue'))
        // isMo: defineAsyncComponent(() => import('./componentsSolt/isMo.vue'))
      },
      formStyle: {
        webs: [
          'grid-column: 1 / span 4;'
        ],
        suen: [
          'grid-column: 1 / span 4;'
        ],
        buss: [
          'grid-column: 1 / span 4;'
        ],
        corp: [
          'grid-column: 1 / span 4;'
        ],
        rema: [
          'grid-column: 1 / span 4;'
        ],
        coma: [
          'grid-column: 1 / span 4;'
        ],
        buid: [
          'grid-column: 1 / span 4;'
        ],
        shah: [
          'grid-column: 1 / span 4;'
        ],
        affi: [
          'grid-column: 1 / span 4;'
        ]
      }
    },
    {
      title: { lank: 'purchase.uploadAttachments', default: '上传附件' },
      type: 'files',
      code: 'Brand',
      templetCode: 'Brand'
    },
    {
      title: { lank: 'purchase.PurchaseOrderConsignee', default: '联系人' },
      type: 'table', // 表单
      formid: 'pid', //  pid="pid"
      id: 'coid', // rowId="ipid"
      columnList: contactsHooks.columnList,
      getInfo: getPageList,
      slot: {
        isen: defineAsyncComponent(() => import('./componentsSlot/isen.vue'))
      }
    },
    {
      title: { lank: 'purchase.productLineAdd', default: '增加产品线' },
      type: 'table',
      formid: 'pid',
      id: 'reid',
      columnList: productHooks.columnList,
      slot: {
        isen: defineAsyncComponent(() => import('./componentsSlot/isen.vue'))
      },
      getInfo: ProductPage
    }
  ]
}
export default formJson
