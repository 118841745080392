/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * 封装仓库相关功能等
 * @author
 * @date 2022-08-10
 */
// 引入文件
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

/**
 * 列表
 * @param params object
 * @returns json object
 */
export const minilist = async (params : any = {}) => {
  let data: any
  await http.get(HUrl.WAERH_MINI_LIST, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 查询仓库列表
 * @param params
 * @returns
 */
export const getWarehouseList = async (params : any = {}) => {
  let data: any
  await http.post(HUrl.WARCH_LIST, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 查询仓库类别
 * @param params
 * @returns
 */
export const getWarehouseCategory = async (params : any = {}) => {
  let data: any
  await http.post(HUrl.WARCH_CATEGORY, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 新增仓库类别
 * @param params
 * @returns
 */
export const addWarehouseCategory = async (params : any = {}) => {
  let data: any
  await http.addPost(HUrl.WARCH_CATEGORY_ADD, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 编辑仓库类别
 * @param params
 * @returns
 */
export const editWarehouseCategory = async (params : any = {}) => {
  let data: any
  await http.post(HUrl.WARCH_CATEGORY_EDIT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 删除仓库类别
 * @param params
 * @returns
 */
export const delWarehouseCategory = async (params : any = {}) => {
  let data: any
  await http.delPost(HUrl.WARCH_CATEGORY_DEL, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 新增仓库
 * @param params
 * @returns
 */
export const handleWarehouseAdd = async (params : any = {}) => {
  let data: any
  await http.addPost(HUrl.WARCH_ADD, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 修改仓库
 * @param params
 * @returns
 */
export const handleWarehouseEdit = async (params : any = {}) => {
  let data: any
  await http.editPost(HUrl.WARCH_EDIT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 删除仓库
 * @param params
 * @returns
 */
export const handleWarehouseDel = async (params : any = {}) => {
  let data: any
  await http.delPost(HUrl.WARCH_DEL, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 批量删除仓库
 * @param params
 * @returns
 */
export const handleWarehouseBatchDel = async (params : any = {}) => {
  let data: any
  await http.delPost(HUrl.WARCH_BATCH_DEL, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 查询单个仓库
 * @param params
 * @returns
 */
export const handleWarehouseDetail = async (params : any = {}) => {
  let data: any
  await http.post(HUrl.WAREHOUSE_SINGLE_QUERY, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 仓库导出excel
 * @param params
 * @returns
 */
export const handleWarehouseExport = async (params : any = {}) => {
  let data: any
  await http.getFilesExt(HUrl.WAREHOUSE_EXPORT, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 下载仓库excel模板
 * @param params
 * @returns
 */
export const handleWarehouseDownloadTemplate = async () => {
  let data: any
  await http.getFiles(HUrl.WAREHOUSE_TEMPLATE).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 导入仓库excel
 * @param params
 * @returns
 */
export const handleWarehouseImportTemplate = async (params : any = {}) => {
  let data: any
  await http.postFiles(HUrl.WAREHOUSE_IMPORT, {}, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 分区导出excel
 * @param params
 * @returns
 */
export const handlePartitionExport = async (params : any = {}) => {
  let data: any
  await http.getFilesExt(HUrl.PARTITION_EXPORT, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 下载分区excel模板
 * @param params
 * @returns
 */
export const handlePartitionDownloadTemplate = async (params : any = {}) => {
  let data: any
  await http.getFiles(HUrl.PARTITION_TEMPLATE).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 导入分区excel
 * @param params
 * @returns
 */
export const handlePartitionImportTemplate = async (params : any = {}) => {
  let data: any
  await http.postFiles(HUrl.PARTITION_IMPORT, {}, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 查询仓库分区
 * @param params
 * @returns
 */
export const handlePartitionList = async (params : any = {}) => {
  let data: any
  await http.post(HUrl.PARTITION_LIST, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 新增分区
 * @param params
 * @returns
 */
export const handlePartitionAdd = async (params : any = {}) => {
  let data: any
  await http.addPost(HUrl.PARTITION_ADD, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 修改分区
 * @param params
 * @returns
 */
export const handlePartitionEdit = async (params : any = {}) => {
  let data: any
  await http.editPost(HUrl.PARTITION_EDIT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 删除分区
 * @param params
 * @returns
 */
export const handlePartitionDel = async (params : any = {}) => {
  let data: any
  await http.delPost(HUrl.PARTITION_DEL, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 批量删除分区
 * @param params
 * @returns
 */
export const handlePartitionBatchDel = async (params : any = {}) => {
  let data: any
  await http.delPost(HUrl.PARTITION_BATCH_DEL, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 查询单个分区
 * @param params
 * @returns
 */
export const handlePartitionDetail = async (params : any = {}) => {
  let data: any
  await http.post(HUrl.PARTITION_SINGLE_QUERY, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 查询操作日志
 * @param params
 * @returns
 */
export const handleViewLog = async (params : any = {}) => {
  let data: any
  await http.post(HUrl.WARCH_CATEGORY_LOG, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * /warehChange/queryIdleStorageSpace 查询空闲储位(quantity：获取数量，list:物料id集合)
 */
export const queryIdleStorageSpace = async (params : any = {}) => {
  let data: any
  await http.post(HUrl.QUERYIDLESTORAGESPACE, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
