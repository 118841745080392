import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

// 新增模板组
export const templateAddGroup = async (params?: any) => {
  let data: any
  await http.addPost(HUrl.TEMPLATE_ADD_GROUP, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 批量新增模板组
export const templateAddGroupBatch = async (params?: any) => {
  let data: any
  await http.addPost(HUrl.TEMPLATE_ADD_GROUP_BATCH, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除模板组
export const templateDeleteGroup = async (params?: any) => {
  let data: any
  await http.delPost(HUrl.TEMPLATE_DELETE_GROUP, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 模板组分页查询
export const templateGroupPageList = async (params?: any) => {
  let data: any
  await http.post(HUrl.TEMPLATE_GROUP_PAGE_LIST, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 模板组列表查询
export const queryGroupList = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_GENERATE + '/template/queryGroupList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 模板组单个查询
export const templateGroup = async (params?: any) => {
  let data: any
  await http.post(HUrl.TEMPLATE_QUERY_GROUP, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询模板组的模板
export const queryTemplateByGroup = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_GENERATE + '/template/queryTemplateByGroup', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 更新模板组
export const templateUpdateGroup = async (params?: any) => {
  let data: any
  await http.editPost(HUrl.TEMPLATE_UPDATE_GROUP, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
