import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import { apiType } from 'types/commonresource'

const url = HUrl.BASE_PREFIX_PURCHASE_V // 路径前缀 + 微服务

// 相虎接口--本地调试
// const url = '/trigerSupp'

// 获取表格分页数据-汇总
export const getPageList = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/purchasePriceMaintenance/queryList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取表格分页查询收起-汇总
export const getRetractPageList = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/purchasePriceMaintenance/queryRetractPage', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取表格分页数据平铺-汇总
export const getqueryTilePageList = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/purchasePriceMaintenance/queryTilePageList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取表格列表数据-单表
export const getTableQueryList = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/priceMaintenanceList/queryList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取表格分页数据-单表
export const getTablePageList = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/priceMaintenanceList/queryPageList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取单个详情
export const getInfo = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/purchasePriceMaintenance/querySingle', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 反审
export const counterReview = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/purchasePriceMaintenance/counterReview', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 撤回
export const revokeApi = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/purchasePriceMaintenance/revoke', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 保存草稿
export const saveDraft = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/purchasePriceMaintenance/save', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 保存草稿-单行
export const saveDraftList = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/priceMaintenanceList/save', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 提交
export const submit = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/purchasePriceMaintenance/submit', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 删除
export const del = async (params: any) => {
  let data = {} as apiType
  await http.delPost(url + '/purchasePriceMaintenance/deleteList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 删除 - 单表
export const delList = async (params: any) => {
  let data = {} as apiType
  await http.delPost(url + '/priceMaintenanceList/deleteList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导入
export const importFiles = async (params: any) => {
  let data = {} as apiType
  await http.postFiles(url + '/purchasePriceMaintenance/import', {}, params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导入-单表
export const importListFiles = async (params: any) => {
  let data = {} as apiType
  await http.postFiles(url + '/priceMaintenanceList/import', {}, params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出
export const exportFiles = async (params?: any) => {
  let data = {} as apiType
  await http.getFilesExt(url + '/purchasePriceMaintenance/export', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出-单表
export const exportListFiles = async (params?: any) => {
  let data: any
  await http.getFilesExt(url + '/priceMaintenanceList/export', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出模板
export const exportFilesTmpl = async (params?: any) => {
  let data: any
  await http.getFiles(url + '/purchasePriceMaintenance/template', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出模板-单表
export const exportListFilesTmpl = async (params?: any) => {
  let data: any
  await http.getFiles(url + '/priceMaintenanceList/template', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
