import { storeToRefs } from 'pinia'
import { tagsviewStore } from '@/store' // tagsview permission的store

// 初始化相关pinia store对象

const initStore = () => {
  const settStore = tagsviewStore()
  // 设置为响应数据
  const refSettStore = storeToRefs(tagsviewStore())
  return { settStore, refSettStore }
}

// 获取高亮
export function getItemHighlight() {
  const { itemHighlight } = initStore().refSettStore
  return itemHighlight
}

// 获取数据
export function getBreadcrumb() {
  const { breadcrumb } = initStore().refSettStore
  return breadcrumb
}

// 关闭当前项
export function setBreadcrumb() {
  const { itemHighlight } = initStore().refSettStore
  const { breadcrumb } = initStore().refSettStore
  const newBreadcrumb = breadcrumb.value.filter((item: any) => {
    return item.path !== itemHighlight.value
  })
  initStore().settStore.setBreadcrumb(newBreadcrumb)
}

// 清空所有tags
export const wipebreadcrumb = () => {
  const { breadcrumb } = initStore().refSettStore
  breadcrumb.value = []
}

