// import { http } from '@/service' // 引入网络请求http
// import { HUrl } from '@/config' // 引入http请求URL地址

export type paramsType = {
  flow: string // 流程 编码
  bill: string // 单据号
}

export type statusType = 'wait' | 'process' | 'error' | 'success' | ''
/*
wait 灰色 #dadad9 默认
success 绿色 #6fd19e 成功
error 红色 #ff6d4b 驳回
process 蓝色 #5393f5 进行中
*/

export type dataType = {
  node: {
    id: string
    name: string,
    time: string,
    take: string,
    status: statusType
    operator?: string
    remarks?: string
    star?: number | string
  }
  merge: 1 | 0
}

// 展示节点
export const flowPathNodeLisrt = async (params: paramsType) => {
  let item: dataType[] = [
    {
      node: {
        name: '原单类型', // 展示名称
        id: '1',
        time: '2000-08-22 12:30', // 时间
        take: '63158400', // 用时
        status: 'success' as statusType, // 'wait' | 'process' | 'error' | 'success'
        operator: '提交人: 张三', // 操作人
        remarks: '展示节点流程展示方式：审批意见：这里是审批意见内容，这里是审批内容。这里是审批意见内容。这里是审批意见内容。这里是审批意见内容。这里是审批意见这里是意见内容。', // 备注
        star: 12 // number
      },
      merge: 1 // 1 | 0
    },
    {
      node: {
        name: '收货', // 展示名称
        id: '2',
        time: '2000-08-22 12:30', // 时间
        take: '63158400', // 用时
        status: 'success' as statusType // 'wait' | 'process' | 'error' | 'success'
      },
      merge: 1 // 1 | 0
    },
    {
      node: {
        name: '质检', // 展示名称
        id: '3',
        time: '2000-08-22 12:30', // 时间
        take: '63158400', // 用时
        status: 'success' as statusType // 'wait' | 'process' | 'error' | 'success'
      },
      merge: 1 // 1 | 0
    },
    {
      node: {
        name: '入库', // 展示名称
        id: '4',
        time: '2000-08-22 12:30', // 时间
        operator: '提交人: 李四', // 操作人
        take: '63158400', // 用时
        status: 'process' as statusType, // 'wait' | 'process' | 'error' | 'success'
        remarks: '展示节点流程展示方式：' // 备注
      },
      merge: 0 // 1 | 0
    }
  ]
  let item1: dataType[] = [
    {
      node: {
        name: '原单类型', // 展示名称
        id: '5',
        time: '2000-08-22 12:30', // 时间
        take: '63158400', // 用时
        status: 'success' as statusType // 'wait' | 'process' | 'error' | 'success'
      },
      merge: 1 // 1 | 0
    },
    {
      node: {
        name: '收货', // 展示名称
        id: '6',
        time: '2000-08-22 12:30', // 时间
        take: '63158400', // 用时
        status: 'success' as statusType // 'wait' | 'process' | 'error' | 'success'
      },
      merge: 1 // 1 | 0
    },
    {
      node: {
        name: '退货', // 展示名称
        id: '7',
        time: '2000-08-22 12:30', // 时间
        take: '63158400', // 用时
        operator: '提交人: 王五', // 操作人
        status: 'success' as statusType // 'wait' | 'process' | 'error' | 'success'
      },
      merge: 1 // 1 | 0
    },
    {
      node: {
        name: '换货', // 展示名称
        id: '8',
        time: '2000-08-22 12:30', // 时间
        take: '63158400', // 用时
        status: 'success' as statusType // 'wait' | 'process' | 'error' | 'success'
      },
      merge: 0 // 1 | 0
    }
  ]
  let item2: dataType[] = [
    {
      node: {
        name: '原单类型', // 展示名称
        id: '9',
        time: '2000-08-22 12:30', // 时间
        take: '63158400', // 用时
        status: 'success' as statusType, // 'wait' | 'process' | 'error' | 'success'
        remarks: '展示节点流程展示方式：' // 备注
      },
      merge: 1 // 1 | 0
    },
    {
      node: {
        name: '收货', // 展示名称
        id: '10',
        time: '2000-08-22 12:30', // 时间
        take: '63158400', // 用时
        status: 'success' as statusType // 'wait' | 'process' | 'error' | 'success'
      },
      merge: 1 // 1 | 0
    },
    {
      node: {
        name: '质检', // 展示名称
        id: '11',
        time: '2000-08-22 12:30', // 时间
        take: '63158400', // 用时
        status: 'success' as statusType // 'wait' | 'process' | 'error' | 'success'
      },
      merge: 1 // 1 | 0
    },
    {
      node: {
        name: '退货', // 展示名称
        id: '12',
        time: '2000-08-22 12:30', // 时间
        take: '63158400', // 用时
        status: 'error', // 'wait' | 'process' | 'error' | 'success'
        remarks: '展示节点流程展示方式：' // 备注
      },
      merge: 0 // 1 | 0
    },
    {
      node: {
        name: '收货', // 展示名称
        id: '13',
        time: '2000-08-22 12:30', // 时间
        take: '63158400', // 用时
        status: '' // 'wait' | 'process' | 'error' | 'success'
      },
      merge: 0 // 1 | 0
    },
    {
      node: {
        name: '质检', // 展示名称
        id: '14',
        time: '2000-08-22 12:30', // 时间
        take: '63158400', // 用时
        status: '' // 'wait' | 'process' | 'error' | 'success'
      },
      merge: 0 // 1 | 0
    },
    {
      node: {
        name: '入库', // 展示名称
        id: '15',
        time: '2000-08-22 12:30', // 时间
        take: '63158400', // 用时
        status: '' // 'wait' | 'process' | 'error' | 'success'
      },
      merge: 0 // 1 | 0
    }
  ]

  return {
    code: 1,
    data: [item, item1, item2],
    message: '成功'
  }
}

// 展示详情
export const flowPathDetilLisrt = async (params: paramsType) => {
  let item: dataType[] = [
    {
      node: {
        name: '原单类型', // 展示名称
        id: '16',
        time: '2000-08-22 12:30', // 时间
        take: '1000000', // 用时
        status: '', // 'wait' | 'process' | 'error' | 'success'
        operator: '', // 操作人
        remarks: '', // 备注
        star: '' // number
      },
      merge: 1 // 1 | 0
    },
    {
      node: {
        name: '收货', // 展示名称
        id: '17',
        time: '2000-08-22 12:30', // 时间
        take: '63158400', // 用时
        status: '' // 'wait' | 'process' | 'error' | 'success'
      },
      merge: 1 // 1 | 0
    },
    {
      node: {
        name: '质检', // 展示名称
        id: '18',
        time: '2000-08-22 12:30', // 时间
        take: '63158400', // 用时
        status: '' // 'wait' | 'process' | 'error' | 'success'
      },
      merge: 1 // 1 | 0
    },
    {
      node: {
        name: '入库', // 展示名称
        id: '19',
        time: '2000-08-22 12:30', // 时间
        take: '63158400', // 用时
        status: '' // 'wait' | 'process' | 'error' | 'success'
      },
      merge: 1 // 1 | 0
    }
  ]

  return {
    code: 1,
    data: [item],
    message: '成功'
  }
}
