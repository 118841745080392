import { computed, defineAsyncComponent } from 'vue'

import essenInfoHooks from '@/views/business/engineer/materialdoc/hooks/essenInfoHooks'
// import inventInfoHooks from '@/views/business/engineer/materialdoc/hooks/inventInfoHooks'
import proceInspecInfoHooks from '@/views/business/engineer/materialdoc/hooks/proceInspecInfoHooks'
import purchSalesInfoHooks from '@/views/business/engineer/materialdoc/hooks/purchSalesInfoHooks'
import deriveInfoController from '@/views/business/engineer/materialdoc/hooks/deriveInfoControllerHooks'
import { Materia } from '@/service'
import { getPageList } from '@/service/modules/engineering/matlDeriveInfo'
const formJson = () => {
  const essenInfo = essenInfoHooks()
  // const inventInfo = inventInfoHooks()
  const proceInspecInfo = proceInspecInfoHooks()
  const purchSalesInfo = purchSalesInfoHooks()
  const essenInfoComp = computed(() => {
    delete essenInfo.formJson.value.carousel
    delete essenInfo.formJson.value.logo
    essenInfo.formJson.value.macm.slot = 'macm'

    return {
      ...essenInfo.formJson.value,
      // ...inventInfo.formJson.value,
      ...proceInspecInfo.formJson.value,
      ...purchSalesInfo.formJson.value
    }
  })

  const deriveInfoControllerHooks = deriveInfoController()
  return [
    {
      title: { lank: 'purchase.essenInfo', default: '基本信息' },
      type: 'form',
      json: essenInfoComp.value,
      slot: {
        ...(() => {
          let obj = {
            incc_code: defineAsyncComponent(() => import('./componentsSlot/essenInfo.vue'))
          }
          for (const key in essenInfoComp.value) {
            const item = essenInfoComp.value[key]
            if (item.slot) {
              obj[key] = defineAsyncComponent(() => import('./componentsSlot/essenInfo.vue'))
            }
          }
          return obj
        })()
      },
      getInfo: Materia.materialGetinfoApprove
    },
    {
      title: { lank: 'purchase.deriveInfoController', default: '衍生收款信息' },
      type: 'table',
      json: essenInfoComp,
      formid: 'maid',
      id: 'id',
      columnList: deriveInfoControllerHooks.columnList,
      getInfo: getPageList
    },
    {
      title: { lank: 'purchase.proceInspecInfo', default: '检查文件' },
      type: 'files',
      code: 'Material',
      templetCode: 'proInfoTemp'
    },
    {
      title: { lank: 'purchase.proDataAtt', default: '产品资料附件' },
      type: 'files',
      code: 'Material',
      templetCode: 'material'
    }
  ]
}

export default formJson
