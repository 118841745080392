/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import { apiType } from 'types/commonresource'

const url = HUrl.BASE_PREFIX_PURCHASE_V // 路径前缀 + 微服务

// 批量删除
export const orderConfigDeleteList = (params: any) => {
  return new Promise((resolve: (value: apiType) => void, reject: (value: Error) => void) => {
    http.delPost(url + '/orderConfig/deleteList', params)
      .then(result => {
        resolve(result)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}
// 导出(采购订单id：purchaseOrderId必传参数)
export const orderConfigExport = (params?: any) => {
  return new Promise((resolve: (value: string) => void, reject: (value: Error) => void) => {
    http.getFilesExt(url + '/orderConfig/export', params)
      .then(result => {
        resolve(result)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}
// 导入(采购订单id：purchaseOrderId必传参数)
export const orderConfigImport = (params: any) => {
  return new Promise((resolve: (value: apiType) => void, reject: (value: Error) => void) => {
    http.post(url + '/orderConfig/import', params)
      .then(result => {
        resolve(result)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}
// 列表查询(采购订单id：purchaseOrderId必传参数)
export const orderConfigQueryList = (params: any) => {
  return new Promise((resolve: (value: apiType) => void, reject: (value: Error) => void) => {
    http.post(url + '/orderConfig/queryList', params)
      .then(result => {
        resolve(result)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}
// 分面查询(采购订单id：purchaseOrderId必传参数)
export const orderConfigQueryPageList = (params: any) => {
  return new Promise((resolve: (value: apiType) => void, reject: (value: Error) => void) => {
    http.post(url + '/orderConfig/queryPageList', params)
      .then(result => {
        resolve(result)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}
// 单个查询
export const orderConfigQuerySingle = (params: any) => {
  return new Promise((resolve: (value: apiType) => void, reject: (value: Error) => void) => {
    http.post(url + '/orderConfig/querySingle', params)
      .then(result => {
        resolve(result)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}
// 保存或修改
export const orderConfigSave = (params: any) => {
  return new Promise((resolve: (value: apiType) => void, reject: (value: Error) => void) => {
    http.savePost(url + '/orderConfig/save', params)
      .then(result => {
        resolve(result)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}
// 模板下载
export const orderConfigTemplate = (params?: any) => {
  return new Promise((resolve: (value: string) => void, reject: (value: Error) => void) => {
    http.getFiles(url + '/orderConfig/template', params)
      .then(result => {
        resolve(result)
      })
      .catch((error: Error) => {
        reject(error)
      })
  })
}
