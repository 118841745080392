/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

//  查询客户基础信息详情
export const getCustomerInfo = async (params : any) => {
  let data: any
  await http.post(HUrl.GET_CUSTOMER_INFO, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 审批流程——反审
export const counterReview = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_SALE + '/Customer/counterReview', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 审批流程——撤回
export const revoke = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_SALE + '/Customer/revoke', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

//  查询客户基础信息分页列表
export const getCustomerPage = async (params : any) => {
  let data: any
  await http.post(HUrl.GET_CUSTOMER_PAGE, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

//  新增修改客户草稿
export const addCustomer = async (params : any) => {
  let data: any
  await http.post(HUrl.SAVE_CUSTOMER, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

//  新增修改客户基础信息
export const submitCustomer = async (params : any) => {
  let data: any
  await http.post(HUrl.SUBMIT_CUSTOMER, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
//  删除客户基础信息
export const delCustomer = async (params : any) => {
  let data: any
  await http.delPost(HUrl.DEL_CUSTOMER, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
//  下载客户信息导入模板
export const downloadCustomer = async (params?: any) => {
  let data: any 
  await http.getFiles(HUrl.DOWNLOAD_CUSTOMER, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
//  导入客户信息
export const importCustomer = async (params : any) => {
  let data: any
  await http.postFiles(HUrl.IMPORT_CUSTOMER, {}, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
//  导出客户
export const exportCustomer = async (params?: any) => {
  let data: any
  await http.javaDl(HUrl.EXPORT_CUSTOMER, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

//  查询客户（供应商）列表
export const csdataList = async (params : any) => {
  let data: any
  await http.post(HUrl.CUSTOMER_CSDATALIST, params).then((result : any) => {
    result.data = result.data.filter((item: { name: any; code: any }) => item.name && item.code)
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询对外投资信息
export const outbouninfo = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_SALE + '/Customer/OutBounInfo', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询对外投资分页列表
export const outbounpagelist = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_SALE + '/Customer/OutBounPageList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 新增对外投资信息
export const addoutboun = async (params?: any) => {
  let data: any
  await http.savePost(HUrl.BASE_PREFIX_SALE + '/Customer/AddOutBoun', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 修改对外投资信息
export const editoutboun = async (params?: any) => {
  let data: any
  await http.savePost(HUrl.BASE_PREFIX_SALE + '/Customer/EditOutBoun', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除对外投资
export const deloutboun = async (params?: any) => {
  let data: any
  await http.delPost(HUrl.BASE_PREFIX_SALE + '/Customer/DelOutBoun', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 下载对外投资导入模板
export const downloadoutboun = async (params?: any) => {
  let data: any
  await http.getFiles(HUrl.BASE_PREFIX_SALE + '/Customer/DownloadOutBoun', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导入对外投资
export const importoutboun = async (params?: any) => {
  let data: any
  await http.postFiles(HUrl.BASE_PREFIX_SALE + '/Customer/ImportOutBoun', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导出对外投资
export const exportoutboun = async (params?: any) => {
  let data: any
  await http.getFilesExt(HUrl.BASE_PREFIX_SALE + '/Customer/ExportOutBoun', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询知识产权信息
export const intepropinfo = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_SALE + '/Customer/IntePropInfo', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询知识产权分页列表
export const inteproppagelist = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_SALE + '/Customer/IntePropPageList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 新增知识产权信息
export const addinteprop = async (params?: any) => {
  let data: any
  await http.savePost(HUrl.BASE_PREFIX_SALE + '/Customer/AddInteProp', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 修改知识产权信息
export const editinteprop = async (params?: any) => {
  let data: any
  await http.savePost(HUrl.BASE_PREFIX_SALE + '/Customer/EditInteProp', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除知识产权
export const delinteprop = async (params?: any) => {
  let data: any
  await http.delPost(HUrl.BASE_PREFIX_SALE + '/Customer/DelInteProp', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 下载知识产权导入模板
export const downloadinteprop = async (params?: any) => {
  let data: any
  await http.getFiles(HUrl.BASE_PREFIX_SALE + '/Customer/DownloadInteProp', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导入知识产权
export const importinteprop = async (params?: any) => {
  let data: any
  await http.postFiles(HUrl.BASE_PREFIX_SALE + '/Customer/ImportInteProp', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导出知识产权
export const exportinteprop = async (params?: any) => {
  let data: any
  await http.getFilesExt(HUrl.BASE_PREFIX_SALE + '/Customer/ExportInteProp', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询软件账号信息
export const softAccInfo = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_SALE + '/Customer/SoftAccInfo', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询软件账号分页列表
export const softAccPageList = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_SALE + '/Customer/SoftAccInfoPageList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 新增软件账号信息
export const addSoftAcc = async (params?: any) => {
  let data: any
  await http.savePost(HUrl.BASE_PREFIX_SALE + '/Customer/AddSoftAcc', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 修改软件账号信息
export const editSoftAcc = async (params?: any) => {
  let data: any
  await http.savePost(HUrl.BASE_PREFIX_SALE + '/Customer/EditSoftAcc', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除软件账号
export const delSoftAcc = async (params?: any) => {
  let data: any
  await http.delPost(HUrl.BASE_PREFIX_SALE + '/Customer/DelSoftAcc', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 下载软件账号导入模板
export const downloadSoftAcc = async (params?: any) => {
  let data: any
  await http.getFiles(HUrl.BASE_PREFIX_SALE + '/Customer/DownloadSoftAcc', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导入软件账号
export const importSoftAcc = async (params?: any) => {
  let data: any
  await http.postFiles(HUrl.BASE_PREFIX_SALE + '/Customer/ImportSoftAcc', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导出软件账号
export const exportSoftAcc = async (params?: any) => {
  let data: any
  await http.getFilesExt(HUrl.BASE_PREFIX_SALE + '/Customer/ExportSoftAcc', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 查询联系人列表
 */
export const contactPageList = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_SALE + '/Customer/ContactPageList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询客户联系人信息
export const contactInfo = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_SALE + '/Customer/ContactInfo', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 新增客户联系人信息
export const addContact = async (params?: any) => {
  let data: any
  await http.savePost(HUrl.BASE_PREFIX_SALE + '/Customer/AddContact', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 修改客户联系人信息
export const editContact = async (params?: any) => {
  let data: any
  await http.savePost(HUrl.BASE_PREFIX_SALE + '/Customer/EditContact', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除客户联系人
export const delContact = async (params?: any) => {
  let data: any
  await http.delPost(HUrl.BASE_PREFIX_SALE + '/Customer/DelContact', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 下载联系人导入模板
export const downloadContact = async (params?: any) => {
  let data: any
  await http.getFiles(HUrl.BASE_PREFIX_SALE + '/Customer/DownloadContact', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导入联系人
export const importContact = async (params?: any) => {
  let data: any
  await http.postFiles(HUrl.BASE_PREFIX_SALE + '/Customer/ImportContact', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导出联系人
export const exportContact = async (params?: any) => {
  let data: any
  await http.getFilesExt(HUrl.BASE_PREFIX_SALE + '/Customer/ExportContact', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询客户地址信息
export const addressInfo = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_SALE + '/Customer/AddressInfo', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询客户地址分页列表
export const addressPageList = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_SALE + '/Customer/AddressInfoPageList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 新增客户地址信息
export const addAddress = async (params?: any) => {
  let data: any
  await http.savePost(HUrl.BASE_PREFIX_SALE + '/Customer/AddAddress', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 修改客户地址信息
export const editAddress = async (params?: any) => {
  let data: any
  await http.savePost(HUrl.BASE_PREFIX_SALE + '/Customer/EditAddress', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除客户地址
export const delAddress = async (params?: any) => {
  let data: any
  await http.delPost(HUrl.BASE_PREFIX_SALE + '/Customer/DelAddress', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 下载地址导入模板
export const downloadAddress = async (params?: any) => {
  let data: any
  await http.getFiles(HUrl.BASE_PREFIX_SALE + '/Customer/DownloadAddress', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导入地址
export const importAddress = async (params?: any) => {
  let data: any
  await http.postFiles(HUrl.BASE_PREFIX_SALE + '/Customer/ImportAddress', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导出地址
export const exportAddress = async (params?: any) => {
  let data: any
  await http.getFilesExt(HUrl.BASE_PREFIX_SALE + '/Customer/ExportAddress', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询付款信息-境内
export const paymentInfo = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_SALE + '/Customer/PaymentInfo', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
import messageCenter from '@/components/qrCode/subscribe'

// 查询付款信息分页列表-境内
export const paymentInfoPageList = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_SALE + '/Customer/PaymentInfoPageList', params).then((result: any) => {
    data = result
    if (result.data && result.data.items.length > 0) {
      messageCenter.publisher({
        oban: result.data.items[0].bank,
        obanN: result.data.items[0].bana,
        bankNo: result.data.items[0].bano
      })
    }
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询付款信息列表
export const paymentInfoList = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_SALE + '/Customer/PaymentInfoList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 新增付款信息-境内
export const addPaymentInfo = async (params?: any) => {
  let data: any
  await http.savePost(HUrl.BASE_PREFIX_SALE + '/Customer/AddPaymentInfo', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 修改付款信息-境内
export const editPaymentInfo = async (params?: any) => {
  let data: any
  await http.savePost(HUrl.BASE_PREFIX_SALE + '/Customer/EditPaymentInfo', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除付款信息-境内
export const delPaymentInfo = async (params?: any) => {
  let data: any
  await http.delPost(HUrl.BASE_PREFIX_SALE + '/Customer/DelPaymentInfo', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 下载付款信息导入模板-境内
export const downloadPaymentInfo = async (params?: any) => {
  let data: any
  await http.getFiles(HUrl.BASE_PREFIX_SALE + '/Customer/DownloadPaymentInfo', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导入付款信息-境内
export const importPaymentInfo = async (params?: any) => {
  let data: any
  await http.postFiles(HUrl.BASE_PREFIX_SALE + '/Customer/ImportPaymentInfo', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导出付款信息-境内
export const exportPaymentInfo = async (params?: any) => {
  let data: any
  await http.getFilesExt(HUrl.BASE_PREFIX_SALE + '/Customer/ExportPaymentInfo', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 查看境外付款信息列表-分页
 */
export const paymentOverseasPageList = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_SALE + '/Customer/PaymentOverseasPageList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 新增境外付款信息
 */
export const addPaymentOverseas = async (params?: any) => {
  let data: any
  await http.savePost(HUrl.BASE_PREFIX_SALE + '/Customer/AddPaymentOverseas', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 修改境外付款信息
 */
export const editPaymentOverseas = async (params?:any) => {
  let data: any
  await http.savePost(HUrl.BASE_PREFIX_SALE + '/Customer/EditPaymentOverseas', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 删除境外账户信息
 */
export const delPaymentOverseas = async (params?:any) => {
  let data: any
  await http.delPost(HUrl.BASE_PREFIX_SALE + '/Customer/DelPaymentOverseas', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 下载付款信息导入模板-境外
export const downloadPaymentOverseas = async (params?: any) => {
  let data: any
  await http.getFiles(HUrl.BASE_PREFIX_SALE + '/Customer/DownloadPaymentOverseas', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 导入付款信息-境外
export const importPaymentOverseas = async (params?: any) => {
  let data: any
  await http.postFiles(HUrl.BASE_PREFIX_SALE + '/Customer/ImportPaymentOverseas', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导出付款信息-境外
export const exportPaymentOverseas = async (params?: any) => {
  let data: any
  await http.getFilesExt(HUrl.BASE_PREFIX_SALE + '/Customer/ExportPaymentOverseas', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
