import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import { cache } from '@/utils'
// 快捷键弹窗获取数据接口
export const getUserShortcut = async (params?:any) => {
  let data:any
  let saveShortcutKey = 'ShortcutData'
  // data = cache.getSessionStorage(saveShortcutKey)
  // if(data&&!params.page) {
  //     data = JSON.parse(data)
  //     if(data.data) {
  //         return data
  //     }
  // }
  // const paramB = JSON.parse(JSON.stringify(params))

  // if(paramB.page) delete paramB.page
  await http.post(HUrl.DOCUMENT_SHORTCUT, params).then(res => {
    data = res
    cache.setSessionStorage(saveShortcutKey, JSON.stringify(data))
  }).catch(err => {
    return http.catch(err)
  })
  return data
}
// 快捷键配置保存接口
export const saveUserShortcut = async (params?:any) => {
  let data:any
  await http.post(HUrl.DOCUMENT_SAVESHORTCUT, params).then(res => {
    data = res
  }).catch(err => {
    return http.catch(err)
  })
  return data
}

// 快捷键页面模板分页接口
export const GetKeyConfigTempPage = async (params?:any) => {
  let data:any
  await http.post(HUrl.DOCUMENT_GETKEYCONFIGTEMPPAGE, params).then(res => {
    data = res
  }).catch(err => {
    return http.catch(err)
  })
  return data
}
// 快捷键页面模板新增
export const SaveKeyConfigTemp = async (params?:any) => {
  let data:any
  await http.addPost(HUrl.DOCUMENT_SAVEKEYCONFIGTEMP, params).then(res => {
    data = res
  }).catch(err => {
    return http.catch(err)
  })
  return data
}
// 通过id查找快捷键页面模板
export const GetKeyConfigTempInfo = async (params?:any) => {
  let data:any
  await http.post(HUrl.DOCUMENT_GETKEYCONFIGTEMPINFO, params).then(res => {
    data = res
  }).catch(err => {
    return http.catch(err)
  })
  return data
}
// 修改快捷键页面模板
export const EditKeyConfigTemp = async (params?:any) => {
  let data:any
  await http.editPost(HUrl.DOCUMENT_EDITKEYCONFIGTEMP, params).then(res => {
    data = res
  }).catch(err => {
    return http.catch(err)
  })
  return data
}
// 删除快捷键页面模板
export const DelKeyConfigTemp = async (params?:any) => {
  let data:any
  await http.delPost(HUrl.DOCUMENT_DELKEYCONFIGTEMP, params).then(res => {
    data = res
  }).catch(err => {
    return http.catch(err)
  })
  return data
}
export const enabSave = async (params?:any) => {
  let data:any
  await http.post(HUrl.DOCUMENT_SETKEYCONFIGTEMPSTATE, params).then(res => {
    data = res
  }).catch(err => {
    return http.catch(err)
  })
  return data
}

// 获取快捷键列表 没有分页
export const funConfigTempPageList = async (params?: any) => {
  const data = await http.post(HUrl.BASE_PREFIX_SYS + '/FunctionConfig/FunConfigTempPageList', params)
  return data
}
