import indexHooks from '@/views/business/sales/salesOrder/hooks/indexHooks'
import companyInfoHookse from '@/views/business/sales/salesOrder/hooks/companyInfo'
import customerInfoHooks from '@/views/business/sales/salesOrder/hooks/customerInfo'
import orderInfoHooks from '@/views/business/sales/salesOrder/hooks/orderInfo'
import orderDetailHookse from '@/views/business/sales/salesOrder/hooks/orderDetail'

import { getPageList, getInfo } from '@/service/modules/sales/salesOrder'
import { computed, ref } from 'vue'
import { getPageList as getOrderList } from '@/service/modules/sales/saleOrderList'
const SalesOrder = () => {
  const { columnList } = indexHooks()
  const { formJson: companyJson } = companyInfoHookse()
  const { formJson: customerJson } = customerInfoHooks()
  const { orderInfo, orderAmountInfo } = orderInfoHooks()
  const { columnList: detailColumnList } = orderDetailHookse(ref(''), ref(''))

  const orderJson = computed(() => {
    return {
      ...orderInfo.value,
      ...orderAmountInfo.value
    }
  })
  const printView = [
    {
      viewCode: 'SalesOrder.index',
      columnList: columnList,
      getData: {
        fn: async () => {
          const result = await getPageList({
            data: {},
            pageIndex: 1,
            pageSize: 9999
          })
          return result
        }
      }
    },
    {
      viewCode: 'SalesOrder.companyInfo', // 公司信息
      formJson: companyJson,
      getData: {
        fnName: 'getInfo',
        fn: async (id: string) => {
          const result = getInfo({ id })
          return result
        }
      }
    },
    {
      viewCode: 'SalesOrder.customerInfo', // 客户信息
      formJson: customerJson,
      getData: {
        fnName: 'getInfo'
      }
    },
    {
      viewCode: 'SalesOrder.orderInfo', // 订单信息
      formJson: orderJson.value,
      getData: {
        fnName: 'getInfo'
      }
    },
    {
      viewCode: 'SalesOrder.orderDetail', // 订单详情
      columnList: detailColumnList,
      getData: {
        fn: async (orderId: string) => {
          const result = await getOrderList({ data: { orderId: orderId }})
          // if (result.code && result.data.customer) {

          // }
          return result
        }
      }
    }

    // {
    //   viewCode: 'SalesOrder.index',
    //   columnList: columnList,
    //   getData: {
    //     fnName: '',
    //     fn: async () => {
    //       const result = await getPageList({
    //         pageIndex: 1,
    //         pageSize: 99
    //       })
    //       return result
    //     }
    //   }
    // }
  ]
  return printView
}

export default SalesOrder()
