/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

// // 查询详情
// export const DBSERVER_INFO = BASE_PREFIX_SYS + '/DBServer/GetInfo'
export const info = async (params: any) => {
  let data: any
  await http.get(HUrl.DBSERVER_INFO, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// // 新增服务器
// export const DBSERVER_SAVE = BASE_PREFIX_SYS + '/DBServer/Save'
export const save = async (params: any) => {
  let data: any
  await http.post(HUrl.DBSERVER_SAVE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// // 修改服务器
// export const DBSERVER_EDIT = BASE_PREFIX_SYS + '/DBServer/Edit'
export const edit = async (params: any) => {
  let data: any
  await http.post(HUrl.DBSERVER_EDIT, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// // 删除服务器
// export const DBSERVER_DEL = BASE_PREFIX_SYS + '/DBServer/Del'
export const del = async (params: any) => {
  let data: any
  await http.post(HUrl.DBSERVER_DEL, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// // 分页查询
// export const DBSERVER_PAGE = BASE_PREFIX_SYS + '/DBServer/GetPage'
export const getPage = async (params: any) => {
  let data: any
  await http.post(HUrl.DBSERVER_PAGE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  // setting.setRenovate()
  return data
}
