import { defineStore } from 'pinia'
import { watch } from 'vue'
import { ElMessage } from 'element-plus'
import { formatKeyStr } from '@/components/operateBar/components/toKeyStr'
let btnsArr: any = []
let errArr = []
export const windowKeyDown = defineStore({
  id: 'windowKeyDownId',
  state: () => ({
    btnsData: [], // 存储用户打开的快捷键 [{key:'',value:''}] key对应的事件名 value 对应的按键组合
    currentBtn: '', // 存储用户按下的按钮
    eventKey: '', // 存储用户按下的按钮对应的事件名
    shortcut: false
  }),
  getters: {
  },
  actions: {
    addWindowsKeyDown() {
      window.addEventListener('keydown', this.keydown)
    },
    removeWindowsKeyDown() {
      window.removeEventListener('keydown', this.keydown)
    },
    setBtnsData(val: any) {
      const btnData = JSON.parse(JSON.stringify(this.btnsData ? this.btnsData : []))
      let btnDataObj = {} as any
      // 把本地已经有了的快捷键数组转为对象 减少循环次数
      if (btnData && btnData.length) {
        btnData.forEach((element:any) => {
          btnDataObj[element.key] = element
        })
      }
      console.log('btnData.lengthset', btnData.length)
      let newKeyObj = JSON.parse(JSON.stringify(btnDataObj))
      // 循环新数据 将新数据更新或者添加到存储的数据中
      for (const key in val) {
        newKeyObj[key] = val[key]
      }
      this.btnsData = Object.values(newKeyObj)
    },
    delBtnsData(val: string) {
      const btnData = JSON.parse(JSON.stringify(this.btnsData ? this.btnsData : []))
      for (let i = 0; i < btnData.length; i++) {
        const btnItem = btnData[i]
        if (btnItem.key === val) {
          btnData.splice(i, 1)
          this.btnsData = btnData
          return
        }
      }
    },
    setCurrentBtn(val: any) {
      console.log('btngf333', val)
      this.currentBtn = val
    },
    seteventKey(val: any) {
      console.log('event', val)

      this.eventKey = val
    },
    setshortcut(val: boolean) {
      this.shortcut = val
    },
    keydown(e: any) {
      let btnTime: any = null
      // ElMessage.info('5')
      const btnsD = this.btnsData
      console.log('trycatch', this.btnsData)
      // e.preventDefault()
      // 清空计时器
      if (btnTime) clearTimeout(btnTime)
      //    try {
      // if (btnsD && btnsD.length) {
      // ElMessage.info('6')
      // 将当前用户的键盘按钮存到数组中
      const strData = formatKeyStr({ 'code': e.code, 'noTip': true }) || []
      // ElMessage.info('7')
      console.log(btnsD.length, 'btnsD343434343ttt')
      if (!btnsArr) {
        alert(btnsArr)
        return
      }
      // ElMessage.info('8')
      if (!(btnsArr.includes(strData)) && btnsArr.length < 4) {
        btnsArr.push(strData)
      }

      console.log(btnsArr, '34444444tnArr124')
      // ElMessage.info('1')
      btnTime = setTimeout(() => {
        // ElMessage.info('2')
        // if(!btnsArr||!btnsArr.length)return
        let str = btnsArr.length > 1 ? btnsArr.join(' + ') : btnsArr[0]
        if (str) {
          console.log('btnsD.length', btnsD.length)
          for (let i = 0; i < btnsD.length; i++) {
            const curItem:IbtnObj = btnsD[i]
            if (curItem.value === str) {
              console.log('ghfgh', str, curItem)
              // 将当前用户按下的按钮进行存储
              // ElMessage.info('buttn')
              console.log(' stopww')

              this.setCurrentBtn(str)
              // // 存储当前按钮对应的事件名
              this.seteventKey(curItem.key)
              btnsArr = []
              str = ''
              console.log('bbbb', btnsArr)

              return
            }
          }
        }
        btnsArr = []
        str = ''
      }, 500)
      // }
      //    } catch (error) {
      //     console.log(error);

      //    }
    }
  },

  persist: {
    enabled: true, // 开启持久化
    strategies: [
      {
        // key: 'token', //自定义Key，也可以去掉，使用默认值为参数id的值
        storage: sessionStorage, // 可以为localStorage或者sessionStorage
        // 可以通过paths指定需要持久化的值，其他没有指定的则不会持久化
        paths: ['btnsData', 'currentBtn', 'eventKey', 'shortcut']
      }
    ]
  }
})
const backKey = (str: string) => {
  if (str === 'Control') str = 'ctrl'
  return str.toUpperCase()
}
// async function keydown(e: any) {
//     console.log('await123');

//     await formatKeyStr(e.code, 'window').then(res => {
//         const windowKeyDownStore = windowKeyDown()
//         // e.preventDefault()
//         // 清空计时器
//         if (btnTime) clearTimeout(btnTime)
//         let btnsD = windowKeyDownStore.btnsData ? windowKeyDownStore.btnsData : []
//         if (btnsD && btnsD.length) {
//             // 将当前用户的键盘按钮存到数组中
//             const strData = res
//             console.log(btnsArr, 'ttttttttttttt');
//             console.log(btnsD.length, 'btnsDttttttttttttt');
//             if (!btnsArr) {
//                 alert(btnsArr)
//                 return
//             }
//             if (!(btnsArr.includes(strData)) && btnsArr.length < 4) {
//                 btnsArr.push(strData)
//             }

//             btnTime = setTimeout(() => {
//                 let str = btnsArr.join(' + ')
//                 // 
//                 if (str) {
//                     console.log('btnsD.length', btnsD.length);

//                     for (let i = 0; i < btnsD.length; i++) {
//                         const curItem: any = btnsD[i];
//                         if (curItem.value === str) {
//                             console.log('ghfgh', str, curItem);
//                             // 将当前用户按下的按钮进行存储
//                             windowKeyDownStore.setCurrentBtn(str)
//                             // 存储当前按钮对应的事件名
//                             windowKeyDownStore.seteventKey(curItem.key)
//                             btnsArr = []
//                             str = ''
//                             console.log('bbbb', btnsArr);

//                             return
//                         }
//                     }
//                 }
//                 btnsArr = []
//                 str = ''
//             }, 500)
//         }
//     })
// }
interface IbtnObj {
    key:string,
    value:string
}
