/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * 封装菜单界面功能、比如：列表、添加、删除、修改、审核、角色、权限、功能等
 * @author lcb
 * @date 2022-03-29
 */
// 引入文件
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

/**
 * 左边菜单
 * @param params object
 * @returns json object
 */
export const getMenu = async (params: any = {}) => {
  let data: any
  await http.post(HUrl.ADMIN_MENU_LIST_GET, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 角色授权菜单树(old) - 菜单设置
 * @param params object
 * @returns json object
 */
// export const getAuthTree = async (params: any = {}) => {
//   let data: any
//   await http.post(HUrl.ADMIN_AUTH_TREE, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }
/**
 * 角色授权菜单树(2023/08/04，替换 角色授权菜单树(old) ) - 菜单设置
 * @param params object
 * @returns json object
 */
export const getAuthTree = async (params: any = {}) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_INIT_ADMIN + '/menu/queryList', params).then((result: any) => {
    console.log('新的接口返回的数据', result.data)
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 菜单权限
 * @param params
 * @returns
 */
export const getPower = async (params: any = {}) => {
  let data: any
  await http.get(HUrl.ADMIN_GET_POWER, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 列表 - 资源列表
 * @param params object
 * @returns json object
 */
export const list = async (params: any = {}) => {
  let data: any
  await http.post(HUrl.BASE_ADMIN_MENU_LIST, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 列表 - 系统设置 - 旧版
 */
// export const getList = async (params: any = {}) => {
//   let data: any
//   await http.post(HUrl.ADMIN_MENU_LIST, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

/**
 * 列表 - 系统设置 2023-08-10更新
 */
export const getList = async (params: any = {}) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_INIT_START_RESOUCE + '/listset/queryList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 获取菜单 详情
export const menuDetail = async (params: any = {}) => {
  let data: any
  await http.post(HUrl.BASE_ADMIN_MENU_DETAIL, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 菜单设置详情
export const menuSettingDetail = async (params: any = {}) => {
  let data: any
  await http.post(HUrl.ADMIN_MENU_LIST_DETAIL, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

export const menuSettingInfo = async (params: any = {}) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_INIT_START_RESOUCE + '/listset/info', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

export const menuDesbInfo = async (params: any = {}) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_INIT_START_RESOUCE + '/listset/info/langsource', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
export const saveLangsource = async (params: any = {}) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_INIT_START_RESOUCE + '/listset/save/langsource', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/** 新增菜单 */
export const addListClick = async (params: any = {}) => {
  let data: any
  await http.addPost(HUrl.BASE_ADMIN_MENU_SAVE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/** 修改菜单 */
export const editListClick = async (params: any = {}) => {
  let data: any
  await http.editPost(HUrl.BASE_ADMIN_MENU_EDIT, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/** 修改菜单 --- 菜单设置 */
export const editMenu = async (params: any = {}) => {
  let data: any
  await http.editPost(HUrl.ADMIN_MENU_EDIT, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
export const submitMenu = async (params: any = {}) => {
  let data: any
  await http.editPost(HUrl.BASE_PREFIX_INIT_START_RESOUCE + '/listset/editMenuDTO', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/** 删除菜单 */
export const delListClick = async (params: any = {}) => {
  let data: any
  await http.delPost(HUrl.BASE_ADMIN_MENU_DEL, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 此接口用于添加数据列信息
export const tableSavecolumn = async (params: any = {}) => {
  let data: any
  await http.addPost(HUrl.TABLE_SAVECOLUMN, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除数据列
export const tableDelcolumn = async (params: any = {}) => {
  let data: any
  await http.delPost(HUrl.TABLE_DELCOLUMN, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 修改数据列-提示
export const tableEditcolumn = async (params: any = {}) => {
  let data: any
  await http.editPost(HUrl.TABLE_EDITCOLUMN, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 修改数据列-不提示
export const tableEditcolumnNo = async (params: any = {}) => {
  let data: any
  await http.post(HUrl.TABLE_EDITCOLUMN, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询数据列详情
export const tableGetinfo = async (params: any) => {
  let data: any
  await http.post(HUrl.TABLE_GETINFO, params).then((result: any) => {
    data = result
  }).catch((err: unknown) => {
    return http.catch(err)
  })
  return data
}
// 此接口查询数据列列表分页信息
export const tableGetcolumnpage = async (params: any = null) => {
  let data: any
  await http.post(HUrl.TABLE_GETCOLUMNPAGE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// json数据导入字段映射
export const syncFields2Table = async (params: any) => {
  const result = await http.post(HUrl.BASE_PREFIX_SYS + '/TableBase/SyncFields2Table', params)
  return result
}

// 从其他视图复制字段到目标视图
export const CoyFields2View = async (params: any) => {
  const result = await http.post(HUrl.BASE_PREFIX_SYS + '/TableBase/CoyFields2View', params)
  return result
}

/** 下载模板 */
export const tableDownloadColumnTemp = async (params: any = {}) => {
  let data: any
  await http.getFiles(HUrl.TABLE_DOWNLOAD_COLUMN_TEMP, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/** 导入数据列 */
export const tableImportColumn = async (formData: any = {}) => {
  let data: any
  await http.postFiles(HUrl.TABLE_IMPORT_COLUMN, {}, formData).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/** 新增关联映射 */
export const tableSaveResources = async (params: any = {}) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_SYS + '/TableBase/AddResources', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/** 删除关联映射 */
export const tableDelResources = async (params: any = {}) => {
  let data: any
  await http.delPost(HUrl.BASE_PREFIX_SYS + '/TableBase/DelResources', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/** 修改关联映射 */
export const tableEditResources = async (params: any = {}) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_SYS + '/TableBase/EditResources', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/** 查询关联映射详情 */
export const tableGetResourcesInfo = async (params: any = {}) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_SYS + '/TableBase/ResourcesInfo', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/** 查询关联映射列表(旧版) */
export const getResourceslist = async (params: any = null, isItems = true) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_SYS + '/TableBase/ResourcesDataList', params).then((result: any) => {
    if (isItems) {
      data = { ...result, data: { items: result.data }}
    } else {
      data = result
    }
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/** 查询关联映射列表(2023-08-10 更新) */
export const tableGetResourceslist = async (params: any = null, isItems = true) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_INIT_START_RESOUCE + '/listset/querySingle', params).then((result: any) => {
    if (isItems) {
      data = { ...result, data: { items: result.data }}
    } else {
      data = result
    }
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询表单列
export const tableGetformcolinfo = async (params: any = {}) => {
  let data: any
  await http.get(HUrl.TABLE_GET_GETFORMCOLINFO, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询表单列列表分页
export const tablePostformcolpage = async (params: any = null) => {
  let data: any
  await http.post(HUrl.TABLE_POST_GETFORMCOLPAGE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询权限表单列集合
export const tablePostformcollist = async (params: any = null) => {
  let data: any
  await http.post(HUrl.TABLE_POST_GETFORMCOLLIST, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 新增表单列
export const tablePostsaveformcol = async (params: any = null) => {
  let data: any
  await http.addPost(HUrl.TABLE_POST_SAVEFORMCOL, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 修改表单列-提示
export const tablePosteditformcol = async (params: any = null) => {
  let data: any
  await http.editPost(HUrl.TABLE_POST_EDITFORMCOL, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 修改表单列-不提示
export const tablePosteditformcolNo = async (params: any = null) => {
  console.log('错误特使')
  let data: any
  await http.post(HUrl.TABLE_POST_EDITFORMCOL, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除表单列
export const tablePostdelformcol = async (params: any = null) => {
  let data: any
  await http.delPost(HUrl.TABLE_POST_DELFORMCOL, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 此接口用于保存菜单资料模板

export const menuInfoTempSave = async (params: any = null) => {
  let data: any
  await http[params.mtid ? 'editPost' : 'addPost'](HUrl.MENU_INFO_TEMP_SAVE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
export const menuinfotempItemsList = async (params: any) => {
  let data: any
  await http.post(HUrl.MENUINFOTEMP_DATALIST, params).then((result: any) => {
    result.data.items = result.data
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 删除资料模板
export const menuInfoTempDel = async (params: any) => {
  let data: any
  await http.delPost(HUrl.MENU_INFO_TEMP_DEL, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查看资料模板详情
export const menuInfoTempInfo = async (params: any) => {
  let data: any
  await http.post(HUrl.MENU_TEMP_INFO, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/* ==============功能 按钮配置============ */
// 查询按钮列表 系统
export const menuFunList = async (params: any) => {
  const data = await http.post(HUrl.BASE_PREFIX_SYS + '/MenuBase/MenuFunList', params)
  return data
}
// 查询按钮列表 用户
export const powerFuncList = async (params: any) => {
  const data = await http.post(HUrl.BASE_PREFIX_SYS + '/Menu/PowerFuncList ', params)
  return data
}

// 新增按钮
export const saveMenuFuns = async (params: any) => {
  const data = await http.post(HUrl.BASE_PREFIX_SYS + '/MenuBase/SaveMenuFuns', params)
  return data
}

// 删除单个按钮配置
export const delMenuFuns = async (params: any) => {
  const data = await http.delPost(HUrl.BASE_PREFIX_SYS + '/MenuBase/DelMenuFuns', params)
  return data
}

// 同步菜单到用户库。
export const dataSyncBusiness = async () => {
  const data = await http.post(HUrl.BASE_PREFIX_SYS + '/MenuBase/DataSyncBusiness')
    .catch(error => {
      return http.catch(error)
    })
  return data
}

