// 字典下拉列表数据- 在职状态
export const staffDown = 'EmployeeStatus'
// 字典下拉列表数据- 最高学历
export const education = 'Education'
// 字典下拉列表数据- 试用期
export const probationDown = 'ProbationPeriod'
// 字典下拉列表数据- 请假类型
export const ask4LeavecodeDown = 'Ask4Leavecode'
// 字典下拉列表数据- 货币类型
export const currencyDown = 'currency'
export const currencysType = 'currencysType'
// 字典下拉列表数据- 账号类型
export const companyAccount = 'companyAccount'
// 字典下拉列表数据- 企业类型
export const companyType = 'companyType'
// 字典下拉列表数据- 设备类型
export const hardwareCategory = 'hardwareCategory'
// 字典下拉列表数据- 设备名称
export const hardwareName = 'hardwareName'
// 字典下拉列表数据- 日志类型
export const logTypeDown = 'entityType'
// 政务硬件类型
export const govHardwareType = 'govHardwareType'
// 字典下拉列表数据- 物料归属
export const materialOwnerDown = 'materialOwner'
// 字典下拉列表数据- 仓库类型
export const warehouseTypeDown = 'warehouseType'
// 字典下拉列表数据- 仓库状态
export const warehouseStatusDown = 'warehouseStatus'
// 字典下拉列表数据- 仓库类别
export const warehouseCategory = 'warehouseCate'

// 字典下拉列表数据- 在职状态
export const employeeStatus = 'EmployeeStatus'

// 字典下拉列表数据- 宿舍号
export const dormitoryNo = 'DormitoryNo'
// 字典下拉列表数据- 驻厂补助
export const factorySubsidy = 'FactorySubsidy'
// 字典下拉列表数据- 公积金基数
export const ppfBase = 'PPFBase'
// 字典下拉列表数据- 社保基数
export const SSWB = 'SSWB'

// 关联公司关系
export const association = 'association'

// 初始化状态
export const InitStatus = 'InitStatus'
// 初始化检查类型
export const initCheckType = 'InitCheckType'
// 初始化检查回退类型
export const initBackCheck = 'InitBackCheck'

// 请假单提交编码
export const leaveEncode = 'Leave'
// 角色类型
export const roleType = 'roleType'
// 员工类型
export const employeeType = 'EmployeeType'
// 员工状态
export const EmployeeStatusDown = 'EmployeeStatus'
// 保险下拉
export const insurance = 'insurance'
// 物料归属
export const materialOwner = 'materialOwner'

// 单位类型
export const unitType = 'unitType'

// 初始化步骤类型
export const inittemplateType = 'InittemplateType'

// 审核状态
export const auditStatus = 'AuditStatus'
// 结项状态
export const closingStatus = 'ClosingStatus'
// 客户状态
export const customerStatus = 'CustomerStatus'
// 客户分类
export const customerClass = 'CustomerClass'
// 客户来源
export const customerSource = 'CustomerSource'
// 客户标签
export const cusLabel = 'CusLabel'
// 客户类型
export const customerType = 'CustomerType'
// 信用评级
export const creditRating = 'CreditRating'
// 客户等级
export const customerGrade = 'CustomerGrade'
//  客户 - 收货地址类型
export const deliveryAddrType = 'DeliveryAddrType'
// 入股类型
export const sharesType = 'sharesType'
// 行业
export const industry = 'Industry'
// 付款方式（物流用）
export const paymentMethods = 'PaymentMethods'
// 付款方式（转账用）(客户供应商)
export const payMethod = 'PayMethod'

// 一级应用-市场
export const appMarket = 'AppMarket'
// 二级应用-行业
export const appIndustry = 'AppIndustry'
// 三级应用-产品
export const appProduct = 'AppProduct'
// 列表格式
export const dataType = 'dataType'
// 结算方式
export const cusPayMethod = 'CTBMethod'
// 预付比例
export const advanceRatio = 'AdvanceRatio'
// 付款类型
export const paymentType = 'PaymentType'
// 付款周期
export const paymentPeriod = 'PaymentPeriod'
// 单据编码类型
export const billType = 'BillType'
// 逾期货款超时时限
export const timeLimitOverduePayment = 'LoanTimeout'
// 默认税率
export const defaultTaxRate = 'TaxRate'
export const TaxRate = 'TaxRate'
// 发货方式
export const deliveryMethod = 'DeliveryMethod'
// 承远商
export const Transporter = 'Transporter'
// 运费支付
export const remotePayment = 'RemotePayment'

// 源单类型
export const sourceDocType = 'sourceDocType'

// 系统型号
export const systemType = 'SystemType'

/**
 *  版本类型
 */
export const versionType = 'VersionType'
// 审批状态
export const approvalStatus = 'approvalStatus'
// 收货类型
export const typeReceived = 'typeReceived'
/**
 *  应用日志类型
 */
export const appLogType = 'AppLogType'

// 供应商类型
export const supplierType = 'supplierType'
export const supplierStatus = 'supplierStatus'
// 供应商分类
export const supplierClass = 'supplierClass'
// 供应商等级
export const supplierGrade = 'supplierGrade'
export const supplierBrand = 'supplierBrand'
// 供应商标签
export const supplierLabel = 'supplierLabel'
export const supplierSource = 'supplierSource'
export const outsType = 'outsType'
export const paymentMethod = 'paymentMethod'
export const fourDict = 'fourDict'
// 类型(知识产权-客户&供应商)
export const iprType = 'IprType'

// 职位编码
export const jobnDict = 'jobnDict'
export const payAccountType = 'PayAccountType'
// 账号类型
export const acctType = 'acctType'
// 承运商
export const carrier = 'carrier'

export const dism = 'dism'
// 归属区域-客户
export const belongingArea = 'belongingArea'

// 时间类型
export const timeType = 'TimeType'
// 附件-到期后处理方式
export const processingMethod = 'ProcessingMethod'
// 附件-提醒备注
export const reminderRemarks = 'ReminderRemarks'
// 附件-提醒类型
export const reminderType = 'ReminderType'
// 附件-更新分类
export const fileNovationType = 'FileNovationType'
// 审核状态
export const AuditStatus = 'AuditStatus'
// 账号状态
export const AccountStatus = 'AccountStatus'

// 仓库类别
export const warehouseCate = 'warehouseCate'
// 仓库类型
export const warehouseType = 'warehouseType'
// 仓库状态
export const warehouseStatus = 'warehouseStatus'
// 入库类型
export const typeWarehousing = 'typeWarehousing'
// 物料归属

// 物料类型，
export const materialType = 'materialType'
// 物料属性，
export const materAttri = 'materAttri'
// 物料分类，
export const materialClass = 'materialClass'
// 物料封装，
export const materialPack = 'materialPack'
// 包装方式，
export const packagingMode = 'packagingMode'
// 物料标签，
export const materialLabel = 'materialLabel'
// 后段工艺，
export const subquentPro = 'subquentPro'
// 商品类别，
export const commCate = 'commCate'
// 产品线
export const productLine = 'ProductLine'
// ProductLine
// 解决方案-入库至使用最长，
export const solution = 'solution'
// 解决方案-有效期管理，
export const overdueValidity = 'overdueValidity'
// 解决方案-盘点周期，
export const overdueInventory = 'overdueInventory'
// 送料器类型，
export const feederType = 'feederType'
// 检验方法，
export const inspectionMethod = 'inspectionMethod'
// 检验标准，
export const inspecStan = 'inspecStan'
// 检验工具，
export const inspectionTool = 'inspectionTool'
// 基本品质检查方案
export const basicQuality = 'basicQuality'
// 计量单位
export const unitMeasu = 'unitMeasu'
// 币种
export const currency = 'currency'
// 存货分类
export const invenClass = 'invenClass'
// 数量精度
export const quantityAccuracy = 'quantityAccuracy'
// 时间单位
export const timeUnit = 'timeUnit'
// 最小数量
export const minQuantity = 'minQuantity'
// 产地
export const producAddress = 'producAddress'
// 入库至使用最长
export const maxUseTime = 'maxUseTime'
// 有效期
export const termValidity = 'termValidity'
// 盘点周期管理
export const countingCycle = 'countingCycle'
// 使用默认损耗
export const useDefaultLoss = 'useDefaultLoss'
// 后端加工点数
export const backPoints = 'backPoints'
// smt加工点数
export const smtPoints = 'smtPoints'
// 原厂名称
export const origFactName = 'origFactName'
// 采购时效(天) PURT
export const purtDict = 'purtDict'
// 采购税率(%) PUTR
export const putrDict = 'TaxRate'
// 销售时效(天) SALT
export const saltDict = 'saltDict'
// 销售税率(%) SATR
export const satrDict = 'TaxRate'
// 出库类型
export const stockOutType = 'stockOutType'

// 代码生成
export const codeTplType = 'CodeTplType'
// 代码生成 - 变量类型
export const varType = 'VarType'
// 代码生成 - 应用模块
export const appModule = 'Module'
// 代码生成 - 元素类型
export const elType = 'ElementType'
// 代码生成 - 表单校验
export const check = 'Check'
// 开关状态1
export const functionalSwitch = 'functionalSwitch'
// 开关状态2
export const sustainSwitch = 'sustainSwitch'

/* ==========项目 - bug=========== */

// 选择BUG反馈模板
export const chooseBugTemp = 'chooseBugTemp'

// 反馈次数
export const feedbackNum = 'feedbackNum'

// 缺陷归属
export const defectAttribute = 'defectAttribute'

// 缺陷类型
export const defectType = 'defectType'

// 产品类型
export const bugProductType = 'bugProductType'

// 优先级
export const priority = 'priority'

// 严重程度
export const severity = 'severity'

// 复现概率
export const recurrent = 'recurrent'

/* ==========采购 - 申请=========== */
// 申请-源单类型
export const sourcePurType = 'sourcePurType'

// 申请-采购状态
export const purOrderStatus = 'purStatus'
export const purStatus = 'purStatus'

/* ==========采购 - 品牌=========== */
// 品牌-产品线
export const brandProductLine = 'brandProductLine'

// 品牌-品牌归属国
export const country = 'country'

// 品牌-地区
export const region = 'region'

// 品牌-增加产品线-产品线名称
export const productLineName = 'productLineName'

// 收款周期
export const collectionPeriod = 'collectionPeriod'
// 提前提醒时间
export const reminderTime = 'reminderTime'

/* ==========销售 - 销售订单=========== */
// 销售订单 - 订单类型
export const orderType = 'orderType'

// 销售订单 - 结算方式
export const settleMethod = 'settleMethod'
// 销售订单 - 销售订单状态
export const salesOrderStatus = 'SalesOrderStatus '

/* ==========销售 - 销售报价=========== */
// 销售报价 - 报价类型
export const quotationType = 'quotationType'

export const salesProductLine = 'salesProductLine'

/* ==========采购 - 采购订单=========== */
// 销售订单 - 订单类型
export const purOrderType = 'purOrderType'
// 销售订单 - 源单类型
export const SourcePurType = 'SourcePurType'
// 销售订单 - 物料分类
export const purMaterialClass = 'purMaterialClass'
// 销售订单 - 结算方式
export const settlementMethod = 'settlementMethod'
// 销售订单 - 付款方式
export const salePayFunc = paymentType
// 销售订单 - 需求状态
export const demandStatus = 'demandStatus'
// 销售订单 - 收货方式
export const receiptMethod = 'ReceiptMethod'
// 销售订单 - 运费支付
export const shippingPayment = 'ShippingPayment'
// 智能货架
export const shelvesType = 'shelvesType'
// 货架层数
export const shelfRowCount = 'shelfRowCount'
// 收款来源
export const collectionSource = 'CollectionSource'
// 应收款条件
export const reivCondition = 'ReivCondition'
// 收款类型
export const CollectionType = 'CollectionType'
// 收款类型
export const receivableType = 'receivableType'
// 收款方式
export const CollectionTerm = 'CollectionTerm'
// 销售订单 - 结案标志
export const closingSign = 'ClosingSign'
//  税率
export const taxRatePayDict = 'TaxRate'
//  紧急状态
export const exigency = 'exigency'
//  付款方式
export const paymentM = 'paymentM'
export const expenseType = 'expenseType'
export const payState = 'payState'
// 研发询样单 - 样品类型
export const sampleType = 'SampleType'

// 源单类型
export const sourceOrderType = 'sourceOrderType'
// 客户编码
export const customerCode = 'customerCode'
// 生成订单-配置方案
export const configScheme = 'configScheme'
// 生成订单任务类型
export const taskOrderType = 'taskOrderType'
// 需求状态
export const requireStatus = 'requireStatus'

//
/* ==========系统管理 - 调度中心========== */
// 调度中心 - 调度状态
export const schedulingState = 'SchedulingState'
// 调度中心 - 调试类型
export const schedulingType = 'SchedulingType'
// 调度中心 - 运行模式
export const operatingMode = 'OperatingMode'
// 调度中心 - 路由策略
export const routingPolicy = 'RoutingPolicy'
// 调度中心 - 调度过期策略
export const expirationPolicy = 'ExpirationPolicy'
// 调度中心 - 阻塞处理策略
export const blockingStrategy = 'BlockingStrategy'
// 替代方案
export const substituteWay = 'substituteWay'
// 启用状态
export const enabled = 'enabled'
// 机型
export const BOMType = 'BOMType'
// 运输状态
export const transportState = 'transportState'

//
/* ==========系统管理 - 数据集成========== */
// 集成方式
export const intgMethod = 'IntgMethod'
// 集成数据
export const intgData = 'IntgData'

//
// 货架维保频率
export const mair = 'mair'
// 入库-源单类型
export const SIsourceType = 'SIsourceType'
// 收货-源单类型
export const REsourceType = 'REsourceType'
// 质检-源单类型
export const QUsourceType = 'QUsourceType'
// 出库-源单类型
export const SOsourceType = 'SOsourceType'

// 流程单据类型
export const processDocType = 'ProcessDocType'
// 基础单据类型
export const baseDocType = 'BaseDocType'

// 页面视图分类
export const webpageViewType = 'webpageViewType'

// 撤回原因
export const deApproveReason = 'deApproveReason'

// 反审原因
export const withdrawReason = 'withdrawReason'

// 不良类型
export const typeOfBad = 'typeOfBad'

// 处理意见
export const treatmentMethods = 'treatmentMethods'

// 来源单位
export const sourceType = 'sourceType'
// 收款单据类型
export const rofBillType = 'RofBillType'

export const orderOption = 'orderOption'
export const orderConfigItem = 'orderConfigItem'

// bug状态
export const bugState = 'bugState'

// 标签状态
export const labelState = 'labelState'

// bug单解决方案
export const bugSolution = 'bugSolution'

// 库存状态
export const inventoryState = 'inventoryState'

// 储位类型
export const storageType = 'storageType'
// 替代方式
export const alterType = 'alterType'

// 领料单类型
export const pickingType = 'pickingType'

// 确认状态
export const confirmStatus = 'confirmStatus'

// BOM编号
export const bomNo = 'bomNo'

//  交货地址类型
export const GoodAdressType = 'GoodAdressType'
//  客户状态
export const CustomerStatus = 'CustomerStatus'
//  货物类型
export const GoodsType = 'GoodsType'
//  发送货物类型
export const SendGoodType = 'SendGoodType'
//  货物标志
export const SendGoodsFlag = 'SendGoodsFlag'
//  时差单位
export const timeDiffUnit = 'TimeDiffUnit'
//  是否能转让
export const canNoTransfer = 'CanNoTransfer'
//  是否能转让
export const timeDiff = 'timeDiff'

