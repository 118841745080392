import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

// 新增数据源
export const datasourceAdd = async (params?: any) => {
  let data: any
  await http.addPost(HUrl.DATASOURCE_ADD, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 删除数据源
export const datasourceDelete = async (params?: any) => {
  let data: any
  await http.delPost(HUrl.DATASOURCE_DELETE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 数据源分页查询
export const datasourceList = async (params?: any) => {
  let data: any
  await http.post(HUrl.DATASOURCE_LIST, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 数据源列表查询
export const datasourceQueryList = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_GENERATE + '/datasource/queryList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 更新数据源
export const datasourceUpdate = async (params?: any) => {
  let data: any
  await http.editPost(HUrl.DATASOURCE_UPDATE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
