/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from '@/service' // 引入网络请求http

// 获取单个详情
export const querySourceOrderList = async (params: any) => {
  let data = {} as any
  await http.post('/api/workflow/business/querySourceOrderList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

