import moment from 'moment'
const digit = [
  '零', '壹', '贰', '叁', '肆',
  '伍', '陆', '柒', '捌', '玖'
]
// 作用于数据字典语言
export const langList = [
  {"laid": "CHS","lanc": "badtChs","lann": "简体中文","rema": "简体中文","lafn": "zh-cn","ordr": 1,"lanv":"badtChs","gevp":"badt_chs"},
  {"laid": "CHT","lanc": "badtCht","lann": "繁體中文","rema": "繁體中文","lafn": "zh-tw","ordr": 99,"lanv": "badtCht","gevp":"badt_cht"},
  {"laid": "ENG","lanc": "EN-US","lann": "English","rema": "(美)英文","lafn": "en","ordr": 99,"lanv": "badtEng","gevp":"badt_eng"},
  {"laid": "RUS","lanc": "RU-RU","lann": "русский язык","rema": "俄文","lafn": "ru","ordr": 99,"lanv": "badtRus","gevp":"badt_rus"},
  {"laid": "JPN","lanc": "JA-JP","lann": "日本語","rema": "日文","lafn": "ja","ordr": 99,"lanv": "badtJpn","gevp":"badt_jpn"},
  {"laid": "DEU","lanc": "badtDeu","lann": "Deutsch","rema": "德文","lafn": "de","ordr": 99,"lanv": "badtDeu","gevp":"badt_deu"},
  {"laid": "KOR","lanc": "KO-KR","lann": "한국어","rema": "韩文","lafn": "ko","ordr": 99,"lanv": "badtKor","gevp":"badt_kor"},
  {"laid": "FRA","lanc": "FR-FR","lann": "Français","rema": "法文","lafn": "fr","ordr": 99,"lanv": "badtFra","gevp":"badt_fra"},
  {"laid": "VIT","lanc": "VI-VN","lann": "Tiếng Việt","rema": "越南文","lafn": "vi","ordr": 99,"lanv": "badtVit","gevp":"badt_vit"},
  {"laid": "PTG","lanc": "PT-PT","lann": "Português","rema": "葡萄牙文","lafn": "pt","ordr": 99,"lanv": "badtPtg","gevp":"badt_ptg"},
  {"laid": "THA","lanc": "TH-TH","lann": "ภาษาไทย","rema": "泰文","lafn": "th","ordr": 99,"lanv": "badtTha","gevp":"badt_tha"},
  {"laid": "ESP","lanc": "ES-ES","lann": "Español","rema": "西班牙文","lafn": "es","ordr": 99,"lanv": "badtEsp","gevp":"badt_esp"},
  {"laid": "HIN","lanc": "HI-IN","lann": "हिन्दी","rema": "印度尼西亚文","lafn": "id-id","ordr": 99,"lanv": "badtIdn","gevp":"badt_inn"},
  {"laid": "ARI","lanc": "AR-IQ","lann": " عربي ،","rema": "阿拉伯语","lafn": "ar","ordr": 99,"lanv": "badtAri","gevp":"badt_ari"},
  {"laid": "HIN","lanc": "HI-IN","lann": "हिन्दी","rema": "印地文","lafn": "hi-in","ordr": 99,"lanv": "badtHin","gevp":"badt_hin"},
  {"laid": "FAR","lanc": "FA-IR","lann": "فارسی","rema": "波斯文","lafn": "fa","ordr": 99,"lanv": "badtFar","gevp":"badt_far"}
]
// 金额转大写
export const digitUppercase = (num: string | number) => {
  let n = 0
  if (typeof +num === 'number') {
    n = +num
  }
  const fraction = ['角', '分']
  const unit = [
    ['元', '万', '亿'],
    ['', '拾', '佰', '仟']
  ]
  const head = n < 0 ? '欠' : ''
  n = Math.abs(n)
  let s = ''
  for (let i = 0; i < fraction.length; i++) {
    s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '')
  }
  s = s || '整'
  n = Math.floor(n)
  for (let i = 0; i < unit[0].length && n > 0; i++) {
    let p = ''
    for (let j = 0; j < unit[1].length && n > 0; j++) {
      p = digit[n % 10] + unit[1][j] + p
      n = Math.floor(n / 10)
    }
    s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s
  }
  return head + s.replace(/(零.)*零元/, '元')
    .replace(/(零.)+/g, '零')
    .replace(/^整$/, '零元整')
}

// 数字转中文
const numberToUppercase = (num: number) => {
  const stringNum = String(num)
  let str = ''
  for (let i = 0; i < stringNum.length; i++) {
    str += digit[+stringNum[i]]
  }
  return str
}

/**
 *  阿拉伯数字转大写，整数转大写
 * @param num 数字
 * @param type 默认繁体字
 * @returns 转换后的字符串
 */
export const numToCapital = (num: number, type = false) => {
  if (!num) return 0
  const strNum = Number((num + '').replace(/[,，]*/g, '')) + '' // 记录字符
  num = parseInt(strNum) // 转为整数，
  let capitalAr = '零壹贰叁肆伍陆柒捌玖拾'
  let unitAr = ['拾', '佰', '仟', '万', '拾', '佰', '仟', '亿', '拾', '佰', '仟'] // 单位
  if (type) {
    capitalAr = '零一二三四五六七八九十'
    unitAr = ['十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千']
  }
  const resultAr: string[] = [] // 记录结果，后边json.in就可
  let index = strNum.length - 1 // 记录位数
  let idx = 0 // 记录单位
  let percent = 10
  const turnNum = (num: number, percent: number, index: number) => {
    const unit = num / percent
    const capital = capitalAr[Number(strNum[index])]
    if (unit < 1) {
      resultAr.push(capital)
      // 出现11【一十一】这种情况
      if (Number(strNum[index]) === 1 && (strNum.length === 2 || strNum.length === 6 || strNum.length === 10)) {
        resultAr.pop()
      }
      return false // 结束递归
    } else {
      if (capital === '零') {
        // 万和亿单位不删除
        if (!['万', '亿'].includes(resultAr[resultAr.length - 1])) {
          resultAr.pop()
        }
        // 前面有零在删掉一个零
        if (resultAr[resultAr.length - 1] === '零') {
          resultAr.pop()
        }
      }
      resultAr.push(capital)
      // 过滤存在【零万】【零亿】这种情况
      if (['万', '亿'].includes(resultAr[resultAr.length - 2]) && capital === '零') {
        resultAr.pop()
      }
      // 过滤【1亿万】这种情况
      if (resultAr[0] === '万' && resultAr[1] === '亿') {
        resultAr.shift()
      }
      // 末尾【零】删掉
      if (resultAr[0] === '零') {
        resultAr.pop()
      }
      resultAr.push(unitAr[idx++])
      turnNum(num, percent * 10, --index)
    }
  }
  turnNum(num, percent, index)
  return resultAr.reverse().join('')
}

/**
 *
 * @param date 时间
 * @param type 支持 YYYY-MM-DD YYYY-MM-DD HH:mm:ss
 * @returns 中文时间
 */
export const dateUppercase = (date: string | Date, type = 'YYYY-MM-DD') => {
  // /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
  const dateValue = moment(date).format(type)
  // 数据是字符串且不是时间格式直接返回
  if (dateValue === 'Invalid date') return ''
  const newDate = new Date(dateValue)
  const year = numberToUppercase(newDate.getFullYear()) // 年
  const month = numToCapital(newDate.getMonth() + 1) // 月
  const day = numToCapital(newDate.getDate()) // 日
  const hour = numToCapital(newDate.getHours()) // 时
  const minute = numToCapital(newDate.getMinutes()) // 分
  const second = numToCapital(newDate.getSeconds()) // 秒
  console.log('yearmonthdayhourminutesecond', year, month, day, hour, minute, second)
  if (type === 'YYYY-MM-DD') {
    const chineseDate = `${year}年${month}月${day}日`
    return chineseDate
  } else if (type === 'YYYY-MM-DD HH:mm:ss') {
    const chineseDate = `${year}年${month}${day}日 ${hour}时${minute}分${second}秒`
    return chineseDate
  } else {
    const chineseDate = `${year}年${month}${day}日 ${hour}时${minute}分${second}秒`
    return chineseDate
  }
}

export function numberToWords(num: any) {
  const th = ['', 'THOUSAND', 'MILLION', 'BILLION', 'TRILLION']
  const dg = ['ZERO', 'ONE', 'TWO', 'THREE', 'FOUR', 'FIVE', 'SIX', 'SEVEN', 'EIGHT', 'NINE']
  const tn = ['TEN', 'ELEVEN', 'TWELVE', 'THIRTEEN', 'FOURTEEN', 'FIFTEEN', 'SIXTEEN', 'SEVENTEEN', 'EIGHTEEN', 'NINETEEN']
  const tw = ['TWENTY', 'THIRTY', 'FORTY', 'FIFTY', 'SIXTY', 'SEVENTY', 'EIGHTY', 'NINETY']
  num = num.toString().replace(/[\, ]/g, '')
  if (num != parseFloat(num)) return 'not a number'
  let x = num.indexOf('.')
  if (x == -1) x = num.length
  if (x > 15) return 'too big'
  let n = num.split('')
  let str = ''
  let sk = 0
  for (let i = 0; i < x; i++) {
    if ((x - i) % 3 == 2) {
      if (n[i] == '1') {
        str += tn[Number(n[i + 1])] + ' '
        i++
        sk = 1
      } else if (n[i] != 0) {
        str += tw[n[i] - 2] + ' '
        sk = 1
      }
    } else if (n[i] != 0) {
      str += dg[n[i]] + ' '
      if ((x - i) % 3 == 0) str += 'HUNDRED '
      sk = 1
    }
    if ((x - i) % 3 == 1) {
      if (sk) str += th[(x - i - 1) / 3] + ' '
      sk = 0
    }
  }
  if (x != num.length) {
    let y = num.length
    str += 'POINT '
    for (let i = x + 1; i < y; i++) str += dg[n[i]] + ' '
  }
  str = str.replace(/\s+/g, ' ').replace('POINT ZERO ZERO', 'ONLY')
  return str.toUpperCase()
}
export function getParams(prefer:any){
  let list = langList.filter(item=>item.lafn==prefer)
  if(list.length>0){
    return list[0].lanv
  }
  return 'badtChs'
}