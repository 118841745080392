/**
 * 封装axios网络请求
 */
import axios from 'axios'
import EventEmitter from 'events'
import { G } from '@/config' // 引入常量和配置信息
import { ElLoading, ElMessage } from 'element-plus'
// 导入模块
import { cache, setting, AES } from '@/utils'
import router from '@/router'
import { settingStore } from '@/store'
import { apiType } from 'types/commonresource'
import { relogin } from '@/utils/cefSharpFun/index'
// eslint-disable-next-line @typescript-eslint/no-var-requires
// 定义一个异常信息接口，返回的数据
interface IException {
  code: number,
  data?: null
  message: string
}

const IExceptionData: IException = {
  code: 0,
  message: '#网络异常-Net Exception#'
}

// 生产环境数据是否加密
const PRODUCTION_ENCRYPT = true

// 必定加解密
const ENCRYPT = true
const ON_ENCRYPT = false

const _axios = axios.create({
  // baseURL: BASE.BASE_URL,
  // baseURL: 'http://47.107.40.88',
  // timeout: 10000
})

let loading: any = null
class Request extends EventEmitter {
  constructor() {
    super()
    this.interceptors()
  }

  /**
     * 获取header
     */
  getHeaders() {
    const newToken = cache.getLocalStorage(G.AUTHORIZATION_TOKEN)
    let Authorization = ''
    let LoginMac = ''

    if (newToken) {
      // const token
      let token = JSON.parse(newToken).token
      if (token.length >= 20) {
        Authorization = 'Basic ' + token
      }
      const login = cache.getLocalStorage(G.AUTHORIZATION_LOGINMAC)

      if (login.length >= 20) {
        LoginMac = login
      }
    }
    return { Authorization, LoginMac }
  }

  /**
     * 数据加载动画效果
     */
  startLoading(value = '') {
    if (value) {
      loading = ElLoading.service({
        target: value, // 局部刷新动画，填写class   .notice-tabs
        lock: false,
        fullscreen: false,
        text: '加载中...',
        background: 'rgba(0,0,0,0.5)'
      })
    } else {
      loading = ElLoading.service({
        lock: false,
        text: '拼命加载中...',
        background: 'rgba(255,255,255,0.5)'
      })
    }
    return loading
  }
  /**
     * 数据停止加载动画效果
     */
  endLoading() {
    if (loading) {
      loading.close()
    }
  }

  /**
     * 拦截器处理
     */
  interceptors() {
    // 添加请求拦截器
    _axios.interceptors.request.use(
      (config) => {
        let paramsData = config.params || {}
        if (!paramsData.hasOwnProperty('getBox')) {
          // 数据加载动画效果（全屏loading）
          // this.startLoading()
        } else {
          if (config.params.getBox) {
            // 数据加载动画效果（局部loading）
            // this.startLoading(config.params.getBox)
            // 剔除该key
            delete config.params.getBox
          }
        }

        // 如果未定义config，则默认为空
        if (!config) {
          config = {}
        }
        if (!config.headers) {
          config.headers = {}
        }

        // const token = cache.getLocalStorage(G.AUTHORIZATION_TOKEN)
        const oldToken: any = cache.getLocalStorage(G.AUTHORIZATION_TOKEN)
        let token = ''
        if (oldToken) {
          token = JSON.parse(oldToken).token
        }

        let Authorization = ''
        if (token.length >= 20) {
          Authorization = 'Basic ' + token
        }
        const login = cache.getLocalStorage(G.AUTHORIZATION_LOGINMAC)

        let LoginMac = ''
        if (login.length === 32) {
          LoginMac = login
        }

        // 为请求头对象，添加 Token 验证的 Authorization 字段
        config.headers.Authorization = Authorization
        config.headers.LoginMac = LoginMac

        // 生产环境加密
        if ((process.env.NODE_ENV === 'production' && PRODUCTION_ENCRYPT || ENCRYPT) && ON_ENCRYPT) {
          // 使用aes加密传参
          if (config.data) {
            config.data = AES.Encrypt(JSON.stringify(config.data))
          }
        }
        // 发送请求前的处理
        return config
      },
      error => {
        this.error(error, 'request')
      }
    )
    // 添加响应拦截器
    _axios.interceptors.response.use(
      // 请求响应的处理
      response => {
        if (response.hasOwnProperty('status')) {
          const status = response.status
          // code 为正常code范围值，响应成功
          if ((status >= 200 && status < 300) || status === 304) {
            this.code(response.data, response.config)

            // if (response && response.data && response.data.code === 0) {
            //     ElMessage.error(response.data.message)
            //     return Promise.reject(new Error(response.data.message))
            // } else {

            // 生产环境解密
            if ((process.env.NODE_ENV === 'production' && PRODUCTION_ENCRYPT || ENCRYPT) && ON_ENCRYPT) {
              return Promise.resolve(typeof response.data === 'string' && JSON.parse(AES.Decrypt(response.data)))
            } else {
              // 开发环境不需要解密
              // return Promise.resolve(response.data)

              if (ON_ENCRYPT) {
                return Promise.resolve(typeof response.data === 'string' && { data: JSON.parse(AES.Decrypt(response.data)), res: response })
              } else {
                return Promise.resolve({ data: response.data, res: response })
              }
            }
          } else {
            // 响应错误逻辑处理 5xx 4xx 等等
            this.error(response, 'response#1')
          }
        } else {
          this.error(response, 'response#2')
        }
      },
      // 响应错误的处理
      error => {
        // 状态码
        // token过期
        if (error.response.status === 401) {
          console.log('error.response', error.response)

          if (error.response.data) {
            // token 过期
            let menu = setting.getMenuList()
            // 有菜单弹窗重新登录
            if (menu.value && menu.value.length > 0) {
              console.log('有菜单弹窗重新登录')
              if (setting.getIsReLogin().value) {
                setting.setIsReLogin(false)
                relogin(() => {
                  const loginPopWindow = setting.getLoginPopWindow()
                  if (!loginPopWindow.value) {
                    setting.setLoginPopWindow(true)
                    ElMessage.error(error.response.data.message)
                  }
                })
              }
            } else {
              this.toLogin(error.response.data.messageCode)
              return Promise.reject(error)
            }
          }
        } else {
          this.error(error, 'response#3')
          return Promise.reject(error)
        }
      }
    )
  }
  get(url: string, params?: any) {
    return new Promise((resolve, reject) => {
      _axios({
        method: 'get',
        url,
        params
      }).then(result => {
        resolve(result && result.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  /**
     *  导入
     * @param url url
     * @param params 参数/数据
     * @param data FormData
     * @returns
     */
  postFiles(url: string, params: any, data: any) { //  post
    return new Promise<apiType>((resolve, reject) => {
      _axios({
        method: 'post',
        url,
        params: params,
        data: data
      }).then((result: any) => {
        if (result.data.code === 1) {
          ElMessage.success('导入成功')
        }
        resolve(result && result.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  /**
     * 下载导入模板
     * @param url
     * @param params 参数/数据
     * @returns
     */
  getFiles(url: string, params?: any) { // get
    return new Promise<string>((resolve, reject) => {
      _axios({
        method: 'post',
        url,
        params, // get 请求时带的参数
        responseType: 'blob'
      }).then((result: any) => {
        if (result.data.code === 1) {
          ElMessage.success('模板下载成功')
        }
        resolve(result && result.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }
  /**
     * 下载导入模板
     * @param url
     * @param params 参数/数据
     * @returns
     */
  files(url: string, params?: any) { // get
    return new Promise((resolve, reject) => {
      _axios({
        method: 'get',
        url,
        params, // get 请求时带的参数
        responseType: 'blob'
      }).then((result: any) => {
        if (result.data.code === 1) {
          ElMessage.success('模板下载成功')
        }
        resolve(result && result.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  /**
     *  导出
     * @param url
     * @param data 参数/数据
     * @returns
     */
  getFilesExt(url: string, data: any) { // post
    return new Promise<string>((resolve, reject) => {
      return _axios({
        method: 'post',
        url,
        data: data,
        responseType: 'blob'
      }).then((result: any) => {
        if (result.data.code === 1) {
          ElMessage.success('导出成功')
        }
        resolve(result && result.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  // 在某个div里显示loading加载
  getBox(url: string, params: any, box: any = {}) {
    return new Promise((resolve) => {
      params['getBox'] = box['getBox'] || ''
      _axios({
        method: 'get',
        url,
        params
      }).then((result: any) => {
        resolve(result && result.data)
      })
    })
  }

  /**
     * upload 请求
     * @param { String } url Url
     * @param { Object } params 参数/数据
     * @param { Object } options 设置header 相关属性、timeout、以及其他http request参数
     */
  upload(url: string, data: any = '') {
    return new Promise((resolve, reject) => {
      _axios({
        method: 'post',
        url,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: data.data,
        params: data.params
      }).then(result => {
        resolve(result && result.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  /**
     * Axios Post 请求
     * @param { String } url Url
     * @param { Object } params 参数/数据
     * @param { Object } options 设置header 相关属性、timeout、以及其他http request参数
     */
  post(url: string, params: any = '', options: any = null) {
    return new Promise((resolve: (value: apiType) => void, reject) => {
      let data = this.reqParams(params, options)
      _axios({
        method: 'post',
        url,
        data,
        headers: {
          'Content-Type': 'application/json',
          processData: false,
          contentType: false
        }
      }).then(result => {
        resolve(result && result.data)
      }).catch((error: Error) => {
        reject(error)
      })
    })
  }

  loginPost(url: string, params: any = '', options: any = null) {
    let data = this.reqParams(params, options)
    // let newCmc = data.LoginMac.split('').reverse().join('')
    return new Promise((resolve, reject) => {
      _axios({
        method: 'post',
        url,
        data,
        headers: {
          'Content-Type': 'application/json',
          'LoginMac': data.LoginMac
        }
      }).then(result => {
        resolve(result && result.data)
      }).catch((err: any) => {
        reject(err)
      })
    })
  }

  // /**
  //    * Axios Delete 请求
  //    * @param { String } url Url
  //    * @param { Object } params 参数/数据
  //    * @param { Object } options 设置header 相关属性、timeout、以及其他http request参数
  //    */
  // delete(url: string, params: any = '', options: any = null) {
  //   let data = this.reqParams(params, options)
  //   return _axios({
  //     method: 'delete',
  //     url,
  //     data
  //   })
  // }

  // /**
  //    * Axios Put 请求
  //    * @param { String } url Url
  //    * @param { Object } params 参数/数据
  //    * @param { Object } options 设置header 相关属性、timeout、以及其他http request参数
  //    */
  // put(url: string, params: any = '', options: any = null) {
  //   let data = this.reqParams(params, options)
  //   return _axios({
  //     method: 'put',
  //     url,
  //     data
  //   })
  // }

  // /**
  //    * Axios Patch 请求
  //    * @param { String } url Url
  //    * @param { Object } params 参数/数据
  //    * @param { Object } options 设置header 相关属性、timeout、以及其他http request参数
  //    */
  // patch(url: string, params: any = '', options: any = null) {
  //   let data = this.reqParams(params, options)
  //   return _axios({
  //     method: 'patch',
  //     url,
  //     data
  //   })
  // }

  /**
     * 处理请求参数
     * @param { Object } params 参数/数据
     * @param { Object } options 设置header 相关属性、timeout、以及其他http request参数
     */
  reqParams(params: any, options: any = null) {
    if (typeof params === 'object') {
      if (Object.keys(params).length < 1) {
        params = {}
      }
      // 过滤 {} null [] '' 等长度少于4的
      if (JSON.stringify(options).length > 4) {
        Object.assign(params, options)
      }
      return params
    }
    return {}
  }

  /**
     * 统一处理code返回值的函数（如果需要对code某些返回值进行拦截处理）
     * @param {*} res
     * @param {*} resolve
     * @param {*} reject
     */
  code(res: any, config: any) {
    // 统一处理一些特殊的code值，比如权限不足，token过期，接口已关闭，维护中等相关的状态
    if (res.hasOwnProperty('code')) {
      // 如果返回值的code中存在responseCode的内容，则进行处理
      if (res.code === 0) {
        // 如果有错误提示的list 就拼接进行提示
        if (res.errorList) {
          let errMes = res.errorList.map((item: { message: string }) => item.message)
          ElMessage.error(errMes.join('/'))
          return
        }
        if (config.url !== 'api/engineer/material/codeTrans') {
          ElMessage.error(res.message)
        }
        return
      }
      // if (responseCode.includes(res.code)) {
      //     let message: any
      //     if (res.hasOwnProperty('message')) {
      //         message = res.message
      //     }
      // }
    }
  }

  /**
  * Axios addPost 新增请求 自带提示
  * @param { String } url Url
  * @param { Object } params 参数/数据
  * @param { Object } options 设置header 相关属性、timeout、以及其他http request参数
  */
  addPost(url: string, params: any = '', options: any = null) {
    return new Promise((resolve: (value: apiType) => void, reject) => {
      let data = this.reqParams(params, options)
      _axios({
        method: 'post',
        url,
        data
      }).then((result: any) => {
        if (result.data.code === 1) {
          ElMessage.success('新增成功')
        }
        resolve(result && result.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  savePost(url: string, params: any = '', options: any = null) {
    return new Promise((resolve: (value: apiType) => void, reject) => {
      let data = this.reqParams(params, options)
      _axios({
        method: 'post',
        url,
        data
      }).then((result: any) => {
        if (result.data.code === 1) {
          ElMessage.success('保存成功')
        }
        resolve(result && result.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }
  /**
  * Axios delPost 删除请求 自带提示
  * @param { String } url Url
  * @param { Object } params 参数/数据
  * @param { Object } options 设置header 相关属性、timeout、以及其他http request参数
  */
  delPost(url: string, params: any = '', options: any = null) {
    return new Promise<apiType>((resolve: (value: apiType) => void, reject) => {
      let data = this.reqParams(params, options)
      _axios({
        method: 'post',
        url,
        data
      }).then((result: any) => {
        if (result.data.code === 1) {
          ElMessage.success('删除成功')
        }
        resolve(result && result.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }
  /**
  * Axios editPost 修改请求 自带提示
  * @param { String } url Url
  * @param { Object } params 参数/数据
  * @param { Object } options 设置header 相关属性、timeout、以及其他http request参数
  */
  editPost(url: string, params: any = '', options: any = null) {
    return new Promise((resolve: (value: apiType) => void, reject) => {
      let data = this.reqParams(params, options)
      _axios({
        method: 'post',
        url,
        data
      }).then((result: any) => {
        if (result.data.code === 1) {
          ElMessage.success('保存成功')
        }
        resolve(result && result.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  /**
   *  Axios javaDl java下载封装
   * @param url Url
   * @param params 参数/数据
   * @param type 传输格式 默认 application/octet-stream; =
   * @param options 设置header 相关属性、timeout、以及其他http request参数
   * @returns
   */
  javaDl(url: string, params: any = '', type?: string, options: any = null) {
    return new Promise((resolve: (value: apiType) => void, reject) => {
      let data = this.reqParams(params, options)
      _axios({
        method: 'post',
        url,
        data,
        responseType: 'blob' // 解决文件损坏
      }).then((result: any) => {
        try {
          if (!type) {
            type = result.res.headers['content-type']
          }
          // 生成blob对象
          const blob = new Blob([result.data], {
            type
          })
          // 转换成路径
          const objectUrl = URL.createObjectURL(blob)
          // 获取文件名称
          let fileName = result.res.headers['filename']
          fileName = decodeURIComponent(fileName)

          // fileName = comm.utf8ToChinese(fileName)
          // 判断是否ie下载
          const nav = (window.navigator as any)
          if (nav.msSaveOrOpenBlob) {
            nav.msSaveOrOpenBlob(blob, fileName)
          } else {
            // 生成a标签
            const a = document.createElement('a')
            // 添加到body上
            document.body.appendChild(a)
            // 隐藏a标签
            a.setAttribute('style', 'display:none')
            // 设置a标签路径
            a.setAttribute('href', objectUrl)
            // 设置文件名称
            a.setAttribute('download', fileName)
            // 点击下载
            a.click()
            // 移除a标签
            a.remove()
            // 释放blob对象
            URL.revokeObjectURL(objectUrl)
            resolve({
              code: 1,
              data: null,
              message: '下载成功'
            })
          }
        } catch (error) {
          resolve({
            code: 0,
            data: null,
            message: '下载失败'
          })
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  /**
     * 成功回调函数
     * @param {*} res
     * @param {*} resolve
     * @param {*} reject
     */
  success(res: any, resolve: any, reject: any) {
    if (res.hasOwnProperty('data')) {
      // 统一处理一些特俗的code值，比如权限不足，token过期，接口已关闭，维护中等相关的状态
      if (res.data) {
        resolve(res)
      } else {
        // 失败回调
        this.fail(res, reject)
      }
    } else {
      resolve(res)
    }
  }

  /**
     * 失败回调函数
     * @param {*} res 数据
     * @param {*} reject
     */
  fail(res: any, reject: any) {
    console.log(res, '+++===---:::Axios Failed:::---===+++')
    // 数据结束加载动画效果
    // this.endLoading();
    let errExp: IException = {
      code: IExceptionData.code,
      message: IExceptionData.message + '#fail'
    }
    return Promise.reject(errExp)
  }

  /**
     * 失败回调函数
     * @param {*} res 数据
     * @param {*} reject
     */
  catch(res: any) {
    console.log(res, '+++===---:::Axios Catch Exception:::---===+++')
    // 数据结束加载动画效果
    // this.endLoading();
    let errExp: IException = {
      code: IExceptionData.code,
      message: IExceptionData.message + '#catch'
    }
    return Promise.reject(errExp)
  }

  /**
   * 跳转登录页
   */
  toLogin(code: string) {
    const settStore = settingStore()

    // ElMessage.error(error.response.data.message)
    // 没菜单跳转登录页
    // 清空数据
    settStore.setUserInfo({})
    settStore.setToken('')
    settStore.setMenuList([]) // 清空菜单
    cache.setLocalStorage('token', '')
    cache.setLocalStorage('userInfo', '')
    cache.setLocalStorage('loginCMC', '')
    // 截取路径
    // let toUrl = ''
    let loginType = ''
    if (code === '1001003') {
      // toUrl = '/login?type=anotherlogin'
      loginType = 'anotherlogin'
    }
    if (code === '1001001') {
      // toUrl = '/login?type=timeout'
      loginType = 'timeout'
    }
    if (setting.getIsReLogin().value) {
      setting.setIsReLogin(false)
      const str = window.location.href.split('#')[1] as string
      // if ((str && loginType) && str !== '/login') {
      // 储存旧路径
      setting.setOldPath(str)

      console.log('没菜单跳转登录页')
      relogin(() => {
        // 跳转登录页
        router.push({
          name: 'Login',
          path: '/login',
          state: {
            params: {
              loginType: loginType
            }
          }
        })
      })
    }
    // }
  }

  /**
     * 异常错误回调函数
     * @param {*} error 异常错误数据
     * @param {*} type 是request还是response类型
     */
  error(error: any, type: string) {
    // if (error.response.status === 403) {
    //   this.toLogin(403)
    // }
    if (error.response.status === 500) {
      ElMessage.error('接口响应异常')
    } else if (error.response.status === 502) {
      ElMessage.error('后端发布中')
    }
    console.log(error, '+++===---:::Axios ' + type + ' Error:::---===+++')
    // 数据结束加载动画效果
    // this.endLoading();
    let errExp: IException = {
      code: IExceptionData.code,
      message: IExceptionData.message + '#' + type
    }
    return Promise.reject(errExp)
  }
}

const http = new Request()

export default http
