/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from '@/service' // 引入网络请求http

const url = 'api'// HUrl.BASE_PREFIX_INIT_START // 路径前缀 + 微服务
// 获取表格分页数据
export const getPageList = async (params: any) => {
  console.log('衍生信息入参', params)
  if (!params.data.maid) {
    return {
      data: {
        items: []
      }
    }
  }
  let data = {} as any
  if (params.total + '') {
    delete params.total
  }
  await http.post(url + '/engineer/derive/queryPageList', params).then((result: any) => {
    data = result
    console.log('result衍生信息返回结果', result)
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 删除
export const del = async (params: any) => {
  let data = {} as any
  await http.delPost(url + '/engineer/derive/deleteList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导入
export const importFiles = async (params: any) => {
  let data = {} as any
  await http.postFiles(url + '/engineer/derive/import', {}, params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出
export const exportFiles = async (params?: any) => {
  let data = {} as any
  await http.getFilesExt(url + '/engineer/derive/export', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出模板
export const exportFilesTmpl = async (params?: any) => {
  let data = {} as any
  await http.getFiles(url + '/engineer/derive/template', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
