/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import moment from 'moment'
// 角色列表
export const getUserRoleData = async (params: any) => {
  let data: any
  await http.post(HUrl.ROLE_USER_PAGE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 角色列表--2
export const getUserRoleMuData = async (params: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_INIT_ADMIN + '/role/queryPageList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 角色信息
export const getInfo = async (params: any) => {
  let data: any
  await http.post(HUrl.ROLE_USER_DATA, params).then((result: any) => {
    result.data.cret = moment(result.data.cret).format('YYYY-MM-DD HH:mm:ss')

    let updt = moment(result.data.updt).format('YYYY-MM-DD HH:mm:ss')
    if (updt.indexOf('0001-01-01') != -1) {
      result.data.updt = ''
    } else {
      result.data.updt = updt
    }

    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
export const adminRoleQuerySingle = async (params: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_INIT_ADMIN + '/role/querySingle', params).then((result: any) => {
    result.data.cret = moment(result.data.cret).format('YYYY-MM-DD HH:mm:ss')

    let updt = moment(result.data.updt).format('YYYY-MM-DD HH:mm:ss')
    if (updt.indexOf('0001-01-01') != -1) {
      result.data.updt = ''
    } else {
      result.data.updt = updt
    }

    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 新增角色
export const addUserRole = async (params: any) => {
  let data: any
  await http.addPost(HUrl.ROLE_USER_ADD, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

export const addUserMenuRole = async (params: any) => {
  let data: any
  await http.savePost(HUrl.BASE_PREFIX_INIT_ADMIN + '/role/save', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

export const copyUserMenuRole = async (params: any) => {
  let data: any
  await http.savePost(HUrl.BASE_PREFIX_INIT_ADMIN + '/role/copy', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 根据角色 查询员工信息
export const roleQueryEmployeeInfo = async (params: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_INIT_ADMIN + '/role/queryListByRole', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 修改角色
export const editUserRole = async (params: any) => {
  let data: any
  await http.editPost(HUrl.ROLE_USER_EDIT, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除角色
export const delUserRole = async (params: any) => {
  let data: any
  await http.delPost(HUrl.ROLE_USER_DEL, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询用户角色功能权限-旧版
// export const getPowerData = async (params: any) => {
//   let data: any
//   await http.post(HUrl.GET_POWER_DATA, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }
// HUrl.BASE_PREFIX_INIT_START_RESOUCE + '/menu/queryList'
export const getPowerData = async (params: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_INIT_ADMIN + '/menu/querySingle', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 保存用户角色功能权限-old
// export const saveFuncPower = async (params: any) => {
//   let data: any
//   await http.editPost(HUrl.SAVE_FUNC_POWER, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }
// 保存用户角色功能权限
export const saveFuncPower = async (params: any) => {
  let data: any
  await http.editPost(HUrl.BASE_PREFIX_INIT_START_RESOUCE + '/menu/save', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
