import { computed, defineAsyncComponent } from 'vue'
// 获取hooks
import basicInfofoHooksIndex from '@/views/business/purchase/supplier/hooks/basicInfoFormJson' // 基本信息
import businInfoHooksIndex from '@/views/business/purchase/supplier/hooks/businInfoFormJson' // 商务信息
import contactsHooksIndex from '@/views/business/purchase/supplier/hooks/contactsInfoFormJson' // 联系人
import shipAddressInfoHooksIndex from '@/views/business/purchase/supplier/hooks/shipAddressInfoJson' // 收货地址
import paymentInHooksIndex from '@/views/business/purchase/supplier/hooks/paymentInfoJson' // 收款信息 境内账户 境外账户
import softAccInfoHooksIndex from '@/views/business/purchase/supplier/hooks/softAccInfoJson' // 软件账号信息
// import membersInfoHooksIndex from '@/views/business/purchase/supplier/hooks/memberInfoJson' // 会员信息
import outBounInfoHooksIndex from '@/views/business/purchase/supplier/hooks/outBounInfoJson' // 对外投资
import intePropInfoHooksIndex from '@/views/business/purchase/supplier/hooks/intePropInfoJson' // 知识产权
// 获取接口
import {
  getSupplierInfo,
  inteproppagelist,
  outbounpagelist,
  softAccPageList,
  paymentOverseasPageList,
  paymentInfoPageList,
  addressPageList,
  contactPageList } from '@/service/modules/purchase/supplier'
import { objAny } from 'types/table'
import { apiType } from 'types/commonresource'
import { SGeneralData } from '@/service'
import { PublicField } from '@/config'
import { comm } from '@/utils'

const formJson = () => {
  /**
 * 获取当前时间
 */
  const getCurrentTime = () => {
    const date = new Date()
    const year = date.getFullYear().toString()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const seconds = date.getSeconds().toString().padStart(2, '0')
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }
  // 基础表单hooks
  // creu: store.userInfo.emplId,cret: getCurrentTime(), arid: store.userInfo.emplId
  const { basicInfoFormJson } = basicInfofoHooksIndex({ }, false) // 基本信息
  const { businInfoFormJson } = businInfoHooksIndex({}, false) // 商务信息

  const res = computed(() => {
    return {
      ...basicInfoFormJson,
      ...businInfoFormJson.value
    }
  })
  // 表格hooks
  const contactsHooks = contactsHooksIndex()
  const shipAddressInfoHooks = shipAddressInfoHooksIndex()
  const paymentInHooks = paymentInHooksIndex()
  const softAccInfoHooks = softAccInfoHooksIndex()
  // const memberInfoJson = membersInfoHooksIndex()
  const outBounInfoHooks = outBounInfoHooksIndex()
  const intePropInfoHooks = intePropInfoHooksIndex()

  return [
    {
      title: { lank: 'purchase.basicInformation', default: '基本信息' }, // 基本和商务信息
      type: 'form', // 表单
      json: res.value,
      getInfo: (params: objAny) => {
        return new Promise((resolve: (value: apiType) => void, reject) => {
          getSupplierInfo(params).then(async(result) => {
            if (result.data) {
              result.data.abvr = +result.data.abvr === 0 ? '' : String(result.data.abvr)
              result.data.taxr = +result.data.taxr === 0 ? '' : String(result.data.taxr)
              result.data.ouon = result.data.ouon ? '1' : '0'
            }
            result.data.affi = result.data && result.data.affi && result.data.affi.map((item:{asso:string | number }) => {
              item.asso = item.asso ? Number(item.asso) : '' //  item.asso = Number(item.asso)
              return item
            }) || []
            if (result.data && result.data.coma && result.data.coma[0]) {
              result.data.creu = result.data.coma[0].creu
              result.data.cret = result.data.coma[0].cret
            }
            const unit = await SGeneralData.basicDataSelect({ bdtc: PublicField.timeType })
            if (result.data.actualAverageAging) { // timeType
              const item = unit?.data.find((v:{val:string}) => result.data.actualAverageAgingUnit === v.val)
              result.data.actualAverageAging = result.data.actualAverageAging + item?.txt || ''
              result.data.actualAverageAgingUnit = undefined
            }
            const currency = await SGeneralData.basicDataSelect({ bdtc: PublicField.currencysType })
            if (result.data.regc) {
              //
              const item = currency?.data.find((v:{val:string}) => result.data.rccc == v.val)
              result.data.regc = `${comm.numberToAmount(result.data.regc)} (${item?.txt || ''})`
              result.data.rccc = undefined
            }
            if (result.data.orom) {
              //
              const item = currency?.data.find((v:{val:string}) => result.data.omcc == v.val)
              result.data.orom = `${comm.numberToAmount(result.data.orom)} (${item?.txt || ''})`
              result.data.omcc = undefined
            }
            if (result.data.yoom) {
              //
              const item = currency?.data.find((v:{val:string}) => result.data.yomc == v.val)
              result.data.yoom = `${comm.numberToAmount(result.data.yoom)} (${item?.txt || ''})`
              result.data.yomc = undefined
            }
            if (result.data.stol) {
              //
              const item = currency?.data.find((v:{val:string}) => result.data.slcc == v.val)
              result.data.stol = `${comm.numberToAmount(result.data.stol)} (${item?.txt || ''})`
              result.data.slcc = undefined
            }
            if (result.data.abvr) {
              result.data.abvr = `${result.data.abvr}%`
            } else {
              result.data.abvr = ''
            }
            console.log('ok||', result)
            resolve({ ...result })
          })
        })
      },
      // getInfo: getSupplierInfo,
      slot: {
        coma: defineAsyncComponent(() => import('./componentsSlot/coma.vue')),
        shah: defineAsyncComponent(() => import('./componentsSlot/shah.vue')),
        affi: defineAsyncComponent(() => import('./componentsSlot/affi.vue')),
        buid: defineAsyncComponent(() => import('./componentsSlot/buid.vue')),
        region: defineAsyncComponent(() => import('./componentsSlot/region.vue'))
        // isMo: defineAsyncComponent(() => import('./componentsSolt/isMo.vue'))
      },
      formStyle: {
        counProvCityRegi: [
          'grid-column: 1 / span 4;'
        ],
        webs: [
          'grid-column: 1 / span 4;'
        ],
        suen: [
          'grid-column: 1 / span 4;'
        ],
        buss: [
          'grid-column: 1 / span 4;'
        ],
        corp: [
          'grid-column: 1 / span 4;'
        ],
        rema: [
          'grid-column: 1 / span 4;'
        ],
        coma: [
          'grid-column: 1 / span 4;'
        ],
        buid: [
          'grid-column: 1 / span 4;'
        ],
        shah: [
          'grid-column: 1 / span 4;'
        ],
        affi: [
          'grid-column: 1 / span 4;'
        ]
      }
    },
    {
      title: { lank: 'purchase.uploadAttachments', default: '上传附件' },
      type: 'files',
      code: 'Supplier',
      templetCode: 'Supplier'
    },
    {
      title: { lank: 'purchase.contacts', default: '联系人' },
      type: 'table', // 表单
      formid: 'pid', //  pid="pid"
      id: 'coid', // rowId="ipid"
      columnList: contactsHooks.columnList,
      getInfo: contactPageList,
      slot: {
        isen: defineAsyncComponent(() => import('./componentsSlot/isen.vue'))
      }
    },
    {
      title: { lank: 'purchase.shipAddress', default: '收货地址' },
      type: 'table',
      formid: 'pid',
      id: 'reid',
      columnList: shipAddressInfoHooks.columnList,
      slot: {
        isen: defineAsyncComponent(() => import('./componentsSlot/isen.vue'))
      },
      getInfo: addressPageList
    },
    // {
    //   title: { lank: 'purchase.paymentInfo', default: '收款信息' },
    //   type: 'table',
    //   formid: 'orderId',
    //   id: 'id',
    //   columnList: contactsInfoFormJson,
    //   getInfo: paymentInfoPageList
    // },

    {
      title: { lank: 'purchase.domesticAccount', default: '境内收款信息' },
      type: 'table',
      formid: 'pid',
      id: 'paid',
      columnList: paymentInHooks.columnList,
      getInfo: paymentInfoPageList,
      slot: {
        isen: defineAsyncComponent(() => import('./componentsSlot/isen.vue')),
        isdf: defineAsyncComponent(() => import('./componentsSlot/isen.vue'))
      }
    },
    {
      title: { lank: 'purchase.overseasAccount', default: '境外收款信息' },
      type: 'table',
      formid: 'pid',
      id: 'paid',
      columnList: paymentInHooks.overFormList,
      getInfo: paymentOverseasPageList
    },
    {

      title: { lank: 'purchase.softwareAccountInfo', default: '软件账号信息' },
      type: 'table',
      formid: 'pid',
      id: 'soid',
      columnList: softAccInfoHooks.columnList,
      getInfo: softAccPageList
    },
    // {
    //   title: { lank: 'purchase.memberInfo', default: '会员信息' },
    //   type: 'table',
    //   formid: 'orderId',
    //   id: 'id',
    //   columnList: contactsInfoFormJson,
    //   getInfo: getSupplierInfo
    // },
    {
      title: { lank: 'purchase.outboundInvestment', default: '对外投资' },
      type: 'table',
      formid: 'pid',
      id: 'oiid',
      columnList: outBounInfoHooks.columnList,
      getInfo: (params:any) => {
        return new Promise((resolve:(value:apiType) => void, reject) => {
          outbounpagelist(params).then(async(res) => {
            // const currency = await SGeneralData.basicDataSelect({ bdtc: PublicField.currencysType })
            // res.data.items.forEach((d:any) => {
            //   const item = currency?.data.find((v:{val:string}) => d.curc == v.val)
            //   d.regc = `${comm.numberToAmount(d.regc)} (${item?.txt || ''})`
            //   // result.data.rccc = undefined
            //   const item2 = currency?.data.find((v:{val:string}) => d.cuif == v.val)
            //   d.invf = `${comm.numberToAmount(d.invf)} (${item2?.txt || ''})`
            // })
            // console.log('res.data.items', res.data.items)
            resolve({ ...res })
          })
        })
      },
      slot: {
        isen: defineAsyncComponent(() => import('./componentsSlot/isen.vue')),
        isdf: defineAsyncComponent(() => import('./componentsSlot/isen.vue'))
      }
    },
    {
      title: { lank: 'purchase.IPR', default: '知识产权' },
      type: 'table',
      formid: 'pid',
      id: 'ipid',
      columnList: intePropInfoHooks.columnList,
      getInfo: inteproppagelist
    }
  ]
}
export default formJson
