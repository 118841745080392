import { SDocumentRules } from '@/service'
import { settingStore } from '@/store'

/**
 * 获取公司ID
 * @returns 当前用户 公司id
 */
export const getCompanyId = () => {
  console.log('settingStore().getUserInfo', settingStore().getUserInfo)
  const companyId = settingStore().getUserInfo.companyId
  return companyId
}

/**
 *
 * 生成编码
 * @param coid 公司编码  传空 默认用户公司
 * @param type 单据类型
 * @returns
 * 导入  import { billNo } from '@/utils'
 * 使用  billNo.getBillNo('381de13915b543e1a706168780a7e909', 4000).then(result => {
     // console.log(result)
    })
 * 返回 编码 和 null
 */
export const getBillNo = (coid: string | number | null, type: string | number) => {
  return new Promise((resolve: (value: string | null) => void, reject) => {
    if (!coid) {
      coid = getCompanyId()
    }
    const res = { coid, type }
    SDocumentRules.getBillNo(res).then(result => {
      if (result.code === 1) {
        resolve(result.data.billNo)
      } else {
        resolve(null)
      }
    }).catch((error) => {
      reject(error)
    })
  })
}

/**
 * 获取用户信息
 * @returns 当前用户 公司id
 */
export const getUserInfo = (user?: string) => {
  if (user) {
    const val = settingStore().getUserInfo[user]
    return val
  } else {
    return settingStore().getUserInfo
  }
}
