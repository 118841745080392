import { computed, ref } from 'vue'

import configPageList from '@/components/configScheme/indexHooks'

import basicInfoHooks from '@/views/business/purchase/apply/hooks/basicInfo'
import { Tany } from 'types/commonresource'
import { SGeneralData } from '@/service'
const productLineVal = ref()
const columnList = [
  { prop: '', label: '', type: 'selection', width: '55', fixed: true },
  { prop: '', label: '序号', type: 'index', title: 'systemmanage.index_row', width: '55', fixed: true },
  {
    label: '源单单号', prop: 'sourceOrder', title: 'production.orderSourceOrder',
    width: 300,
    form: {
      validator: [],
      iconSlot: 'sourceOrderIcon'
    }
  },
  {
    label: '产品料号', prop: 'matn', title: 'production.orderMatn', width: 230,
    form: {
      validator: 'required',
      name: 'materialNumber',
      optionsSetting: {
        params: {
          gocc: productLineVal
        },
        optionsLabel: {
          label: 'matn',
          value: 'maid'
        }
      }
    }
  },
  {
    label: '产品全名', prop: 'fullName', title: 'production.orderFullName', width: 300,
    form: {
      name: 'materialNumber',
      optionsSetting: {
        params: {
          gocc: productLineVal
        },
        optionsLabel: {
          label: 'mata',
          value: 'maid'
        }
      }
    }
  },
  {
    label: '规格型号', prop: 'spec', title: 'production.orderSpec', width: 220,
    form: {
      name: 'materialNumber',
      optionsSetting: {
        params: {
          gocc: productLineVal
        },
        optionsLabel: {
          label: 'spec',
          value: 'maid'
        }
      }
    }
  },
  {
    label: '计量单位', prop: 'unit', title: 'production.orderUnit', width: 150,
    options: {
      options: { optionsLabel: { label: 'value', value: 'key' }, API: SGeneralData.basicDataCUSelect({}) }
    }
  },
  {
    label: '订单数量', prop: 'count', title: 'production.orderCount',
    width: 100,
    form: {
      validator: []
    },
    sortable: true // 排序
  },
  {
    label: '生产周期(工作日)', prop: 'productionCycle', title: 'production.orderProductionCycle',
    form: {
      validator: []
    },
    width: 160,
    sortable: true // 排序
  },
  {
    label: '交货日期', prop: 'deliveryDate', title: 'production.orderDeliveryDate',
    form: {
      validator: [],
      name: 'picker'
    },
    width: 160,
    sifting: {
      name: 'picker'
    },
    colLink: true
  },
  {
    label: '产品订单备注', prop: 'notes', title: 'production.orderNotes',
    width: 280,
    form: {
      validator: []
    }
  },
  {
    label: '客户编码', prop: 'customerCode', title: 'production.orderCustomerCode',
    width: 200,
    options: {
      formatter: 'customerCode'
    },
    form: {
      validator: [],
      optionsSetting: {
        dict: 'customerCode'
      },
      name: 'select'
    },
    sifting: {
      name: 'select',
      optionsSetting: {
        dict: 'customerCode'
      }
    }, // 筛选
    colLink: true
  },
  {
    label: '业务跟进人', prop: 'businessFollower', title: 'production.orderBusinessFollower',
    width: 220,
    options: { formatter: 'sys_employee' },
    form: { validator: [], name: 'selectPerson', singleSelect: true },
    sifting: {
      name: 'selectPerson',
      singleSelect: true
    }, // 筛选
    colLink: true
  },
  {
    label: '内部协作人', prop: 'internalCollaborators', title: 'production.orderInternalCollaborators',
    width: 220, options: { formatter: 'sys_employee' },
    form: { validator: [], name: 'selectPerson', singleSelect: true },
    sifting: { name: 'selectPerson', singleSelect: true }, // 筛选
    colLink: true
  }
]

const formJson = () => {
  const basicInfo: Tany = (() => {
    const object = basicInfoHooks({}).formJson

    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        const item = object[key]
        item.required = true
      }
    }
    return object
  })()
  const res = computed(() => {
    return {
      ...basicInfo
    }
  })
  return [{
    title: { lank: 'administration.essentialSes', default: '基本信息' },
    type: 'form',
    json: res.value,
    getInfo: () => new Promise((resolve) => {
      resolve({})
    })
  }, {
    title: { lank: 'production.productInfo', default: '产品信息' },
    type: 'table',
    json: res,
    formid: 'bomInfoId',
    id: 'id',
    columnList: columnList,
    getInfo: () => new Promise((resolve) => {
      resolve({ data: { items: [], totalCount: 0 }})
    })
  }, {
    title: { lank: 'production.configInfor', default: '产品信息' },
    type: 'table',
    json: res,
    formid: 'bomInfoId',
    id: 'id',
    columnList: configPageList.value,
    getInfo: () => new Promise((resolve) => {
      resolve({ data: { items: [], totalCount: 0 }})
    })
  }]
}

export default formJson
