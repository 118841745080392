import { ElMessage } from 'element-plus'

/* =========================数据流=========文件名称=======格式============================= */
export const download = (data: string, fileName: string, isStream: boolean) => {
  try {
    const type = isStream ? 'applicatoin/octet-stream' : 'application/1vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    const blob = new Blob([data], {
      type
    })
    const nav = (window.navigator as any)
    if (nav.msSaveOrOpenBlob) {
      nav.msSaveOrOpenBlob(blob, fileName)
    } else {
      const objectUrl = URL.createObjectURL(blob)
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.setAttribute('style', 'display:none')
      a.setAttribute('href', objectUrl)
      a.setAttribute('download', fileName)
      a.click()
      a.remove()
      URL.revokeObjectURL(objectUrl)
    }
  } catch (err) {
    ElMessage.error('下载失败')
  }
}

export const urlDownload = (url: string, filename: string) => {
  const elelink = document.createElement('a')
  // elelink.style.display = 'none'
  elelink.target = '_blank'
  elelink.href = url
  elelink.download = filename
  // document.body.appendChild(elelink)
  elelink.click()
  elelink.remove()
  // document.body.removeChild(elelink)
  // let link = document.createElement('a')
  // // let url = data.fileUrl // codeIMG  要下载的路径
  // // 这里是将url转成blob地址，
  // fetch(url)
  //   .then((res) => res.blob())
  //   .then((blob) => {
  //     // 将链接地址字符内容转变成blob地址
  //     link.href = window.URL.createObjectURL(blob)
  //     link.download = filename
  //     document.body.appendChild(link)
  //     link.click()
  //     document.body.removeChild(link)
  //     window.URL.revokeObjectURL(link.href)
  //   })
}

// 这个是下载图片
//  解决跨域  imgsrc 文件全路径
// name 文件名
export function downloadIamge(imgsrc: string, name: string) { // 下载图片地址和图片名
  let image = new Image()
  // 解决跨域 Canvas 污染问题
  image.setAttribute('crossOrigin', 'anonymous')
  image.onload = function () {
    let canvas = document.createElement('canvas')
    canvas.width = image.width
    canvas.height = image.height
    let context = canvas.getContext('2d')
    if (context) {
      context.drawImage(image, 0, 0, image.width, image.height)
      let url = canvas.toDataURL('image/png') // 得到图片的base64编码数据

      let a = document.createElement('a') // 生成一个a元素
      let event = new MouseEvent('click') // 创建一个单击事件
      a.download = name || 'photo' // 设置图片名称
      a.href = url // 将生成的URL设置为a.href属性
      a.dispatchEvent(event) // 触发a的单击事件
      document.body.removeChild(a)
    }
    document.body.removeChild(canvas)
  }
  image.src = imgsrc
}
