/* eslint-disable @typescript-eslint/no-explicit-any */

import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

const url = HUrl.BASE_PREFIX_SYS
const javaUrl = HUrl.BASE_PREFIX_INIT_START_RESOUCE

// 新增菜单资源与打印标签模板及设置的关系 （系统）
export const menuAddPrintSettings = async (params: any) => {
  const result = await http.post(url + '/MenuBase/AddPrintSettings', params)
  return result
}

// 编辑菜单资源与打印标签模板及设置的关系 （系统）
export const menuEditPrintSettings = async (params: any) => {
  const result = await http.post(url + '/MenuBase/EditPrintSettings', params)
  return result
}

// 删除菜单资源与打印标签模板及设置的关系 （系统）
export const menuDelPrintSettings = async (params: any) => {
  const result = await http.delPost(url + '/MenuBase/DelPrintSettings', params)
  return result
}

// 查询菜单资源与打印标签模板及设置的关系列表 （系统）
export const menuPrintSettingsList = async (params: any) => {
  const result = await http.post(url + '/MenuBase/PrintSettingsList', params)
  return result
}

// 查询菜单资源与打印标签模板及设置的关系详情 （系统）
export const menuPrintSettingsInfo = async (params: any) => {
  const result = await http.post(url + '/MenuBase/PrintSettingsInfo', params)
  return result
}

// 查询所有标签打印模板（pc端使用） （系统）
export const printTicketPatternDataList = async (params: any) => {
  const result = await http.post(url + '/PrintTicketPatternBase/DataList', params)
  return result
}

// 分页所有标签打印模板（web端使用） （系统）
export const printTicketPatternPageList = async (params: any) => {
  const result = await http.post(url + '/PrintTicketPatternBase/PageList', params)
  return result
}

// 查询所有标签打印设置（pc端使用） （系统）
export const printSetPatternDataList = async (params: any) => {
  const result = await http.post(url + '/PrintSetPatternBase/DataList', params)
  return result
}

// 分页所有标签打印设置（web端使用） （系统）
export const printSetPatternPageList = async (params: any) => {
  const result = await http.post(url + '/PrintSetPatternBase/PageList', params)
  return result
}

/* ================================================================================= */
// java-删除打印模板
export const deleteTemplate = async (params: any) => {
  const result = await http.delPost(javaUrl + '/printTemplate/delete', params)
  return result
}

// java-查询列表
export const queryList = async (params: any) => {
  const result = await http.post(javaUrl + '/printTemplate/queryList', params)
  return result
}

// java-查询分页
export const queryPageList = async (params: any) => {
  const result = await http.post(javaUrl + '/printTemplate/queryPageList', params)
  return result
}

// java-查询单个
export const querySingle = async (params: any) => {
  const result = await http.post(javaUrl + '/printTemplate/querySingle', params)
  return result
}

// java-新增打印模板
export const save = async (params: any) => {
  const result = await http.post(javaUrl + '/printTemplate/save', params)
  return result
}

// java-修改打印模板内容
export const saveContent = async (params: any) => {
  const result = await http.savePost(javaUrl + '/printTemplate/saveContent', params)
  return result
}

// java-修改打印模板视图
export const saveView = async (params: any) => {
  const result = await http.post(javaUrl + '/printTemplate/saveView', params)
  return result
}

// 设置默认模板
export const setDefaultTemplate = async (params: any) => {
  const result = await http.post(javaUrl + '/printTemplate/setDefaultTemplate', params)
  return result
}
