import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

// 上传文件
export const uploadPicture = async (params: any) => {
  let data: any
  await http.upload(HUrl.UPLOAD_PICTURE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 上传文件
export const uploadFile = async (params: any) => {
  let data: any
  await http.upload(HUrl.UPLOAD_FILE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 初始化文件列表
export const saveDocs = async (params: any) => {
  let data: any
  await http.post(HUrl.SAVE_DOCS, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询日志
export const docOptLoglIst = async (params: any) => {
  let data: any
  await http.post(HUrl.DOC_OPT_LOGL_IST, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 新增文件夹
export const saveFolders = async (params: any) => {
  let data: any
  await http.post(HUrl.SAVE_FOLDERS, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 根据表单id查询文件夹
export const folderList = async (params: any) => {
  let data: any
  await http.get(HUrl.FOLDER_LIST, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 删除文件
export const delDoc = async (params: any) => {
  let data: any
  await http.delPost(HUrl.DEL_DOC, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 获取文件详情
export const docInfo = async (params: any) => {
  let data: any
  await http.post(HUrl.DOC_INFO, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 修改文件名称和描述
export const editDoc = async (params: any) => {
  let data: any
  await http.editPost(HUrl.EDIT_DOC, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 修改文件夹名称和描述
export const editFolder = async (params: any) => {
  let data: any
  await http.post(HUrl.EDIT_FOLDER, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 删除文件夹
export const delFolder = async (params: any) => {
  let data: any
  await http.delPost(HUrl.DEL_FOLDERS, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 彻底删除文件夹
export const permanentDelFolder = async (params: any) => {
  let data: any
  await http.delPost(HUrl.PERMANENT_DEL_FOLDERS, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

