/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from '@/service' // 引入网络请求http\
import { apiType } from 'types/commonresource'

const url = 'api/production' // 路径前缀 + 微服务

// 获取表格分页数据
export const getPageList = async (params: any) => {
  const data = await http.post(url + '/completeSuit/queryPageList', params).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取单个详情
export const getInfo = async (params: any) => {
  const data = await http.post(url + '/completeSuit/querySingle', params).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 保存草稿
export const saveDraft = async (params: any) => {
  const data = await http.post(url + '/completeSuit/save', params).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 提交
export const submit = async (params: any) => {
  const data = await http.post(url + '/completeSuit/submit', params).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 删除
export const del = async (params: any) => {
  const data = await http.delPost(url + '/completeSuit/deleteList', params).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导入
export const importFiles = async (params: any) => {
  const data = await http.postFiles(url + '/completeSuit/import', {}, params).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出
export const exportFiles = async (params?: any) => {
  const data = await http.javaDl(url + '/completeSuit/export', params).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出模板
export const exportFilesTmpl = async (params?: any) => {
  const data = await http.javaDl(url + '/completeSuit/template', params).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 审批流程——反审
export const counterReview = async (params: any) => {
  const data = await http.post(url + '/salesOrder/counterReview', params).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 审批流程——撤回
export const revoke = async (params: any) => {
  const data = await http.post(url + '/salesOrder/revoke', params).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 根据参数以及类型获取相应的数据源
export const getTreeDataInfoByType = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/completeSuit/getTreeDataInfoByType', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 根据参数获取需要处理的BOM明细
export const getBomDataByParam = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/completeSuit/getBomDataByParam', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 行处理
// BOM明细分页查询
export const itemQueryPageList = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/completeSuitItem/queryPageList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出
export const itemExportFiles = async (params?: any) => {
  const data = await http.javaDl(url + '/completeSuitItem/export', params).catch((err) => {
    return http.catch(err)
  })
  return data
}