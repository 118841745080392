/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import { apiType } from 'types/commonresource'

const url = HUrl.BASE_PRODUCTION // 路径前缀 + 微服务
// 获取表格分页数据 - 收起明细
export const getPageList = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/picking/queryPageList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取表格分页数据 - 平铺明细 /picking/queryPageTiledList
export const queryPageTiledList = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/picking/queryPageTiledList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// /sourceOrder/querySourceOrderDetails 查询源单子列表
export const querySourceOrderDetails = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/sourceOrder/querySourceOrderDetails', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// /sourceOrder/querySourceOrderList 查询源单下拉列表
export const querySourceOrderList = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/sourceOrder/querySourceOrderList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// /sourceOrder/querySourceOrderPage 查询源单列表
export const querySourceOrderPage = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/sourceOrder/querySourceOrderPage', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取单个详情
export const getInfo = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/picking/querySingle', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 保存草稿
export const saveDraft = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/picking/save', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 提交
export const submit = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/picking/submit', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 删除
export const del = async (params: any) => {
  let data = {} as apiType
  await http.delPost(url + '/picking/deleteList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导入
export const importFiles = async (params: any) => {
  let data = {} as apiType
  await http.postFiles(url + '/picking/import', {}, params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出
export const exportFiles = async (params?: any) => {
  let data = {} as apiType
  await http.javaDl(url + '/picking/export', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出模板
export const exportFilesTmpl = async (params?: any) => {
  let data = {} as apiType
  await http.javaDl(url + '/picking/template', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 审批流程——反审
export const counterReview = async (params: any) => {
  const data = await http.post(url + '/picking/counterReview', params).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 审批流程——撤回
export const revoke = async (params: any) => {
  const data = await http.post(url + '/picking/revoke', params).catch((err) => {
    return http.catch(err)
  })
  return data
}
