import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import { SGeneralData } from '@/service'
import i18n from '@/i18n'
//  获得ID
export const getId = async (params? : any) => {
  let data: any
  await http.post(HUrl.GETID, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
//  查询物料待检信息
export const queryQualityControlAwait = async (params : any) => {
  let data: any
  const t = i18n.global.t
  await http.post(HUrl.QUERYQUALITYCONTROLAWAIT, params).then((result : any) => {
    // 将表格中收货类型key变为value
    SGeneralData.basicDataSelect({ bdtc: 'typeReceived' }).then((res) => {
      if (res.code == 1) {
        result.data.items?.forEach((val: { typeOfGoodsReceived: any, asReceivedCondition: string, quantityOfGoodsReceiveddisabled: boolean }) => {
          let str = t('commonresource.complete.key', '完成')
          val.asReceivedCondition = val.asReceivedCondition ? str + val.asReceivedCondition + '%' : ''
          res.data.forEach((type: { key: any; value: any }) => {
            if (type.key === val.typeOfGoodsReceived) val.typeOfGoodsReceived = type.value
          })
        })
      }
      data = result
    })
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询物料待检-收起明细
export const queryReceiptTicket = async (params : any) => {
  let data: any
  await http.post(HUrl.QUERYRECEIPTTICKET, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

//  待检物料导出
export const handleExport = async (params : any = {}) => {
  let data: any
  await http.getFilesExt(HUrl.QUALITYCONTROLAWAITEXPORT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
//  查询质检记录--平铺明细
export const queryQualityInspectionRecords = async (params : any) => {
  let data: any
  await http.post(HUrl.INSPECTIONRECORDS_LIST, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

//  查询质检记录--收起明细
export const queryQualityAnalysisCertificate = async (params : any) => {
  let data: any
  await http.post(HUrl.ANALYSISCERTIFICATE_LIST, params).then((result : any) => {
    result.data.items.forEach((e:any) => {
      e.qualityControlRemark = e.remark
    });
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 质检记录-批量删除质检单-收起明细
export const delQualityAnalysisCertificate = async (params : any) => {
  let data: any
  await http.delPost(HUrl.DELINSPECTIONRECORD, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 质检记录-批量删除质检单-平铺明细
export const delQualityControl = async (params : any) => {
  let data: any
  await http.delPost(HUrl.DELQUALITYCONTROL, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 质检记录-收起-导出
export const qualityAnalysisCertificateExport = async (params? : any) => {
  let data: any
  await http.getFilesExt(HUrl.QUALITYANALYSISCERTIFICATEEXPORT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 质检记录--平铺-导出
export const qualityControlExport = async (params? : any) => {
  let data: any
  await http.getFilesExt(HUrl.QUALITYCONTROLEXPORT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 新增质检单 不再使用
// export const addQualityAnalysisCertificate = async (params : any) => {
//   let data: any
//   await http.post(HUrl.ADDINSPECTIONRECORD, params).then((result : any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }
// 查询质检单关联源单
export const qualityControlSourceDoc = async (params : any) => {
  let data: any
  await http.post(HUrl.QUALITYCONTROLSOURCEDOC, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 修改质检单行
// export const UPDATEQUALITYCONTROL = async (params : any) => {
//   let data: any
//   await http.post(HUrl.UPDATEQUALITYCONTROL, params).then((result : any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

// 修改质检单
export const UPDATEQUALITYCONTROLAWAIT = async (params : any) => {
  let data: any
  await http.post(HUrl.UPDATEQUALITYCONTROLAWAIT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询质检单货品信息
export const qualityproductInformation = async (params : any) => {
  let data: any
  await http.post(HUrl.QUALITYPRODUCTINFORMATION, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 根据质检单号，查询质检单详情
export const queryQualityControl = async (params : any) => {
  let data: any
  await http.post(HUrl.QUERYQUALITYCONTROL, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 提交质检单
export const addBill = async (params : any) => {
  let data: any
  await http.post(HUrl.ADDBILL, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 保存质检单草稿
export const addBillDraft = async (params : any) => {
  let data: any
  await http.post(HUrl.ADDBILLDRAFT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 质检单保存草稿
export const addQualityAnalysisCertificateDraft = async (params : any) => {
  let data: any
  await http.post(HUrl.ADDQACDRAFT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 新增质检单（行）-草稿
export const addRow = async (params : any) => {
  let data: any
  await http.post(HUrl.ADDROW, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 导出质检单（行）
export const rowExport = async (params? : any) => {
  let data: any
  await http.getFiles(HUrl.ROWEXPORT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导入质检单行-草稿(excel表格file，key:质检单号,value:单据start)
export const rowImport = async (file : any) => {
  let data: any
  await http.postFiles(HUrl.ROWIMPORT, {}, file).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 质检单行模板下载
export const rowTemplate = async () => {
  let data: any
  await http.getFiles(HUrl.ROWTEMPLATE, {}).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 修改质检单-草稿/提交
export const updateBill = async (params : any) => {
  let data: any
  await http.post(HUrl.UPDATEBILL, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 修改质检单行-草稿/提交
export const updateRow = async (params : any) => {
  let data: any
  await http.post(HUrl.UPDATEROW, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// /warehQualityControl/counterReview
// 反审
export const qualityInspectionRecordsCounterReview = async (params : any) => {
  let data: any
  await http.post(HUrl.COUNTERREVIEW, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// /warehQualityControl/counterReview
// 撤回
export const qualityInspectionRecordsRevoke = async (params : any) => {
  let data: any
  await http.post(HUrl.REVOKE, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

