import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

// 系统初始化 /init/start
export const initStart = async (params: any) => {
  let data: any
  await http.post(HUrl.INIT_START, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 系统初始化分页列表
export const queryTempLateList = async (params?: any) => {
  let data: any
  await http.post(HUrl.QUERY_TEMPLATE_PAGE_LIST, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 数据库版本选择
export const selectDbVersion = async (params?: any) => {
  let data: any
  await http.post(HUrl.SELECT_DB_VERSION, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 系统初始化列表
export const tempLateDown = async (params?: any) => {
  let data: any
  await http.post(HUrl.QUERY_TEMPLATE_LIST, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 新增初始化模板
export const addTemplate = async (params: any) => {
  let data: any
  await http.addPost(HUrl.ADD_TEMPLATE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 修改初始化模板
export const updateTemplate = async (params: any) => {
  let data: any
  await http.editPost(HUrl.UPDATE_TEMPLATE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 删除初始化模板
export const deleteTemplate = async (params: any) => {
  let data: any
  await http.delPost(HUrl.DELETE_TEMPLATE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 新增初始化检查
export const addCheckDetail = async (params: any) => {
  let data: any
  await http.addPost(HUrl.ADD_CHECK_DETAIL, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 批量新增初始化检查
export const addCheckDetailBatch = async (params: any) => {
  let data: any
  await http.post(HUrl.ADD_CHECK_DETAIL_BATCH, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 新增初始化模板步骤
export const addTemplateLine = async (params: any) => {
  let data: any
  await http.addPost(HUrl.ADD_TEMPLATE_LINE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 删除初始化检查
export const deleteCheckDetail = async (params: any) => {
  let data: any
  await http.delPost(HUrl.DELETE_CHECK_DETAIL, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 删除初始化模板步骤
export const deleteTemplateLine = async (params: any) => {
  let data: any
  await http.delPost(HUrl.DELETE_TEMPLATE_LINE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询初始化模板步骤
export const queryTemplateLineList = async (params: any) => {
  let data: any
  await http.post(HUrl.QUERY_TEMPLATE_LINE_LIST, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询初始化检查
export const queryCheckDetailList = async (params: any) => {
  let data: any
  await http.post(HUrl.QUERY_CHECK_DETAIL_LIST, params).then((result: any) => {
    // if (!result.data?.items?.length) {
    //  // console.log(result)
    //   result.data.items = []
    // }
    result.data = {
      items: result.data || []
    }

    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 更新初始化检查
export const updateCheckDetail = async (params: any) => {
  let data: any
  await http.editPost(HUrl.UPDATE_CHECK_DETAIL, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 更新初始化模板步骤
export const updateTemplateLine = async (params: any) => {
  let data: any
  await http.editPost(HUrl.UPDATE_TEMPLATE_LINE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询初始化检查日志
export const queryInitCheckLog = async (params: any) => {
  let data: any
  await http.post(HUrl.QUERY_INIT_CHECK_LOG, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询初始化执行日志
export const queryInitExecuteLog = async (params: any) => {
  let data: any
  await http.post(HUrl.QUERY_INIT_EXECUTE_LOG, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 检查系统初始化状态
export const checkInitStatus = async (params: any) => {
  let data: any
  await http.post(HUrl.CHECK_INIT_STATUS, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 回退初始化
export const initFallback = async (params: any) => {
  let data: any
  await http.post(HUrl.INIT_FALLBACK, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
