import clickOutside from './click-outside'
import debounce from './debounce'
import throttle from './throttle'
import focus from './focus'
import imgerror from './imgerror'
import tooltip from './tooltip'
import type { App } from 'vue'
export default (app: App<Element>) => {
  clickOutside(app)
  debounce(app)
  throttle(app)
  focus(app)
  imgerror(app)
  tooltip(app)
}
