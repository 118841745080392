import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

//  合并标签
export const creaeByMerge = async (params : any) => {
  let data: any
  await http.post(HUrl.MATERIALLABEL_CREAEBYMERGE, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

//  根据源单创建标签
export const creaeBySource = async (params : any) => {
  let data: any
  await http.post(HUrl.MATERIALLABEL_CREAEBYSOURCE, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

//  分拆标签
export const creaeBySplit = async (params : any) => {
  let data: any
  await http.post(HUrl.MATERIALLABEL_CREAEBYSPLIT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 批量删除
export const deleteList = async (params : any) => {
  let data: any
  await http.post(HUrl.MATERIALLABEL_DELETELIST, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 列表查询
export const queryList = async (params : any) => {
  let data: any
  await http.post(HUrl.MATERIALLABEL_QUERYLIST, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 分面查询
export const queryPageList = async (params : any) => {
  let data: any
  await http.post(HUrl.MATERIALLABEL_QUERYPAGELIST, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 单个查询
 * */
export const querySingle = async (params:any = {}) => {
  let data: any
  await http.post(HUrl.MATERIALLABEL_QUERYSINGLE, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 查询源单已有标签数量
 * */
export const querySourceLabelQty = async (params:any = {}) => {
  let data: any
  await http.post(HUrl.MATERIALLABEL_QUERYSOURCELABELQTY, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
