/* eslint-disable @typescript-eslint/no-explicit-any */
// 数据集成接口

import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

// 新增集成版本
export const addIntegration = async (params: any) => {
  const result = await http.addPost(HUrl.BASE_PREFIX_INIT_START + '/integration/addIntegration', params)
  return result
}

// 查询账套集成日志分页
export const queryAccountIntegrationLogPageList = async (params: any) => {
  const result = await http.post(HUrl.BASE_PREFIX_INIT_START + '/integration/queryAccountIntegrationLogPageList', params)
  return result
}

// 查询集成分页
export const queryIntegrationPageList = async (params: any) => {
  const result = await http.post(HUrl.BASE_PREFIX_INIT_START + '/integration/queryIntegrationPageList', params)
  return result
}

// 开始集成
export const startIntegration = async (params: any) => {
  const result = await http.post(HUrl.BASE_PREFIX_INIT_START + '/integration/startIntegration', params)
  return result
}

