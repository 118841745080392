// import domToImage from 'dom-to-image'
import { ElMessage } from 'element-plus'
import type { apiType } from 'types/commonresource'
import type { objAny } from 'types/table'
import { downloadIamge, urlDownload } from '../modules/fileDownload'
import { cloneDeep } from 'lodash'

type FnType = {
  [key: string]: (params?: string | objAny | number) => Promise<any>
}

type CswindowType = {
  csWebCall?: FnType
}

const cswindow: Window & CswindowType = window
// const csWebCall: FnType = cswindow.csWebCall ? cswindow.csWebCall : null

/**
 * 调用 CefSharp 快捷键控制
 * @param val 打开快捷键编辑页面时 传0，关闭时传1.
 */
export const shortcutKeysSwitch = (val: boolean) => {
  cswindow.csWebCall && cswindow.csWebCall.shortcutKeysSwitch(val ? 0 : 1)
}

/**
 * 文件下载预览
 * @param fileIds 文件Id数组
 * @param folderIds 文件夹id数组
 */
type fileType = {
  id: string | number | undefined | unknown
  formId: string | number | undefined | unknown
  guid: string | number | undefined | unknown
  ftype: string | number | undefined | unknown
  fname: string | number | undefined | unknown
  fsize: string | number | undefined | unknown
  version: string | number | undefined | unknown
  url: string | number | undefined | unknown
}

const fileLayout = ['bmp', 'jpg', 'png', 'tif', 'gif', 'pcx', 'webp', 'jpeg']
// 文件下载
export const loadingFiles = (fileIds: fileType[], fn?: () => void) => {
  if (cswindow.csWebCall) {
    cswindow.csWebCall.List(fileIds)
  } else {
    if (fn) {
      fn()
    } else {
      console.log(fileIds)

      fileIds.map((item:any) => {
        if (item.url) {
          let replaceUrl = item.url
          // if (replaceUrl.startsWith('http:')) {
          //   replaceUrl = replaceUrl.replace('http:', 'https:')
          // }
          const arr = replaceUrl.split('.')
          if (fileLayout.includes(arr[arr.length - 1])) {
            downloadIamge(replaceUrl, item.fname)
          } else {
            urlDownload(replaceUrl, item.fname)
          }
        }
      })
    }
  }
}

/*
web端从客户端读取基础数据的方法，
readBaseData(BasicDataWebQueryModel queryModel)；
输入参数：{"typeCode":"code","enab":1,"isde":0}
typeCode:基础数据类型编码
enab是否启用：默认1=启用，0未启用的，-1=全部；isde是否删除，默认0=未删除，1=删除，-1全部，
输出的是跟api的数据模型一样。
*/

// 获取下拉数据
export const readBaseData = (typeCode: string, updateCache: boolean) => {
  return new Promise((resolve: (value: apiType | boolean) => void) => {
    const params = { typeCode, enab: 1, isde: 0 }
    if (cswindow.csWebCall) {
      if (updateCache) {
        // 客户端从数据库调用数据
        cswindow.csWebCall.updateBaseData(params).then((result) => {
          resolve(result as apiType)
        })
      } else {
        // 获取客户端数据
        cswindow.csWebCall.readBaseData(params).then((result) => {
          resolve(result as apiType)
        })
      }
    } else {
      resolve(false)
    }
  })
}

/**
 * 新增-编辑标签模板
 * @param params {
 * sysuser //1系统模板，2用户模板
 * flag //1新增，2编辑
 * tpid //标签Id,新增时赋值空
 * }
 */
export const addOrUpdTicketMode = (params: { sysuser: number, flag: number, tpid: number | string }) => {
  return new Promise((resolve: (value: objAny | boolean) => void) => {
    if (cswindow.csWebCall) {
      cswindow.csWebCall.addOrUpdTicketMode(params).then((result) => {
        resolve(result)
      })
    } else {
      ElMessage.error('c端操作')
      resolve(false)
    }
  })
}

/**
 * 新增-编辑标签打印设置
 * @param params {
 * sysuser //1系统模板，2用户模板
 * flag //1新增，2编辑
 * psid //标签打印 Id
 * }
 */
export const addOrUpdPrintSettings = (params: { sysuser: number, flag: number, psid: number | string }) => {
  return new Promise((resolve: (value: objAny | boolean) => void) => {
    if (cswindow.csWebCall) {
      cswindow.csWebCall.addOrUpdPrintSettings(params).then((result) => {
        resolve(result)
      })
    } else {
      ElMessage.error('c端操作')
      resolve(false)
    }
  })
}

/**
 * 退出登录
 * @param fn 回调函数
 */
export const relogin = (fn: () => void) => {
  if (cswindow.csWebCall) {
    ElMessage.error('账号异地登录')
    cswindow.csWebCall.relogin()
  } else {
    fn()
  }
}

type PrintParams = {
  width: string| number //  宽
  length: string| number // 高
  id: string // 模板id
  sourceCode: string| number // 资源编码
  viewCode: string| number // 视图编码
  pageCode: string| number // 纸张类型
  rowo: string| number // 平铺模板行
  colo: string| number // 平铺模板列
  pleft: string| number // 左内边距
  ptop: string| number // 上内边距
  pbottom: string| number // 下内边距
  pright: string| number // 右内边距
  pageTop: string| number // 页面向下偏移
  pageLeft: string| number // 页面向右偏移
  pageWidth: string| number // 纸张宽
  pageLength: string| number // 纸张高
  borderShape: string| number // 是否有边框
  filletSize: string| number // 圆角大小
  printConfig: string| number //  打印设置
  printView: string| number //  模板视图
  pplay: string| number // 打印方向
  colour: number // 打印色彩|1黑白，2彩色
  duplex: number // 双面打印|1=单面，2双面
  flip: number //  翻页方式|双面打印时有效，1长边翻页，2短边翻页
  prnm: string //  打印机名称|print name
  printd: string | number// 打印深度|1~10，默认6，暂时没用起来
}

const getParams = (data?: PrintParams) => {
  // data
  if (!data) return {}
  const params = {
    psid: data.id, // 标签打印设置参数模型Id|
    // psnm: data.width, // 打印设置名称|tpnm
    prnm: data.prnm, // 打印机名称|print name
    ppnm: data.pageCode === 'other' ? 'Custom' : data.pageCode, // 纸张类型名称|paper type name
    pplay: +data.pplay, // 纸张布局|1=纵向，2=横向
    ppwd: +data.width, // 纸张宽度|单位:毫米，一般纵向时，宽度没高度大
    ppht: +data.length, // 纸张高度|单位:毫米
    toffset: +data.pageTop, // 上偏移量|单位:毫米，是指纸张与打印机之间的物理物理值，设定偏移之后保证打印精准度，可为负数
    loffset: +data.pageLeft, // 左偏移量|单位:毫米
    pprn: +data.colo, // 打印行数|指纸张上打印标签的行数
    ppcn: +data.rowo, // 打印列数|指纸张上打印标签的列数
    // tmargin: data.width, // 上边距|指打印内容与纸张上边边沿的距离，单位毫米
    // lmargin: data.width, // 左边距|指打印内容与纸张左边边沿的距离，单位毫米
    // rmargin: data.width, // 右边距|指打印内容与纸张右边边沿的距离，单位毫米
    // bmargin: data.width, // 下边距|指打印内容与纸张下边边沿的距离，单位毫米
    // space: data.width, // 行列间距|指多行列之间的间距，单位:毫米，不可为负
    colour: +data.colour, // 打印色彩|1黑白，2彩色
    printq: 1, // 打印质量|1=Custom-600,2=Custom-1200,3=高，4=中，5=低
    duplex: +data.duplex, // 双面打印|1=单面，2双面
    flip: +data.flip, // 翻页方式|双面打印时有效，1长边翻页，2短边翻页
    printd: +data.printd, // 打印深度|1~10，默认6
    dmode: 1 // 显示模式|1满屏填充，2=等比显示；（默认赋值1，这里指打印配置时样例纸张在界面上显示方式）
  }
  return params
}

/**
 * 打印
 * @param params PrintParamsType 传入参数
 * @param fn 回调函数
 */
export const printTicketOrTable = ({
  settings,
  ticketMode,
  ticketData
} = {
  settings: {} as PrintParams | undefined,
  ticketData: [],
  ticketMode: {} as objAny
}, fn: () => void) => {
  if (cswindow.csWebCall) {
    const paramsData = {
      settings: getParams(settings),
      ticketData: cloneDeep(ticketData),
      ticketMode: ticketMode.panels[0]
      // pageCount, // 本次打印总页数
      // pageIndex: index + 1, // 当前打印页序号
      // imgBas64: dataUrl
    }
    console.log('paramsData', paramsData)
    if (settings && settings.prnm) {
      cswindow.csWebCall?.printTicketOrTable(paramsData)
    } else {
      // 打印机必填
      ElMessage.warning('打印机必填')
    }

    // const itemHtml = document.querySelectorAll('.hiprint-printPanel')
    // if (itemHtml && itemHtml.length > 0) {
    //   // const promiseAll: any = []
    //   let pageCount = itemHtml.length
    //   itemHtml.forEach((item, index) => {
    //     domToImage.toPng(item).then(function (dataUrl: string) {
    //       // 按页打印 每一页调用方法
    //       const paramsData = {
    //         settings: getParams(settings),
    //         ticketData,
    //         ticketMode,
    //         pageCount, // 本次打印总页数
    //         pageIndex: index + 1, // 当前打印页序号
    //         imgBas64: dataUrl
    //       }
    //       console.log('paramsData', paramsData)

    //       cswindow.csWebCall?.printTicketOrTable(paramsData)
    //     })
    //     // promiseAll.push(new Promise((resolve: (value: string) => void, reject) => {
    //     //   domToImage.toPng(item).then(function (dataUrl: string) {
    //     //     resolve(dataUrl)
    //     //   })
    //     //     .catch(function (error: Error) {
    //     //       reject(error)
    //     //     })
    //     // }))
    //   })
    //   // Promise.all(promiseAll).then(result => {
    //   //   console.log(result)
    //   // })
    // }
  } else {
    fn()
  }
}

interface PrintListType {
  key: string
  value: string // 打印机名称
}

/**
 * 获取打印机列表
 * @param params {printerType 1查询标签打印机，2其他}
 * @param fn 返回打印机列表
 */
export const getPrintList = (params: {printerType: number}, fn: (list: PrintListType[]) => void) => {
  if (cswindow.csWebCall) {
    cswindow.csWebCall.getPrinter(params).then((result: string[]) => {
      const list = result.map((item, index) => {
        return {
          value: item,
          key: String(index + 1)
        }
      })
      fn(list)
    })
  } else {
    fn([])
  }
}
