// 待入库页面接口

import { http } from '@/service'
import { HUrl } from '@/config'
import { apiType } from 'types/commonresource'
import { commonFunc } from '@/utils'
type Tservice = (back:apiType) => void
// 正式提交与保存草稿接口
export const addBill = async(params:object = {}) => {
  let data:any
  await http.post(HUrl.BASE_PREFIX_FINISHEDWAREHOUTAWAIT + '/addBill', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// 保存出库单草稿
export const addBillDraft = async(params:object = {}) => {
  let data:any
  await http.post(HUrl.BASE_PREFIX_FINISHEDWAREHOUTAWAIT + '/addBillDraft', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// 新增待出库-提供给第三方
export const addOutAwait = async(params:object = {}) => {
  let data:any
  await http.post(HUrl.BASE_PREFIX_FINISHEDWAREHOUTAWAIT + '/addOutAwait', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// 新增出库单行（草稿）
export const addRow = async(params:object = {}) => {
  let data:any
  await http.post(HUrl.BASE_PREFIX_FINISHEDWAREHOUTAWAIT + '/addRow', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}

// 批量删除出库单行
export const deleteOutDetails = async(params:object = {}) => {
  let data:any
  await http.delPost(HUrl.BASE_PREFIX_FINISHEDWAREHOUTAWAIT + '/deleteOutDetails', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}

// 批量删除出库单
export const deleteStockOut = async(params:object = {}) => {
  let data:any
  await http.delPost(HUrl.BASE_PREFIX_FINISHEDWAREHOUTAWAIT + '/deleteStockOut', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}

// 获取出库单fromId(草稿)
export const getId = async(params:object = {}) => {
  let data:any
  await http.post(HUrl.BASE_PREFIX_FINISHEDWAREHOUTAWAIT + '/getId', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}

// 查询待出库记录-平铺明细
export const queryOutAwait = (params:object = {}) => {
  return new Promise((resolve:Tservice) => {
    http.post(HUrl.BASE_PREFIX_FINISHEDWAREHOUTAWAIT + '/queryOutAwait', params).then((res:any) => {
      if (res && res.code == 1) {
        const back:apiType = JSON.parse(JSON.stringify(res))
        commonFunc.keyToVal(back.data.items, { typeOfMaterial: 'materialClass', state: 'approvalStatus' }).then((backData) => {
          back.data.items = backData
          resolve(back)
        })
      } else {
        resolve(res)
      }
    }).catch((err:any) => {
      return http.catch(err)
    })
  })
}
// 查询待出库记录-收起明细
export const queryOutAwaitPack = (params:object = {}) => {
  return new Promise((resolve:Tservice) => {
    http.post(HUrl.BASE_PREFIX_FINISHEDWAREHOUTAWAIT + '/queryOutAwaitPack', params).then((res:any) => {
      if (res && res.code == 1) {
        const back:apiType = JSON.parse(JSON.stringify(res))
        commonFunc.keyToVal(back.data.items, { typeOfMaterial: 'materialClass', state: 'approvalStatus' }).then((backData) => {
          back.data.items = backData
          resolve(back)
        })
      } else {
        resolve(res)
      }
    }).catch((err:any) => {
      return http.catch(err)
    })
  })
}
// 查询出库记录-平铺明细
export const queryOutDetailsList = async (params: object = {}) => {
  let data:any
  await http.post(HUrl.BASE_PREFIX_FINISHEDWAREHOUTAWAIT + '/queryOutDetailsList', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}

// 关联源单
export const querySourceDocList = async(params:object = {}) => {
  let data:any
  await http.post(HUrl.BASE_PREFIX_FINISHEDWAREHOUTAWAIT + '/querySourceDocList', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}

// 根据出库单code，查询出库单详情
export const queryStockOut = async(params:object = {}) => {
  let data:any
  await http.post(HUrl.BASE_PREFIX_FINISHEDWAREHOUTAWAIT + '/queryStockOut', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// 查询出库记录-收起明细
export const queryStockOutList = async(params:object = {}) => {
  let data:any
  await http.post(HUrl.BASE_PREFIX_FINISHEDWAREHOUTAWAIT + '/queryStockOutList', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// 出库单行导出(根据出库单号)
export const rowExport = async(params:object = {}) => {
  let data:any
  await http.getFiles(HUrl.BASE_PREFIX_FINISHEDWAREHOUTAWAIT + '/rowExport', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// 出库单行导入-草稿(excel表格file，key:入库单号,value:单据start)
export const rowImport = async(file: any) => {
  let data:any
  await http.postFiles(HUrl.BASE_PREFIX_FINISHEDWAREHOUTAWAIT + '/rowImport', {}, file).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// 修改出库单行
export const updateOutDetails = async(params:object = {}) => {
  let data:any
  await http.post(HUrl.BASE_PREFIX_FINISHEDWAREHOUTAWAIT + '/updateOutDetails', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// 修改出库单行
export const updateRow = async(params:object = {}) => {
  let data:any
  await http.post(HUrl.BASE_PREFIX_FINISHEDWAREHOUTAWAIT + '/updateRow', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// 查询货品信息
export const queryItemInformation = async(params:object = {}) => {
  let data:any
  await http.post(HUrl.BASE_PREFIX_FINISHEDWAREHOUTAWAIT + '/queryItemInformation', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}

// --------------------------
// storageSourceDocList 查询出库单关联源单
export const storageSourceDocList = (params:object = {}) => {
  let data:any
  return new Promise((resolve:any) => {
    http.post(HUrl.PUTINSTORAGE_STORAGESOURCEDOCLIST, params).then((res:any) => {
      if (res && res.code === 1) {
        const back = JSON.parse(JSON.stringify(res))
        commonFunc.keyToVal(back.data.items, { typeOfMaterial: 'materialClass' }).then((backData) => {
          back.data.items = backData
          data = back
          resolve(data)
        })
      }
    }).catch((err:any) => {
      return http.catch(err)
    })
  })
}
// 文件相关接口
// 下载出库单货品信息导入模板
export const rowTemplate = async(params:object = {}) => {
  let data:any
  await http.getFiles(HUrl.BASE_PREFIX_FINISHEDWAREHOUTAWAIT + '/rowTemplate', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// /warehousing/storageExport 待入库记录-导出-收起
export const storageExport = async(params:object = {}) => {
  let data:any
  await http.getFilesExt(HUrl.BASE_PREFIX_WAREHOUSING + '/storageExport', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// /warehousing/waitingStorageExport 待入库记录-导出-平铺
export const waitingStorageExport = async(params:object = {}) => {
  let data:any
  await http.getFilesExt(HUrl.BASE_PREFIX_WAREHOUSING + '/waitingStorageExport', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}

// 出库记录-导出-收起明细
export const stockOutExport = async(params:object = {}) => {
  let data:any
  await http.getFilesExt(HUrl.BASE_PREFIX_FINISHEDWAREHOUTAWAIT + '/stockOutExport', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}
// 出库记录-导出-平铺明细
export const outAwaitExport = async(params:object = {}) => {
  let data:any
  await http.getFilesExt(HUrl.BASE_PREFIX_FINISHEDWAREHOUTAWAIT + '/outAwaitExport', params).then((res:any) => {
    data = res
  }).catch((err:any) => {
    return http.catch(err)
  })
  return data
}

// /warehOutAwait/counterReview 反审
export const warehOutAwaitCounterReview = async (params : any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_FINISHEDWAREHOUTAWAIT + '/counterReview', params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// /warehOutAwait/revoke 撤回流程
export const warehOutAwaitRevoke = async (params : any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_FINISHEDWAREHOUTAWAIT + '/revoke', params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
