import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import i18n from '@/i18n'

const baseUrl = HUrl.HTTP_BASE_URL_JAVACHANGE + '/warehIntransit'
// 收货请求-------------------

//  查询在途物料列表  /warehIntransit/queryInTransitMaterials根据条件，查询在途物料列表-平铺
export const queryInTransitMaterials = async (params : any) => {
  let data: any
  const t = i18n.global.t
  await http.post(HUrl.QUERYINTRANSITMATERIALS, params).then((result : any) => {
    data = result
    data.data.items.forEach((item:any) => {
      let str = t('commonresource.complete.key', '完成')
      item.asReceivedCondition = item.asReceivedCondition ? str + item.asReceivedCondition + '%' : ''
      if (item.asReceivedCondition === str + '100%') {
      // 如果收货状态时100% 就禁止输入收货数量
        item.quantityOfGoodsReceiveddisabled = true
      }
    })
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

//  查询在途物料列表  /warehIntransit/getInTransitMaterialsPack根据条件，查询在途物料列表-收起
export const getInTransitMaterialsPack = async (params : any) => {
  let data: any
  const t = i18n.global.t
  await http.post(HUrl.GETINTRANSITMATERIALSPACK, params).then((result : any) => {
    data = result
    data.data.items.forEach((item:any) => {
      let str = t('commonresource.complete.key', '完成')
      item.asReceivedCondition = item.asReceivedCondition ? str + item.asReceivedCondition + '%' : ''
      if (item.asReceivedCondition === str + '100%') {
      // 如果收货状态时100% 就禁止输入收货数量
        item.quantityOfGoodsReceiveddisabled = true
      }
    })
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
//  根据条件查询收货记录明细 平铺
export const queryReceiptDetails = async (params: any) => {
  let data: any
  const t = i18n.global.t
  await http.post(HUrl.QUERYRECEIPTDETAILS, params).then((result : any) => {
    data = result
    data.data.items.forEach((item:any) => {
      let str = t('commonresource.complete.key', '完成')
      item.asReceivedCondition = item.asReceivedCondition ? str + item.asReceivedCondition + '%' : ''
      if (item.asReceivedCondition === str + '100%') {
      // 如果收货状态时100% 就禁止输入收货数量
        item.quantityOfGoodsReceiveddisabled = true
      }
    })
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
//  查询收货单提交页面货品信息
export const queryProductInformation = async (params : any) => {
  let data: any
  await http.post(HUrl.QUERYPRODUCTINFORMATION, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
//  根据条件查询收货记录明细 收起
export const queryReceiptTicketList = async (params : any) => {
  let data: any
  const t = i18n.global.t
  await http.post(HUrl.QUERYRECEIPTTICKETLIST, params).then((result : any) => {
    data = result
    data.data.items.forEach((item:any) => {
      let str = t('commonresource.complete.key', '完成')
      item.asReceivedCondition = item.asReceivedCondition ? str + item.asReceivedCondition + '%' : ''
      if (item.asReceivedCondition === str + '100%') {
      // 如果收货状态时100% 就禁止输入收货数量
        item.quantityOfGoodsReceiveddisabled = true
      }
    })
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 根据条件，查询收货单关联的源单信息
export const receiptSourceDoc = async (params : any) => {
  let data: any
  await http.post(HUrl.RECEIPTSOURCEDOC, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 新增收货单
export const addBill = async (params : any) => {
  let data: any
  await http.post(HUrl.RECEIPTADDBILL, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 新增收货单--草稿
export const addBillDraft = async (params : any) => {
  let data: any
  console.log(params, 'addBillDraft')
  await http.post(HUrl.BASE_PREFIX_ADDBILLDRAFT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 在途物料导出excl-平铺
 * */
export const intransitexport:any = async (params:any = {}) => {
  let data: any
  await http.getFilesExt(HUrl.INTRANSITEXPORT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 在途物料导出excl-收起
 * */
export const inTransitPackExport:any = async (params:any = {}) => {
  let data: any
  await http.getFilesExt(HUrl.INTRANSITPACKEXPORT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 收记录导出excl--平铺
 * */
export const receiptDetailsExport:any = async (params:any = {}) => {
  let data: any
  await http.getFilesExt(HUrl.RECEIPTDETAILSEXPORT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 收记录导出excl--收起
 * */
export const receiptTicketExport:any = async (params:any = {}) => {
  let data: any
  await http.getFilesExt(HUrl.RECEIPT_RECEIPTTICKETEXPORT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 收货记录删除接口 收起明细
export const deleteReceiptTicket = async (params : any) => {
  let data: any
  await http.delPost(HUrl.DELETERECEIPTTICKET, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 收货记录删除接口 平铺明细
export const deleteReceiptTicketRow = async (params : any) => {
  let data: any
  await http.delPost(HUrl.DELETERECEIPTTICKETROW, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 收货单新增行
export const receiptAddRow = async (params : any) => {
  let data: any
  await http.post(HUrl.RECEPTADDROW, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 更新单行
export const receiptUpdateRow = async (params : any) => {
  let data: any
  await http.post(HUrl.RECEIPT_UPDATEROW, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导入收货单行
export const receiptRowImport = async (file:any) => {
  let data: any
  await http.postFiles(HUrl.RECEIPT_ROWIMPORT, {}, file).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导出收货单行
export const receiptRowexport = async (params : any) => {
  let data: any
  await http.getFiles(HUrl.RECEIPT_ROWEXPORT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导入下载收货单行
export const receiptRowDown = async (params?: any) => {
  let data: any
  await http.getFilesExt(baseUrl + '/rowTemplate', params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 根据收货单编码查询收货单详情
export const queryReceiptTicket = async (params : any) => {
  let data: any
  await http.post(HUrl.RECEIPT_QUERYRECEIPTTICKET, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 获取formif
export const getId = async (params : any) => {
  let data: any
  await http.post(HUrl.RECEIPT_GETID, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询源单列表
export const sourcequerypage = async (params : any) => {
  let data: any
  await http.post(HUrl.SOURCEQUERYPAGE, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询源单详情
export const sourcequerydetailspage = async (params : any) => {
  let data: any
  await http.post(HUrl.SOURCEQUERYDETAILSPAGE, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询收货、质检单源单信息(key:单据类型编码，value:源单单号) /sourceorder/queryRQ
export const sourceorderQueryRQ = async (params : any) => {
  let data: any
  await http.post(HUrl.SOURCEORDERQUERYRQ, params).then((result : any) => {
    data = result
    console.log('查询收货、质检', data)
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 源单使用校验 /sourceOrder/useValid
export const useValid = async (params : any) => {
  let data: any
  await http.post(HUrl.USEVALID, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// /sourceorder/scanVerification 源单校验(key:单据类型编码，value:源单单号)
export const sourceorderScanVerification = async (params : any) => {
  let data: any
  await http.post(HUrl.SOURCEORDERSCANVERIFICATION, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询源单详情 /warehIntransit/counterReview 反审
export const warehIntransitCounterReview = async (params : any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_COUNTERREVIEW, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询源单详情 /warehIntransit/revoke 撤回流程
export const warehIntransitRevoke = async (params : any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_REVOKE, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
