import { computed, defineAsyncComponent } from 'vue'
import { objAny } from 'types/table'
import { apiType } from 'types/commonresource'
// 获取hooks
import basicInfoHooks from '@/views/business/finance/fxrate/fxratemanger/hooks/basicInfo' // 基本信息
import cfgHooks from '@/views/business/finance/fxrate/fxratemanger/hooks/fxRateConfig' // 金额明细
// 获取接口
import { getInfo, getPageListDetail } from '@/service/modules/finance/fxratemanger'
// import { queryByCurrency } from '@/service/modules/finance/fxratert'
const formJsonData = () => {
  // 基础表单hooks
  const res = computed(() => {
    return {
      ...basicInfoHooks().formJson
    }
  })
  // 表格hooks
  return [
    {
      title: { lank: 'finance.essentialInfo', default: '基本信息' }, // 基本信息
      type: 'form', // 表单
      json: res.value,
      getInfo: (params: objAny) => {
        console.log('fxratemanger============', params)
        return new Promise((resolve: (value: apiType) => void, reject) => {
          return getInfo(params).then((res) => {
            console.log('123||', res)
            resolve(res)
          })
        })
      },
      slot: {
      },
      formStyle: {
        // company: [
        //   'grid-column: 1 / span 4;'
        // ]
      }
    },
    {
      title: { lank: 'finance.FxRateConfig', default: '汇率维护' },
      type: 'table', // 表格
      formid: 'exrateId',
      id: 'id',
      columnList: cfgHooks().columnList,
      slot: {
        enable: defineAsyncComponent(() => import('./componentsSlot/isen.vue'))
      },
      getInfo: (params: objAny) => {
        return new Promise((resolve: (value: apiType) => void, reject) => {
          getPageListDetail({
            keyWord: '',
            data: {
              exrateId: params.exrateId
            },
            pageIndex: params.pageIndex,
            pageSize: params.pageSize,
            total: 0
          }).then(async (result) => {
            if (result.data && result.data.items) {
              result.data.items.forEach((v: any) => {
                //
              })
            }
            resolve(result)
          })
        })
      }
    }
  ]
}
export default formJsonData
