/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment'
import i18n from '@/i18n'
import { nextTick } from 'vue'
import { bomInfoCheck } from '@/service/modules/production/bom'
import { bomInfoStore } from '@/store'

type TLanFn = (str1: string, str2: string) => string
let t: TLanFn = (str1: string, str2: string) => str2
nextTick(() => {
  t = i18n.global.t as TLanFn
})
// 数字字母
export const isNumberString = (rule: any, value: any, callback: any) => {
  const Fomrrule = /^[A-Za-z0-9]+$/
  if (!Fomrrule.test(value) && value !== '') {
    callback(new Error(t('commonresource.isNumberString.key', '只能输入字母和数字')))
  } else {
    callback()
  }
}

// 不能输入中文
export const onChineseCharacter = (rule: any, value: any, callback: any) => {
  const Fomrrule = /[\u4E00-\u9FA5]/g
  if (Fomrrule.test(value)) {
    callback(new Error(t('commonresource.onChineseCharacter.key', '不能输入汉字!')))
  } else {
    callback()
  }
}

// 非负数
export const nonNegative = (rule: any, value: any, callback: any) => {
  const Fomrrule = /^[0-9+][0-9 |.]*$/
  if (Fomrrule.test(value) || !value) {
    callback()
  } else {
    callback(new Error(t('commonresource.nonNegative.key', '请输入正数')))
  }
}

// 座机号
export const isTelephone = (rule: any, value: any, callback: any) => {
  const Fomrrule = /^(([0-9]{3,4}[-])?[0-9]{7,8}([-][0-9]+)*$)/
  if (!Fomrrule.test(value)) {
    callback(new Error(t('commonresource.isTelephone.key', '请输入正确的座机号')))
  } else {
    callback()
  }
}

// 手机和座机
export const checkTelAndMobile = (rule: any, value: any, callback: any) => {
  if (!value) {
    callback()
    return
  }
  const allTel = /^[0-9-]+$/
  if (!allTel.test(value)) {
    callback(new Error(t('commonresource.isTeleMobphone.key', '请输入正确的手机号/座机号')))
  } else {
    callback()
  }
}

// 数组字符串和符号
export const isPassword = (rule: any, value: any, callback: any) => {
  const Fomrrule = /^[A-Za-z0-9]+$/
  if (!Fomrrule.test(value)) {
    callback(new Error(t('commonresource.isPassword.key', '只能输入字母和数字')))
  } else {
    callback()
  }
}

// 至少输入两个字符
export const twoLength = (rule: any, value: any, callback: any) => {
  if (value.length < 2) {
    callback(new Error(t('commonresource.twoLength.key', '至少需要输入两个字符')))
  } else {
    callback()
  }
}
// 手机号
export const isPhoneNumber = (rule: any, value: any, callback: any) => {
  const Fomrrule = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
  if (!Fomrrule.test(value)) {
    callback(new Error(t('commonresource.isPhoneNumber.key', '请输入正确的手机号码')))
  } else {
    callback()
  }
}

// 金额
export const isMoney = (rule: any, value: any, callback: any) => {
  const Fomrrule = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/
  if (!Fomrrule.test(value)) {
    callback(new Error(t('commonresource.isMoney.key', '请输入金额')))
  } else {
    callback()
  }
}

// 大于0 的正整数
export const isNumberPerson = (rule: any, value: any, callback: any) => {
  const Fomrrule = /^([1-9][0-9]*)$/
  if (!Fomrrule.test(value)) {
    callback(new Error(t('commonresource.isNumber.key', '请输入大于0的正整数')))
  } else {
    callback()
  }
}

// 数字
export const isNumber = (rule: any, value: any, callback: any) => {
  const Fomrrule = /^[0-9]*$/
  if (!Fomrrule.test(value)) {
    callback(new Error(t('commonresource.isNumber.key', '请输入数字')))
  } else {
    callback()
  }
}
// 整数
export const integer = (rule: any, value: any, callback: any) => {
  const Fomrrule = /^[0-9]*$/
  if (!Fomrrule.test(value)) {
    callback(new Error(t('commonresource.integer.key', '请输入整数')))
  } else {
    callback()
  }
}

// 邮箱
export const isEmail = (rule: any, value: any, callback: any) => {
  const Fomrrule = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
  if (value && !Fomrrule.test(value)) {
    callback(new Error(t('commonresource.isEmail.key', '请输入正确的邮箱格式')))
  } else {
    callback()
  }
}

// 身份证校验
export const idCard = (rule: any, value: any, callback: any) => {
  const Fomrrule = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
  if (!Fomrrule.test(value)) {
    callback(new Error(t('commonresource.idCard.key', '请输入正确的身份证号码')))
  } else {
    callback()
  }
}

// 只能输入英文
export const EN = (rule: any, value: any, callback: any) => {
  const Fomrrule = /^[a-zA-Z]+$/
  if (!Fomrrule.test(value)) {
    callback(new Error(t('commonresource.EN.key', '只能输入英文')))
  } else {
    callback()
  }
}

// 时间不能大于当前时间
export const maxTime = (rule: any, value: any, callback: any) => {
  console.log(value)
  const endTime = moment(value)
  const startTime = moment(new Date())
  const diff = moment(endTime).diff(moment(startTime), 'seconds')
  console.log(diff)
  if (diff <= 0) {
    callback()
  } else {
    callback(new Error(t('commonresource.maxTime.key', '选择时间不能大于当前时间')))
  }
}
// 时间不能小于当前时间
export const minTime = (rule: any, value: any, callback: any) => {
  console.log(value)
  const endTime = moment(value)
  const startTime = moment(new Date())
  const diff = moment(endTime).diff(moment(startTime), 'seconds')
  console.log(diff)
  if (diff >= 0) {
    callback()
  } else {
    callback(new Error(t('commonresource.minTime.key', '选择时间不能小于于当前时间')))
  }
}
// 限制百分比数值不能大于100
export const percentageNum = (rule: any, value: any, callback: any) => {
  if (value) {
    const num = Number(value || 0)
    if (num > 100) {
      callback(new Error(t('commonresource.percentageNum.key', '数值不能大于100')))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

// el-input 只能输入整数 使用方法 :formatter="numFormatter"
export const numFormatter = (value: string) => {
  if (value) {
    value = value.replace(/[^0-9]/g, '')
    return parseInt(value)
  } else {
    return 0
  }
}
// el-input 只能输入数字（大于等于0的任意自然数） 使用方法 :formatter="floatFormatter"
export const floatFormatter = (value: string) => {
  if (value) {
    value = value.replace(/[^\d\.]/g, '')
    if (value.indexOf('.') > 0 && value.match(/\./g)?.length === 1) {
      return value
    } else {
      return parseFloat(value)
    }
  } else {
    return 0
  }
}

// 银行卡校验
export const bankAccountValid = (rule: any, value: any, callback: any) => {
  if (!value) callback()
  if (!(/^[0-9 _]*$/).test(value)) {
    callback(new Error(t('commonresource.bankCardNum.key', '银行卡号必须全为数字')))
  } else if (value.replace(/\s*/g, '').length < 16 || value.replace(/\s*/g, '').length > 21) {
    callback(new Error(t('commonresource.bankCardLength.key', '银行卡号长度必须在16到21之间')))
  } else {
    callback()
  }
}
// 最大值
export const maxValue = (rule: any, value: any, callback: any) => {
  if (+value > 255) {
    callback(new Error(t('commonresource.bigNum.key', '排序最大值255')))
  } else if (+value < 0) {
    callback(new Error(t('commonresource.minNum.key', '排序最小值不能小于0')))
  } else {
    callback()
  }
}
// 纳税识别号
export const taxIDNumber = (rule: any, value: string, callback: any) => {
  if (value === '' || value.length === 18 || value.length === 15 || value.length === 20) {
    callback()
  } else {
    callback(new Error(t('administration.taxIDNumberLength.key', '纳税识别号长度为 15、18或20位')))
  }
}

// 该料号已存在对应BOM,请确认后重试
export const existsedBom = (rule: any, value: any, callback: any) => {
  const bomStore = bomInfoStore()
  const bomInfoId = bomStore.getBomInfoId
  console.log('调用pinia的的bomId', bomInfoId)
  bomInfoCheck({ matn: value, id: bomInfoId }).then(res => {
    if (res.code !== 1) {
      callback(new Error(res.message))
    } else {
      callback()
    }
  })
}

/**
 * 账号校验规则，3到20位
 */
export const logaLength = (rule: any, value: any, callback: any) => {
  const Fomrrule = /^[a-zA-Z0-9._@]+$/
  // if (!Fomrrule.test(value)) {
  if (!Fomrrule.test(value)) {
    callback('账号只允许数字、字母和三个特殊符号. _ @')
  } else if (value.length < 6 || value.length > 32) {
    callback(new Error('只能输入6-32位字符'))
  } else {
    callback()
  }
}
/**
 * 货架设备号校验
 */
export const imeiCheck = (rule: any, value: any, callback: any) => {
  const Fomrrule = /^\d{15}$/
  // if (!Fomrrule.test(value)) {
  if (!Fomrrule.test(value)) {
    callback('请输入15位整数')
  } else {
    callback()
  }
}
