import { computed } from 'vue'
import warehouseReceipt from '@/views/business/warehouse/receipt/materialInTransit/hooks/indexHooks'
import { goodsColumnList } from '@/views/business/warehouse/receipt/receiptRecord/hooks/tableForm'
import { queryReceiptTicket, queryReceiptDetails } from '@/service/modules/warehouse/materialInTransit'
import { ref } from 'vue'
import moment from 'moment'
import { cache } from '@/utils'
import { objAny } from 'types/table'

const formJson = () => {
  let addReceSes = {
    userSes: JSON.parse(cache.getLocalStorage('userInfo')),
    receDate: moment().format('YYYY-MM-DD')
  }
  const receiptTicketCode = ref('') // 收货单号
  const sourceBillCode = ref('')
  const dataEntryClerk = ref(JSON.parse(cache.getLocalStorage('userInfo')).emplId)// 录入人
  const timeOfReceipt = ref(moment().format('YYYY-MM-DD'))// 收货时间
  const disabledComp = ref(false) // 所属公司是否禁用
  const dataEntryClerkName = ref('select')// 录入人组件名
  const originalOrderTypeOptionData = ref([])
  const unitOfOriginName = ref('')
  const receipt = warehouseReceipt({ receiptTicketCode, sourceBillCode, timeOfReceipt, dataEntryClerk, dataEntryClerkName, disabledComp, originalOrderTypeOptionData })
  const res = computed(() => {
    const { department, customer, supplier, ...newBaseJson } = receipt.baseJson
    const obj = {
      companyId: newBaseJson.companyId,
      departmentId: newBaseJson.departmentId,
      dataEntryClerk: newBaseJson.dataEntryClerk,
      timeOfReceipt: newBaseJson.timeOfReceipt,
      receivingType: newBaseJson.receivingType,
      receiptTicketCode: newBaseJson.receiptTicketCode,
      sourceBillCode: newBaseJson.sourceBillCode,
      originalOrderType: newBaseJson.originalOrderType,
      unitOfOriginName: {
        label: { lank: 'warehouse.unitOfOrigin', default: '来源单位' },
        span: 6,
        disabled: true,
        required: true,
        value: unitOfOriginName.value
      },
      sourceOfDeliveryNote: newBaseJson.sourceOfDeliveryNote,
      receivingDepartment: newBaseJson.receivingDepartment,
      receipt: newBaseJson.receipt,
      remark: newBaseJson.remark
    }
    return obj
  })
  receipt.getOptionsData(addReceSes.userSes.companyId, addReceSes.userSes.companyId)

  return [
    {
      title: { lank: 'administration.basicInformation', default: '基本信息' },
      type: 'form',
      json: res.value,
      getInfo: async (params: objAny) => {
        const result = await queryReceiptTicket(params)
        unitOfOriginName.value = result.data.unitOfOriginName
        result.data.remark = result.data.receiptRemark
        return result
      }
    },
    {
      title: { lank: 'warehouse.productInformation', default: '货品信息' },
      type: 'table',
      formid: 'receiptTicketId',
      columnList: goodsColumnList,
      id: 'id',
      getInfo: queryReceiptDetails
    },
    {
      title: { lank: 'warehouse.receiptFile', default: '收货单附件' },
      type: 'files',
      code: 'ReceiptRecord',
      templetCode: 'ReceiptRecord'
    }
  ]
}

export default formJson
