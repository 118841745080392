/* eslint-disable @typescript-eslint/no-explicit-any */
import { http, SDivisional } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import { getCompanyDataSelect } from '../administration/company'

const url = HUrl.ENGINEERING // 路径前缀 + 微服务
// 取消
export const cancel = async (params: any) => {
  let data: any
  await http.post(url + '/substitute/cancel', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 获取表格分页数据 -1
export const getPageList = async (params: any) => {
  let data: any
  await http.post(url + '/substitute/queryPageList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 获取表格(收起)分页数据 -1
export const queryRetractPage = async (params: any) => {
  let data: any
  await http.post(url + '/substitute/queryRetractPage', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 获取单个详情 -2
export const getInfo = async (params: any) => {
  let data: any
  await http.post(url + '/substitute/querySingle', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
export const getInfoApps = async (params: any) => {
  let data: any
  const companys = await getCompanyDataSelect()
  await http.post(url + '/substitute/querySingle', params).then(async (result: any) => {
    if (result.code === 1) {
      const compOjb = companys.data.find((item: { val: string }) => {
        item.val = result.data.company
        return item
      })
      if (compOjb) {
        result.data.company = compOjb.txt
        const deptRes = await SDivisional.TreeDepartmentInfo({ coid: compOjb.val })
        if (deptRes.code === 1 && deptRes.data) {
          const getDeptFunc:any = (list: any[], id: any) => {
            for (let index = 0; index < list.length; index++) {
              const item = list[index]
              if (item.id == id) {
                return item
              } else if (item.children && item.children.length > 0) {
                let obj = getDeptFunc(item.children, id)
                if (obj) {
                  return obj
                }
              }
            }
          }
          const deptObj = getDeptFunc(deptRes.data, result.data.department)
          result.data.department = deptObj.title
        }
      }
    }
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 保存草稿 -3
export const saveDraft = async (params: any) => {
  let data: any
  await http.post(url + '/substitute/save', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 提交 -4
export const submit = async (params: any) => {
  let data: any
  await http.post(url + '/substitute/submit', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 删除 -5-平铺
export const del = async (params: any) => {
  let data: any
  await http.delPost(url + '/substitute/deleteList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 删除 -5-1-平铺
export const deleteListT1 = async (params: any) => {
  let data: any
  await http.delPost(url + '/substituteList/deleteList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 删除 -5-1-平铺 收起
export const deleteListT2 = async (params: any) => {
  let data: any
  await http.delPost(url + '/substitute/deleteList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导入 -6
export const importFiles = async (params: any) => {
  let data: any
  await http.postFiles(url + '/substitute/import', {}, params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出 -7
export const exportFiles = async (params?: any) => {
  let data: any
  await http.getFilesExt(url + '/substitute/export', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出 -7 - 收起
export const exportRetract = async (params?: any) => {
  let data: any
  await http.getFilesExt(url + '/substitute/exportRetract', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出模板 -8
export const exportFilesTmpl = async (params?: any) => {
  let data: any
  await http.getFiles(url + '/substitute/template', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 提交 -9
export const approve = async (params: any) => {
  let data: any
  await http.post(url + '/substitute/approve', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 撤回流程
export const revoke = async (params: any) => {
  let data: any
  await http.post(url + '/substitute/revoke', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 反审
export const counterReview = async (params: any) => {
  let data: any
  await http.post(url + '/substitute/counterReview', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 更新审批状态 -11
export const updateSubstituteStatus = async (params: any) => {
  let data: any
  await http.post(url + '/substitute/updateSubstituteStatus', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 列表查询（下拉） -12
export const queryList = async (params: any) => {
  let data: any
  await http.post(url + '/substitute/queryList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
export const tablePageApi = {
  t1: getPageList,
  t2: queryRetractPage
}
