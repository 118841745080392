/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from '@/service' // 引入网络请求http
// import { HUrl } from '@/config' // 引入http请求URL地址

const url = 'api/production' // 路径前缀 + 微服务
// 获取表格分页数据
export const getPageList = async (params: any) => {
  let data: any
  await http.post(url + '/order/queryPageList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
export const queryRetractPage = async (params: any) => {
  let data: any
  await http.post(url + '/order/spreadPage', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
export const tablePageApi = {
  t2: getPageList,
  t1: queryRetractPage
}

// 获取单个详情
export const getInfo = async (params: any) => {
  let data: any
  await http.post(url + '/order/querySingle', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 保存草稿
export const saveDraft = async (params: any) => {
  let data: any
  await http.post(url + '/order/save', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 提交
export const submit = async (params: any) => {
  let data: any
  await http.post(url + '/order/submit', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 删除
export const del = async (params: any) => {
  let data: any
  await http.delPost(url + '/order/deleteList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导入
export const importFiles = async (params: any) => {
  let data: any
  await http.postFiles(url + '/order/import', {}, params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出
export const exportFiles = async (params?: any) => {
  let data: any
  await http.getFilesExt(url + '/order/export', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出模板
export const exportFilesTmpl = async (params?: any) => {
  let data: any
  await http.getFiles(url + '/order/template', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 取消
export const cancel = async (params: any) => {
  let data: any
  await http.post(url + '/order/cancel', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 反审
export const counterReview = async (params: any) => {
  let data: any
  await http.post(url + '/order/counterReview', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 撤回流程
export const revoke = async (params: any) => {
  let data: any
  await http.post(url + '/order/revoke', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 修改审批状态
export const updateApproveStatus = async (params: any) => {
  let data: any
  await http.post(url + '/order/updateApproveStatus', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 配置信息-保存
// 批量删除
export const orderConfigDeleteList = async (ids: string[]) => {
  let data: any
  await http.delPost(url + '/orderConfig/deleteList', { ids }).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出
export const orderConfigExport = async (params?: any) => {
  let data: any
  await http.getFilesExt(url + '/orderConfig/export', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导入
export const orderConfigImport = async (params: any) => {
  let data: any
  await http.postFiles(url + '/orderConfig/import', {}, params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 列表查询
export const orderconfigQueryList = async (params: any) => {
  let data: any
  await http.post(url + '/orderConfig/queryList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 分面查询传入orderld
export const orderconfigQueryPageList = async (params: any) => {
  let data: any
  await http.post(url + '/orderConfig/queryPageList', {
    data: { orderId: params.orderId }
  }).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 单个查询
export const orderConfigQuerysingle = async (params: any) => {
  let data: any
  await http.post(url + '/orderConfig/querySingle', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 保存或修改
export const orderconfigSave = async (params: any) => {
  let data: any
  await http.post(url + '/orderConfig/save', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 模板下载
export const orderconfigFilesTemp = async (params?: any) => {
  let data: any
  await http.getFiles(url + '/orderConfig/template', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

export const querySourceOrderList = async (params: any) => {
  let data: any
  await http.post(url + '/sourceOrder/querySourceOrderList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
export const querySourceOrderDetails = async (params: any) => {
  let data: any
  await http.post(url + '/sourceOrder/querySourceOrderDetails', params).then((result: any) => {
    if (result.code === 1 && result.data && result.data.length > 0) {
      result.data.forEach((item: { fullName: string; materialFullName: string }) => {
        item.fullName = item.materialFullName
      })
    }
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

export const querySourceOrderPage = async (params: any) => {
  let data: any
  await http.post(url + '/sourceOrder/querySourceOrderPage', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
