import { http } from '@/service' // 引入网络请求http
import { apiType } from 'types/commonresource'

const url = 'api/production' // 路径前缀 + 微服务

// 列表--收起
export const queryRetractPage = async (params: apiType) => {
  let data = {} as apiType
  await http.post(url + '/fewMaterialInfo/queryRetractPage', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 列表--平铺
export const queryTilePageList = async (params: apiType) => {
  let data = {} as apiType
  await http.post(url + '/fewMaterialInfo/queryTilePageList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 列表--基于BOM
export const queryRetractPageListOnBom = async (params: apiType) => {
  let data = {} as apiType
  await http.post(url + '/fewMaterialInfo/queryRetractPageListOnBom', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 列表--基于制令单
export const queryRetractPageListOnMakeOrder = async (params: apiType) => {
  let data = {} as apiType
  await http.post(url + '/fewMaterialInfo/queryRetractPageListOnMakeOrder', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 列表--基于任务单
export const queryRetractPageListOnTaskOrder = async (params: apiType) => {
  let data = {} as apiType
  await http.post(url + '/fewMaterialInfo/queryRetractPageListOnTaskOrder', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
