import { computed, defineAsyncComponent, ref } from 'vue'
// 获取hooks
import basicInfo from '@/views/business/sales/quote/hooks/basicInfo' // 基本信息
import quoteDescInfo from '@/views/business/sales/quote/hooks/quoteDescInfo' //
import saleQuoteList from '@/views/business/sales/quote/hooks/saleQuoteList'
// 获取接口
import { getInfo } from '@/service/modules/sales/quote'
import { getPageList } from '@/service/modules/sales/saleQuoteList'
import { objAny } from 'types/table'
import { apiType } from 'types/commonresource'
import { SGeneralData } from '@/service'
import { PublicField } from '@/config'
import { comm } from '@/utils'

const formJsonData = () => {
  // 基础表单hooks

  const res = computed(() => {
    return {
      ...basicInfo(ref(false)).formJson,
      ...quoteDescInfo().formJson
    }
  })
  // 表格hooks
  const saleQuoteListHooks = saleQuoteList()

  return [
    {
      title: { lank: 'sales.basicInfo', default: '基本信息' }, // 基本和商务信息
      type: 'form', // 表单
      json: res.value,
      getInfo: (params: objAny) => {
        return new Promise((resolve: (value: apiType) => void, reject) => {
          getInfo(params).then(async(result) => {
            const currency = await SGeneralData.basicDataSelect({ bdtc: PublicField.currencysType })
            if (result.data.discountAmountCurrType) {
              const item = currency?.data.find((v:{val:string}) => result.data.discountAmountCurrType == v.val)
              result.data.discountAmount = `${comm.numberToAmount(result.data?.discountAmount)} (${item?.txt || ''})`
              result.data.discountAmountCurrType = undefined
            }
            if (result.data.advanceAmountCurrType) {
              const item = currency?.data.find((v:{val:string}) => result.data.advanceAmountCurrType == v.val)
              result.data.advanceAmount = `${comm.numberToAmount(result.data?.advanceAmount)} (${item?.txt || ''})`
              result.data.advanceAmountCurrType = undefined
            }
            result.data.advanceRatio = `${result.data.advanceRatio || 0}%`
            result.data.discount = `${result.data.discount || 0}%`
            result.data.customerId = result.data.customer
            result.data.customerAbbId = result.data.customerAbbreviation
            result.data.customerCodeId = result.data.customerCode
            console.log('result::', result)
            resolve(result)
          })
        })
      },
      slot: {
        coma: defineAsyncComponent(() => import('./componentsSlot/coma.vue')),
        shah: defineAsyncComponent(() => import('./componentsSlot/shah.vue')),
        affi: defineAsyncComponent(() => import('./componentsSlot/affi.vue')),
        buid: defineAsyncComponent(() => import('./componentsSlot/buid.vue'))
      },
      formStyle: {
        // engn: [
        //   'grid-column: 1 / span 4;'
        // ],
        // webn: [
        //   'grid-column: 1 / span 4;'
        // ],
        // buss: [
        //   'grid-column: 1 / span 4;'
        // ],
        // corp: [
        //   'grid-column: 1 / span 4;'
        // ],
        // rema: [
        //   'grid-column: 1 / span 4;'
        // ],
        // nama: [
        //   'grid-column: 1 / span 4;'
        // ],
        // coma: [
        //   'grid-column: 1 / span 4;'
        // ],
        // buid: [
        //   'grid-column: 1 / span 4;'
        // ],
        // shah: [
        //   'grid-column: 1 / span 4;'
        // ],
        // affi: [
        //   'grid-column: 1 / span 4;'
        // ]
      }
    },
    {
      title: { lank: 'sales.quoteDetail', default: '报价明细' },
      type: 'table', // 表格
      formid: 'quoteId', //
      id: 'id', //
      columnList: saleQuoteListHooks.columnList,
      slot: {
        isen: defineAsyncComponent(() => import('./componentsSlot/isen.vue'))
      },
      getInfo: (params: objAny) => {
        return new Promise((resolve: (value: apiType) => void, reject) => {
          getPageList({
            keyWord: '',
            data: {
              quoteId: params.quoteId
            },
            pageIndex: params.pageIndex,
            pageSize: params.pageSize,
            total: 0
          }).then(result => {
            resolve(result)
          })
        })
      }
    }
  ]
}
export default formJsonData
