import { computed, defineAsyncComponent } from 'vue'

import hooks from '@/views/sysmanage/materialManage/orgMaterMain/hooks/formHooks'
import { orgMaterMain } from '@/service'
const formJson = () => {
  const essenInfo = hooks({ query: { name: 'detailOrgMaterMain' }})
  const essenInfoComp = computed(() => {
    return {
      ...essenInfo
    }
  })

  return [
    {
      title: { lank: 'purchase.essenInfo', default: '基本信息' },
      type: 'form',
      json: essenInfoComp.value,
      formid: 'originCode',
      getInfo: orgMaterMain.originInfoDetailInfoApps
    },
    {
      title: { lank: 'administration.attrInfo', default: '属性信息' },
      json: essenInfoComp.value,
      formid: 'originCode',
      type: 'slot',
      getInfo: orgMaterMain.originInfoDetailInfoApps,
      component: defineAsyncComponent(() => import('./componentsSlot/tabs.vue'))
    }
  ]
}

export default formJson
