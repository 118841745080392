/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config'

const url = HUrl.HTTP_BASE_URL_JAVACHANGE + '/sourceOrder'

// 查询源单行列表
export const queryItemList = async (params: any) => {
  return await http.post(url + '/queryItemList', params).catch(error => {
    return http.catch(error)
  })
}

// 查询源单头列表
export const queryList = async (params: any) => {
  return await http.post(url + '/queryList', params).catch(error => {
    return http.catch(error)
  })
}

// 查询源单头分页
export const queryPageList = async (params: any) => {
  return await http.post(url + '/queryPageList', params).catch(error => {
    return http.catch(error)
  })
}

