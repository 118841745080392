import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

// 新增模板
export const templateAdd = async (params?: any) => {
  let data: any
  await http.addPost(HUrl.TEMPLATE_ADD, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 删除模板
export const templateDelete = async (params?: any) => {
  let data: any
  await http.delPost(HUrl.TEMPLATE_DELETE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 模板分页查询
export const templatePageList = async (params?: any) => {
  let data: any
  await http.post(HUrl.TEMPLATE_PAGE_LIST, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 模板查询
export const templateSingle = async (params?: any) => {
  let data: any
  await http.post(HUrl.TEMPLATE_QUERY_SINGLE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 更新模板
export const templateUpdate = async (params?: any) => {
  let data: any
  await http.editPost(HUrl.TEMPLATE_UPDATE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 模板列表查询
export const templateQueryList = async (params?: any) => {
  let data: any
  await http.post(HUrl.TEMPLATE_QUERY_LIST, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
