import { defineStore } from 'pinia'
export const companyDown = defineStore({
  id: 'companyDown',
  state: () => ({
    divisionalDown: '',
    staffDown: '',
    jobDown: ''
  }),
  getters: {
    getDivisionalDown: (state) => state.divisionalDown,
    getStaffDown: (state) => state.staffDown,
    getJobDown: (state) => state.jobDown
  },
  actions: {
    setDivisionalDown(divisionalDown: string) {
      this.divisionalDown = divisionalDown
    },

    setStaffDown(staffDown: string) {
      this.staffDown = staffDown
    },

    setJobDown(jobDown: string) {
      this.jobDown = jobDown
    }
  },

  persist: {
    enabled: true, // 开启持久化
    strategies: [
      {
        // key: 'token', //自定义Key，也可以去掉，使用默认值为参数id的值
        storage: localStorage, // 可以为localStorage或者sessionStorage
        // 可以通过paths指定需要持久化的值，其他没有指定的则不会持久化
        paths: ['divisionalDown', 'staffDown', 'jobDown']
      }
    ]
  }
})
