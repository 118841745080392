/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * 封装日志Log（管理员操作日志）相关功能等
 * @author jihua.huang
 * @date 2022-04-10
 */
// 引入文件
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

const url = HUrl.BASE_PREFIX_INIT_ADMIN
// 分配公司角色详情
export const allotRoleDet = async (params : any = {}) => {
  let data: any
  await http.post(url + '/account/allotRoleDet', params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })

  return data
}
// export const allotRoleDet = async (params : any = {}) => {
//   let data: any
//   await http.post(url + '/employee/querySingle', params).then((result : any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })

//   return data
// }
//
// 流转次数
export const employeeCountLog = async (params : any = {}) => {
  let data: any
  await http.post(url + '/account/queryPageList/log', params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })

  return data
}
// 分配公司详情
export const allotAccDet = async (params : any = {}) => {
  let data: any
  await http.post(url + '/account/allotAccDet', params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })

  return data
}

// 分配账号保存
export const allotAcc = async (params : any = {}) => {
  let data: any
  await http.post(url + '/account/allotAcc', params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })

  return data
}
/**
 * .net 角色列表
 * @param params object
 * @returns json object
 */
//  /UserAccount/GetRole
export const getRole = async (params : any = {}) => {
  let data: any
  await http.post(HUrl.ROLE_USER_ROLE, params).then((result : any) => {
    if (result.code === 1) {
      result.data.forEach((item: { key: any; val: any; value: any; txt: any }) => {
        item.key = item.val
        item.value = item.txt
      })
    }
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })

  return data
}

/**
 * java 角色列表
 * @param params object
 * @returns json object
 */
//
export const getRoleList = async (params : any = {}) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_INIT_ADMIN + '/role/queryList', params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })

  return data
}

export const binEmployee = async (params : any = {}) => {
  let data: any
  await http.post(HUrl.BIND_EMP_LOYEE, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })

  return data
}

export const userAccountEdit = async (params : any = {}) => {
  let data: any
  await http.post(HUrl.ACCOUNT_EDIT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

export const userAccountExtend = async (params : any = {}) => {
  let data: any
  await http.post(HUrl.ACCOUNT_EXTEND, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })

  return data
}
/** 账号激活 */
export const userAccountActivation = async (params : any = {}) => {
  let data: any
  await http.post(url + '/account/activate', params).then((result : any) => {
  // await http.post(HUrl.ACCOUNT_ACTIVATION, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })

  return data
}
export const delayed = async (params?: any) => {
  let data: any
  await http.post(HUrl.ACCOUNT_DELAYED, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
