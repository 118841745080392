import { computed, defineAsyncComponent } from 'vue'
// 获取hooks
import basicInfoHooksIndex from '@/views/business/sales/customer/hooks/basicInfoHooks' // 基本信息
import flpfrqHooksIndex from '@/views/business/sales/customer/hooks/flpfrqHooks' // 跟进频率
import businessInfoHooksIndex from '@/views/business/sales/customer/hooks/businessInfoHooks' // 商务信息
import contactsInfoHooksIndex from '@/views/business/sales/customer/hooks/contactsInfoHooks' // 联系人
import shipAddressInfoHooksIndex from '@/views/business/sales/customer/hooks/shipAddressInfoHooks' // 收货地址
import paymentInfoHooksIndex from '@/views/business/sales/customer/hooks/paymentInfoHooks' // 收款信息
import appAccountInfoHooksIndex from '@/views/business/sales/customer/hooks/appAccountInfoHooks' // 软件账号
import outBounInfoIndex from '@/views/business/sales/customer/hooks/outBounInfo' // 对外投资
import iprHooksIndex from '@/views/business/sales/customer/hooks/iprHooks' // 知识产权
// 获取接口
import { getCustomerInfo, contactPageList, addressPageList, paymentInfoPageList, paymentOverseasPageList, softAccPageList, outbounpagelist, inteproppagelist } from '@/service/modules/sales/customer'
import { objAny } from 'types/table'
import { apiType } from 'types/commonresource'
import { SGeneralData } from '@/service'
import { PublicField } from '@/config'
import { comm } from '@/utils'

const formJsonData = () => {
  // 基础表单hooks
  // const { formJson: basic } = basicInfoHooksIndex({}, false) // 基本信息
  // const { formJson: flpfrq } = flpfrqHooksIndex({}, false) // 跟进频率信息
  // const { formJson: business } = businessInfoHooksIndex({}, false) // 商务信息

  const res = computed(() => {
    return {
      ...basicInfoHooksIndex({}, false).formJson,
      ...flpfrqHooksIndex({}, false).formJson,
      ...businessInfoHooksIndex({}, false).formJson
    }
  })
  // 表格hooks
  const contactsHooks = contactsInfoHooksIndex()
  const shipAddressInfoHooks = shipAddressInfoHooksIndex()
  const paymentInHooks = paymentInfoHooksIndex()
  const appAccountInfoHooks = appAccountInfoHooksIndex()
  const outBounInfoHooks = outBounInfoIndex()
  const iprHooks = iprHooksIndex()

  return [
    {
      title: { lank: 'sales.essentialInfo', default: '基本信息' }, // 基本和商务信息
      type: 'form', // 表单
      json: res.value,
      getInfo: (params: objAny) => {
        return new Promise((resolve: (value: apiType) => void, reject) => {
          getCustomerInfo(params).then(async(result) => {
            result.data.advr = result.data.advr ? `${result.data.advr} %` : ''
            result.data.affi = result.data && result.data.affi && result.data.affi.map((item:{
              shpr: string, asso:string | number }) => {
              // item.shpr = item.shpr ? `${item.shpr} %` : ''
              item.asso = item.asso ? Number(item.asso) : ''
              return item
            }) || []
            const currency = await SGeneralData.basicDataSelect({ bdtc: PublicField.currencysType })
            if (result.data.regc) {
              //
              const item = currency?.data.find((v:{val:string}) => result.data.curc == v.val)
              result.data.regc = `${comm.numberToAmount(result.data?.regc)} (${item?.txt || ''})`
              result.data.curc = undefined
            }
            if (result.data.stol) {
              //
              const item = currency?.data.find((v:{val:string}) => result.data.cusl == v.val)
              result.data.stol = `${comm.numberToAmount(result.data?.stol)} (${item?.txt || ''})`
              result.data.cusl = undefined
            }
            if (result.data.aobc) {
              //
              const item = currency?.data.find((v:{val:string}) => result.data.cuao == v.val)
              result.data.aobc = `${comm.numberToAmount(result.data?.aobc)} (${item?.txt || ''})`
              result.data.cuao = undefined
            }

            const unit = await SGeneralData.basicDataSelect({ bdtc: PublicField.timeType })
            if (result.data.overduePaymentTimeout) { // timeType
              const item = unit?.data.find((v:{val:string}) => result.data.overduePaymentTimeoutUnit === v.val)
              result.data.overduePaymentTimeout = result.data.overduePaymentTimeout + (item?.txt || '')
              result.data.overduePaymentTimeoutUnit = undefined
            }

            console.log('result::', result)

            resolve(result)
          })
        })
      },
      slot: {
        coma: defineAsyncComponent(() => import('./componentsSlot/coma.vue')),
        shah: defineAsyncComponent(() => import('./componentsSlot/shah.vue')),
        affi: defineAsyncComponent(() => import('./componentsSlot/affi.vue')),
        buid: defineAsyncComponent(() => import('./componentsSlot/buid.vue'))
      },
      formStyle: {
        engn: [
          'grid-column: 1 / span 4;'
        ],
        webn: [
          'grid-column: 1 / span 4;'
        ],
        buss: [
          'grid-column: 1 / span 4;'
        ],
        corp: [
          'grid-column: 1 / span 4;'
        ],
        rema: [
          'grid-column: 1 / span 4;'
        ],
        nama: [
          'grid-column: 1 / span 4;'
        ],
        coma: [
          'grid-column: 1 / span 4;'
        ],
        buid: [
          'grid-column: 1 / span 4;'
        ],
        shah: [
          'grid-column: 1 / span 4;'
        ],
        affi: [
          'grid-column: 1 / span 4;'
        ]
      }
    },
    {
      title: { lank: 'sales.uploadAttachments', default: '上传附件' },
      type: 'files',
      code: 'Customer',
      templetCode: 'Customer'
    },
    {
      title: { lank: 'sales.contacts', default: '联系人' },
      type: 'table', // 表格
      formid: 'pId', //  pid="pid"
      id: 'coid', // rowId="ipid"
      columnList: contactsHooks.columnList,
      slot: {
        isen: defineAsyncComponent(() => import('./componentsSlot/isen.vue'))
      },
      getInfo: (params: objAny) => {
        return new Promise((resolve: (value: apiType) => void, reject) => {
          contactPageList({
            keyWord: '',
            pId: params.pId,
            pageIndex: params.pageIndex,
            pageSize: params.pageSize,
            total: 0
          }).then(result => {
            resolve(result)
          })
        })
      }
    },
    {
      title: { lank: 'sales.shipAddress', default: '收货地址' },
      type: 'table',
      formid: 'pId',
      id: 'reid',
      columnList: shipAddressInfoHooks.columnList,
      slot: {
        isen: defineAsyncComponent(() => import('./componentsSlot/isen.vue'))
      },
      getInfo: (params: objAny) => {
        return new Promise((resolve: (value: apiType) => void, reject) => {
          addressPageList({
            keyWord: '',
            pId: params.pId,
            pageIndex: params.pageIndex,
            pageSize: params.pageSize,
            total: 0
          }).then(result => {
            resolve(result)
          })
        })
      }
    },

    {
      title: { lank: 'sales.domestic', default: '境内付款信息' },
      type: 'table',
      formid: 'pId',
      id: 'paid',
      columnList: paymentInHooks.columnList,
      slot: {
        isen: defineAsyncComponent(() => import('./componentsSlot/isen.vue')),
        isdf: defineAsyncComponent(() => import('./componentsSlot/isen.vue'))
      },
      getInfo: (params: objAny) => {
        return new Promise((resolve: (value: apiType) => void, reject) => {
          paymentInfoPageList({
            keyWord: '',
            pId: params.pId,
            pageIndex: params.pageIndex,
            pageSize: params.pageSize,
            total: 0
          }).then(result => {
            resolve(result)
          })
        })
      }
    },
    {
      title: { lank: 'sales.abroad', default: '境外收款信息' },
      type: 'table',
      formid: 'pId',
      id: 'paid',
      columnList: paymentInHooks.overFormList,
      getInfo: (params: objAny) => {
        return new Promise((resolve: (value: apiType) => void, reject) => {
          paymentOverseasPageList({
            keyWord: '',
            pId: params.pId,
            pageIndex: params.pageIndex,
            pageSize: params.pageSize,
            total: 0
          }).then(result => {
            resolve(result)
          })
        })
      }
    },
    {
      title: { lank: 'sales.softwareAccountInfo', default: '软件账号信息' },
      type: 'table',
      formid: 'pId',
      id: 'soid',
      columnList: appAccountInfoHooks.columnList,
      getInfo: (params: objAny) => {
        return new Promise((resolve: (value: apiType) => void, reject) => {
          softAccPageList({
            keyWord: '',
            pId: params.pId,
            pageIndex: params.pageIndex,
            pageSize: params.pageSize,
            total: 0
          }).then(result => {
            resolve(result)
          })
        })
      }
    },
    {
      title: { lank: 'sales.outbound', default: '对外投资' },
      type: 'table',
      formid: 'pId',
      id: 'oiid',
      columnList: outBounInfoHooks.columnList,
      getInfo: (params: objAny) => {
        return new Promise((resolve: (value: apiType) => void, reject) => {
          outbounpagelist({
            keyWord: '',
            pId: params.pId,
            pageIndex: params.pageIndex,
            pageSize: params.pageSize,
            total: 0
          }).then(result => {
            resolve(result)
          })
        })
      }
    },
    {
      title: { lank: 'sales.IPR', default: '知识产权' },
      type: 'table',
      formid: 'pId',
      id: 'ipid',
      columnList: iprHooks.columnList,
      getInfo: (params: objAny) => {
        return new Promise((resolve: (value: apiType) => void, reject) => {
          inteproppagelist({
            keyWord: '',
            pId: params.pId,
            pageIndex: params.pageIndex,
            pageSize: params.pageSize,
            total: 0
          }).then(result => {
            resolve(result)
          })
        })
      }
    }
  ]
}
export default formJsonData
