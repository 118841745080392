import { defineStore } from 'pinia'
type Tdoc = {
  isBook?:boolean
  filePath:string
  fileName:string
}
type Tstate = {
  originCode:string
  dataBook:string
  docs:Tdoc[]
}
export const orgMaterMainStore = defineStore({
  id: 'orgMaterMain',
  state: () => {
    // 物料数据字典
    const data:Tstate = {
      originCode: '', // 原厂料号
      dataBook: '', // FDF技术手册
      docs: [] // 技术手册
    }
    return data
  },
  getters: {
    getOriginCode: (state) => {
      return state.originCode
    },
    getDataBook: (state) => {
      return state.dataBook
    },
    getDocs: (state) => {
      return state.docs
    }

  },
  actions: {
    setOriginCode(data: string) {
      this.originCode = data
    },
    setDataBook(data: {filePath:string, fileName:string, isBook:boolean}) {
      this.dataBook = data.filePath
      const bookIndex = this.docs.findIndex((item:Tdoc) => item.isBook === true)
      if (bookIndex !== -1) {
        this.docs.splice(bookIndex, 1, data)
      } else {
        this.docs.unshift(data)
      }
    },
    setDocs(data: Tdoc[]) {
      this.docs = data
    },
    clearOrgMater() {
      this.originCode = ''
      this.dataBook = ''
      this.docs = []
    }
  },
  persist: {
    enabled: true, // 开启持久化
    strategies: [
      {
        storage: localStorage, // 可以为localStorage或者sessionStorage
        paths: [
          'orgMaterMain', 'originCode', 'dataBook', 'docs'
        ]
      }
    ]
  }
})

export default orgMaterMainStore
