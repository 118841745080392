/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineStore } from 'pinia'
type TmaterialdocForm = { [x: string]: number | string | boolean }
type Tstate = {
  materialdocForm:TmaterialdocForm
  unitMeasuOptions:[]
  matcOptions:never[]
  maccOptions:never[]
  materialOwners:never[]
}
export const bomInfoStore = defineStore({
  id: 'materialdocStore',
  state: ():Tstate => {
    // 物料数据字典
    return {
      materialdocForm: {
        maid: ''
      },
      // 计量单位
      unitMeasuOptions: [],
      matcOptions: [],
      maccOptions: [],
      materialOwners: []
    }
  },
  getters: {
    getMaterialdocFormId: (state) => {
      return state.materialdocForm.maid
    },
    getMaterialdocForm: (state) => {
      return state.materialdocForm
    },
    getUnitMeasuOptions: (state) => {
      return state.unitMeasuOptions
    },
    getMatcOptions: (state) => {
      return state.matcOptions
    },
    getMaccOptions: (state) => {
      return state.maccOptions
    },
    getMaterialOwners: (state) => {
      return state.materialOwners
    }
  },
  actions: {
    setMaterialdocFormId(id: string) {
      this.materialdocForm.maid = id
    },
    setMaterialdocForm(obj: TmaterialdocForm) {
      this.materialdocForm = { ...this.materialdocForm, ...obj }
    },
    setUnitMeasuOptions(list: []) {
      this.unitMeasuOptions = list
    },
    setMatcOptions(list: never[]) {
      this.matcOptions = list
    },
    setMaccOptions(list: never[]) {
      this.maccOptions = list
    },
    setMaterialOwners(list: never[]) {
      this.materialOwners = list
    }
  },
  persist: {
    enabled: true, // 开启持久化
    strategies: [
      {
        storage: localStorage, // 可以为localStorage或者sessionStorage
        paths: [
          'materialdocStore', 'materialdocForm', 'unitMeasuOptions', 'setmatcOptions', 'setmaccOptions',
          'materialOwners'
        ]
      }
    ]
  }
})

export default bomInfoStore
