/**
 * 语言
 * @author lcb
 * @date 2022-03-29
 */
// 引入文件
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

// 获取语种下拉组件数据
export const langSelect = async () => {
  let data: any
  await http.post(HUrl.LANGUAGE_SELECT).then((result : any) => {
    if (result.code == 1) {
      result.data.forEach((item:{[x:string]:unknown}) => {
        item.key = item.val
        item.value = item.txt
      })
    }
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 切换语种
export const changeLang = async (params: any) => {
  let data: any
  await http.get(HUrl.CHANGE_LANG, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
