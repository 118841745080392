// ========================对应的Route component Vue组件模板地址（非赖加载方式）======================
import Layout from '@/views/common/layout/index.vue'
import LoginLayout from '@/views/common/login/index.vue'
import NotFound from '@/views/common/error/404.vue'
import FormLogin from '@/views/common/formLogin/index.vue'

// ========================对应的Route component Vue组件模板地址（赖加载方式）======================
// 首页
const Home = () => import('@/views/common/home/index.vue')
const HomeIndex = () => import('@/views/common/home/index.vue')

// 目录
const Sysmanage = Layout // 系统
const Administration = Layout // 行政
const Engineering = Layout // 工程
const Warehouse = Layout // 仓库
const Production = Layout // 生产管理
const Project = Layout // 项目
const Sales = Layout // 销售
const Purchase = Layout // 采购
const Settings = Layout // 设置
const Shelves = Layout // 货架
const RM = Layout // 收货管理
const QIMGT = Layout // 质检管理
// const DataIntegration = Layout // 数据集成
const Scheduler = Layout // 调度中心
// 系统配置
const SysConfig = Layout
// 基础数据模板
const DataTemplate = Layout
const BaseData = Layout
const Oa = Layout
const BasicInfo = Layout
// 代码生成
const CodeGenerate = Layout
// 账套管理
const AccountsetMgt = Layout
// 入库管理
const Warehousing = Layout
// 出库管理
const StockOut = Layout
// 仓库管理
const Inventory = Layout
const Label = Layout
// const Label = Layout
// const Label = Layout
// const Label = Layout
// 生产任务
const ProductionTask = Layout
// 收付款
const CollectionPayment = Layout
// 财务管理
const Finance = Layout
// 替代和物料转换
const SubstituteTransition = Layout
// 流程管理
const Process = Layout
// 采购-采购价格
const PurchasePrice = Layout
// 销售-销售价格
const SalesPrice = Layout
// 销售-发货通知
const DeliverGoods = Layout
// 销售-销售价格
const FQIMGT = Layout
// 财务-汇率
const FxRate = Layout
// 物料管理
const MaterialManage = Layout
// 欠料管理
const FewMaterial = Layout
// 成品入库
const FWarehousing = Layout
// 成品出库
const FStockOut = Layout
// 成品出库
const ProductionPicking = Layout
// 齐套核算
const CompleteSuit = Layout

const AdminMenu = () => import('@/views/sysmanage/menu/index.vue') // 资源列表
const Accountset = () => import('@/views/sysmanage/accountset/index.vue') // 账套管理
const ServerManage = () => import('@/views/sysmanage/serverManagement/index.vue')
const LanguageConfig = () => import('@/views/sysmanage/languageConfig/index.vue') // 语言配置
const ApplicationVersion = () => import('@/views/sysmanage/applicationVersion/index.vue') // 应用版本
const UpgradeTemplate = () => import('@/views/sysmanage/upgradeTemplate/index.vue') // 升级初始化
const InitTemplate = () => import('@/views/sysmanage/initTemplate/index.vue')
const KeyConfig = () => import('@/views/sysmanage/keyconfig/index.vue')
const SystemRoleList = () => import('@/views/sysmanage/systemRole/index.vue')
const SystemRoleForm = () => import('@/views/sysmanage/systemRole/components/add/index.vue')

const GeneralData = () => import('@/views/sysmanage/generalData/index.vue')
const Megatemperature = () => import('@/views/sysmanage/megatemperature/index.vue') // 系统 - 高温补贴
const UnitMeasurement = () => import('@/views/sysmanage/unitMeasurement/index.vue')
const AccountsetForm = () => import('@/views/sysmanage/accountset/components/addAccountset/index.vue')
const Measurementunit = () => import('@/views/sysmanage/unitMeasurement/index.vue')
const Hightempsubsidy = () => import('@/views/business/administration/hightempsubsidy/index.vue') // 行政 - 高温补贴
const Dictmanage = () => import('@/views/sysmanage/dictmanage/index.vue')
const DataManagement = () => import('@/views/sysmanage/dataManagement/index.vue') // 系统资料
const Company = () => import('@/views/business/administration/company/index.vue')
const CompanyDetailTemp = () => import('@/views/business/administration/company/components/index.vue') // 公司详情
const Department = () => import('@/views/business/administration/department/index.vue') // 部门
const NewDepartment = () => import('@/views/business/administration/department/components/formPage/index.vue') // 部门表单
const Employee = () => import('@/views/business/administration/employee/index.vue') // 员工
const EmployeeForm = () => import('@/views/business/administration/employee/components/formPage/index.vue') // 员工表单
const Job = () => import('@/views/business/administration/job/index.vue')
const AccountNumber = () => import('@/views/business/administration/accountNumber/index.vue')
const UserRole = () => import('@/views/business/administration/userRole/index.vue')
const ProcessVersion = () => import('@/views/business/administration/processVersion/index.vue')
const AskForLeave = () => import('@/views/business/administration/askForLeave/index.vue')
const AskForLeaveDetails = () => import(
  '@/views/business/administration/askForLeave/components/details/index.vue'
)
const Material = () => import('@/views/business/engineer/materialdoc/index.vue')
const Materialdoc = () => import('@/views/business/engineer/materialdoc/compontens/container.vue')
const Mcodingrules = () => import('@/views/business/engineer/materialRules/index.vue')

const Billcodingrules = () => import('@/views/business/settings/billCodingRules/index.vue') // 单据编码规则 - 用户
const SysBillRules = () => import('@/views/sysmanage/sysBillRules/index.vue') // 单据编码规则 - 系统
// const Billcodingrules = () => import('@/views/sysmanage/sysBillRules/index.vue') // 单据编码规则 - 系统
/** 替代料 */
const Substitute = () => import('@/views/business/engineer/substituteTransition/substitute/index.vue')
// 替代关系
const SubstituteRules = () => import('@/views/business/engineer/substituteTransition/substituteRules/index.vue')
const SubstituteRolesInfo = () => import('@/views/business/engineer/substituteTransition/substituteRules/components/formPage/index.vue')
const SubstituteForm = () => import('@/views/business/engineer/substituteTransition/substitute/components/formPage/index.vue')
const AlternativeMaterialQuery = () => import('@/views/business/engineer/substituteTransition/alternativeMaterialQuery/index.vue')

const WarehouseList = () => import('@/views/business/warehouse/basicInfo/index.vue')
const WarehouseDetail2 = () => import('@/views/business/warehouse/basicInfo/maintenance/index.vue')
const WarehouseLocationPage = () => import(
  '@/views/business/warehouse/basicInfo/maintenance/warehouseLocation/index.vue'
)

// 标签管理
const LabelList = () => import('@/views/business/warehouse/label/labelList/index.vue')
// 标签管理 - 源单标签
const SourceLabel = () => import('@/views/business/warehouse/label/labelList/source/components/formPage/index.vue')
// 标签管理 - 标签合码
const CombinedCodeLabel = () => import('@/views/business/warehouse/label/labelList/combinedCode/components/formPage/index.vue')
// 标签管理 - 标签拆码
const DecodingLabel = () => import('@/views/business/warehouse/label/labelList/decoding/components/formPage/index.vue')

// const Logistics = () => import('@/views/business/warehouse/basicInfo/logisticsInfo/index.vue')
// 收货
const MaterialInTransit = () => import(
  '@/views/business/warehouse/receipt/materialInTransit/index.vue'
)
const ReceiptRecord = () => import(
  '@/views/business/warehouse/receipt/receiptRecord/index.vue'
)
const ReceiptRecordForm = () => import(
  '@/views/business/warehouse/receipt/receiptRecord/components/receiptRecordInfo.vue'
)
// const PurchaseOutboundInbound = () => import('@/views/business/warehouse/purchaseOutboundInbound/index.vue')
// const SaleOutboundInbound = () => import('@/views/business/warehouse/saleOutboundInbound/index.vue')
// 质检
const MaterialsToBeInspected = () => import(
  '@/views/business/warehouse/qualityInspection/materialsToBeInspected/index.vue'
)
const QIRecords = () => import(
  '@/views/business/warehouse/qualityInspection/qualityInspectionRecords/index.vue'
)
const QIRecordForm = () => import(
  '@/views/business/warehouse/qualityInspection/qualityInspectionRecords/component/qualityInspectionRecordInfo.vue'
)

// 质检
const FMTBeInspected = () => import(
  '@/views/business/warehouse/finishedQualityInspection/materialsToBeInspected/index.vue'
)
const FQIRecords = () => import(
  '@/views/business/warehouse/finishedQualityInspection/qualityInspectionRecords/index.vue'
)
const FQIRecordForm = () => import(
  '@/views/business/warehouse/finishedQualityInspection/qualityInspectionRecords/component/qualityInspectionRecordInfo.vue'
)

// const ProduceOutboundInbound = () => import('@/views/business/warehouse/produceOutboundInbound/index.vue')
const Projectlist = () => import('@/views/business/project/projectlist/index.vue')
const Stroylist = () => import('@/views/business/project/stroylist/index.vue')
const Buglist = () => import('@/views/business/project/buglist/index.vue')
const BuglistForm = () => import('@/views/business/project/buglist/components/formPage/index.vue')
const Tasklist = () => import('@/views/business/project/tasklist/index.vue')
const Whstatistics = () => import('@/views/business/project/whstatistics/index.vue')
const Workinghours = () => import('@/views/business/project/workinghours/index.vue')
const Customer = () => import('@/views/business/sales/customer/index.vue')
const CustomerForm = () => import('@/views/business/sales/customer/components/customerForm/index.vue')
// 销售订单
const SalesOrder = () => import('@/views/business/sales/salesOrder/index.vue')
const SalesOrderForm = () => import('@/views/business/sales/salesOrder/components/formPage//index.vue')

// 销售报价--客户报价
const SalesCommPc = () => import('@/views/business/sales/commodityPrice/index.vue')
const SalesCommPcForm = () => import('@/views/business/sales/commodityPrice/components/formPage/index.vue')
// 销售报价--价格维护表单
const SalesPcMaint = () => import('@/views/business/sales/priceMaintenance/index.vue')
const SalesPcMaintForm = () => import('@/views/business/sales/priceMaintenance/components/formPage/index.vue')

const Supplier = () => import('@/views/business/purchase/supplier/index.vue')
const SupplierForm = () => import(
  '@/views/business/purchase/supplier/components/customerForm/index.vue'
)

// 供应商商品价格
const PurchaseCommPc = () => import('@/views/business/purchase/commodityPrice/index.vue')
// 供应商商品价格 - 表单
const PurchaseCommPcForm = () => import('@/views/business/purchase/commodityPrice/components/formPage/index.vue')

// 供应商价格维护
const PurchasePcMaint = () => import('@/views/business/purchase/priceMaintenance/index.vue')
// 供应商价格维护 - 表单
const PurchasePcMaintForm = () => import('@/views/business/purchase/priceMaintenance/components/formPage/index.vue')

const Workbench = Layout
const TaskManagementNoshow = () => import('@/views/business/administration/taskManagement/index.vue')
const TaskManagementAddTask = () => import(
  '@/views/business/administration/taskManagement/components/addTask.vue'
)
const WarehouseLocation = () => import(
  '@/views/business/warehouse/basicInfo/maintenance/warehouseLocation/index.vue'
)
const WarehouseNo = () => import(
  '@/views/business/warehouse/basicInfo/maintenance/warehouseNo/index.vue'
)

const FlowVerDetails = () => import(
  '@/views/business/administration/processVersion/components/details/index.vue'
)
const MenuSettings = () => import('@/views/business/settings/menuSetting/index.vue') // 菜单设置
const TableColumns = () => import('@/views/business/settings/tableColumns/index.vue') // 列表设置
const AttachmentTemplate = () => import('@/views/business/settings/attachmentTemplate/index.vue') // 附件模板
const addAttachment = () => import(
  '@/views/business/settings/attachmentTemplate/components/addAttachment.vue'
) // 新增附件模板
const SchedulerTask = () => import('@/views/sysmanage/scheduler/task/index.vue')
const SchedulerLog = () => import('@/views/sysmanage/scheduler/log/index.vue')
const SchedulerExecutor = () => import('@/views/sysmanage/scheduler/executor/index.vue')

const MaterialCode = () => import('@/views/business/engineer/materialcode/index.vue') // 物料分类
// 出库管理
const NotesStockOut = () => import('@/views/business/warehouse/stockOut/notesStockOut/index.vue')
// 出库记录
const TreatStockOut = () => import('@/views/business/warehouse/stockOut/treatStockOut/index.vue')
// 出库单
const NotesStockOutForm = () => import('@/views/business/warehouse/stockOut/notesStockOut/components/editPage.vue')

// 出库管理
const FNotesStockOut = () => import('@/views/business/warehouse/finishedStockOut/notesStockOut/index.vue')
// 出库记录
const FTreatStockOut = () => import('@/views/business/warehouse/finishedStockOut/treatStockOut/index.vue')
// 出库单
const FNotesStockOutForm = () => import('@/views/business/warehouse/finishedStockOut/notesStockOut/components/editPage.vue')

// 入库管理
const WarehousingRecord = () => import('@/views/business/warehouse/stockIn/warehousingRecord/index.vue')
// 待入库
const RendingStorage = () => import('@/views/business/warehouse/stockIn/rendingStorage/index.vue')
// 入库单
const WarehousingRecordForm = () => import('@/views/business/warehouse/stockIn/warehousingRecord/components/editReceiptOrder.vue')

// 成品入库管理
const FWarehousingRecord = () => import('@/views/business/warehouse/finishedStockIn/warehousingRecord/index.vue')
// 成品待入库
const FRendingStorage = () => import('@/views/business/warehouse/finishedStockIn/rendingStorage/index.vue')
// 成品入库单
const FWarehousingRecordForm = () => import('@/views/business/warehouse/finishedStockIn/warehousingRecord/components/editReceiptOrder.vue')

// 仓库库存
const InventoryList = () => import('@/views/business/warehouse/Inventory/InventoryList/index.vue')
// 物料库存查询
const MaterialInventory = () => import('@/views/business/warehouse/Inventory/materialInventory/components/formPage/index.vue')
// 物料入库查询
const MaterialWarehousing = () => import('@/views/business/warehouse/Inventory/materialWarehousing/components/formPage/index.vue')
// 物料出库查询
const MaterialOutbound = () => import('@/views/business/warehouse/Inventory/materialOutbound/components/formPage/index.vue')
const ShelvesModelNum = () => import('@/views/business/warehouse/shelves/modelNum/index.vue')
const ShelvesModelNumForm = () => import('@/views/business/warehouse/shelves/modelNum/components/formPage/index.vue')
const ShelvesModelCode = () => import('@/views/business/warehouse/shelves/modelCode/index.vue')
const ShelvesModelCodeForm = () => import('@/views/business/warehouse/shelves/modelCode/components/formPage/index.vue')
const ShelfBoundWarehouse = () => import('@/views/business/warehouse/shelves/shelfBoundWarehouse/index.vue')
const ShelfBoundWarehouseForm = () => import('@/views/business/warehouse/shelves/shelfBoundWarehouse/components/formPage/index.vue')
const SingleShelf = () => import('@/views/business/warehouse/shelves/singleShelf/index.vue')
const ShelfSummary = () => import('@/views/business/warehouse/shelves/shelfSummary/index.vue')
const ShelvesEquipment = () => import('@/views/business/warehouse/shelves/shelvesEquipment/index.vue')
// 编辑入库单
// const WarehousingRecordForm = () => import('@/views/business/warehouse/warehousingManagement/rendingStorage/components/editReceiptOrder.vue')
// /warehouse/shelves/modelNum

const CategoryFeature = () => import(
  '@/views/business/engineer/materialcode/components/materialCategory/components/categoryFeature.vue'
) // 物料分类属性

/* ===================代码生成================== */
// 数据源
const Datasource = () => import('@/views/sysmanage/codeGenerate/datasource/index.vue')
// 代码模板
const CodeTemplate = () => import('@/views/sysmanage/codeGenerate/codeTemplate/index.vue')
// 代码模板表单
const CodeTemplateForm = () => import('@/views/sysmanage/codeGenerate/codeTemplate/components/formPage.vue')
// 模板组
const TemplateGroup = () => import('@/views/sysmanage/codeGenerate/templateGroup/index.vue')
// 生成代码
const GenerateCode = () => import('@/views/sysmanage/codeGenerate/generateCode/index.vue')
// 变量
const Variable = () => import('@/views/sysmanage/codeGenerate/variable/index.vue')
// 代码生成 - 代码管理
const CodeMgt = () => import('@/views/sysmanage/codeGenerate/codeMgt/index.vue')
// 代码生成 - 代码管理 - 代码预览
const CodePreview = () => import('@/views/sysmanage/codeGenerate/codeMgt/components/codePreview/index.vue')
// 代码生成 - 模块
const CodeModule = () => import('@/views/sysmanage/codeGenerate/codeModule/index.vue')

/* ============采购============ */
// 采购-采购订单
const PurchaseOrder = () => import('@/views/business/purchase/order/index.vue')
// 采购-采购订单 - 表单
const PurchaseOrderForm = () => import('@/views/business/purchase/order/components/formPage/index.vue')

// 采购-采购申请
const PurchaseApply = () => import('@/views/business/purchase/apply/index.vue')
// 采购-采购申请 - 表单
const PurchaseApplyForm = () => import('@/views/business/purchase/apply/components/formPage/index.vue')

// 采购- 采购询价
const Inquiry = () => import('@/views/business/purchase/inquiry/index.vue')
// 采购-	采购询价 - 表单
const InquiryForm = () => import('@/views/business/purchase/inquiry/components/formPage/index.vue')

// 采购-研发询详单
const ResearchInquiry = () => import('@/views/business/purchase/researchInquiry/index.vue')
// 采购-研发询详单 - 表单
const ResearchInquiryForm = () => import('@/views/business/purchase/researchInquiry/components/formPage/index.vue')

// 采购-品牌管理
const Brand = () => import('@/views/business/purchase/brand/index.vue')
// 采购-品牌管理 - 表单
const BrandForm = () => import('@/views/business/purchase/brand/components/formPage/index.vue')

/** ========财务管理======== */
// 收款单
const Collection = () => import('@/views/business/finance/collectionPayment/collection/index.vue')
// 付款单
const Payment = () => import('@/views/business/finance/collectionPayment/payment/index.vue')
// 收款单新增
const CollectionForm = () => import('@/views/business/finance/collectionPayment/collection/components/formPage/index.vue')
// 付款单form
const PaymentForm = () => import('@/views/business/finance/collectionPayment/payment/components/formPage/index.vue')
// 应收管理
const Receivable = () => import('@/views/business/finance/receivable/index.vue')
const ReceivableForm = () => import('@/views/business/finance/receivable/components/formPage/index.vue')
// 应付管理
const Payable = () => import('@/views/business/finance/payable/index.vue')
const PayableForm = () => import('@/views/business/finance/payable/components/formPage/index.vue')
// 汇率
const FxRateManger = () => import('@/views/business/finance/fxrate/fxratemanger/index.vue')
const FxRateMangerForm = () => import('@/views/business/finance/fxrate/fxratemanger/components/formPage/index.vue')
const RecentRateForm = () => import('@/views/business/finance/fxrate/fxratemanger/components/recentRate/formPage/index.vue')
const FxRateRt = () => import('@/views/business/finance/fxrate/fxratert/index.vue')

// 订单管理
const Order = () => import('@/views/business/production/orderMaterial/index.vue')
const OrderForm = () => import('@/views/business/production/orderMaterial/components/formPage/index.vue')
const Task = () => import('@/views/business/production/task/index.vue')
const BOM = () => import('@/views/business/production/bom/index.vue')
const BOMForm = () => import('@/views/business/production/bom/components/formPage/index.vue')
const TaskForm = () => import('@/views/business/production/task/components/formPage/index.vue')
const MaterialCtrl = () => import('@/views/business/production/materialCtrl/components/formPage/index.vue')
// 销售-销售报价
const Quote = () => import('@/views/business/sales/quote/index.vue')
// 销售-报价管理
const QuoteForm = () => import('@/views/business/sales/quote/components/formPage/index.vue')
// 销售-发货通知
const DeliverAdvice = () => import('@/views/business/sales/deliverGoods/deliverAdvice/index.vue')
const DeliverAdviceForm = () => import('@/views/business/sales/deliverGoods/deliverAdvice/components/formPage/index.vue')
// 销售-订单入库
const OrderStockIn = () => import('@/views/business/sales/deliverGoods/orderStockIn/index.vue')
const OrderStockInForm = () => import('@/views/business/sales/deliverGoods/orderStockIn/components/formPage/index.vue')

/* ==================系统管理 - 流程管理=============== */
// 流程管理 - 工作流
const Approval = () => import('@/views/sysmanage/process/approval/index.vue')
// 流程管理 - 流程绘制
const ProcessDraw = () => import('@/views/sysmanage/process/approval/components/processDraw.vue')
// 流程管理 - 业务流程
const BusinessProcess = () => import('@/views/sysmanage/process/businessProcess/index.vue')
// 数据集成
const DataIntegration = () => import('@/views/sysmanage/dataIntegration/index.vue')
// 数据集成 - 表单
const DataIntegrationForm = () => import('@/views/sysmanage/dataIntegration/components/formPage/index.vue')

// 报警记录
const AlarmController = () => import('@/views/business/warehouse/shelves/alarmController/index.vue')
// 操作记录
const OperationController = () => import('@/views/business/warehouse/shelves/operationController/index.vue')
// 货架统计
const ShelfStatistics = () => import('@/views/business/warehouse/shelves/shelfStatistics/index.vue')

// 系统配置
const LabelPrintingTmpl = () => import('@/views/sysmanage/labelPrinting/template/index.vue') // 打印标签模板
const PrintTemplateDrawing = () => import('@/views/sysmanage/labelPrinting/template/components/index.vue') // 打印标签模板
// const LabelPrintingSettings = () => import('@/views/sysmanage/labelPrinting/settings/index.vue') // 打印标签设置

// const ShelvesModelNum = () => import('@/views/business/warehouse/shelves/modelNum/index.vue')

const OemPartNum = () => import('@/views/business/engineer/oemPartNum/index.vue') // 公共物料
const OrgMaterMain = () => import('@/views/sysmanage/materialManage/orgMaterMain/index.vue') // 原厂料维护
const OrgMaterMainForm = () => import('@/views/sysmanage/materialManage/orgMaterMain/compontens/originLink.vue') // 原厂料表单

const OrfaMater = () => import('@/views/business/engineer/oemPartNum/OrfaMater/index.vue') // 原厂物料
const OrfaMaterForm = () => import('@/views/business/engineer/oemPartNum/OrfaMater/compontens/originLink.vue') // 原厂物料表单

const PublicMaterials = () => import('@/views/sysmanage/materialManage/publicMaterials/index.vue') // 原厂料维护
const PublicMaterialsInfo = () => import('@/views/sysmanage/materialManage/publicMaterials/compontens/container.vue') // 原厂料表单

const TaskDevel = () => import('@/views/business/production/productionTask/taskDevel/index.vue')
const TaskRecord = () => import('@/views/business/production/productionTask/taskRecord/index.vue')
const TaskRecordForm = () => import('@/views/business/production/productionTask/taskRecord/components/formPage/index.vue')

// 欠料处理相关路由
// 信息
const FewMaterialInfo = () => import('@/views/business/production/fewMaterial/info/index.vue')
// 处理记录
const FewMaterialHistory = () => import('@/views/business/production/fewMaterial/history/index.vue')
// 处理记录表单
const FewMaterialHtForm = () => import('@/views/business/production/fewMaterial/history/components/formPage/index.vue')
// 领料单列表
const Picking = () => import('@/views/business/production/picking/index.vue')
// 领料单表单
const PickingForm = () => import('@/views/business/production/picking/components/formPage/index.vue')

// 齐套核算相关路由
// 待处理
const CompleteSuitWaitHandle = () => import('@/views/business/production/completeSuit/waitHandle/index.vue')
// BOM齐套
const CompleteSuitBom = () => import('@/views/business/production/completeSuit/bomCompleteSuit/index.vue')
// BOM齐套表单
const CompleteSuitBomForm = () => import('@/views/business/production/completeSuit/bomCompleteSuit/components/formPage/index.vue')
// 物料齐套
const CompleteSuitOrder = () => import('@/views/business/production/completeSuit/materialCompleteSuit/index.vue')
// 物料齐套表单
const CompleteSuitOrderForm = () => import('@/views/business/production/completeSuit/materialCompleteSuit/components/formPage/index.vue')

// ========================测试路由======================
const CSML = Layout
const Demo = () => import('@/views/business/test/Demo/index.vue')
const PQJ = () => import('@/views/business/test/PQJ/index.vue')


// ========================对应的Route component Vue组件模板地址（赖加载方式）======================
export const componentRouter = {
  CSML,
  Demo,
  PQJ,
  Layout,
  LoginLayout,
  FormLogin,
  Sysmanage,
  Administration,
  Engineering,
  Warehouse,
  Production,
  Project,
  Sales,
  Purchase,
  Settings,
  SysConfig,
  DataTemplate,
  Oa,
  Process,
  PurchasePrice,
  SalesPrice,
  FQIMGT,
  BasicInfo,
  RM,
  QIMGT,
  AccountsetMgt,
  BaseData,
  NotFound,
  CodeGenerate,
  Scheduler,
  Warehousing,
  Shelves,
  StockOut,
  Inventory,
  Workbench,
  CollectionPayment,
  Finance,
  SubstituteTransition,
  Label,
  MaterialManage,
  FewMaterial,
  ProductionTask,
  FxRate,
  FWarehousing,
  FStockOut,
  DeliverGoods,
  ProductionPicking,
  DeliverAdvice,
  DeliverAdviceForm,
  OrderStockIn,
  OrderStockInForm,
  ApplicationVersion,
  DataIntegration,
  DataIntegrationForm,
  DataManagement,
  FlowVerDetails,
  UpgradeTemplate,
  TaskManagementAddTask,
  Home,
  HomeIndex,
  TaskManagementNoshow,
  MenuSettings,
  AdminMenu,
  TableColumns,
  Accountset,
  WarehouseLocation,
  WarehouseNo,
  ReceiptRecordForm,
  ServerManage,
  LanguageConfig,
  CodeModule,
  CodePreview,
  InitTemplate,
  LabelPrintingTmpl,
  KeyConfig,
  SystemRoleList,
  SystemRoleForm,
  Variable,
  // LabelPrintingSettings,
  GeneralData,
  Megatemperature,
  UnitMeasurement,
  AccountsetForm,
  Measurementunit,
  Hightempsubsidy,
  Dictmanage,
  Company,
  CompanyDetailTemp,
  Department,
  PrintTemplateDrawing,
  NewDepartment,
  Datasource,
  CodeTemplate,
  CodeTemplateForm,
  TemplateGroup,
  GenerateCode,
  Employee,
  EmployeeForm,
  Job,
  AccountNumber,
  UserRole,
  ProcessVersion,
  AskForLeave,
  AskForLeaveDetails,
  Material,
  Materialdoc,
  Mcodingrules,
  MaterialCode,
  Billcodingrules,
  WarehouseList,
  LabelList,
  SourceLabel,
  CombinedCodeLabel,
  DecodingLabel,
  WarehouseDetail2,
  WarehouseLocationPage,
  // Logistics,
  MaterialInTransit,
  ReceiptRecord,
  // PurchaseOutboundInbound,
  // SaleOutboundInbound,
  MaterialsToBeInspected,
  QIRecords,
  QIRecordForm,
  // ProduceOutboundInbound,
  Projectlist,
  Stroylist,
  Buglist,
  BuglistForm,
  Tasklist,
  Whstatistics,
  Workinghours,
  Customer,
  CustomerForm,
  SalesCommPc,
  SalesCommPcForm,
  SalesPcMaint,
  SalesPcMaintForm,
  Supplier,
  SupplierForm,
  PurchaseCommPc,
  PurchaseCommPcForm,
  PurchasePcMaint,
  PurchasePcMaintForm,
  AttachmentTemplate,
  addAttachment,
  SchedulerTask,
  SchedulerLog,
  SchedulerExecutor,
  CodeMgt,
  CategoryFeature,
  WarehousingRecord,
  NotesStockOut,
  TreatStockOut,
  NotesStockOutForm,
  RendingStorage,
  WarehousingRecordForm,
  InventoryList,
  ShelvesModelNum,
  ShelvesModelNumForm,
  BusinessProcess,
  PurchaseOrder,
  PurchaseOrderForm,
  PurchaseApply,
  PurchaseApplyForm,
  Brand,
  BrandForm,
  ShelvesModelCode,
  ShelvesModelCodeForm,
  SalesOrder,
  SalesOrderForm,
  ShelfBoundWarehouse,
  ShelfBoundWarehouseForm,
  SingleShelf,
  Collection,
  Payment,
  Receivable,
  ReceivableForm,
  Payable,
  PayableForm,
  PaymentForm,
  CollectionForm,
  Inquiry,
  InquiryForm,
  Order,
  Task,
  TaskForm,
  OrderForm,
  Quote,
  ShelfSummary,
  QuoteForm,
  ResearchInquiry,
  ResearchInquiryForm,
  Substitute,
  SubstituteRules,
  SubstituteRolesInfo,
  AlternativeMaterialQuery,
  SubstituteForm,
  Approval,
  BOM,
  BOMForm,
  MaterialCtrl,
  ProcessDraw,
  ShelvesEquipment,
  AlarmController,
  OperationController,
  ShelfStatistics,
  SysBillRules,
  OemPartNum,
  MaterialInventory,
  MaterialWarehousing,
  MaterialOutbound,
  OrgMaterMain,
  OrgMaterMainForm,
  OrfaMater,
  OrfaMaterForm,
  PublicMaterials,
  PublicMaterialsInfo,
  TaskDevel,
  TaskRecord,
  TaskRecordForm,
  FMTBeInspected,
  FQIRecords,
  FewMaterialInfo,
  FewMaterialHistory,
  FewMaterialHtForm,
  FQIRecordForm,
  FWarehousingRecord,
  FRendingStorage,
  FWarehousingRecordForm,
  FxRateManger,
  FxRateMangerForm,
  RecentRateForm,
  FxRateRt,
  FNotesStockOut,
  FTreatStockOut,
  FNotesStockOutForm,
  Picking,
  PickingForm,
  CompleteSuit,
  CompleteSuitBom,
  CompleteSuitBomForm,
  CompleteSuitOrder,
  CompleteSuitOrderForm,
  CompleteSuitWaitHandle
}
