import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import { apiType } from 'types/commonresource'

const url = HUrl.BASE_PREFIX_PURCHASE_V // 路径前缀 + 微服务
// 获取表格分页数据
export const getPageList = async (params: object) => {
  let data = {} as apiType
  await http.post(url + '/orderList/queryList', params).then((result: apiType) => {
    data = {
      code: result.code,
      data: {
        items: result.data
      },
      message: result.message
    }
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 保存
export const save = async (params: object) => {
  let data = {} as apiType
  await http.post(url + '/orderList/save', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 删除
export const del = async (params: object) => {
  let data = {} as apiType
  await http.delPost(url + '/orderList/deleteList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导入
export const importFiles = async (params: object) => {
  let data = {} as apiType
  await http.postFiles(url + '/orderList/import', {}, params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出
export const exportFiles = async (params?: object) => {
  let data = ''
  await http.getFilesExt(url + '/orderList/export', params).then((result: string) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出模板
export const exportFilesTmpl = async (params?: object) => {
  let data = ''
  await http.getFiles(url + '/orderList/template', params).then((result: string) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
