import { computed } from 'vue'
import warehousingRecordHook from '@/views/business/warehouse/stockIn/rendingStorage/hooks/indexHooks'
import { columnList } from '@/views/business/warehouse/stockIn/warehousingRecord/hooks/tableForm'
import { queryStockIn, queryStorageDetailsList } from '@/service/modules/warehouse/putInStorage'
import { ref } from 'vue'
import { objAny } from 'types/table'
// 数据回显
const formJson = () => {
  const unitOfOriginName = ref('')
  const warehousingRecord = warehousingRecordHook({})
  const res = computed(() => {
    const { department, customer, supplier, ...newBaseJson } = warehousingRecord.baseJson.value
    const obj = {
      companyId: newBaseJson.companyId,
      departmentId: newBaseJson.departmentId,
      dataEntryClerk: newBaseJson.dataEntryClerk,
      warehousingTime: newBaseJson.warehousingTime,
      warehousingType: newBaseJson.warehousingType,
      warehousingCode: newBaseJson.warehousingCode,
      sourceBillCode: newBaseJson.sourceBillCode,
      originalOrderType: newBaseJson.originalOrderType,
      unitOfOriginName: {
        label: { lank: 'warehouse.unitOfOrigin', default: '来源单位' },
        span: 6,
        disabled: true,
        required: true,
        value: unitOfOriginName.value
      },
      sourceOfDeliveryNote: newBaseJson.sourceOfDeliveryNote,
      consignee: newBaseJson.consignee,
      startReceivingDingesteds: newBaseJson.startReceivingDingesteds,
      warehousingDepartment: newBaseJson.warehousingDepartment,
      qualityInspector: newBaseJson.qualityInspector,
      qualityInspectionTime: newBaseJson.qualityInspectionTime
    }
    return obj
  })

  return [
    {
      title: { lank: 'warehouse.basicInfo', default: '基本信息' },
      type: 'form',
      json: res.value,
      getInfo: async (params: objAny) => {
        const result = await queryStockIn(params)
        unitOfOriginName.value = result.data.unitOfOriginName
        return result
      }
    },
    {
      title: { lank: 'warehouse.productInformation', default: '货品信息' },
      type: 'table',
      formid: 'warehousingId',
      id: 'id',
      columnList: columnList,
      getInfo: queryStorageDetailsList
    },
    {
      title: { lank: 'warehouse.WarehousingRecordAnnex', default: '入库单附件' },
      type: 'files',
      code: 'WarehousingRecord',
      templetCode: 'WarehousingRecord'
    }
  ]
}

export default formJson
