import { defineStore } from 'pinia'

type selectType = {
  val: string,
  value: string,
  txt: string,
  bdet: string
}

type DataType = {
  [x: string]: {
    state: boolean
    list: selectType[]
  }
}

type State = {
  data: DataType
}

const useCompanyStore = defineStore({
  id: 'dictionarySelect',
  state: ():State => ({
    data: {}
  }),
  getters: {
    getData: (state) => state.data

  },
  actions: {
    setData(data: DataType) {
      this.data = data
    }
  }
})

export default useCompanyStore

