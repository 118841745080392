import { computed, ref, defineAsyncComponent } from 'vue'
import companyInfoHooksIndex from '@/views/business/purchase/order/hooks/companyInfo'
import supplierInfoHooksIndex from '@/views/business/purchase/order/hooks/supplierInfo'
import orderInfoHooksIndex from '@/views/business/purchase/order/hooks/orderInfo'
import orderDetailHooksIndex from '@/views/business/purchase/order/hooks/orderDetail'
import { getInfo } from '@/service/modules/purchase/purchaseOrder'
import { getPageList } from '@/service/modules/purchase/orderDetail'
import payTaskHooksIndex from '@/views/business/purchase/order/hooks/payTask'
import { getPageList as payTaskGetPage } from '@/service/modules/purchase/payTask'

const formJson = () => {
  const companyInfoHooks = companyInfoHooksIndex()
  const supplierInfoHooks = supplierInfoHooksIndex()
  const orderInfoHooks = orderInfoHooksIndex()
  const payTaskHooks = payTaskHooksIndex()
  const res = computed(() => {
    return { ...companyInfoHooks.formJson, ...supplierInfoHooks.formJson, ...orderInfoHooks.orderInfo.value, ...orderInfoHooks.orderAmountInfo.value }
  })

  const orderDetailHooks = orderDetailHooksIndex(ref(''))

  return [
    {
      title: { lank: 'administration.basicInformation', default: '基本信息' },
      type: 'form',
      json: res.value,
      getInfo: getInfo,
      slot: {
        currency: defineAsyncComponent(() => import('./componentsSlot/currency.vue')),
        discountAmount: defineAsyncComponent(() => import('./componentsSlot/discountAmount.vue'))
      }
    },
    {
      title: { lank: 'purchase.orderDetail', default: '订单详情' },
      type: 'table',
      formid: 'orderId',
      id: 'id',
      columnList: orderDetailHooks.columnList,
      getInfo: getPageList
    },
    {
      title: { lank: 'purchase.orderFiles', default: '订单附件' },
      type: 'files',
      code: 'PurchaseOrder',
      templetCode: 'PurchaseOrder'
    },
    {
      title: { lank: 'purchase.derivativePayTask', default: '衍生付款任务' },
      type: 'table',
      formid: 'orderId',
      id: 'id',
      columnList: payTaskHooks.columnList,
      getInfo: payTaskGetPage,
      slot: {
        cycleType: defineAsyncComponent(() => import('./componentsSlot/cycleType.vue'))
      }
    }
  ]
}

export default formJson
