import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

const url = HUrl.BASE_PREFIX_PROJECT // 路径前缀 + 微服务
// 获取表格列表数据
export const querySingle = async (params: any) => {
  let data: any
  await http.post(url + '/buglist/querySingle', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取表格列表数据
export const getPageList = async (params: any) => {
  let data: any
  await http.post(url + '/buglist/queryList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取单个详情
export const getInfo = async (params: any) => {
  let data: any
  await http.post(url + '/buglist/querySingle', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 保存草稿
export const saveDraft = async (params: any) => {
  let data: any
  await http.post(url + '/buglist/save', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 提交
export const submit = async (params: any) => {
  let data: any
  await http.post(url + '/buglist/submit', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 删除
export const del = async (params: any) => {
  let data: any
  await http.delPost(url + '/buglist/delete', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导入
export const importFiles = async (params: any) => {
  let data: any
  await http.postFiles(url + '/buglist/import', {}, params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出
export const exportFiles = async (params?: any) => {
  let data: any
  await http.getFilesExt(url + '/buglist/export', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出模板
export const exportFilesTmpl = async (params?: any) => {
  let data: any
  await http.getFiles(url + '/buglist/template', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 批量删除收起
export const deleteList = async (params: any) => {
  let data: any
  await http.post(url + '/buglist/deleteList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 分页查询
export const queryPageList = async (params: any) => {
  let data: any
  await http.post(url + '/buglist/queryPageList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 更新bug单
export const update = async (params: any) => {
  let data: any
  await http.post(url + '/buglist/update', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 指派bug单
export const assignBug = async (params: any) => {
  let data: any
  await http.post(url + '/buglist/assign', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// /buglist/solve 解决
export const solveBug = async (params: any) => {
  let data: any
  await http.post(url + '/buglist/solve', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// /buglist/transfer 转交(code:转交人；list:bugId;remark:备注)
export const transfer = async (params: any) => {
  let data: any
  await http.post(url + '/buglist/transfer', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// /buglist/queryOperation 根据bugId查询操作记录
export const queryOperation = async (params: any) => {
  let data: any
  await http.post(url + '/buglist/queryOperation', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// /buglist/solve 解决
export const addRemarks = async (params: any) => {
  let data: any
  await http.post(url + '/buglist/addRemarks', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
