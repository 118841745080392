import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

// 新增升级模板
export const addUpgradeTemplate = async (params : any) => {
  let data: any
  await http.addPost(HUrl.ADD_UPGRADE_TEMPLATE, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除升级模板
export const deleteUpgradeTemplate = async (params : any) => {
  let data: any
  await http.delPost(HUrl.DELETE_UPGRADE_TEMPLATE, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询升级模板
export const queryUpgradeTemplate = async (params : any) => {
  let data: any
  await http.post(HUrl.QUERY_UPGRADE_TEMPLATE, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 分页查询升级模板
export const queryUpgradeTemplateList = async (params : any) => {
  let data: any
  await http.post(HUrl.QUERY_UPGRADE_TEMPLATE_LIST, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 更新升级模板
export const updateUpgradeTemplate = async (params : any) => {
  let data: any
  await http.editPost(HUrl.UPDATE_UPGRADE_TEMPLATE, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 批量系统升级
export const upgradeStart = async (params : any) => {
  let data: any
  await http.post(HUrl.UPGRADE_START, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 批量系统升级回退
export const upgradeFallback = async (params : any) => {
  let data: any
  await http.post(HUrl.UPGRADE_FALLBACK, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
