/**
 * 公司下拉框数据缓存
 * @author jihua.huang
 * @since 2022-03-14
*/

import { storeToRefs } from 'pinia'
import { companyDown } from '@/store' // 引入setting permission的store

/**
 * 初始化相关pinia store对象
 * @return {*}
 */
const initStore = () => {
  const settStore = companyDown()
  // 设置为响应数据
  const refSettStore = storeToRefs(companyDown())
  return { settStore, refSettStore }
}

// 获取部门下拉公司缓存
export function getDivisionalDown() {
  const { getDivisionalDown } = initStore().refSettStore
  return getDivisionalDown
}

// 修改部门下拉公司缓存
export function setDivisionalDown(val: any) {
  initStore().settStore.setDivisionalDown(val)
}

// 获取员工下拉公司缓存
export function getStaffDown() {
  const { getStaffDown } = initStore().refSettStore
  return getStaffDown
}

// 修改员工下拉公司缓存
export function setStaffDown(val: any) {
  initStore().settStore.setStaffDown(val)
}

// 获取职位下拉公司缓存
export function getJobDown() {
  const { getJobDown } = initStore().refSettStore
  return getJobDown
}

// 修改员工下拉公司缓存
export function setJobDown(val: any) {
  initStore().settStore.setJobDown(val)
}

