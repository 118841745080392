/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import { apiType } from 'types/commonresource'

// 查询任务分页
export const getSchedulerTaskLPage = async (params: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_SCHEDULER + '/jobinfo/pageList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 新增任务
export const addTask = async (params: any) => {
  let data: any
  await http.addPost(HUrl.BASE_PREFIX_SCHEDULER + '/jobinfo/add', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 编辑任务
export const editTask = async (params: any) => {
  let data: any
  await http.addPost(HUrl.BASE_PREFIX_SCHEDULER + '/jobinfo/update', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 删除任务
export const delTask = async (params: any) => {
  let data: any
  await http.delPost(HUrl.BASE_PREFIX_SCHEDULER + '/jobinfo/remove', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 执行一次任务
export const triggerTask = async (params: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_SCHEDULER + '/jobinfo/trigger', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 注册节点
export const loadById = async (params: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_SCHEDULER + '/jobgroup/loadById', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 启动任务
export const taskStart = async (params: any) => {
  let data = {} as apiType
  await http.post(HUrl.BASE_PREFIX_SCHEDULER + '/jobinfo/start', params).then((result: apiType) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 停止任务
export const taskStop = async (params: any) => {
  let data = {} as apiType
  await http.post(HUrl.BASE_PREFIX_SCHEDULER + '/jobinfo/stop', params).then((result: apiType) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 下次执行时间
export const nextTriggerTime = async (params: any) => {
  let data = {} as apiType
  await http.post(HUrl.BASE_PREFIX_SCHEDULER + '/jobinfo/nextTriggerTime', params).then((result: apiType) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

//  查询日志分页
export const joblogPageList = async (params: any) => {
  let data = {} as apiType
  await http.post(HUrl.BASE_PREFIX_SCHEDULER + '/joblog/pageList', params).then((result: apiType) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// // 查询执行器分页
// export const getSchedulerExecutorPage = async (params: any) => {
//   let data: any
//   await http.post(HUrl.BASE_PREFIX_SCHEDULER + '/jobgroup/pageList', params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

// // 查询执行器分页
// export const getSchedulerLogPage = async (params: any) => {
//   let data: any
//   await http.post(HUrl.BASE_PREFIX_SCHEDULER + '/joblog/pageList', params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }
