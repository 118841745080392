import { computed/*, defineAsyncComponent*/ } from 'vue'

import essenInfoHooks from '@/views/business/engineer/substituteTransition/substituteRules/hooks/basicInfo'
import { getInfoApps } from '@/service/modules/engineering/substituteRules'
import matlSubstitute from '@/views/business/engineer/substituteTransition/substituteRules/hooks/matlSubstitute'
import * as $http from '@/service/modules/engineering/substituteRules'
const formJson = () => {
  const essenInfo = essenInfoHooks({})
  const essenInfoComp = computed(() => {
    return {
      ...essenInfo.formJson
    }
  })

  return [{
    title: { lank: 'purchase.essenInfo', default: '基本信息' },
    type: 'form',
    json: essenInfoComp.value,
    getInfo: getInfoApps
  }, {
    title: { lank: 'engineering.SubstituteDetail', default: '替代料明细' },
    type: 'table',
    formid: 'substituteId',
    id: 'id',
    columnList: matlSubstitute(),
    getInfo: $http.queryInfos
  }]
}

export default formJson
