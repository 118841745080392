import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import moment from 'moment'

// 查询流程模板
export const flowTempList = async (params: any) => {
  let data: any
  await http.get(HUrl.FLOW_TEMP_LIST, params).then((result: any) => {
    result.data.items.forEach((item: any) => {
      item.updt = moment(item.updt).format('YYYY-MM-DD HH:mm:ss')
      item.cret = moment(item.cret).format('YYYY-MM-DD HH:mm:ss')
    })
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询流程模板节点
export const flowTempNodeList = async (params: any) => {
  let data: any
  await http.get(HUrl.FLOW_TEMP_NODE_LIST, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询流程模板详情
export const flowTempDetails = async (params: any) => {
  let data: any
  await http.get(HUrl.FLOW_TEMP_DETAILS, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 新增流程模板
export const addFlowTemp = async (params: any) => {
  let data: any
  await http.addPost(HUrl.ADD_FLOW_TEMP, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 编辑流程模板
export const editFlowTemp = async (params: any) => {
  let data: any
  await http.post(HUrl.EDIT_FLOW_TEMP, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除流程模板
export const delFlowTemp = async (params: any) => {
  let data: any
  await http.delPost(HUrl.DEL_FLOW_TEMP, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询流程最新版本列表
export const flowList = async (params: any) => {
  let data: any
  await http.get(HUrl.FLOW_LIST, params).then((result: any) => {
    result.data.items.forEach((item: any) => {
      item.updt = moment(item.updt).format('YYYY-MM-DD HH:mm:ss')
      item.cret = moment(item.cret).format('YYYY-MM-DD HH:mm:ss')
    })
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询流程历史版本列表
export const oldFlowList = async (params: any) => {
  let data: any
  await http.get(HUrl.OLD_FLOW_LIST, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询流程版本节点
export const flowNodeList = async (params: any) => {
  let data: any
  await http.get(HUrl.FLOW_NODE_LIST, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询流程版本详情
export const flowDetails = async (params: any) => {
  let data: any
  await http.get(HUrl.FLOW_DETAILS, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 申请编辑流程版本
export const appLyEditFlow = async (params: any) => {
  let data: any
  await http.get(HUrl.APP_LY_EDIT_FLOW, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 保存流程版本
export const saveFlowVer = async (params: any) => {
  let data: any
  await http.post(HUrl.SAVE_FLOW_VER, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 发布流程版本
export const publishFlow = async (params: any) => {
  let data: any
  await http.post(HUrl.PUBLISH_FLOW, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除流程草稿版本
export const delFlow = async (params: any) => {
  let data: any
  await http.delPost(HUrl.DEL_FLOW, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 启动业务流程
export const startFlow = async (params: any) => {
  let data: any
  await http.post(HUrl.START_FLOW, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 审核业务流程
export const auditFlow = async (params: any) => {
  let data: any
  await http.post(HUrl.AUDIT_FLOW, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 驳回业务流程
export const rejectFlow = async (params: any) => {
  let data: any
  await http.post(HUrl.Reject_Flow, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 撤销业务流程
export const cancelFlow = async (params: any) => {
  let data: any
  await http.post(HUrl.Cancel_Flow, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 流转业务流程
export const changeFlow = async (params: any) => {
  let data: any
  await http.post(HUrl.CHANGE_FLOW, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 根据业务单号查询流程信息
export const processInfo = async (params: any) => {
  let data: any
  await http.get(HUrl.PROCESS_INFO, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 我的相关流程
export const myFlowList = async (params: any) => {
  let data: any
  await http.get(HUrl.MY_FLOW_LIST, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询流程任务
export const flowTaskList = async (params: any) => {
  let data: any
  await http.get(HUrl.FLOW_TASK_LIST, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 保存动态任务
export const saveDynamicTask = async (params: any) => {
  let data: any
  await http.post(HUrl.SAVE_DYNAMIC_TASK, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 我的待办任务
export const myTaskList = async (params: any) => {
  let data: any
  await http.get(HUrl.MY_TASK_LIST, params).then((result: any) => {
    result.data.items.forEach((item: any) => {
      item.cret = moment(item.cret).format('YYYY-MM-DD')
    })
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  console.log('========', data)

  return data
}
// 发布任务
export const publishTask = async (params: any) => {
  let data: any
  await http.post(HUrl.PUBLISH_TASK, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除任务
export const delTask = async (params: any) => {
  let data: any
  await http.post(HUrl.DEL_TASK, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

