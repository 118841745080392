/* eslint-disable @typescript-eslint/no-explicit-any */
// 供应商接口
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

/** 前缀地址 java采购*/
const url = HUrl.HTTP_BASE_URL + '/pur'
// const url = HUrl.HTTP_BASE_URL_JAVA + '/pur'

// 审批流程——反审
export const counterReview = async (params?: any) => {
  let data: any
  await http.post(url + '/supplier/counterReview', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 审批流程——撤回
export const revoke = async (params?: any) => {
  let data: any
  await http.post(url + '/supplier/revoke', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询供货商基础数据
export const getSupplierInfo = async (params?: any) => {
  let data: any
  await http.post(url + '/supplier/SupplierInfo', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询供货商基础信息分页列表
export const getSupplierPage = async (params: any) => {
  let data: any
  await http.post(HUrl.GETSUPPLIERPAGE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询供应商列表
export const querySupplierList = async (params?: any) => {
  let data: any
  await http.post(HUrl.QUERY_SUPPLIER_LIST, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 保存供货商数据保存草稿
export const saveDraft = async (params?: any) => {
  let data = {} as apiType
  await http.post(HUrl.SUPPLIER_SAVEDRAFT, params).then((result) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 修改供货商基础信息
export const editSupplier = async (params?: any) => {
  let data: any
  await http.post(HUrl.EDITSUPPLIER, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 删除供货商基础信息
export const delSupplier = async (params?: any) => {
  let data: any
  await http.delPost(HUrl.DELSUPPLIER, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

export const supplierSaveDraft = async (params?: any) => {
  let data: any
  await http.post(HUrl.SUPPLIER_SAVE_DRAFT, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
export const supplierSaveContact = async (params?: any) => {
  let data: any
  await http.addPost(url + '/supplier/AddContact', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 查询联系人列表
 */
export const contactPageList = async (params?: any) => {
  let data: any
  await http.post(url + '/supplier/ContactPageList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询供应商统计详情，（根据供应商编号查询）
export const queryStatistics = async (params: any) => {
  let data: any
  await http.post(HUrl.QUERY_STATISTICS, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 提交供应商信息
 */
export const supplierSubmit = async (params?:any) => {
  let data: any
  await http.post(HUrl.SUPPLIER_SUBMIT, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询客户联系人信息
export const contactInfo = async (params?: any) => {
  let data: any
  await http.post(url + '/supplier/ContactInfo', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 新增客户联系人信息
export const addContact = async (params?: any) => {
  let data: any
  await http.post(url + '/supplier/AddContact', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 修改客户联系人信息
export const editContact = async (params?: any) => {
  let data: any
  await http.post(url + '/supplier/EditContact', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除客户联系人
export const delContact = async (params?: any) => {
  let data: any
  await http.delPost(url + '/supplier/DelContact', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 下载联系人导入模板
export const downloadContact = async (params?: any) => {
  let data: any
  await http.getFiles(url + '/supplier/DownloadContact', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导入联系人
export const importContact = async (params?: any) => {
  let data: any
  await http.postFiles(url + '/supplier/ImportContact', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导出联系人
export const exportContact = async (params?: any) => {
  let data: any
  await http.javaDl(url + '/supplier/ExportContact', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询客户地址信息
export const addressInfo = async (params?: any) => {
  let data: any
  await http.post(url + '/supplier/AddressInfo', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询客户地址分页列表
export const addressPageList = async (params?: any) => {
  let data: any
  await http.post(url + '/supplier/AddressInfoPageList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 新增客户地址信息
export const addAddress = async (params?: any) => {
  let data: any
  await http.post(url + '/supplier/AddAddressInfo', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 修改客户地址信息
export const editAddress = async (params?: any) => {
  let data: any
  await http.post(url + '/supplier/EditAddressInfo', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除客户地址
export const delAddress = async (params?: any) => {
  let data: any
  await http.delPost(url + '/supplier/DelAddressInfo', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 下载地址导入模板
export const downloadAddress = async (params?: any) => {
  let data: any
  await http.getFiles(url + '/supplier/DownloadAddress', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导入地址
export const importAddress = async (params?: any) => {
  let data: any
  await http.postFiles(url + '/supplier/ImportAddress', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导出地址
export const exportAddress = async (params?: any) => {
  let data: any
  await http.javaDl(url + '/supplier/ExportAddress', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询软件账号分页列表
export const softAccPageList = async (params?: any) => {
  let data: any
  await http.post(url + '/supplier/SoftAccInfoPageList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 新增软件账号信息
export const addSoftAcc = async (params?: any) => {
  let data: any
  await http.post(url + '/supplier/AddSoftAccInfo', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 修改软件账号信息
export const editSoftAcc = async (params?: any) => {
  let data: any
  await http.post(url + '/supplier/EditSoftAccInfo', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除软件账号
export const delSoftAcc = async (params?: any) => {
  let data: any
  await http.delPost(url + '/supplier/DelSoftAccInfo', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 下载软件账号导入模板
export const downloadSoftAcc = async (params?: any) => {
  let data: any
  await http.getFiles(url + '/supplier/DownloadSoftAcc', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导入软件账号
export const importSoftAcc = async (params?: any) => {
  let data: any
  await http.postFiles(url + '/supplier/ImportSoftAcc', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导出软件账号
export const exportSoftAcc = async (params?: any) => {
  let data: any
  await http.getFilesExt(url + '/supplier/ExportSoftAcc', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询对外投资分页列表
export const outbounpagelist = async (params?: any) => {
  let data: any
  await http.post(url + '/supplier/OutBounPageList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 新增对外投资信息
export const addoutboun = async (params?: any) => {
  let data: any
  await http.post(url + '/supplier/AddOutBoun', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 修改对外投资信息
export const editoutboun = async (params?: any) => {
  let data: any
  await http.post(url + '/supplier/EditOutBoun', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除对外投资
export const deloutboun = async (params?: any) => {
  let data: any
  await http.delPost(url + '/supplier/DelOutBoun', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 下载对外投资导入模板
export const downloadoutboun = async (params?: any) => {
  let data: any
  await http.getFiles(url + '/supplier/DownloadOutBoun', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导入对外投资
export const importoutboun = async (params?: any) => {
  let data: any
  await http.postFiles(url + '/supplier/ImportOutBoun', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导出对外投资
export const exportoutboun = async (params?: any) => {
  let data: any
  await http.getFilesExt(url + '/supplier/ExportOutBoun', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

export const paymentInfo = async (params?: any) => {
  let data: any
  await http.post(url + '/supplier/PaymentInfo', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// import messageCenter from '@/components/qrCode/subscribe'
import { apiType } from 'types/commonresource'
// 查询付款信息分页列表-境内
export const paymentInfoPageList = async (params?: any) => {
  let data: any
  await http.post(url + '/supplier/PaymentInfoPageList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 新增付款信息-境内
export const addPaymentInfo = async (params?: any) => {
  let data: any
  await http.post(url + '/supplier/AddPaymentInfo', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 修改付款信息-境内
export const editPaymentInfo = async (params?: any) => {
  let data: any
  await http.post(url + '/supplier/EditPaymentInfo', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除付款信息-境内
export const delPaymentInfo = async (params?: any) => {
  let data: any
  await http.delPost(url + '/supplier/DelPaymentInfo', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 下载付款信息导入模板-境内
export const downloadPaymentInfo = async (params?: any) => {
  let data: any
  await http.getFiles(url + '/supplier/DownloadPaymentInfo', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导入付款信息-境内
export const importPaymentInfo = async (params?: any) => {
  let data: any
  await http.postFiles(url + '/supplier/ImportPaymentInfo', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导出付款信息-境内
export const exportPaymentInfo = async (params?: any) => {
  let data: any
  await http.getFilesExt(url + '/supplier/ExportPaymentInfo', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 新增境外付款信息
 */
export const addPaymentOverseas = async (params?: any) => {
  let data: any
  await http.addPost(url + '/supplier/AddPaymentOverseas', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 修改境外付款信息
 */
export const editPaymentOverseas = async (params?:any) => {
  let data: any
  await http.editPost(url + '/supplier/EditPaymentOverseas', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 查看境外付款信息列表-分页
 */
export const paymentOverseasPageList = async (params?: any) => {
  let data: any
  await http.post(url + '/supplier/PaymentOverseasPageList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 删除境外账户信息
 */
export const delPaymentOverseas = async (params?:any) => {
  let data: any
  await http.delPost(url + '/supplier/DelPaymentOverseas', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 下载付款信息导入模板-境外
export const downloadPaymentOverseas = async (params?: any) => {
  let data: any
  await http.getFiles(url + '/supplier/DownloadPaymentOverseas', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导入付款信息-境外
export const importPaymentOverseas = async (params?: any) => {
  let data: any
  await http.postFiles(url + '/supplier/ImportPaymentOverseas', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导出付款信息-境外
export const exportPaymentOverseas = async (params?: any) => {
  let data: any
  await http.javaDl(url + '/supplier/ExportPaymentOverseas', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询供应商基础信息分页列表
export const SupplierPage = async (params?: any) => {
  let data: any
  await http.post(HUrl.SUPPLIERPAGE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询知识产权分页列表
export const inteproppagelist = async (params?: any) => {
  let data: any
  await http.post(url + '/supplier/IntePropPageList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 新增知识产权信息
export const addinteprop = async (params?: any) => {
  let data: any
  await http.post(url + '/supplier/AddInteProp', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 修改知识产权信息
export const editinteprop = async (params?: any) => {
  let data: any
  await http.post(url + '/supplier/EditInteProp', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除知识产权
export const delinteprop = async (params?: any) => {
  let data: any
  await http.delPost(url + '/supplier/DelInteProp', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 下载知识产权导入模板
export const downloadinteprop = async (params?: any) => {
  let data: any
  await http.getFiles(url + '/supplier/DownloadInteProp', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导入知识产权
export const importinteprop = async (params?: any) => {
  let data: any
  await http.postFiles(url + '/supplier/ImportInteProp', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 导出知识产权
export const exportinteprop = async (params?: any) => {
  let data: any
  await http.javaDl(url + '/supplier/ExportInteProp', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

//  下载客户信息导入模板
export const downloadCustomer = async (params?: any) => {
  let data: any
  await http.getFiles(url + '/supplier/template', params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
//  导入客户信息
export const importCustomer = async (params : any) => {
  let data: any
  await http.postFiles(url + '/supplier/import', {}, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
//  导出客户
export const exportCustomer = async (params?: any) => {
  let data: any
  await http.javaDl(url + '/supplier/export', params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
