// 物料编码接口
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

// 查询物料编码列表数据
export const getMaterialRulesData = async (params : any) => {
  let data: any
  await http.post(HUrl.MATERIAL_RULES_DATA, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 获取物料分类列详情
export const getStuffCategory = async (params : any) => {
  let data: any
  await http.get(HUrl.MATERIAL_RULES_CATEGORY, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询物料规则分类分页列表
export const getStuffCategoryPage = async (params : any) => {
  let data: any
  await http.post(HUrl.MATERIAL_RULES_CLASSIFY_DATA, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询物料规则分类分页列表
export const saveStuffCategory = async (params : any) => {
  let data: any
  await http.addPost(HUrl.MATERIAL_RULES_CLASSIFY_ADD, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 修改物料规则分类
export const editStuffCategory = async (params : any) => {
  let data: any
  await http.editPost(HUrl.MATERIAL_RULES_CLASSIFY_EDIT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除物料规则分类
export const delStuffCategory = async (params : any) => {
  let data: any
  await http.delPost(HUrl.MATERIAL_RULES_CLASSIFY_DEL, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 新增编物料规则特性
export const addMaterialRulesFeatures = async (params : any) => {
  let data: any
  await http.addPost(HUrl.MATERIAL_RULES_FEATURES_ADD, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 修改物料规则特性
export const editMaterialRulesFeatures = async (params : any) => {
  let data: any
  await http.editPost(HUrl.MATERIAL_RULES_FEATURES_EDIT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除物料规则特性
export const delMaterialRulesFeatures = async (params : any) => {
  let data: any
  await http.delPost(HUrl.MATERIAL_RULES_FEATURES_DEL, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
