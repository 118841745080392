import { computed } from 'vue'
import warehouseQualityInspection from '@/views/business/warehouse/qualityInspection/qualityInspectionRecords/hooks/indexHooks'
import { columnList } from '@/views/business/warehouse/qualityInspection/qualityInspectionRecords/hooks/goodsInfo'
import { queryQualityControl, queryQualityInspectionRecords } from '@/service/modules/warehouse/qualityInspectionManagement'
import { ref } from 'vue'
import { objAny } from 'types/table'
// 数据回显
const formJson = () => {
  const unitOfOriginName = ref('')
  const qualityInspection = warehouseQualityInspection({})
  const res = computed(() => {
    const { department, customer, supplier, ...newBaseJson } = qualityInspection.baseForm.value
    const obj = {
      companyId: newBaseJson.companyId,
      departmentId: newBaseJson.departmentId,
      dataEntryClerk: newBaseJson.dataEntryClerk,
      qualityInspectionTime: newBaseJson.qualityInspectionTime,
      qualityControlCode: newBaseJson.qualityControlCode,
      sourceBillCode: newBaseJson.sourceBillCode,
      originalOrderType: newBaseJson.originalOrderType,
      unitOfOriginName: {
        label: { lank: 'warehouse.unitOfOrigin', default: '来源单位' },
        span: 6,
        disabled: true,
        required: true,
        value: unitOfOriginName.value
      },
      sourceOfDeliveryNote: newBaseJson.sourceOfDeliveryNote,
      quality: newBaseJson.quality
    }
    return obj
  })

  return [
    {
      title: { lank: 'warehouse.basicInfo', default: '基本信息' },
      type: 'form',
      json: res.value,
      // getInfo: queryQualityControl
      getInfo: async (params: objAny) => {
        const result = await queryQualityControl(params)
        unitOfOriginName.value = result.data.unitOfOriginName
        return result
      }
    },
    {
      title: { lank: 'warehouse.productInformation', default: '货品信息' },
      type: 'table',
      formid: 'qualityControlId',
      id: 'id',
      columnList: columnList,
      getInfo: queryQualityInspectionRecords
    },
    {
      title: { lank: 'warehouse.qualityControlAccessory', default: '质检单附件' },
      type: 'files',
      code: 'QIRecords',
      templetCode: 'QIRecordInfo'
    }
  ]
}

export default formJson
