import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import { apiType } from 'types/commonresource'

const url = HUrl.BASE_PREFIX_PURCHASE_V // 路径前缀 + 微服务
// 分页查询-收起明细
export const getPageList = async (params: object) => {
  let data = {} as apiType
  await http.post(url + '/purchaseOrder/queryPageList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 分页查询-平铺明细
export const queryPageTile = async (params: object) => {
  let data = {} as apiType
  await http.post(url + '/purchaseOrder/queryPageTile', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取单个详情
export const getInfo = async (params: object) => {
  let data = {} as apiType
  await http.post(url + '/purchaseOrder/querySingle', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 保存草稿
export const saveDraft = async (params: object) => {
  let data = {} as apiType
  await http.post(url + '/purchaseOrder/save', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 提交
export const submit = async (params: object) => {
  let data = {} as apiType
  await http.post(url + '/purchaseOrder/submit', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 删除
export const del = async (params: object) => {
  let data = {} as apiType
  await http.delPost(url + '/purchaseOrder/deleteList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导入
export const importFiles = async (params: object) => {
  let data = {} as apiType
  await http.postFiles(url + '/purchaseOrder/import', {}, params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出 收起
export const exportPackUp = async (params?: object) => {
  let data = '' as string
  await http.getFilesExt(url + '/purchaseOrder/exportPackUp', params).then((result: string) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出 平铺
export const exportTile = async (params?: object) => {
  let data = '' as string
  await http.getFilesExt(url + '/purchaseOrder/exportTile', params).then((result: string) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出 /purchaseOrder/exportTile
export const exportFiles = async (params?: object) => {
  let data = '' as string
  await http.getFilesExt(url + '/purchaseOrder/export', params).then((result: string) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出模板
export const exportFilesTmpl = async (params?: object) => {
  let data = ''
  await http.getFiles(url + '/purchaseOrder/template', params).then((result: string) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 取消流程
export const cancel = async (params: object) => {
  const data = await http.post(url + '/purchaseOrder/cancel', params)
  return data
}

// 流程监听：完成审批
export const complete = async (params: object) => {
  const data = await http.post(url + '/purchaseOrder/complete', params)
  return data
}

// 反审
export const counterReview = async (params: object) => {
  const data = await http.post(url + '/purchaseOrder/counterReview', params)
  return data
}

// 流程监听：驳回
export const reject = async (params: object) => {
  const data = await http.post(url + '/purchaseOrder/reject', params)
  return data
}

// 撤回流程
export const revoke = async (params: object) => {
  const data = await http.post(url + '/purchaseOrder/revoke', params)
  return data
}

