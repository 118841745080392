import { computed, defineAsyncComponent, ref } from 'vue'
// 获取hooks
import basicInfoHooks from '@/views/business/administration/company/hooks/basicInfo' // 基本信息
import hardwareDeviceHooks from '@/views/business/administration/company/hooks/hardwareDevice' // 基本信息
import paymentInfoHooks from '@/views/business/administration/company/hooks/paymentInfoJson' // 基本信息
import shipAddressInfoHooks from '@/views/business/administration/company/hooks/shipAddressInfoJson' // 基本信息

// 获取接口
import {
  SCompany
} from '@/service'
import { objAny } from 'types/table'
import { apiType } from 'types/commonresource'
import { SGeneralData } from '@/service'
import { PublicField } from '@/config'
import { comm } from '@/utils'
import { re } from 'mathjs'

const formJsonData = () => {
  // 基础表单hooks
  const res = computed(() => {
    return {
      ...basicInfoHooks({}, false).basicInfoJson
    }
  })
  // 表格hooks
  const hardwareDeviceHook = hardwareDeviceHooks(ref(''))
  const paymentInfoHook = paymentInfoHooks()
  const shipAddressInfoHook = shipAddressInfoHooks()

  return [
    {
      title: { lank: 'administration.basicInformation', default: '基本信息' }, // 基本和商务信息
      type: 'form', // 表单
      json: res.value,
      getInfo: (params: objAny) => {
        return new Promise((resolve: (value: apiType) => void, reject) => {
          SCompany.getCompanyDetail(params).then(async (result) => {
            console.log(result)
            // result.data.affi =
            //   (result.data &&
            //     result.data.affi &&
            //     result.data.affi.map((item: { asso: string | number }) => {
            //       console.log('item.asso||', item.asso)
            //       item.asso = item.asso ? Number(item.asso) : ''
            //       console.log('item.asso||1', item.asso)
            //       return item
            //     })) ||
            //   []
            const currency = await SGeneralData.basicDataSelect({
              bdtc: PublicField.currencysType
            })
            if (result.data.regc) {
              //
              const item = currency?.data.find(
                (v: { val: string }) => result.data.curc == v.val
              )
              result.data.regc = `${comm.numberToAmount(result.data?.regc)} (${
                item?.txt || ''
              })`
              result.data.curc = undefined
            }
            if (result.data.stol) {
              //
              const item = currency?.data.find(
                (v: { val: string }) => result.data.cusl == v.val
              )
              result.data.stol = `${comm.numberToAmount(result.data?.stol)} (${
                item?.txt || ''
              })`
              result.data.cusl = undefined
            }
            if (result.data.aobc) {
              //
              const item = currency?.data.find(
                (v: { val: string }) => result.data.cuao == v.val
              )
              result.data.aobc = `${comm.numberToAmount(result.data?.aobc)} (${
                item?.txt || ''
              })`
              result.data.cuao = undefined
            }

            const unit = await SGeneralData.basicDataSelect({
              bdtc: PublicField.timeType
            })
            if (result.data.overduePaymentTimeout) {
              // timeType
              const item = unit?.data.find(
                (v: { val: string }) =>
                  result.data.overduePaymentTimeoutUnit === v.val
              )
              result.data.overduePaymentTimeout =
                result.data.overduePaymentTimeout + (item?.txt || '')
              result.data.overduePaymentTimeoutUnit = undefined
            }

            console.log('result::', result)

            resolve(result)
          })
        })
      },
      slot: {
        companyaddrList: defineAsyncComponent(() => import('./componentsSlot/coma.vue'))
        // shah: defineAsyncComponent(() => import('./componentsSlot/shah.vue')),
        // affi: defineAsyncComponent(() => import('./componentsSlot/affi.vue')),
        // buid: defineAsyncComponent(() => import('./componentsSlot/buid.vue'))
      },
      formStyle: {
        // buss: ['grid-column: 1 / span 4;'],
        corp: ['grid-column: 1 / span 4;'],
        rema: ['grid-column: 1 / span 4;'],
        comn: ['grid-column: 1 / span 4;'],
        webn: ['grid-column: 1 / span 4;'],
        companyaddrList: ['grid-column: 1 / span 4;']
        // buid: ['grid-column: 1 / span 4;'],
        // shah: ['grid-column: 1 / span 4;'],
        // affi: ['grid-column: 1 / span 4;']
      }
    },
    {
      title: { lank: 'administration.uploadAttachments', default: '上传附件' },
      type: 'files',
      code: 'Company',
      templetCode: 'Company'
    },

    {
      title: { lank: 'administration.collectionPaymentAccount', default: '收付款账号' },
      type: 'table', // 表格
      formid: 'coid', //  pid
      id: 'acid', // rowId
      columnList: paymentInfoHook.columnList,
      slot: {
        stat: defineAsyncComponent(() => import('./componentsSlot/isen.vue')),
        isbc: defineAsyncComponent(() => import('./componentsSlot/isen.vue')),
        isdf: defineAsyncComponent(() => import('./componentsSlot/isen.vue'))
      },
      getInfo: (params: objAny) => {
        return new Promise((resolve: (value: apiType) => void, reject) => {
          console.log('p1', params)
          const data = {
            ...params,
            'pageSize': 1000,
            'pageIndex': 1,
            'data': {
              'coid': params.coid
            }
          }
          SCompany.getAccountPage(params).then((result:any) => {
            if (result?.data?.items) {
              result.data.items.forEach((v:{ stat :number|string, isbc :number|string, isdf :number|string }) => {
                v.stat = +v.stat
                v.isbc = +v.isbc
                v.isdf = +v.isdf
              })
            }
            resolve(result)
          })
        })
      }
    },
    {
      title: { lank: 'administration.deliveryAddress', default: '收货地址' },
      type: 'table',
      formid: 'coid', //  pid
      id: 'crid', // rowId
      columnList: shipAddressInfoHook.columnList,
      slot: {
        isdf: defineAsyncComponent(() => import('./componentsSlot/isen.vue'))
      },
      getInfo: (params: objAny) => {
        return new Promise((resolve: (value: apiType) => void, reject) => {
          console.log('p2', params)
          const data = {
            ...params,
            'pageSize': 1000,
            'pageIndex': 1,
            'data': {
              'coid': params.coid
            }
          }
          SCompany.getReceiveAddrList(params).then((result) => {
            if (result?.data?.items) {
              result.data.items.forEach((v:{ isdf :number|string }) => {
                // v.stat = +v.stat
                // v.isbc = +v.isbc
                v.isdf = +v.isdf
              })
            }
            resolve(result)
          })
        })
      }
    },

    {
      title: { lank: 'administration.hardwareEquipment', default: '硬件设备' },
      type: 'table',
      formid: 'coid',
      id: 'cqid',
      columnList: hardwareDeviceHook.columnList.value,
      slot: {
        isen: defineAsyncComponent(() => import('./componentsSlot/isen.vue')),
        isdf: defineAsyncComponent(() => import('./componentsSlot/isen.vue'))
      },
      getInfo: (params: objAny) => {
        return new Promise((resolve: (value: apiType) => void, reject) => {
          console.log('p3', params)
          const data = {
            ...params,
            'pageSize': 1000,
            'pageIndex': 1,
            'data': {
              'coid': params.coid
            }
          }
          SCompany.getEquipmentPage(params).then((result:any) => {
            resolve(result)
          })
        })
      }
    }

  ]
}
export default formJsonData
