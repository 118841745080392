import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

// 数据列 - 旧版
// export const getColumnByCode = async (params : any) => {
//   const result = await http.post(HUrl.BASE_PREFIX_SYS + '/Table/ViewColumnSetList ', params)
//   if (result.code) {
//     result.data = {
//       items: result.data
//     }
//   }
//   return result
// }
export const getColumnByCode = async (params : any) => {
  const result = await http.post(HUrl.BASE_PREFIX_INIT_START_RESOUCE + '/listset/queryTablecolumnList', params)
  if (result.code) {
    result.data = {
      items: result.data
    }
  }
  return result
}

// 保存数据列-旧版本
// export const saveColumnList = async (params : any) => {
//   let data: any
//   await http.editPost(HUrl.BASE_PREFIX_SYS + '/Table/SaveColumnList', params).then((result : any) => {
//     data = JSON.parse(JSON.stringify(result))
//     data.data = {}
//     data.data.items = result.data
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }
export const saveColumnList = async (params : any) => {
  let data: any
  await http.editPost(HUrl.BASE_PREFIX_INIT_START_RESOUCE + '/listset/save', params).then((result : any) => {
    data = JSON.parse(JSON.stringify(result))
    data.data = {}
    data.data.items = result.data
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
