// import { getColumnList } from '@/views/business/engineer/materialdoc/hooks/index'
import hooks from '@/views/business/warehouse/label/labelList/indexHooks'
import { MaterialLabel } from '@/service'
import { objAny } from 'types/table'

const LabelList = () => {
  const { columnList } = hooks()

  const printView = [
    {
      viewCode: 'LabelList.List',
      columnList: columnList,
      getData: {
        fnName: '',
        fn: async () => {
          const result = await MaterialLabel.queryPageList({
            data: {},
            pageIndex: 1,
            pageSize: 9999
          })
          return result
        }
      },
      formatter: {
        productionDate: (row: objAny, value: string) => {
          return value + ' 至 ' + row.expirationDate
        }
      }
    }
  ]
  return printView
}

export default LabelList()
