/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from '@/service' // 引入网络请求http
// import { HUrl } from '@/config' // 引入http请求URL地址

const url = 'api/production' // 路径前缀 + 微服务
// 获取表格分页数据
export const getPageList = async (params: any) => {
  let data: any
  await http.post(url + '/orderProduct/queryPageList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
export const getList = async (params: any) => {
  let data: any
  await http.post(url + '/orderProduct/queryList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 保存
export const save = async (params: any) => {
  let data: any
  await http.post(url + '/orderProduct/save', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 删除
export const del = async (ids: string[]) => {
  let data: any
  await http.delPost(url + '/orderProduct/deleteList', { ids }).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导入
export const importFiles = async (params: any) => {
  let data: any
  await http.postFiles(url + '/orderProduct/import', {}, params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出
export const exportFiles = async (params?: any) => {
  let data: any
  await http.getFilesExt(url + '/orderProduct/export', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出模板
export const exportFilesTmpl = async (params?: any) => {
  let data: any
  await http.getFiles(url + '/orderProduct/template', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出模板
export const querySingle = async (params?: any) => {
  let data: any
  await http.getFiles(url + '/orderProduct/querySingle', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
