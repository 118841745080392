/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

const url = HUrl.HTTP_BASE_URL_JAVACHANGE // 路径前缀 + 微服务
// 获取表格分页数据
export const getPageList = async (params: any) => {
  let data: any
  await http.post(url + '/warehousing/shelvesModelCode/queryPageList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 列表查询-下拉
export const getList = async (params: any) => {
  let data: any
  await http.post(url + '/warehousing/shelvesModelCode/queryList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// shelvesModelCode/submit
// 获取单个详情
export const getInfo = async (params: any) => {
  let data: any
  await http.post(url + '/warehousing/shelvesModelCode/querySingle', params).then((result: any) => {
    if (result.code === 1 && result.data) {
      result.data.shelfRowCount = result.data.shelfRowCount.toString()
    }
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 保存草稿
export const saveDraft = async (params: any) => {
  let data: any
  await http.post(url + '/warehousing/shelvesModelCode/save', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 提交
export const shelvesModelCodeSubmit = async (params: any) => {
  let data: any
  await http.post(url + '/warehousing/shelvesModelCode/submit', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 删除
export const del = async (params: any) => {
  let data: any
  await http.delPost(url + '/warehousing/shelvesModelCode/deleteList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导入
export const importFiles = async (params: any) => {
  let data: any
  await http.postFiles(url + '/warehousing/shelvesModelCode/import', {}, params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出
export const exportFiles = async (params?: any) => {
  let data: any
  await http.getFilesExt(url + '/warehousing/shelvesModelCode/export', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出模板
export const exportFilesTmpl = async (params?: any) => {
  let data: any
  await http.getFiles(url + '/warehousing/shelvesModelCode/template', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

export const saveRough = async (params?: any) => {
  let data: any
  await http.post(url + '/shelvesModelCode/save', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 获取设备下拉
export const shelfDeviceQueryList = async (params?: any) => {
  let data: any
  await http.post(url + '/warehousing/shelfDevice/queryList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取账套下拉
export const accountSet = async (params?: any) => {
  let data: any
  await http.post(url + '/warehousing/shelvesModelCode/accountSet', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
export const sell = async (params?: any) => {
  let data: any
  await http.post(url + '/warehousing/shelvesModelCode/sell', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
export const initStrart = async (params?: any) => {
  let data: any
  await http.post(url + '/warehousing/shelfBoundWarehouse/init', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
