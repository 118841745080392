/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineStore } from 'pinia'

const draftFormIdStroe = defineStore({
  id: 'draftFormIdStroe',
  state: () => {
    // 物料数据字典
    return {
      draftFormId: '',
      bomDetLevel1Id: ''
    }
  },
  getters: {
    getDraftFormId: (state) => state.draftFormId,
    getBomDetLevel1Id: (state) => state.bomDetLevel1Id
  },
  actions: {
    setDraftFormId(data: string) {
      this.draftFormId = data
    },
    setBomDetLevel1Id(data: string) {
      this.bomDetLevel1Id = data
    }
  },
  persist: {
    enabled: true, // 开启持久化
    strategies: [
      {
        storage: localStorage, // 可以为localStorage或者sessionStorage
        paths: ['draftFormId', 'bomDetLevel1Id']
      }
    ]
  }
})

export default draftFormIdStroe
