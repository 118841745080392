import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import { apiType } from 'types/commonresource'

const url = HUrl.BASE_PREFIX_INIT_WORKFLOW // 路径前缀 + 微服务

// 查询业务流程分页
export const queryFlowPageList = async (params: object) => {
  let data = {} as apiType
  await http.post(url + '/business/queryFlowPageList', params).then((result: apiType) => {
    data = result
  })
  return data
}

// 新增业务流程
export const addFlow = async (params: object) => {
  let data = {} as apiType
  await http.addPost(url + '/business/addFlow', params).then((result: apiType) => {
    data = result
  })
  return data
}

// 删除业务流程
export const deleteFlow = async (params: object) => {
  let data = {} as apiType
  await http.delPost(url + '/business/deleteFlow', params).then((result: apiType) => {
    data = result
  })
  return data
}
// 删除业务流程节点
export const deleteNode = async (params: object) => {
  let data = {} as apiType
  await http.post(url + '/business/deleteNode', params).then((result: apiType) => {
    data = result
  })
  return data
}

// 更新业务流程
export const updateFlow = async (params: object) => {
  let data = {} as apiType
  await http.editPost(url + '/business/updateFlow', params).then((result: apiType) => {
    data = result
  })
  return data
}
// 更新业务流程节点
export const saveNode = async (params: object) => {
  let data = {} as apiType
  await http.post(url + '/business/saveNode', params).then((result: apiType) => {
    data = result
  })
  return data
}
// 更新业务流程节点状态
export const updateNodeStatus = async (params: object) => {
  let data = {} as apiType
  await http.post(url + '/business/updateNodeStatus', params).then((result: apiType) => {
    data = result
  })
  return data
}

