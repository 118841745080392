// plugins
import { computed } from 'vue'

// component
import basicInfoHooks from '@/views/business/production/fewMaterial/history/hooks/basicInfo'
import bomTableHooks from '@/views/business/production/fewMaterial/history/hooks/bomTableJson'
import generateTableHooks from '@/views/business/production/fewMaterial/history/hooks/generateTableJson'

// service
import { getInfo, itemQueryPageList, queryRecordItemHandlePageList } from '@/service/modules/production/fewMaterial'

const formJson = () => {
  // 表单信息
  const { companyFormJson,
    productFormJson,
    BOMFormJson } = basicInfoHooks()
  // BOM明细
  const { columnList: bomColumnList } = bomTableHooks()
  // 采购申请
  const { columnList: generateColumnList } = generateTableHooks()

  const formRes = computed(() => ({
    ...companyFormJson,
    ...productFormJson,
    ...BOMFormJson
  }))

  return [
    {
      title: { lank: 'production.basicInfo', default: '基本信息' },
      type: 'form',
      json: formRes.value,
      getInfo
    }, {
      title: {
        lank: 'produciton.fewMaterialTableDetails',
        default: '欠料明细'
      },
      type: 'table',
      formid: 'id',
      id: 'id',
      columnList: bomColumnList,
      getInfo: itemQueryPageList
    }, {
      title: {
        lank: 'production.fewMaterialHandleDetails',
        default: '处理明细'
      },
      type: 'table',
      columnList: generateColumnList,
      formid: 'id',
      id: 'id',
      getInfo: queryRecordItemHandlePageList
    }

  ]
}

export default formJson
