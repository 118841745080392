import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import { apiType } from 'types/commonresource'

const url = HUrl.BASE_PREFIX_FINANCE // 路径前缀 + 微服务
// 获取表格分页数据
export const getPageList = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/receivable/queryPageList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 分页平铺查询
export const queryPageListTile = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/receivable/queryPageListTile', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取单个详情
export const getInfo = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/receivable/querySingle', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
/**
 * 原单统计
 code	string 单号
 rootNodeId	string 源头单据id
 sourceBillCode	string 源单单号
*/
export const getStatistics = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/receivable/getSourceOrderStatistics', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 应收款详情同步
export const syncDetail = async (params: any[]) => {
  let data = {} as apiType
  await http.post(url + '/receivable/receivableDetailSync', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 保存草稿
export const saveDraft = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/receivable/save', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 提交
export const submit = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/receivable/submit', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 删除
export const del = async (params: any) => {
  let data = {} as apiType
  await http.delPost(url + '/receivable/deleteList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导入
export const importFiles = async (query:any, params: any) => {
  let data = {} as apiType
  await http.postFiles(url + '/receivable/import', query, params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出
export const exportFiles = async (params?: any) => {
  let data = {} as apiType
  await http.javaDl(url + '/receivable/export', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出模板
export const exportFilesTmpl = async (params?: any) => {
  let data = {} as apiType
  await http.getFiles(url + '/receivable/template', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

/** 审批流程——撤回 */
export const revoke = async (params: any) => {
  let data: any
  await http.post(url + '/receivable/revoke', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

/** 审批流程——反审 */
export const counterReview = async (params: any) => {
  let data: any
  await http.post(url + '/receivable/counterReview', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

export const getPageListDetail = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/receivable/detail/queryPageList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 保存
export const saveDetail = async (params: any) => {
  let data = {} as apiType
  await http.post(url + '/receivable/detail/save', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 删除
export const delDetail = async (params: any) => {
  let data = {} as apiType
  await http.delPost(url + '/receivable/detail/deleteList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导入
export const importFilesDetail = async (params: any) => {
  let data = {} as apiType
  await http.postFiles(url + '/receivable/detail/import', {}, params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出
export const exportFilesDetail = async (params?: any) => {
  let data = {} as string
  await http.getFilesExt(url + '/receivable/detail/export', params).then((result: string) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出模板
export const exportFilesTmplDetail = async (params?: any) => {
  let data = {} as string
  await http.getFiles(url + '/receivable/detail/template', params).then((result: string) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
