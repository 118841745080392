import { computed } from 'vue'
import notesStockOutInspection from '@/views/business/warehouse/stockOut/notesStockOut/hooks/indexHooks'
import { columnList } from '@/views/business/warehouse/stockOut/notesStockOut/hooks/tableForm'
import { putOutStorage } from '@/service'
// 数据回显
const formJson = () => {
  const notesStockOut = notesStockOutInspection({})
  const res = computed(() => {
    return { ...notesStockOut.baseJson }
  })

  return [
    {
      title: { lank: 'warehouse.basicInfo', default: '基本信息' },
      type: 'form',
      json: res.value,
      getInfo: putOutStorage.queryStockOut
    },
    {
      title: { lank: 'warehouse.productInformation', default: '货品信息' },
      type: 'table',
      formid: 'stockOutId',
      id: 'id',
      columnList: columnList,
      getInfo: putOutStorage.queryOutDetailsList
    },
    {
      title: { lank: 'warehouse.NotesStockOutFile', default: '出库单附件' },
      type: 'files',
      code: 'QIRecords',
      templetCode: 'QIRecordInfo'
    }
  ]
}

export default formJson
