// name 需要和资源编码对上
// import PurchaseOrder from '/purchase/purchaseOrder/purchaseOrder'
// import SalesOrder from '/sales/salesOrder/salesOrder'
// import ReceiptRecord from '/warehouse/receiptRecord'
// import QIRecords from '/warehouse/qualityInspection'
// import WarehousingRecord from '/warehouse/warehousingRecord'
// import NotesStockOut from '/warehouse/notesStockOut'
// import Material from '/engineering/materialdoc/materialdoc'
// import Apply from '/purchase/apply/apply'
// import Supplier from '/purchase/supplier/supplier'
// import Quote from '/sales/quote/quote'
// import DeliverAdvice from '/sales/deliverAdvice/deliverAdvice'
// import OrderStockIn from '/sales/orderStockIn/orderStockIn'
// import Brand from '/purchase/brand/brand'
// import Customer from '/sales/customer/customer'

// import Collection from '/finance/collection/collection'
// import Payment from '/finance/payment/payment'
// import Receivable from '/finance/receivable/receivable'
// import Payable from '/finance/payable/payable'
// import BOM from '/production/bom/bom'
// import SubstituteRules from '/engineering/substituteRules/index'
// import Substitute from '/engineering/substitute/index'
// import Company from '/administration/company/company'
// import PurchaseMaint from '/purchase/priceMaint/priceMaint'
// import SalesMaint from '/sales/priceMaint/priceMaint'
// import OrgMaterMain from '/sysmanage/materialManage/orgMaterMain/index'
// import PublicMaterials from '/sysmanage/materialManage/publicMaterials/index'
// import InquiryApprove from '/purchase/inquiry/inquiry'
// import Order from '/production/orderMaterial/order'
// import FewMaterial from '/production/fewMaterial/fewMaterial'
// import FxRateManger from '/finance/fxratemanger/fxratemanger'
// import Picking from '/production/picking/order'
// import CompleteSuitBom from '/production/completeSuitBom/completeSuitBom'
const list:any =
  {
    PurchaseOrder:'/purchase/purchaseOrder/purchaseOrder.ts',
    SalesOrder:'/sales/salesOrder/salesOrder.ts',
    ReceiptRecord:'/warehouse/receiptRecord.ts',
    QIRecords:'/warehouse/qualityInspection.ts',
    WarehousingRecord:'/warehouse/warehousingRecord.ts',
    NotesStockOut:'/warehouse/notesStockOut.ts',
    Material:'/engineering/materialdoc/materialdoc.ts',
    Apply:'/purchase/apply/apply.ts',
    Supplier:'/purchase/supplier/supplier.ts',
    Quote:'/sales/quote/quote.ts',
    DeliverAdvice:'/sales/deliverAdvice/deliverAdvice.ts',
    OrderStockIn:'/sales/orderStockIn/orderStockIn.ts',
    Brand:'/purchase/brand/brand.ts',
    Customer:'/sales/customer/customer.ts',
    Collection:'/finance/collection/collection.ts',
    Payment:'/finance/payment/payment.ts',
    Receivable:'/finance/receivable/receivable.ts',
    Payable:'/finance/payable/payable.ts',
    BOM:'/production/bom/bom.ts',
    SubstituteRules:'/engineering/substituteRules/index.ts',
    Substitute:'/engineering/substitute/index.ts',
    Company:'/administration/company/company.ts',
    PurchaseMaint:'/purchase/priceMaint/priceMaint.ts',
    SalesMaint:'/sales/priceMaint/priceMaint.ts',
    OrgMaterMain:'/sysmanage/materialManage/orgMaterMain/index.ts',
    PublicMaterials:'/sysmanage/materialManage/publicMaterials/index.ts',
    InquiryApprove:'/purchase/inquiry/inquiry.ts',
    Order:'/production/orderMaterial/order.ts',
    FewMaterial:'/production/fewMaterial/fewMaterial.ts',
    FxRateManger:'/finance/fxratemanger/fxratemanger.ts',
    Picking:'/production/picking/order.ts',
    CompleteSuitBom:'/production/completeSuitBom/completeSuitBom.ts',
  }
export default {
  list
}
