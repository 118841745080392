import { cloneDeep } from 'lodash'
import { unsavedPrompt } from './commonFunc'
import type { apiType } from 'types/commonresource'
import type { objAny } from 'types/table'
import type { Ref } from 'vue'
import i18n from '@/i18n'

// 用法可以参考 src\views\business\finance\collectionPayment\payment\components\formPage\index.vue
/**
 * 校验ggForm方法用于提交 和保存草稿
 * @param refs ref的数组
 * @param refAll // 二次封装有 formValidate 的ref数组
 * @param fnName 'validate' | 'getCheckState'
 * @returns boolean
 */
export const formValidate = ({ refs = [], refAll = [], fnName } : {refs?: Ref[] | any, refAll?: Ref[] | any, fnName: 'validate' | 'getCheckState'}) => {
  return new Promise(async(resolve: (value: boolean) => void) => {
    try {
      const promiseFnArr1 = refs.map((item: Ref) => {
        if (item[fnName]) {
          return (item[fnName]())
        }
        return (item.value[fnName]())
      })
      const promiseFnArr2 = refAll.reduce((newArr: any, item: any) => {
        const result = item.map((item: any) => {
          if (item[fnName]) {
            return (item[fnName]())
          }
          return (item.value[fnName]())
        })
        return [...newArr, ...result]
      }, [])

      const result = await Promise.all([...promiseFnArr1, ...promiseFnArr2]).catch(() => {
        resolve(false)
      })
      if (result && result.length > 0) {
        const res = result.every(item => item)
        resolve(res)
      }
    } catch (error) {
      console.log(new Error('未获取到Ref对应的方法, 请检查代码是否正确'))
      resolve(false)
    }
    resolve(false)
  })
}

interface DraftType {
  refs?: Ref[] | any // form表单的ref
  refAll?: any // 二次封装有 formValidate 的ref
  data: objAny // 保存的数据
  saveApi: (params: any) => Promise<apiType> // 保存草稿的方法
}

/**
 * 草稿保存的方法
 * @param DraftType {}
 * @DraftType refs?: Ref[] // form表单的ref
 * @DraftType refAll?: Ref[] // 二次封装有 formValidate 的ref
 * @DraftType data?: objAny // 保存的数据
 * @DraftType saveApi?: (params: any) => Promise<apiType> // 保存草稿的方法
 * @returns
 */
export const saveDraft = ({ refs = [], refAll = [], data, saveApi }: DraftType) => {
  return new Promise(async(resolve: (value: false | apiType) => void, reject) => {
    const result = await formValidate({
      refs,
      refAll,
      fnName: 'getCheckState'
    })
    // 判断校验是否通过 通过并且有传入保存的方法和数据
    if (result) {
      // 有数据和接口 调用方法保存数据
      await saveApi(cloneDeep(data)).then(result => {
        resolve(result)
      }).catch((error) => {
        reject(error)
      })
    }
    // 数据不正确返回false
    resolve(false)
  })
}

interface QueryType {
  refs: any[], // ref合集
  messageList: {lank: string, default: string}[] // 提示合集
}

// 获取子项是否有未保存的数据
/**
 *
 * @param QueryType
 * @QueryType  refs ref合集
 * @QueryType  messageList 提示合集
 * @attention ress 和 messageList需要对应上
 * @returns boolean true代表通过 false 代表未通过
 */
export const statusQuery = async ({ refs, messageList }: QueryType) => {
  const t = i18n.global.t
  if (refs.length === messageList.length) {
    try {
      // 循环获取需要处理ref数据的方法
      const promiseArr = refs.map(item => {
        if (item.statusQuery) {
          return item.statusQuery()
        }
        return item.value.statusQuery()
      })
      // 获取是否有修改未保存
      const result = await Promise.all(promiseArr)
      let errorMessageList: string[] = []
      // 有修改未保存的数据添加到数组里面
      result.forEach((item, index) => {
        if (item) {
          errorMessageList.push(t(messageList[index].lank, messageList[index].default))
        }
      })
      if (errorMessageList && errorMessageList.length > 0) {
        unsavedPrompt(errorMessageList)
        return false
      } else {
        return true
      }
    } catch (error) {
      console.log(new Error('代码报错, 查看是否入参错误'))
      return false
    }
  } else {
    console.log(new Error('数据未对应上, 请检查代码'))
    return false
  }
}
