import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

// 查询字典数据
export const getDictionaryList = async (params : any) => {
  let data: any
  await http.post(HUrl.DICTIONARY_LIST, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 此接口用于添加字典数据信息
export const addDictionaryData = async (params : any) => {
  let data: any
  await http.post(HUrl.DICTIONARY_ADD_LIST, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 修改字典数据
export const editDictionaryData = async (params : any) => {
  let data: any
  await http.post(HUrl.DICTIONARY_EDIT_LIST, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除字典数据
export const DictionaryDel = async (params : any) => {
  let data: any
  await http.delPost(HUrl.DICTIONARY_DEL, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 获取字典数据下拉组件数据
export const DictionaryTypeESelect = async (params : any) => {
  let data: any
  await http.get(HUrl.DICTIONARY_CATEGORY_SELECT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 类别列表
export const getDictionaryTypeList = async (params : any) => {
  let data: any
  await http.post(HUrl.DICTIONARY_CATEGORY_LIST, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 新增字典类别
export const addDictionaryType = async (params : any) => {
  let data: any
  await http.post(HUrl.DICTIONARY_CATEGORY_ADD_LIST, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 删除字典类别
export const DictionaryTypeDel = async (params : any) => {
  let data: any
  await http.delPost(HUrl.DICTIONARY_CATEGORY_DEL, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 编辑字典类别
export const DictionaryTypeEdit = async (params : any) => {
  let data: any
  await http.post(HUrl.DICTIONARY_CATEGORY_EDIT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 修改启用状态
export const DictionarySetStateData = async (params : any) => {
  let data: any
  await http.post(HUrl.DICTIONARY_SET_STATE_DATA, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 此接口查询单个字典类型信息
export const dictionaryGetTypeInfo = async (params : any) => {
  let data: any
  await http.get(HUrl.DICTIONARY_GET_TYPE_INFO, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 此接口查询单个字典数据信息
export const dictionaryGetDataInfo = async (params : any) => {
  let data: any
  await http.get(HUrl.DICTIONARY_GET_DATA_INFO, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 语言类别列表
export const languageGetGroupList = async (params? : any) => {
  let data: any
  await http.post(HUrl.LANGUAGE_GET_GROUP_LIST, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 新增语种分组
export const languageSaveGroup = async (params : any) => {
  let data: any
  await http.post(HUrl.LANGUAGE_SAVE_GROUP, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 此接口查询单个语种分组信息
export const languageGetGroupInfo = async (params : any) => {
  let data: any
  await http.post(HUrl.LANGUAGE_GET_GROUP_INFO, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 编辑字典类别
export const languageEditGroup = async (params : any) => {
  let data: any
  await http.post(HUrl.LANGUAGE_EDIT_GROUP, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除字典类别
export const languageDelGroup = async (params : any) => {
  let data: any
  await http.delPost(HUrl.LANGUAGE_DEL_GROUP, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 此接口查询语种资源列表信息
export const languageGetSourceList = async (params : any) => {
  let data: any
  await http.post(HUrl.LANGUAGE_GET_SOURCE_LIST, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

export const languageSaveSource = async (params : any) => {
  let data: any
  await http.post(HUrl.LANGUAGE_SAVE_SOURCE, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 此接口查询单个字典数据信息
export const languageGetSourceInfo = async (params : any) => {
  let data: any
  await http.post(HUrl.LANGUAGE_GET_SOURCE_INFO, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 此接口查询单个字典数据信息
export const languageEditSource = async (params : any) => {
  let data: any
  await http.post(HUrl.LANGUAGE_EDIT_SOURCE, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 此接口查询单个字典数据信息
export const languageDelSource = async (params : any) => {
  let data: any
  await http.delPost(HUrl.LANGUAGE_DEL_SOURCE, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

export const languageExport = async (params? : any) => {
  let data: any
  await http.getFilesExt(HUrl.LANGUAGE_EXPORT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

export const languageDownloadJson = async (params? : any) => {
  let data: any
  await http.getFiles(HUrl.LANGUAGE_DOWNLOADJSON, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 下载字典模板
export const dictionaryExport = async (params? : any) => {
  let data: any
  await http.getFilesExt(HUrl.DICTIONARY_EXPORT, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 导入字典数据
export const dictionaryImport = async (params : any) => {
  let data: any
  await http.postFiles(HUrl.DICTIONARY_IMPORT, {}, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
