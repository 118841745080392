import { computed, defineAsyncComponent, ref } from 'vue'
// 获取hooks
import companyInfo from '@/views/business/sales/deliverGoods/deliverAdvice/hooks/companyInfo' // 基本信息
import receivingInfo from '@/views/business/sales/deliverGoods/deliverAdvice/hooks/receivingInfo' // 基本信息
import descInfo from '@/views/business/sales/deliverGoods/deliverAdvice/hooks/descInfo' // 基本信息
import { addColumnList } from '@/views/business/sales/deliverGoods/deliverAdvice/hooks/tableForm' // 表格
// 获取接口
import { getInfo, getPageListDetail } from '@/service/modules/sales/deliverAdvice'
// import { SSalesOrder } from '@/service' //
import { objAny } from 'types/table'
import { apiType } from 'types/commonresource'

const formJsonData = () => {
  // 基础表单hooks
  const basicHooks = receivingInfo(ref(false))
  const res = computed(() => {
    return {
      ...companyInfo().formJson,
      ...basicHooks.formJsonTop,
      ...basicHooks.formJsonBot,
      ...descInfo().formJson
    }
  })
  // 表格hooks
  const columnList = addColumnList({
    deliveryMethod: ref(''),
    paymentFreight: ref(''),
    carrierNo: ref(''),
    taxRate: ref(0),
    currency: ref('CNY') })

  return [
    {
      title: { lank: 'sales.basicInfo', default: '基本信息' }, // 基本和商务信息
      type: 'form', // 表单
      json: res.value,
      getInfo: (params: objAny) => {
        return new Promise((resolve: (value: apiType) => void, reject) => {
          getInfo(params).then(async(result) => {
            result.data.customerId = result.data.customerName
            result.data.customerAbbId = result.data.customerSampleName
            result.data.customerCodeId = result.data.customerCode
            console.log('result::', result)
            resolve(result)
          })
        })
      },
      slot: {
        coma: defineAsyncComponent(() => import('./componentsSlot/coma.vue')),
        shah: defineAsyncComponent(() => import('./componentsSlot/shah.vue')),
        affi: defineAsyncComponent(() => import('./componentsSlot/affi.vue')),
        buid: defineAsyncComponent(() => import('./componentsSlot/buid.vue'))
      },
      formStyle: {
        // engn: [
        //   'grid-column: 1 / span 4;'
        // ],
      }
    },
    {
      title: { lank: 'sales.quoteDetail', default: '报价明细' },
      type: 'table', // 表格
      formid: 'adviceId', //
      id: 'id', //
      columnList: columnList,
      slot: {
        isen: defineAsyncComponent(() => import('./componentsSlot/isen.vue'))
      },
      getInfo: (params: objAny) => {
        return new Promise((resolve: (value: apiType) => void, reject) => {
          getPageListDetail({
            keyWord: params.keyWord,
            data: {
              adviceId: params.adviceId
            },
            adviceId: params.adviceId,
            pageIndex: params.pageIndex,
            pageSize: params.pageSize,
            total: 0
          }).then(result => {
            resolve(result)
          })
        })
      }
    }
  ]
}
export default formJsonData
