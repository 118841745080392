/* HTTP Restful API 数据交换地址 */
if (process.env.NODE_ENV === 'test') {
  // 测试环境
  exports.BASE_URL = 'http://192.168.2.235:80'
  exports.BASE_URL_JAVA = 'http://192.168.2.235:80'
  exports.BASE_URL_TMAP = 'http://api.tianditu.gov.cn' // 天地图API
} else if (process.env.NODE_ENV === 'production') {
  // 生产环境
  exports.BASE_URL = 'http://47.107.40.88/'
  exports.BASE_URL_JAVA = 'http://47.107.40.88/' // 发布环境
  exports.BASE_URL_TMAP = 'http://api.tianditu.gov.cn'// 天地图API
} else {
  // Dev开发环境 http restful API 请求地址
  exports.BASE_URL = 'http://192.168.2.235:80'
  // exports.BASE_URL = 'http://47.107.40.88' // 生产接口
  // exports.BASE_URL_JAVA = 'http://192.168.2.235:80'
  // exports.BASE_URL_JAVA = 'http://192.168.2.55:9999'
  // exports.BASE_URL_JAVA = 'http://192.168.2.117:5000' // 马工
  // exports.BASE_URL_JAVA = 'http://192.168.2.125:8089' // 李工
  exports.BASE_URL_JAVA = 'http://192.168.2.31:5000' // 付工
  exports.BASE_URL_TMAP = 'http://api.tianditu.gov.cn'// 天地图API
}
/**  后缀地址（可以为版本目录，如：/v1）  暂时弃用*/
exports.PREFIX_PATH = '/v1'

// DEV host 地址
exports.DEV_HOST = 'localhost'
// DEV port 端口
exports.DEV_PORT = '8080'
// DEV proxy
exports.PROXY_ROOT = '/api'

exports.PROXY_ROOT_JAVA = '/wareh'

exports.PROXY_ROOT_JAVACHANGE = '/api/smartshelf' // java非简写字段接口

exports.PROXY_ROOT_TMAP = '/t/map'
// exports.PROXY_ROOT_JAVACHANGE = '/api/smartshelf/warehChange' // java非简写字段接口
// exports.PROXY_ROOT_JAVACHANGE = '/warehChange' // java非简写字段接口 本地
// exports.PROXY_ROOT_JAVACHANGE = '/warehChange' // java非简写字段接口
// DEV环境数据使用 mock数据 还是api接口数据
// value:  mock  |  api
exports.DEV_DATA_SOURCE = 'mock'
