import { computed, ref } from 'vue'
// 获取hooks
import basicInfoHooksIndex from '@/views/business/finance/collectionPayment/collection/hooks/basicInfoHooks' // 基本信息(公司和客户)
import { addColumnList, sourceColumnList } from '@/views/business/finance/collectionPayment/collection/hooks/detailTableForm' // 金额明细
// 获取接口
import { getInfo, queryPageDetail } from '@/service/modules/finance/collection'
import { objAny } from 'types/table'
import { apiType } from 'types/commonresource'
import { SCompany, SCustomer } from '@/service'
import { materialCategory } from '@/service/modules/engineering/materialCode'

const formJsonData = () => {
  // 基础表单hooks
  const { formJson, collectionInfo } = basicInfoHooksIndex({ serviceCharge: ref(''), exchangeRate: ref('') }, ref([]), ref([]), ref([]), ref(false), ref(false)) // 基本信息

  const res = computed(() => {
    return {
      ...formJson.value,
      ...collectionInfo.value
    }
  })
  // 表格hooks
  const exchangeRate = ref(collectionInfo.value.exchangeRate)
  const currency = ref(collectionInfo.value.currency)
  let mColumnList = []
  if (formJson.value.sourceCode) {
    mColumnList = sourceColumnList({ exchangeRate: exchangeRate.value, currency: currency.value })
  } else {
    mColumnList = addColumnList({ exchangeRate: exchangeRate.value, currency: currency.value })
  }
  return [
    {
      title: { lank: 'finance.essentialInfo', default: '基本信息' }, // 基本信息
      type: 'form', // 表单
      json: res.value,
      getInfo: (params: objAny) => {
        return new Promise((resolve: (value: apiType) => void, reject) => {
          getInfo(params).then(async (res) => {
            const resCmp = await SCompany.getAccountPage({
              pageIndex: 1,
              pageSize: 99999,
              companyId: res.data.company
            })
            let collection = { accn: '', bann: '', bana: '', debn: '' }
            let payment = { accn: '', bank: '', bana: '', bano: '' }
            if (resCmp.code && resCmp.data && resCmp.data.items) {
              collection = resCmp.data.items.find((v:{ acid:string}) => res.data.collectionAccount == v.acid)
            }
            const resCus = await SCustomer.paymentInfoList({
              keyWord: '',
              pId: res.data.customer
            })
            if (resCus.code && resCus.data && resCus.data.items) {
              payment = resCus.data.items.find((v:{ paid:string}) => res.data.paymentAccount == v.paid)
            }
            const obj = {
              collection: res.data?.collection || 0,
              collectionTotal: res.data?.collectionTotal || 0,
              collectionAccount: collection?.accn, // 账户
              collectionBank: collection?.bann, // 开户行
              collectionBankId: collection?.debn, // '收款银行行号
              collectionId: collection?.bana, // 账号
              paymentAccount: payment?.accn,
              paymentBank: payment?.bank,
              paymentBankId: payment?.bano,
              paymentId: payment?.bana
            }
            res.data = { ...res.data, ...obj }

            resolve(res)
          })
        })
      },
      slot: {
      },
      formStyle: {
      }
    },
    {
      title: { lank: 'finance.uploadAttachments', default: '上传附件' },
      type: 'files',
      code: 'Collection',
      templetCode: 'Collection'
    },
    {
      title: { lank: 'finance.moneyDetail', default: '金额明细' },
      type: 'table', // 表格
      formid: 'collectionId',
      id: 'id',
      columnList: mColumnList,
      getInfo: (params: objAny) => {
        return new Promise((resolve: (value: apiType) => void, reject) => {
          queryPageDetail({
            keyWord: '',
            data: {
              collectionId: params.collectionId
            },
            pageIndex: params.pageIndex,
            pageSize: params.pageSize,
            total: 0
          }).then(async (result) => {
            const res = await materialCategory({}) as any
            let materialList = [] as any
            if (res.code) {
              materialList = res.data
            }
            if (result.data && result.data.items) {
              result.data.items.forEach((v: any) => {
                // v.materialId = v.customerMaterial
                const material = materialList.find((item:any) => v.materialId === item.maid) as any
                if (material) {
                  const { gocc, macc, unid, spec, mata, matn } = material
                  v.unitOfMeasurement = unid
                  v.productLine = gocc
                  v.materialClassification = macc
                  v.materialName = mata
                  v.materialNo = matn
                  v.materialCode = matn
                  v.specification = spec
                }
              })
            }
            resolve(result)
          })
        })
      }
    }
  ]
}
export default formJsonData
