// 员工接口
import { http } from "@/service"; //引入网络请求http
import { HUrl } from "@/config"; //引入http请求URL地址
import moment from 'moment'

// 查询请假单列表
export const getAskForLeaveList = async (params: any) => {
  let data: any;
  await http.get(HUrl.ASK_FOR_LEAVE_LIST, params).then((result: any) => {
    result.data.items.forEach((item: any) => {
      // item.cret = moment(item.cret).format("YYYY-MM-DD HH:mm:ss")
      item.lebt = moment(item.lebt).format("YYYY-MM-DD HH:mm:ss")
      item.leet = moment(item.leet).format("YYYY-MM-DD HH:mm:ss")
    })
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 保存请假单列表新增编辑
export const saveAskForLeave = async (params: any) => {
  let data: any;
  await http.editPost(HUrl.ASK_FOR_LEAVE_SAVE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除请假单列表
export const delAskForLeave = async (params: any) => {
  let data: any;
  await http.delPost(HUrl.ASK_FOR_LEAVE_DEL, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询请假单详情
export const detailsAskForLeave = async (params: any) => {
  let data: any;
  await http.get(HUrl.ASK_FOR_LEAVE_DETAILS, params).then((result: any) => {
    result.data.lebt = moment(result.data.lebt).format("YYYY-MM-DD HH:mm:ss")
    result.data.leet = moment(result.data.leet).format("YYYY-MM-DD HH:mm:ss")
    result.data.cret = moment(result.data.cret).format("YYYY-MM-DD HH:mm:ss")
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
