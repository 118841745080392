// ========================对应的Route component Vue组件模板地址======================
import { componentRouter } from './components'
// ========================对应的Route component Vue组件模板地址======================

// 静态路由列表
export const routesStatic: any = [
  {
    path: '/', // 路径地址
    redirect: '/home', // 重定向地址
    component: componentRouter.Layout, // view组件
    abbr: '首页', // 简称
    name: 'Home',
    meta: {
      title: '首页', // 菜单标题名称
      icon: '#icon-shouye1', // 菜单图标
      requiresAuth: true, // 需认证才能显示该页面
      hidden: false, // 该菜单是否在view的menu组件隐藏
      layout: true, // 该菜单只作为一级菜单标题显示
      keepAlive: true, // 该菜单对应的页面是否保存缓存，用于keep-alive组件设置
      highlight: '/', // 高亮路径 默认path
      affix: true // 固定在 tags-view中不可被删除

    },
    children: [
      {
        path: '/home',
        name: 'Home',
        component: componentRouter.Home,
        abbr: '首页',
        meta: {
          title: '首页',
          icon: 'House',
          requiresAuth: true,
          hidden: false,
          keepAlive: true,
          affix: true

        }
      }
    ]
  }
  // {
  //   path: '/sysmanage',
  //   name: 'sysmanage',
  //   component: componentRouter.Layout,
  //   meta: {
  //     title: '系统管理',
  //     icon: 'Document',
  //     requiresAuth: true,//需认证才能显示该页面
  //     keepAlive: true,//该菜单对应的页面是否保存缓存，用于keep-alive组件设置
  //     new: false,//是否为新功能
  //     layout: true,
  //     abbreviation: '系统',
  //   },
  //   children: [
  //     {
  //       path: '/sysmanage/Dictmanage',
  //       name: 'Dictmanage',
  //       component: componentRouter.Dictmanage,
  //       meta: {
  //         title: '字典管理',
  //         icon: 'Document',
  //         requiresAuth: true,
  //         hidden: false,
  //         keepAlive: true,
  //         new: false,
  //       },
  //     },
  //     {
  //       path: '/sysmanage/menu',
  //       name: 'AdminMenu',
  //       component: componentRouter.AdminMenu,
  //       meta: {
  //         title: '菜单列表',
  //         icon: 'Menu',
  //         requiresAuth: true,
  //         hidden: false,
  //         keepAlive: true,
  //         new: false,
  //       },
  //     },
  //     {
  //       path: '/sysmanage/systemRoleList',
  //       name: 'SystemRoleList',
  //       component: componentRouter.SystemRole,
  //       meta: {
  //         title: '系统角色管理',
  //         icon: 'Document',
  //         requiresAuth: true,
  //         hidden: false,
  //         keepAlive: true,
  //         new: false,
  //       }
  //     },
  //     {
  //       path: '/sysmanage/systemRoleForm',
  //       name: 'SystemRoleForm',
  //       component: componentRouter.SystemRole,
  //       meta: {
  //         title: '系统角色详情',
  //         icon: 'Document',
  //         requiresAuth: true,
  //         hidden: false,
  //         keepAlive: true,
  //         new: false,
  //       }
  //     },
  //     {
  //       path: '/administration/taskManagement/customs',
  //       name: 'customs',
  //       component: componentRouter.Customs,
  //       meta: {
  //         title: '自定义',
  //         icon: 'Document',
  //         requiresAuth: true,
  //         hidden: true,//不显示菜单
  //         highlight: '/administration/taskManagement', // 高亮路径 默认path
  //         keepAlive: true,
  //         new: false,
  //         props: true
  //       }
  //     },
  //     {
  //       path: '/sysmanage/accountsetList',
  //       name: 'AccountsetList',
  //       component: componentRouter.AccountsetList,
  //       meta: {
  //         title: '账套管理',
  //         icon: 'Document',
  //         requiresAuth: true,
  //         hidden: false,
  //         keepAlive: true,
  //         new: false,
  //       }
  //     },
  //     {
  //       path: '/sysmanage/accountsetForm',
  //       name: 'AccountsetForm',
  //       component: componentRouter.AccountsetForm,
  //       meta: {
  //         title: '账套详情',
  //         icon: 'Document',
  //         requiresAuth: true,
  //         hidden: true,
  //         keepAlive: true,
  //         new: false,
  //       }
  //     },
  //     {
  //       path: '/sysmanage/processTemplate',
  //       name: 'ProcessTemplate',
  //       component: componentRouter.ProcessTemplate,
  //       meta: {
  //         title: '流程模板',
  //         icon: 'Document',
  //         requiresAuth: true,
  //         hidden: false,
  //         keepAlive: true,
  //         new: false,
  //       }
  //     },
  //   ]
  // },
  // {
  //   path: '/administration',
  //   name: 'administration',
  //   component: componentRouter.Layout,
  //   meta: {
  //     title: '行政管理',
  //     icon: 'Document',
  //     requiresAuth: true,
  //     hidden: false,
  //     layout: true,
  //     keepAlive: true,
  //     new: false,
  //     abbreviation: '行政',
  //   },
  //   children: [
  //     {
  //       path: '/administration/company',
  //       name: 'company',
  //       component: componentRouter.Company,
  //       meta: {
  //         title: '公司',
  //         icon: 'Document',
  //         requiresAuth: true,
  //         hidden: false,
  //         keepAlive: true,
  //         new: false,
  //       }
  //     },
  //     {
  //       path: '/administration/company/companyDetail',
  //       name: 'companyDetail',
  //       component: componentRouter.companyDetail,
  //       meta: {
  //         title: '公司信息',
  //         icon: 'Document',
  //         requiresAuth: true,
  //         hidden: true,
  //         highlight: '/administration/company', // 高亮路径 默认path
  //         keepAlive: true,
  //         new: false,
  //       }
  //     },
  //     {
  //       path: '/administration/receiveAddr',
  //       name: 'receiveAddr',
  //       component: componentRouter.ReceiveAddr,
  //       meta: {
  //         title: '公司收货地址',
  //         icon: 'Document',
  //         requiresAuth: true,
  //         hidden: true,
  //         highlight: '/administration/company', // 高亮路径 默认path
  //         keepAlive: true,
  //         new: false,
  //       }
  //     },
  //     {
  //       path: '/administration/companyAccount',
  //       name: 'companyAccount',
  //       component: componentRouter.CompanyAccount,
  //       meta: {
  //         title: '公司账号',
  //         icon: 'Document',
  //         requiresAuth: true,
  //         hidden: true,
  //         highlight: '/administration/company', // 高亮路径 默认path
  //         keepAlive: true,
  //         new: false,
  //       }
  //     },
  //     {
  //       path: '/administration/companyHardware',
  //       name: 'companyHardware',
  //       component: componentRouter.CompanyHardware,
  //       meta: {
  //         title: '公司硬件设备',
  //         icon: 'Document',
  //         requiresAuth: true,
  //         hidden: true,
  //         highlight: '/administration/company', // 高亮路径 默认path
  //         keepAlive: true,
  //         new: false,
  //       }
  //     },
  //     {
  //       path: '/administration/divisional',
  //       name: 'divisional',
  //       component: componentRouter.Divisional,
  //       meta: {
  //         title: '部门',
  //         icon: 'Document',
  //         requiresAuth: true,
  //         hidden: false,
  //         keepAlive: true,
  //         new: false,
  //       }
  //     },
  //     {
  //       path: '/administration/newDepartment',
  //       name: 'NewDepartment',
  //       component: componentRouter.NewDepartment,
  //       meta: {
  //         title: '新增部门',
  //         icon: 'Document',
  //         requiresAuth: true,
  //         hidden: true,
  //         keepAlive: true,
  //         highlight: '/administration/divisional',// 高亮路径 默认部门
  //         new: false,
  //       }
  //     },
  //     {
  //       path: '/administration/staff',
  //       name: 'staff',
  //       component: componentRouter.SEmployee,
  //       meta: {
  //         title: '员工',
  //         icon: 'Document',
  //         requiresAuth: true,
  //         hidden: false,
  //         keepAlive: true,
  //         new: false,
  //       }
  //     },
  //     {
  //       path: '/administration/staff/newEmployee',
  //       name: 'NewEmployee',
  //       component: componentRouter.NewEmployee,
  //       meta: {
  //         title: '新增员工',
  //         icon: 'Document',
  //         requiresAuth: true,
  //         hidden: true,
  //         keepAlive: true,
  //         highlight: '/administration/staff',// 高亮路径 默认员工
  //         new: false,
  //       }
  //     },
  //     {
  //       path: '/administration/jobList',
  //       name: 'jobList',
  //       component: componentRouter.JobList,
  //       meta: {
  //         title: '职位',
  //         icon: 'Document',
  //         requiresAuth: true,
  //         hidden: false,
  //         keepAlive: true,
  //         new: false,
  //       }
  //     },
  //     {
  //       path: '/administration/jobForm',
  //       name: 'jobForm',
  //       component: componentRouter.JobForm,
  //       meta: {
  //         title: '职位详情',
  //         icon: 'Document',
  //         requiresAuth: true,
  //         hidden: true,
  //         keepAlive: true
  //       }
  //     },
  //     {
  //       path: '/administration/accountNumber',
  //       name: 'accountNumber',
  //       component: componentRouter.AccountNumber,
  //       meta: {
  //         title: '账号',
  //         icon: 'Document',
  //         requiresAuth: true,
  //         hidden: false,
  //         keepAlive: true,
  //         new: false,
  //       }
  //     },
  //     {
  //       path: '/administration/accountNumber/bindUser',
  //       name: 'bindUser',
  //       component: componentRouter.BindUser,
  //       meta: {
  //         title: '绑定用户',
  //         icon: 'Document',
  //         requiresAuth: true,
  //         hidden: true,//不显示菜单
  //         highlight: '/administration/accountNumber', // 高亮路径 默认path
  //         keepAlive: true,
  //         new: false,
  //       }
  //     },
  //     {
  //       path: '/administration/taskManagement',
  //       name: 'taskManagement',
  //       component: componentRouter.TaskManagement,
  //       meta: {
  //         title: '任务',
  //         icon: 'Document',
  //         requiresAuth: true,
  //         hidden: false,
  //         keepAlive: true,
  //         new: false,
  //       }
  //     },
  //     {
  //       path: '/administration/taskManagement/addTask',
  //       name: 'bindUser',
  //       component: componentRouter.AddTask,
  //       meta: {
  //         title: '新增任务',
  //         icon: 'Document',
  //         requiresAuth: true,
  //         hidden: true,//不显示菜单
  //         highlight: '/administration/taskManagement', // 高亮路径 默认path
  //         keepAlive: true,
  //         new: false,
  //       }
  //     },
  //     {
  //       path: '/administration/UserRoleList',
  //       name: 'UserRoleList',
  //       component: componentRouter.UserRoleList,
  //       meta: {
  //         title: '用户角色',
  //         icon: 'Document',
  //         requiresAuth: true,
  //         hidden: false,
  //         keepAlive: true,
  //         new: false,
  //       }
  //     },
  //     {
  //       path: '/administration/userRoleForm',
  //       name: 'userRoleForm',
  //       component: componentRouter.UserRoleForm,
  //       meta: {
  //         title: '角色详情',
  //         icon: 'Document',
  //         requiresAuth: true,
  //         hidden: true,
  //         keepAlive: true,
  //         new: false,
  //       }
  //     },
  //     {
  //       path: '/administration/askForLeave',
  //       name: 'AskForLeave',
  //       component: componentRouter.AskForLeave,
  //       meta: {
  //         title: '请假',
  //         icon: 'Document',
  //         requiresAuth: true,
  //         hidden: false,
  //         keepAlive: true,
  //         new: false,
  //       }
  //     },
  //     {
  //       path: '/administration/askForLeave/askForLeaveDetails',
  //       name: 'AskForLeaveDetails',
  //       component: componentRouter.AskForLeaveDetails,
  //       meta: {
  //         title: '请假详情',
  //         icon: 'Document',
  //         requiresAuth: true,
  //         hidden: true,
  //         keepAlive: false,
  //         new: false,
  //         highlight: '/administration/askForLeave',// 高亮路径
  //       }
  //     },
  //     {
  //       path: '/administration/processVersion',
  //       name: 'ProcessVersion',
  //       component: componentRouter.ProcessVersion,
  //       meta: {
  //         title: '流程版本管理',
  //         icon: 'Document',
  //         requiresAuth: true,
  //         hidden: false,
  //         keepAlive: true,
  //         new: false,
  //       }
  //     },
  //     {
  //       path: '/administration/processVersion/details',
  //       name: 'ProcessVersionDetails',
  //       component: componentRouter.ProcessVersionDetails,
  //       meta: {
  //         title: '流程版本详情',
  //         icon: 'Document',
  //         requiresAuth: true,
  //         hidden: true,
  //         keepAlive: false,
  //         new: false,
  //       }
  //     },
  //   ]
  // },

  // {
  //   path: '/engineering',
  //   name: 'engineering',
  //   component: componentRouter.Layout,
  //   meta: {
  //     title: '工程管理',
  //     icon: 'User',
  //     requiresAuth: true,
  //     hidden: false,
  //     keepAlive: true,
  //     layout: true,
  //     new: false,
  //     abbreviation: '工程',
  //   },
  //   children: [
  //     {
  //       path: '/engineering/materialRules',
  //       name: 'materialRules',
  //       component: componentRouter.MaterialRules,
  //       meta: {
  //         title: '物料编码规则',
  //         icon: 'Document',
  //         requiresAuth: true,
  //         hidden: false,
  //         keepAlive: true,
  //         new: false,
  //       }
  //     },
  //     {
  //       path: '/engineering/documentCodeRule',
  //       name: 'documentCodeRule',
  //       component: componentRouter.documentCodeRule,
  //       meta: {
  //         title: '单据编码规则',
  //         icon: 'User',
  //         requiresAuth: true,
  //         hidden: false,
  //         keepAlive: true,
  //         new: false,
  //       },
  //     },
  //     {
  //       path: '/demo',
  //       name: '组件页面',
  //       component: componentRouter.NewComponent,
  //       meta: {
  //         title: '组件页面',
  //         icon: 'InfoFilled',
  //         requiresAuth: true,
  //         hidden: false,
  //         keepAlive: true,
  //         new: false,
  //       }
  //     }
  //   ]
  // },
]

// 静态白名单路由列表
export const routesWhite = [
  // {
  //   path: '/404',
  //   name: '404',
  //   component: componentRouter.NotFound,
  //   meta: {
  //     title: '404-NotFound',
  //     requiresAuth: false,
  //     hidden: true,
  //     layout: true
  //   }
  // },
  {
    hide: true,
    path: '/:pathMatch(.*)*',
    component: componentRouter.NotFound,
    meta: {
      title: '404-NotFound',
      requiresAuth: false,
      hidden: true,
      layout: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: componentRouter.LoginLayout,
    meta: {
      title: '登录',
      layout: true,
      requiresAuth: false,
      hidden: true
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: componentRouter.LoginLayout,
    meta: {
      title: '注册',
      layout: true,
      requiresAuth: false,
      hidden: true
    }
  },
  {
    path: '/formLogin',
    name: 'formLogin',
    component: componentRouter.FormLogin,
    meta: {
      title: '跳转',
      layout: true,
      requiresAuth: false,
      hidden: true
    }
  },
  // {
  //   path: '/engineering/substituteTransition/substituteRoles/info',
  //   name: 'SubstituteRolesInfo',
  //   component: componentRouter.SubstituteRolesInfo,
  //   meta: {
  //     title: '替代关系表单',
  //     layout: true,
  //     requiresAuth: false,
  //     hidden: true
  //   }
  // }
]

// 获取动态路由列表
export const routes = routesStatic.concat(routesWhite)
// 获取路由url path地址白名单列表   路由白名单  - 不重定向白名单 ['/404','/login', '/register']
const whiteList = () => {
  let list: any = []
  for (let item of routes) {
    // 也可以转换为大写或小写再保存
    list.push(item.path)
  }
  return list
}

// 静态路由name list
export const whiteNameList = [...routesWhite.map(item => item.name), 'Home']

const myWhiteList = () => {
  let list: any = ['/shelf/shelf', '/shelf/shelflist', '/shelf/shelfstatis']

  return list
}

// 路由在白名单里面
export const existWhite = (path: string) => {
  if (whiteList().indexOf(path) !== -1 || myWhiteList().indexOf(path) !== -1) {
    return true
  } else return false
}
