/* eslint-disable @typescript-eslint/no-explicit-any */

import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import { apiType } from 'types/commonresource'

const url = HUrl.BASE_PREFIX_SALE // 路径前缀 + 微服务
// 获取表格分页数据
export const getPageList = async (params: any) => {
  let data: any
  await http.post(url + '/salesOrder/queryPageList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取单个详情
export const getInfo = async (params: any) => {
  let data: any
  await http.post(url + '/salesOrder/querySingle', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 保存草稿
export const saveDraft = async (params: any) => {
  let data: any
  await http.post(url + '/salesOrder/save', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 提交
export const submit = async (params: any) => {
  let data: any
  await http.post(url + '/salesOrder/submit', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 删除
export const del = async (params: any) => {
  let data: any
  await http.delPost(url + '/salesOrder/deleteList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导入
export const importFiles = async (params: any) => {
  let data: any
  await http.postFiles(url + '/salesOrder/import', {}, params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出
export const exportFiles = async (params?: any) => {
  let data: any
  await http.getFilesExt(url + '/salesOrder/export', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出模板
export const exportFilesTmpl = async (params?: any) => {
  let data: any
  await http.getFiles(url + '/salesOrder/template', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 获取客户信息
export const CustomerPageList = async (params?: any) => {
  let data = {} as apiType
  await http.post(url + '/Customer/CustomerPageList', params).then((result: apiType) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 获取客户信息--商务信息
// export const CustomerInfo = async (params?: any) => {
//   let data = {} as apiType
//   await http.post(url + '/Customer/CustomerInfo', params).then((result: apiType) => {
//     data = result
//   }).catch((err) => {
//     return http.catch(err)
//   })
//   return data
// }
// 根据名称或者编码查询剩余客户信息
export const queryCustomer = async (params?: any) => {
  let data: any
  await http.post(url + '/Customer/queryCustomer', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 通过币别获取汇率
export const queryExchangeRate = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_INIT_START_RESOUCE + '/exchangeRate/queryExchangeRate', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 审批流程——反审
export const counterReview = async (params: any) => {
  const data = await http.post(url + '/salesOrder/counterReview', params).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 审批流程——撤回
export const revoke = async (params: any) => {
  const data = await http.post(url + '/salesOrder/revoke', params).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 审批流程——取消
export const cancel = async (params: any) => {
  const data = await http.post(url + '/salesOrder/cancel', params)
  return data
}

// 审批流程——完成审批
export const complete = async (params: any) => {
  const data = await http.post(url + '/salesOrder/complete', params)
  return data
}

// 审批流程——驳回
export const reject = async (params: any) => {
  const data = await http.post(url + '/salesOrder/reject', params)
  return data
}

// 销售-产品描述
export const deleteListDesc = async (params?: any) => {
  let data: any
  await http.delPost(url + '/orderConfig/deleteList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 销售-产品描述
export const queryListDesc = async (params?: any) => {
  let data: any
  await http.post(url + '/orderConfig/queryList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 销售-产品描述
export const queryPageListDesc = async (params?: any) => {
  let data: any
  await http.post(url + '/orderConfig/queryPageList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 销售-产品描述
export const querySingleDesc = async (params?: any) => {
  let data: any
  await http.post(url + '/orderConfig/querySingle', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 销售-产品描述
export const saveDesc = async (params?: any) => {
  let data: any
  await http.post(url + '/orderConfig/save', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导入
export const importFilesDesc = async (params: any) => {
  let data: any
  await http.postFiles(url + '/orderConfig/import', {}, params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出
export const exportFilesDesc = async (params?: any) => {
  let data: any
  await http.javaDl(url + '/orderConfig/export', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出模板
export const exportFilesTmplDesc = async (params?: any) => {
  let data: any
  await http.getFiles(url + '/orderConfig/template', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
