/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from '@/service'
export const searchV2 = async (params?: any) => {
  let data: any
  await http.get('t/map/v2/search', params).then((result: any) => {
    console.log('result', result)
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
