// plugins
import { computed } from 'vue'

// component
import basicInfoHooks from '@/views/business/production/completeSuit/bomCompleteSuit/hooks/basicInfo'
import bomTableHooks from '@/views/business/production/completeSuit/bomCompleteSuit/hooks/bomTableJson'

// service
import { getInfo, itemQueryPageList } from '@/service/modules/production/completeSuitBom'

const formJson = () => {
  // 表单信息
  const { basicFormJson,
    fewMaterialFormJson } = basicInfoHooks()
  // BOM明细
  const { columnList: bomColumnList } = bomTableHooks()

  const formRes = computed(() => ({
    ...basicFormJson,
    ...fewMaterialFormJson
  }))

  return [
    {
      title: { lank: 'production.basicInfo', default: '基本信息' },
      type: 'form',
      json: formRes.value,
      getInfo
    }, {
      title: {
        lank: 'produciton.bomDetailed',
        default: 'BOM明细'
      },
      type: 'table',
      formid: 'id',
      id: 'id',
      columnList: bomColumnList,
      getInfo: itemQueryPageList
    }

  ]
}

export default formJson
