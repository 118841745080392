// 公司接口
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
const url = HUrl.BASE_PREFIX_INIT_ADMIN

// 审批流程——反审
export const counterReview = async (params?: any) => {
  let data: any
  await http.post(url + '/company/counterReview', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 审批流程——撤回
export const revoke = async (params?: any) => {
  let data: any
  await http.post(url + '/company/revoke', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 保存公司草稿信息
export const CompanySaveDraft = async (params: any) => {
  let data: any
  await http.post(url + '/company/save', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 保存公司草稿信息
// export const CompanySaveDraft = async (params: any) => {
//   let data: any
//   await http.post(HUrl.COMPANY_SAVE_DRAFT, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }
// 提交公司基础信息
export const CompanySubmit = async (params: any) => {
  let data: any
  await http.post(url + '/company/submit', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 提交公司基础信息
// export const CompanySubmit = async (params: any) => {
//   let data: any
//   await http.post(HUrl.COMPANY_SUBMIT, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

// 查询公司列表数据
export const getCompanyData = async (params: any) => {
  let data: any
  await http.post(url + '/company/queryPageList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// export const getCompanyData = async (params: any) => {
//   let data: any
//   await http.post(HUrl.COMPANY_DATA, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }
// 查询公司列表数据-结构树
export const getCompanyTree = async (params: any) => {
  let data: any
  await http.post(url + '/company/queryTree', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 新增公司列表数据
// export const addCompanyData = async (params: any) => {
//   let data: any
//   await http.addPost(HUrl.COMPANY_DATA_ADD, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }
// 编辑公司列表数据
// export const editCompanyData = async (params: any) => {
//   let data: any
//   await http.post(HUrl.COMPANY_DATA_EDIT, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }
// 删除公司列表数据
export const delCompanyData = async (params: any) => {
  let data: any
  // await http.delPost(HUrl.COMPANY_DATA_DEL, params).then((result: any) => {
  await http.delPost(url + '/company/deleteList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询公司基础信息
export const getCompanyDetail = async (params: any) => {
  if (params.id) {
    params.coid = params.id
  }
  let data: any
  await http.post(url + '/company/querySingle', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询公司基础信息
// export const getCompanyDetail = async (params: any) => {
//   let data: any
//   await http.post(HUrl.COMPANY_DATA_DETAIL, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

// 查询公司下拉组件数据
export const getCompanyDataSelect = async (params? : any) => {
  let data: any
  // await http.post(HUrl.COMPANY_DATA_SELECT, params).then((result : any) => {
  await http.post(url + '/company/queryList', params).then((result : any) => {
    let arr = result.data
    for (let i = 0; i < arr.length; i++) {
      const element = arr[i]
      const obj = {
        value: element.comn,
        txt: element.comn,
        val: element.coid,
        key: element.coid
      }
      Object.assign(element, obj)
    }
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询单个公司收货地址信息
// export const getReceiveDetail = async (params: any) => {
//   let data: any
//   await http.post(HUrl.COMPANY_DATA_RECEIVE_DETAIL, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

// 查询公司收货地址分页列表
export const getReceiveAddrList = async (params: any) => {
  let data: any
  // await http.post(HUrl.COMPANY_DATA_RECEIVE_ADDR, params).then((result: any) => {
  await http.post(url + '/company/receaddr/queryPageList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 新增公司收货地址信息
// export const addReceiveAddr = async (params: any) => {
//   let data: any
//   await http.addPost(HUrl.COMPANY_DATA_RECEIVE_ADD, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

// 修改公司收货地址信息
export const editReceiveAddr = async (params: any) => {
  let data: any
  // await http.editPost(HUrl.COMPANY_DATA_RECEIVE_EDIT, params).then((result: any) => {
  await http.editPost(url + '/company/receaddr/save', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 删除公司收货地址信息
export const delReceiveAddr = async (params: any) => {
  let data: any
  // await http.delPost(HUrl.COMPANY_DATA_RECEIVE_DEL, params).then((result: any) => {
  await http.delPost(url + '/company/receaddr/deleteList', {ids:params}).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 公司收货地址设置默认状态
// export const setReceiveAddrState = async (params: any) => {
//   let data: any
//   await http.post(HUrl.COMPANY_DATA_RECEIVE_ADDR_SET_STATE, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

// 查询公司账号信息
// export const getAccountInfo = async (params: any) => {
//   let data: any
//   await http.post(HUrl.COMPANY_DATA_ACCOUNT_DETAIL, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

// 查询公司账号分页列表
export const getAccountPage = async (params: any) => {
  if (params.companyId) {
    if (params.data) {
      params.data.coid = params.companyId
    } else {
      params.data = { coid: params.companyId }
    }
  }
  let data: any
  // await http.post(HUrl.COMPANY_DATA_ACCOUNT, params).then((result: any) => {
  await http.post(url + '/company/acc/queryPageList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 新增公司账号信息
export const saveAccount = async (params: any) => {
  let data: any
  // await http.post(HUrl.COMPANY_DATA_ACCOUNT_SAVE, params).then((result: any) => {
  await http.post(url + '/company/acc/save', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 修改公司账号信息
// export const editAccount = async (params: any) => {
//   let data: any
//   await http.post(HUrl.COMPANY_DATA_ACCOUNT_EDIT, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

// 删除公司账号信息
export const delAccount = async (params: any) => {
  let data: any
  // await http.delPost(HUrl.COMPANY_DATA_ACCOUNT_DEL, params).then((result: any) => {
  await http.delPost(url + '/company/acc/deleteList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 公司账号设置状态
// export const setAccountState = async (params: any) => {
//   let data: any
//   await http.post(HUrl.COMPANY_DATA_ACCOUNT_SET_STATE, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

// 弹窗
// 查询账号类型
// export const getAccountTypeInfo = async (params: any) => {
//   let data: any
//   await http.post(HUrl.COMPANY_ACCOUNT_TYPE_INFO, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }
// 获取账号类型下拉组件数据
// export const getAccountTypeSelect = async (params: any) => {
//   let data: any
//   await http.post(HUrl.COMPANY_ACCOUNT_TYPE_SELECT, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }
// 新增账号类型信息
// export const handleAccountTypeSave = async (params: any) => {
//   let data: any
//   await http.addPost(HUrl.COMPANY_ACCOUNT_TYPE_SAVE, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }
// 修改账号类型信息
// export const handleAccountTypeEdit = async (params: any) => {
//   let data: any
//   await http.editPost(HUrl.COMPANY_ACCOUNT_TYPE_EDIT, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }
// 删除账号类型信息
// export const handleAccountTypeDel = async (params: any) => {
//   let data: any
//   await http.delPost(HUrl.COMPANY_ACCOUNT_TYPE_DEL, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

// List-公司账号类型分页列表
// export const getAccountCategoryConfigList = async (params: any) => {
//   let data: any
//   await http.post(HUrl.COMPANY_ACCOUNT_TYPE_CONFIG_LIST, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

// 弹窗

// 查询硬件类型
// export const getHardwareTypeInfo = async (params: any) => {
//   let data: any
//   await http.post(HUrl.COMPANY_HARDWARE_TYPE_INFO, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }
// 获取硬件类型下拉组件数据
// export const getHardwareTypeSelect = async (params: any) => {
//   let data: any
//   await http.post(HUrl.COMPANY_HARDWARE_TYPE_SELECT, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }
// 新增硬件类型信息
// export const handleHardwareTypeSave = async (params: any) => {
//   let data: any
//   await http.addPost(HUrl.COMPANY_HARDWARE_TYPE_SAVE, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }
// 修改硬件类型信息
// export const handleHardwareTypeEdit = async (params: any) => {
//   let data: any
//   await http.editPost(HUrl.COMPANY_HARDWARE_TYPE_EDIT, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }
// 删除硬件类型信息
// export const handleHardwareTypeDel = async (params: any) => {
//   let data: any
//   await http.editPost(HUrl.COMPANY_HARDWARE_TYPE_DEL, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

// List-公司硬件类型分页列表
// export const getHardwareCategoryConfigList = async (params: any) => {
//   let data: any
//   await http.post(HUrl.COMPANY_HARDWARE_TYPE_CONFIG_LIST, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

// 获取硬件名称下拉组件数据
// export const getHardwareNameSelect = async (params: any) => {
//   let data: any
//   await http.get(HUrl.COMPANY_HARDWARE_NAME_SELECT, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

// 新增公司硬件名称信息
// export const handleHardwareNameAdd = async (params: any) => {
//   let data: any
//   await http.post(HUrl.COMPANY_HARDWARE_NAME_ADD, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }
// 修改公司硬件名称信息
// export const handleHardwareNameEdit = async (params: any) => {
//   let data: any
//   await http.post(HUrl.COMPANY_HARDWARE_NAME_EDIT, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }
// 删除公司硬件名称信息
// export const handleHardwareNameDel = async (params: any) => {
//   let data: any
//   await http.post(HUrl.COMPANY_HARDWARE_NAME_DEL, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }
// List-公司硬件名称分页列表
// export const getHardwareNameConfigList = async (params: any) => {
//   let data: any
//   await http.post(HUrl.COMPANY_HARDWARE_NAME_CONFIG_LIST, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

// 查询公司硬件设备
// export const getEquipmentInfo = async (params: any) => {
//   let data: any
//   await http.post(HUrl.COMPANY_EQUIPMENT_DETAIL, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

// 查询公司硬件设备分页列表
export const getEquipmentPage = async (params: any) => {
  let data: any
  // await http.post(HUrl.COMPANY_EQUIPMENT_LIST, params).then((result: any) => {
  await http.post(url + '/company/equi/queryPageList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 新增公司硬件设备信息
// export const addEquipment = async (params: any) => {
//   let data: any
//   await http.addPost(HUrl.COMPANY_EQUIPMENT_ADD, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

// 修改公司硬件设备信息
export const editEquipment = async (params: any) => {
  let data: any
  // await http.editPost(HUrl.COMPANY_EQUIPMENT_EDIT, params).then((result: any) => {
  await http.editPost(url + '/company/equi/save', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 删除公司硬件设备信息
export const delEquipment = async (params: any) => {
  let data: any
  // await http.delPost(HUrl.COMPANY_EQUIPMENT_DEL, params).then((result: any) => {
  await http.delPost(url + '/company/equi/deleteList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 下载公司导入模板
export const downloadCompanyTemp = async (params?: any) => {
  let data: any
  await http.getFiles(url + '/company/export/template', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// export const downloadCompanyTemp = async () => {
//   let data: any
//   await http.getFiles(HUrl.COMPANY_DOWNLOADCOMPANY_TEMP).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

// 导入公司信息
export const importCompanyInfo = async (params: any) => {
  let data: any
  // await http.postFiles(HUrl.COMPANY_IMPORT_COMPANY_INFO, {}, params).then((result: any) => {
  await http.postFiles(url + '/company/import', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 导出公司信息
export const exportCompanyInfo = async (params?: any) => {
  let data: any
  await http.javaDl(url + '/company/export', params).then((result: any) => {
  // await http.getFilesExt(HUrl.COMPANY_EXPORT_COMPANY_INFO, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 下载公司收货地址导入模板
export const downloadReceAddrTemp = async () => {
  let data: any
  // await http.getFiles(HUrl.COMPANY_DOWNLOAD_RECEADDR_TEMP).then((result: any) => {
  await http.getFiles(url + '/company/receaddr/export/template').then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 导入公司收货地址信息
export const importReceAddrInfo = async (params: any) => {
  let data: any
  // await http.postFiles(HUrl.COMPANY_IMPORT_RECEADDR_INFO, {}, params).then((result: any) => {
  await http.postFiles(url + '/company/receaddr/import', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 导出公司收货地址信息
export const exportReceAddrInfo = async (params?: any) => {
  let data: any
  await http.javaDl(url + '/company/receaddr/export', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 下载公司账号导入模板
export const downloadAccountTemp = async (params?: any) => {
  let data: any
  // await http.getFiles(HUrl.COMPANY_DOWNLOAD_ACCOUNT_TEMP).then((result: any) => {
  await http.getFiles(url + '/company/acc/export/template', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 导入公司账号信息
export const importAccountInfo = async (params: any) => {
  let data: any
  // await http.postFiles(HUrl.COMPANY_IMPORT_ACCOUNT_INFO, {}, params).then((result: any) => {
  await http.postFiles(url + '/company/acc/import', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 导出公司账号信息
export const exportAccountInfo = async (params?: any) => {
  let data: any
  // await http.getFilesExt(HUrl.COMPANY_EXPORT_ACCOUNT_INFO, params).then((result: any) => {
  await http.javaDl(url + '/company/acc/export', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 下载公司硬件模板
export const downloadHardwareTemp = async (params?: any) => {
  let data: any
  // await http.getFiles(HUrl.COMPANY_DOWNLOAD_HARDWARE_TEMP).then((result: any) => {
  await http.getFiles(url + '/company/equi/export/template', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 导入公司硬件信息
export const importHardwareInfo = async (params: any) => {
  let data: any
  // await http.postFiles(HUrl.COMPANY_IMPORT_HARDWARE_INFO, {}, params).then((result: any) => {
  await http.postFiles(url + '/company/equi/import', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 导出公司硬件信息
export const exportHardwareInfo = async (params?: any) => {
  let data: any
  // await http.getFilesExt(HUrl.COMPANY_EXPORT_HARDWARE_INFO, params).then((result: any) => {
  await http.javaDl(url + '/company/equi/export', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 账号类型弹窗
// 下载公司账号类型导入模板
// export const downloadAccountTypeTemp = async () => {
//   let data: any
//   await http.getFiles(HUrl.COMPANY_DOWNLOAD_ACCOUNT_TYPE_TEMP).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

// 导入公司账号类型信息
// export const importAccountTypeInfo = async (params: any) => {
//   let data: any
//   await http.postFiles(HUrl.COMPANY_IMPORT_ACCOUNT_TYPE_INFO, {}, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

// 导出公司账号类型信息
// export const exportAccountTypeInfo = async (params: any) => {
//   let data: any
//   await http.getFilesExt(HUrl.COMPANY_EXPORT_ACCOUNT_TYPE_INFO, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

// 硬件类型弹窗
// 下载公司硬件类型导入模板
// export const downloadHardwareTypeTemp = async () => {
//   let data: any
//   await http.getFiles(HUrl.COMPANY_DOWNLOAD_HARDWARE_TYPE_TEMP).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

// 导入公司硬件类型信息
// export const importHardwareTypeInfo = async (params: any) => {
//   let data: any
//   await http.postFiles(HUrl.COMPANY_IMPORT_HARDWARE_TYPE_INFO, {}, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

// 导出公司硬件类型信息
// export const exportHardwareTypeInfo = async (params: any) => {
//   let data: any
//   await http.getFilesExt(HUrl.COMPANY_EXPORT_HARDWARE_TYPE_INFO, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

// 硬件名称弹窗
// 下载公司硬件名称导入模板
// export const downloadHardwareNameTemp = async () => {
//   let data: any
//   await http.getFiles(HUrl.COMPANY_DOWNLOAD_HARDWARE_NAME_TEMP).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

// 导入公司硬件名称信息
// export const importHardwareNameInfo = async (params: any) => {
//   let data: any
//   await http.postFiles(HUrl.COMPANY_IMPORT_HARDWARE_NAME_INFO, {}, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

// 导出公司硬件名称信息
// export const exportHardwareNameInfo = async (params: any) => {
//   let data: any
//   await http.getFilesExt(HUrl.COMPANY_EXPORT_HARDWARE_NAME_INFO, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }
export const UploadFile = async (params: any, queryString: any) => {
  let data: any
  console.log(params, HUrl.UPLOAD_FILE + queryString)

  await http.post(HUrl.UPLOAD_FILE + queryString, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 文档列表信息
// export const getDocListInfo = async (params: any) => {
//   let data: any
//   await http.get(HUrl.GET_DOCUMENT_LIST_INFO, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

// 账号下拉数据
// export const selectsEquipment = async (params : any) => {
//   let data: any
//   await http.get(HUrl.COMPANY_EQUIPMENT_SELECTS, params).then((result : any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }
