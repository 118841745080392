/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * 封装登录/登出/验证码/token等
 * @author jihua.huang
 * @date 2022-03-09
 */
// 引入文件
import { http } from '@/service' // 引入网络请求http
import { G, HUrl } from '@/config' // 引入http请求URL地址
// 导入 store的存储模块
import { settingStore, pullDownData } from '@/store'
// 导入cache模块
import { cache, comm, tagsview } from '@/utils'
import { apiType } from 'types/commonresource'

/**
 * 账号登录
 * @param params object
 * @returns json object
 */
export const login = async (params: any) => {
  // 获取setting store
  const settStore = settingStore()

  let url = HUrl.LOGIN_URL

  let LoginMac = comm.guid().replaceAll('-', '')
  params.LoginMac = LoginMac

  // LocalStorage原始也保存一份
  cache.setLocalStorage(G.AUTHORIZATION_LOGINMAC, LoginMac)
  return new Promise((resolve: (value: any) => void, reject) => {
    http
      .loginPost(url, params)
      .then(async (result: any) => {
      // 只处理code = 0的数据
        if (result.code === 1) {
          // console.log(result)
          // type:0 为ping 心跳，不需处理，WS数据
          if (result.type !== 1) {
          // 设置token，并写入到store中
            let token = result.data.token || ''

            const newToken = {
              time: params.CachePeriod,
              date: new Date(),
              token
            }

            // Pinia 保存一份
            settStore.setToken(token)

            // LocalStorage原始也保存一份
            cache.setLocalStorage(G.AUTHORIZATION_TOKEN, JSON.stringify(newToken))
          }

          // 把重新登录弹窗是否弹出设置为true
          settStore.setIsReLogin(true)

          // 设置用户基本信息
          let userInfo = result.data
          if (userInfo) {
            cache.setLocalStorage('userInfo', JSON.stringify(userInfo))
            settStore.setUserInfo(userInfo)
          }
        }
        resolve(result)
      })
      .catch((err: any) => {
        reject(err)
      })
  })
}

/**
 * 用户登出（需要清理相关的数据和缓存，已经初始化某些数据）
 * @param params object
 * @returns json object
 */
export const logout = async (params?: any) => {
  let data: any
  const settStore = settingStore()
  const pullDownStore = pullDownData()

  await http
    .post(HUrl.LOGOUT_URL, params)
    .then((result: any) => {
      // 退出登录要清理相关的localstrage和session缓存
      // 清理session
      // 清除localStorage所有的值
      cache.setLocalStorage('token', '')
      cache.setLocalStorage('userInfo', '')
      cache.setLocalStorage('loginCMC', '')
      // cache.setLocalStorage('menu', '')
      // cache.clearLocalStorage()
      // cache.clearSessionStorage()
      settStore.setUserInfo({})
      settStore.setToken('')
      settStore.setMenuList([]) // 清空菜单
      pullDownStore.setState(false)
      tagsview.wipebreadcrumb()
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

/**
 * 修改密码
 * @param params object
 * @returns json object
 */
export const updateCypher = async (params: any) => {
  let data: any
  await http.post(HUrl.UPDATE_CYPHER, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// token登录接口
export const loginForm = async (params : any) => {
  let data: any
  await http.post(HUrl.LOGIN_FORM, params).then((result : any) => {
    const settStore = settingStore()
    let LoginMac = params.Cmc
    params.LoginMac = LoginMac
    if (result.type !== 1) {
      // 设置token，并写入到store中
      let token = result.data.token || ''

      const newToken = {
        time: params?.CachePeriod || 1,
        date: new Date(),
        token
      }

      // Pinia 保存一份
      settStore.setToken(token)

      // LocalStorage原始也保存一份
      cache.setLocalStorage(G.AUTHORIZATION_TOKEN, JSON.stringify(newToken))

      // LocalStorage原始也保存一份
      cache.setLocalStorage(G.AUTHORIZATION_LOGINMAC, LoginMac)
      // 设置用户基本信息
      let userInfo = result.data

      if (userInfo) {
        cache.setLocalStorage('userInfo', JSON.stringify(userInfo))
        settStore.setUserInfo(userInfo)
      }
    }

    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

export const cefSharpTohome = (data: any) => {
  return new Promise((resolve: ((value: boolean) => void), reject) => {
    try {
      const settStore = settingStore()

      // 设置token，并写入到store中
      let token = data.token || ''

      const time = data.tKPeriod / 24
      const newToken = {
        time,
        date: new Date(),
        token
      }
      cache.delLocalStorage('colList') // 登录成功后删除本地存储的表单和表格数据

      // Pinia 保存一份
      settStore.setToken(token)
      // 把重新登录弹窗是否弹出设置为true
      settStore.setIsReLogin(true)
      // LocalStorage原始也保存一份
      cache.setLocalStorage(G.AUTHORIZATION_TOKEN, JSON.stringify(newToken))

      // LocalStorage原始也保存一份
      cache.setLocalStorage(G.AUTHORIZATION_LOGINMAC, data.loginMac)
      cache.setLocalStorage('userInfo', JSON.stringify(data))
      settStore.setUserInfo(data)
      resolve(true)
    } catch (error) {
      reject(false)
    }
  })
}

// 获取侧边栏菜单数据
export const getMenuListData = async () => {
  let data: any
  await http.post(HUrl.ADMIN_MENU_LIST_GET)
    .then((result: any) => {
      // 处理菜单数据
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 获取表单table数据集合
export const getColumnList = async (params:any) => {
  let data = {} as apiType
  await http.post(HUrl.BASE_PREFIX_SYS + '/Table/PowerViewColumnList', params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
// 获取表单form数据集合
// export const getFormColList = async (params:any) => {
//   let data: any
//   await http.post(HUrl.GETFORMCOLLIST_SYS, params)
//     .then((result: any) => {
//       data = result
//     })
//     .catch((err: any) => {
//       return http.catch(err)
//     })
//   return data
// }
// 查询资源版本
export const querySystemVersion = async (params:any) => {
  let data: any
  await http.post(HUrl.QUERYsYSTEMVERSION, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
// 获取国际化
export const GetSourceByKey = async (params:any) => {
  let data: any
  await http.post(HUrl.GETSOURCEBYKEY, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
