/**
 * 接口请求地址配置信息
 * @author jihua.huang
 * @since 2022-03-19
 */
// 引入基本配置文件base.js
// eslint-disable-next-line @typescript-eslint/no-var-requires
const BASE = require('./base.ts')

// HTTP RESTFUL根地址
// api代理
export const HTTP_BASE_URL = BASE.PROXY_ROOT
// wareh代理
export const HTTP_BASE_URL_JAVA = BASE.PROXY_ROOT_JAVA
// java非简写字段接口
export const HTTP_BASE_URL_JAVACHANGE = BASE.PROXY_ROOT_JAVACHANGE

/** 前缀地址 用户*/
export const BASE_PREFIX_USER = HTTP_BASE_URL + '/UserManage'

/** 前缀地址 采购*/
export const BASE_PREFIX_PURCHASE = HTTP_BASE_URL + '/Purchase'

/** 前缀地址 java采购*/
export const BASE_PREFIX_PURCHASE_V = HTTP_BASE_URL + '/pur'

/** 前缀地址 系统*/
export const BASE_PREFIX_SYS = HTTP_BASE_URL + '/SysManage'

/** 前缀地址 货架*/
export const BASE_PREFIX_WAERH_SHELF = HTTP_BASE_URL + '/Warehouse'

/** 前缀地址 仓库*/
export const BASE_PREFIX_WAERHOUSE = HTTP_BASE_URL_JAVA

/** 前缀地址 销售*/
export const BASE_PREFIX_SSALE = HTTP_BASE_URL + '/SaleManage'

/** 前缀地址 项目*/
export const BASE_PREFIX_PROJECT = HTTP_BASE_URL + '/project'

/** 前缀地址  java 销售*/
export const BASE_PREFIX_SALE = HTTP_BASE_URL + '/sale'

/** 前缀地址  java 财务*/
export const BASE_PREFIX_FINANCE = HTTP_BASE_URL + '/finance'

/** 前缀地址 java 系统*/
export const BASE_PREFIX_INIT_START = HTTP_BASE_URL + '/system'
/** 前缀地址 java 系统版本*/
export const BASE_PREFIX_INIT_START_RESOUCE = HTTP_BASE_URL + '/systemconfig'
/** 前缀地址 java 行政*/
export const BASE_PREFIX_INIT_ADMIN = HTTP_BASE_URL + '/admin'
/** 前缀地址 java 系统版本*/
export const BASE_PREFIX_INIT_WORKFLOW = HTTP_BASE_URL + '/workflow'
/** 前缀地址 java 系统版本*/
export const BASE_PREFIX_SCHEDULER = HTTP_BASE_URL + '/scheduler'
/** 前缀地址 java 代码生成*/
export const BASE_PREFIX_GENERATE = HTTP_BASE_URL + '/generate'
/** 前缀地址 java 系统*/
export const BASE_PRODUCTION = HTTP_BASE_URL + '/production'

// 仓库非简写字段接口
export const BASE_PREFIX_WAERHOUSE_CHANGE = HTTP_BASE_URL_JAVACHANGE + '/warehChange'
// 收货管理非简写字段接口
export const BASE_PREFIX_WAREHINTRANSIT_CHANGE = HTTP_BASE_URL_JAVACHANGE + '/warehIntransit'
// 收货管理非简写字段接口
export const BASE_PREFIX_WAREHQUALITYCONTROL = HTTP_BASE_URL_JAVACHANGE + '/warehQualityControl'
// 成品质检
export const BASE_PREFIX_FINISHEDWAREHQUALITYCONTROL = HTTP_BASE_URL_JAVACHANGE + '/finishedWarehQualityControl'
// 成品入库
export const BASE_PREFIX_FINISHEDWAREHOUSING = HTTP_BASE_URL_JAVACHANGE + '/finishedWarehousing'
// 收货管理非简写字段接口
export const BASE_PREFIX_MATERIALLABEL = HTTP_BASE_URL_JAVACHANGE + '/materialLabel'
// 入库管理非简写字段接口
export const BASE_PREFIX_WAREHOUSING = HTTP_BASE_URL_JAVACHANGE + '/warehousing'
export const BASE_PREFIX_SHELF_PLATE_MODEL = HTTP_BASE_URL_JAVACHANGE + '/shelfPlateModel'
// 出库管理非简写字段接口
export const BASE_PREFIX_WAREHOUTAWAIT = HTTP_BASE_URL_JAVACHANGE + '/warehOutAwait'
// 出库管理非简写字段接口
export const BASE_PREFIX_FINISHEDWAREHOUTAWAIT = HTTP_BASE_URL_JAVACHANGE + '/finishedWarehOutAwait'
// 库存管理非简写字段接口
export const BASE_PREFIX_INVENTORY = HTTP_BASE_URL_JAVACHANGE + '/inventory'
/** 前缀地址 行政管理*/
export const BASE_PREFIX_COMPANY = HTTP_BASE_URL + '/Administration'

/** 前缀地址 java 行政管理*/
export const BASE_PREFIX_JAVA_COMPANY = HTTP_BASE_URL_JAVACHANGE

/** 前缀地址 流程管理*/
export const BASE_PREFIX_FLOW = HTTP_BASE_URL + '/FlowManage'

/** 前缀地址 文件管理*/
export const BASE_FILE_MANAGE = HTTP_BASE_URL + '/FileManage'

// export const BASE_PREFIX_DICTIONARY = HTTP_BASE_URL + '/FileManage'

/** 账号登录提交数据接口 */
export const LOGIN_URL = BASE_PREFIX_USER + '/Authentication/Login'

/** 用户登出提交数据接口 */
export const LOGOUT_URL = BASE_PREFIX_USER + '/Authentication/Logout'

/** 用户修改密码数据接口 */
export const UPDATE_CYPHER = BASE_PREFIX_USER + '/UserAccount/UpdateCypher'
// token登录接口
export const LOGIN_FORM = BASE_PREFIX_USER + '/Authentication/LoginForm'

/** 管理员管理提交数据接口 */
export const ADMIN_URL = BASE_PREFIX_USER + '/admin/user'

/** 广告橱窗数据接口 */
export const BANNER_URL = BASE_PREFIX_USER + '/banner/index'

/** 管理员操作日志数据接口 */
export const LOG_ADMIN_OPR_URL = BASE_PREFIX_USER + '/log/adminopr'

/** ==============省市区====================== */
export const GET_CONTROLLER_DATA = BASE_PREFIX_SYS + '/District/DistrictDataList'

/** ==============菜单设置====================== */
/** 管理员菜单列表数据接口 */
export const ADMIN_MENU_LIST = BASE_PREFIX_SYS + '/Menu/DataList'
/** 修改菜单  */
export const ADMIN_MENU_EDIT = BASE_PREFIX_SYS + '/Menu/Edit'
// 获取侧边栏菜单
export const ADMIN_MENU_LIST_GET = BASE_PREFIX_SYS + '/Menu/Menus'
// 角色授权菜单树
export const ADMIN_TREEDATA = BASE_PREFIX_SYS + '/Menu/TreeData'
// 全部菜单树
export const ADMIN_AUTH_TREE = BASE_PREFIX_SYS + '/Menu/AuthTree'
/** 查询单个菜单信息  */
export const ADMIN_MENU_LIST_DETAIL = BASE_PREFIX_SYS + '/Menu/Info'

/** ==============资源设置菜单====================== */
export const BASE_ADMIN_MENU_LIST = BASE_PREFIX_SYS + '/MenuBase/DataList'
/** 查询单个菜单信息  */
export const BASE_ADMIN_MENU_DETAIL = BASE_PREFIX_SYS + '/MenuBase/Info'
/** 新增菜单  */
export const BASE_ADMIN_MENU_SAVE = BASE_PREFIX_SYS + '/MenuBase/Add'
/** 修改菜单  */
export const BASE_ADMIN_MENU_EDIT = BASE_PREFIX_SYS + '/MenuBase/Edit'
/** 删除菜单  */
export const BASE_ADMIN_MENU_DEL = BASE_PREFIX_SYS + '/MenuBase/Del'
// // 角色授权菜单树
// export const BASE_ADMIN_TREEDATA = BASE_PREFIX_SYS + '/MenuBase/TreeData'
// // 角色全部授权菜单树
// export const BASE_ADMIN_AUTH_TREE = BASE_PREFIX_SYS + '/MenuBase/AuthTree'

// 获取权限
export const ADMIN_GET_POWER = BASE_PREFIX_USER + '/Authentication/GetPower'

/** ==============服务器管理接口====================== */
// 分页查询
export const DBSERVER_PAGE = BASE_PREFIX_SYS + '/DBServer/PageList'
// 查询详情
export const DBSERVER_INFO = BASE_PREFIX_SYS + '/DBServer/GetInfo'
// 新增服务器
export const DBSERVER_SAVE = BASE_PREFIX_SYS + '/DBServer/Save'
// 修改服务器
export const DBSERVER_EDIT = BASE_PREFIX_SYS + '/DBServer/Edit'
// 删除服务器
export const DBSERVER_DEL = BASE_PREFIX_SYS + '/DBServer/Del'
/** 配置资源 */
export const TABLE_SAVECOLUMN = BASE_PREFIX_SYS + '/TableBase/AddColumn'
export const TABLE_DELCOLUMN = BASE_PREFIX_SYS + '/TableBase/DelColumn'
export const TABLE_EDITCOLUMN = BASE_PREFIX_SYS + '/TableBase/EditColumn'
export const TABLE_GETINFO = BASE_PREFIX_SYS + '/TableBase/ColumnInfo'
export const TABLE_GETCOLUMNPAGE = BASE_PREFIX_SYS + '/TableBase/ColumnPageList'

export const TABLE_DOWNLOAD_COLUMN_TEMP = BASE_PREFIX_SYS + '/TableBase/DownloadColumnTemp'
export const TABLE_IMPORT_COLUMN = BASE_PREFIX_SYS + '/TableBase/ImportColumn'

/** 映射资源 */
export const TABLE_SAVE_RESOURCES = BASE_PREFIX_SYS + '/TableBase/SaveResources'
export const TABLE_DEL_RESOURCES = BASE_PREFIX_SYS + '/TableBase/DelResources'
export const TABLE_EDIT_RESOURCES = BASE_PREFIX_SYS + '/TableBase/EditResources'
export const TABLE_GET_RESOURCES_INFO = BASE_PREFIX_SYS + '/TableBase/GetResourcesInfo'
export const TABLE_GET_RESOURCESLIST = BASE_PREFIX_SYS + '/TableBase/GetResourcesList'

/* 编辑菜单资料 */
export const MENU_INFO_TEMP_SAVE = BASE_PREFIX_SYS + '/MenuInfoTemp/Save'
/* 删除菜单资料 */
export const MENU_INFO_TEMP_DEL = BASE_PREFIX_SYS + '/MenuInfoTemp/Del'
export const MENU_TEMP_INFO = BASE_PREFIX_SYS + '/MenuInfoTemp/Info'

export const MENU_TREEDATALIST = BASE_PREFIX_SYS + '/MenuInfoTemp/TreeDataList'

/** 表单列 */
// 查询表单列
export const TABLE_GET_GETFORMCOLINFO = BASE_PREFIX_SYS + '/Table/GetFormColInfo'
// 查询表单列列表分页
export const TABLE_POST_GETFORMCOLPAGE = BASE_PREFIX_SYS + '/Table/GetFormColPage'
// 查询权限表单列集合
export const TABLE_POST_GETFORMCOLLIST = BASE_PREFIX_SYS + '/Table/GetFormColList'
// 新增表单列
export const TABLE_POST_SAVEFORMCOL = BASE_PREFIX_SYS + '/Table/SaveFormCol'
// 修改表单列
export const TABLE_POST_EDITFORMCOL = BASE_PREFIX_SYS + '/Table/EditFormCol'
// 删除表单列
export const TABLE_POST_DELFORMCOL = BASE_PREFIX_SYS + '/Table/DelFormCol'

// 查询物料
export const ENGINEERING = HTTP_BASE_URL + '/engineer'
// 查询替代规则规则 /engineer/substituteRule
export const ENGINEERRULES = HTTP_BASE_URL + '/engineer/substituteRule'
// 物料分类列表
export const MATERIAL_LIST_DATA = HTTP_BASE_URL + '/material/category/secTreeByParents'
// 物料树状结构
export const SEC_TREE_BY_PARENTS = HTTP_BASE_URL + '/material/category/secTreeByParents'
// 全局属性列表
export const MATERIAL_ATTRIBUTE_DATA = HTTP_BASE_URL + '/material/feature/getGlobalFeatureList'
// 当前全局属性
export const MATERIAL_ATTRIBUTENOW_DATA = HTTP_BASE_URL + '/material/global/codeConfig'
// 物料分类  保存更新
// export const MATERIAL_SAVEUP_DATA = HTTP_BASE_URL + '/material/category/saveOrUpdate'
export const MATERIAL_SAVEUP_DATA = HTTP_BASE_URL + '/material/category/updateBatch'
// 全局属性配置保存
export const MATERIAL_ATTRIBUTESAVEUP_DATA = HTTP_BASE_URL + '/material/global/setCodeConfig'
// 物料分类  批量新增
// export const MATERIAL_SAVEBATCH_DATA = HTTP_BASE_URL + '/material/category/saveBatch'
export const MATERIAL_SAVEBATCH_DATA = HTTP_BASE_URL + '/material/category/addBatch'
// 物料分类  名称编号检查
export const MATERIAL_CHECKNAMECODE_DATA = HTTP_BASE_URL + '/material/category/check'
// 物料分类  导入
export const MATERIAL_READFILE_DATA = HTTP_BASE_URL + '/material/category/readFile'
// 物料分类  三级分类导入
export const MATERIAL_CATEGORY_READ_FILE_DATA = HTTP_BASE_URL + '/material/category/uploadThreeLevel'
// 物料分类  删除数据
export const MATERIAL_DELCATEGORY_DATA = HTTP_BASE_URL + '/material/category/delete'
// 物料分类  三级分类克隆
export const MATERIAL_CLONECATEGORY_DATA = HTTP_BASE_URL + '/material/category/clone'
// 属性列表  物料
export const MATERIAL_LIKECATEGORY_DATA = HTTP_BASE_URL + '/material/category/like'
// 属性列表  编码长度
export const MATERIAL_CODELENGTH_DATA = HTTP_BASE_URL + '/material/global/codeLength'
// 属性列表  添加属性
export const MATERIAL_ADDTOCATEGORY_DATA = HTTP_BASE_URL + '/material/feature/addToCategory'
// 属性列表  添加之所有子类属性
export const MATERIAL_FLUSHTOCATEGORY_DATA = HTTP_BASE_URL + '/material/feature/flushToCategory'
// 属性列表  属性ID获取特性信息
export const MATERIAL_FEATUREBYID_DATA = HTTP_BASE_URL + '/material/feature/getById'
// 属性列表  获取物料分页列表信息
export const MATERIAL_PRODUCTLIST_DATA = HTTP_BASE_URL + '/material/material/list'
// 属性列表  查询分类下所有子类信息
export const MATERIAL_LISTSONCATEGORY_DATA = HTTP_BASE_URL + '/material/category/listSonCategoryAndFeature'
// 属性列表  查询分类特性列表
export const MATERIAL_FIELDLIST_DATA = HTTP_BASE_URL + '/material/field/list'
// 属性列表  分类特性列表导入
export const MATERIAL_FIELDREADFILE_DATA = HTTP_BASE_URL + '/material/field/readFile'

// 属性列表  分类特性列表下载
export const MATERIAL_FIELD_DOWNLOAD_FILE_DATA = HTTP_BASE_URL + '/material/field/downLoadField'
// 属性列表  分类特性列表导入模板
export const MATERIAL_FIELD_TEMPLATE = HTTP_BASE_URL + '/material/field/fieldTemplate'
// 属性列表  分类特性列表新增
// export const MATERIAL_FIELDSAVEORUPDATE_DATA = HTTP_BASE_URL + '/material/field/saveOrUpdate'
export const MATERIAL_FIELDSAVEORUPDATE_DATA = HTTP_BASE_URL + '/material/field/addBatch'
// 属性列表  分类特性列表编辑
export const MATERIAL_FIELDUPDATE_BATCH = HTTP_BASE_URL + '/material/field/updateBatch'
// 属性列表  分类特性列表编码名称查重
export const MATERIAL_FIELDCHECK_DATA = HTTP_BASE_URL + '/material/field/check'
// 属性列表  分类特性列表批量删除
export const MATERIAL_FIELDDELETE_DATA = HTTP_BASE_URL + '/material/field/delete'
// 属性列表  分类特性列表  获取；列表信息
export const MATERIAL_FOLDERCASCADER_DATA = HTTP_BASE_URL + '/material/feature/folder/cascader'
// 属性列表  分类特性列表  导入
export const MATERIAL_FOLDERREADFILE_DATA = HTTP_BASE_URL + '/material/feature/readFile'
// 全局特性列表  删除
export const MATERIAL_FOLDERDELETE_DATA = HTTP_BASE_URL + '/material/feature/folder/delete'
export const MATERIAL_DELETE_DATA = HTTP_BASE_URL + '/material/feature/delete'
//  全局特性列表  获取信息
export const MATERIAL_GETFOLDER_DATA = HTTP_BASE_URL + '/material/feature//folder/list'
//  全局特性列表  继承父类属性
export const MATERIAL_COPYPARENT_DATA = HTTP_BASE_URL + '/material/feature/copyParentProductFeature'
//  全局特性列表  保存更新属性
// export const MATERIAL_FEATURESAVEORUPDATE_DATA = HTTP_BASE_URL + '/material/feature/saveOrUpdate'
export const MATERIAL_FEATURESAVEORUPDATE_DATA = HTTP_BASE_URL + '/material/feature/update'
export const MATERIAL_FEATUREADD_DATA = HTTP_BASE_URL + '/material/feature/add'
//  全局特性列表  更新子类的基础属性和排序
export const MATERIAL_FEATUREUPDATESUBFEATUREBASE = HTTP_BASE_URL + '/material/feature/updateSubFeatureBase'
//  全局特性列表  保存更新folder
export const MATERIAL_FOLDERSAVEORUPDATE_DATA = HTTP_BASE_URL + '/material/feature/folder/saveOrUpdate'
// 全局特性列表
export const MATERIAL_LISTBYLEVELANDPARENT_DATA = HTTP_BASE_URL + '/material/category/listByLevelAndParent'
// 物料详情   图示
export const MATERIAL_MATCHUIMG_DATA = HTTP_BASE_URL + '/material/brand/matchImage'
// 物料详情   LIST
export const MATERIAL_GETPROLIST_DATA = HTTP_BASE_URL + '/material/material/get'
// 物料详情   ATTRLIST
export const MATERIAL_GETATTRPROLIST_DATA = HTTP_BASE_URL + '/dictionary/get'
// 物料分类   二级分类下三级分类模板
export const MATERIAL_THREELEVELTEMPLATE = HTTP_BASE_URL + '/material/category/threeLevelTemplate'
// 物料分类   导出一级分类
export const EXPORT_ALL_MATERIAL_CATEGORY = HTTP_BASE_URL + '/material/category/exportCategory'
// 物料分类   导出三级分类
export const EXPORT_ALL_MATERIAL_THREE_CATEGORY = HTTP_BASE_URL + '/material/category/exportThreeLevel'
// 属性弹窗   属性搜索框下拉数据
export const CHANGE_LOCATION_FIELDVALUE = HTTP_BASE_URL + '/material/field/like'

/** ==============客户管理====================== */
// 查询客户基础信息详情
export const GET_CUSTOMER_INFO = BASE_PREFIX_SALE + '/Customer/CustomerInfo'
// 查询客户基础信息分页列表
export const GET_CUSTOMER_PAGE = BASE_PREFIX_SALE + '/Customer/CustomerPageList'
// 新增客户基础信息
export const SAVE_CUSTOMER = BASE_PREFIX_SALE + '/Customer/SaveDraft'
// 修改客户基础信息
export const SUBMIT_CUSTOMER = BASE_PREFIX_SALE + '/Customer/Submit'
// 删除客户基础信息
export const DEL_CUSTOMER = BASE_PREFIX_SALE + '/Customer/DelCustomer'
// 下载客户信息导入模板
export const DOWNLOAD_CUSTOMER = BASE_PREFIX_SALE + '/Customer/ExportTemplate'
// 导入客户信息
export const IMPORT_CUSTOMER = BASE_PREFIX_SALE + '/Customer/ImportCustomer'
// 导出客户
export const EXPORT_CUSTOMER = BASE_PREFIX_SALE + '/Customer/ExportCustomer'
// 查询客户（供应商）列表
export const CUSTOMER_CSDATALIST = BASE_PREFIX_SALE + '/Customer/CSDataList'

/** ==============字典管理列表数据接口====================== */
export const DICTIONARY_LIST = BASE_PREFIX_SYS + '/Dictionary/GetDataPage'
/** 字典管理新增列表数据接口 */
export const DICTIONARY_ADD_LIST = BASE_PREFIX_SYS + '/Dictionary/SaveData'
/** 字典管理修改列表数据接口 */
export const DICTIONARY_EDIT_LIST = BASE_PREFIX_SYS + '/Dictionary/EditData'
/** 字典管理删除接口 */
export const DICTIONARY_DEL = BASE_PREFIX_SYS + '/Dictionary/DelData'
/** 字典管理类别列表数据接口 */
export const DICTIONARY_CATEGORY_LIST = BASE_PREFIX_SYS + '/Dictionary/GetTypePage'
/** 新增字典类别接口 */
export const DICTIONARY_CATEGORY_ADD_LIST = BASE_PREFIX_SYS + '/Dictionary/SaveType'
/** 删除字典类别 */
export const DICTIONARY_CATEGORY_DEL = BASE_PREFIX_SYS + '/Dictionary/DelType'
/** 编辑字典类别 */
export const DICTIONARY_CATEGORY_EDIT = BASE_PREFIX_SYS + '/Dictionary/EditType'
/** 获取字典数据下拉组件数据 */
export const DICTIONARY_CATEGORY_SELECT = BASE_PREFIX_SYS + '/Dictionary/SelectData'
/** 用于修改字典状态 */
export const DICTIONARY_SET_STATE_DATA = BASE_PREFIX_SYS + '/Dictionary/SetStateData'
/** 查询单个字典类型信息 */
export const DICTIONARY_GET_TYPE_INFO = BASE_PREFIX_SYS + '/Dictionary/GetTypeInfo'
/** 此接口查询单个字典数据信息 */
export const DICTIONARY_GET_DATA_INFO = BASE_PREFIX_SYS + '/Dictionary/GetDataInfo'
/** 下载模板 */
export const DICTIONARY_EXPORT = BASE_PREFIX_SYS + '/Dictionary/Export'
/** 下载模板 */
export const DICTIONARY_IMPORT = BASE_PREFIX_SYS + '/Dictionary/Import'

/** ========语种相关接口======== */
// 查询语种分组
export const LANGUAGE_GET_GROUP_LIST = BASE_PREFIX_SYS + '/Language/GroupPageList'
// 新增语种分组
export const LANGUAGE_SAVE_GROUP = BASE_PREFIX_SYS + '/Language/AddGroup'
// 查询语种分组详情
export const LANGUAGE_GET_GROUP_INFO = BASE_PREFIX_SYS + '/Language/GroupInfo'
// 修改语种分组
export const LANGUAGE_EDIT_GROUP = BASE_PREFIX_SYS + '/Language/EditGroup'
// 删除语种分组
export const LANGUAGE_DEL_GROUP = BASE_PREFIX_SYS + '/Language/DelGroup'
// 查询语种资源列表信息
export const LANGUAGE_GET_SOURCE_LIST = BASE_PREFIX_SYS + '/Language/SourcePageList'
// 查询语种资源列表信息
export const LANGUAGE_SAVE_SOURCE = BASE_PREFIX_SYS + '/Language/AddSource'
// 查询单个语种资源信息
export const LANGUAGE_GET_SOURCE_INFO = BASE_PREFIX_SYS + '/Language/SourceInfo'
// 修改单个语种资源信息
export const LANGUAGE_EDIT_SOURCE = BASE_PREFIX_SYS + '/Language/EditSource'
// 删除语种资源
export const LANGUAGE_DEL_SOURCE = BASE_PREFIX_SYS + '/Language/DelSource'
// 下载语种模板
export const LANGUAGE_EXPORT = BASE_PREFIX_SYS + '/Language/Export'
// 下载语种json
export const LANGUAGE_DOWNLOADJSON = BASE_PREFIX_SYS + '/Language/DownLoadJson'
/** 客户基础数据-系统-基础数据模板 */
/** ==============客户基础数据接口====================== */
// 查询基础数据类型
export const GET_BASIC_DATA_TYPE_BASE = BASE_PREFIX_SYS + '/BasicDataBase/TypeInfo'
// 查询基础数据类型分页
export const GET_BASIC_DATA_TYPE_PAGE_BASE = BASE_PREFIX_SYS + '/BasicDataBase/TypePageList'
// 新增基础数据类型
export const ADD_BASIC_DATA_TYPE_BASE = BASE_PREFIX_SYS + '/BasicDataBase/AddType'
// 修改基础数据类型
export const EDIT_BASIC_DATA_TYPE_BASE = BASE_PREFIX_SYS + '/BasicDataBase/EditType'
// 删除基础数据类型
export const DEL_BASIC_DATA_TYPE_BASE = BASE_PREFIX_SYS + '/BasicDataBase/DelType'
// 查询基础数据
export const GET_BASIC_DATA_BASE = BASE_PREFIX_SYS + '/BasicDataBase/Info'
// 查询基础数据分页
export const GET_BASIC_DATA_PAGE_BASE = BASE_PREFIX_SYS + '/BasicDataBase/PageList'
// 新增基础数据
export const ADD_BASIC_DATA_BASE = BASE_PREFIX_SYS + '/BasicDataBase/Add'
// 修改基础数据
export const EDIT_BASIC_DATA_BASE = BASE_PREFIX_SYS + '/BasicDataBase/Edit'
// 删除基础数据
export const DEL_BASIC_DATA_BASE = BASE_PREFIX_SYS + '/BasicDataBase/Del'
// 获取基础数据数据下拉组件数据
export const BASIC_DATA_SELECT_BASE = BASE_PREFIX_SYS + '/BasicDataBase/select'
// 设置基础数据字典启用状态
export const BASIC_DATA_SET_STATE_BASE = BASE_PREFIX_SYS + '/BasicDataBase/SetState'

/** ==============客户基础数据接口-设置公共字典，====================== */
// 查询基础数据类型
export const GET_BASIC_DATA_TYPE = BASE_PREFIX_SYS + '/BasicData/TypeInfo'
// 查询基础数据类型分页
export const GET_BASIC_DATA_TYPE_PAGE = BASE_PREFIX_SYS + '/BasicData/TypePageList'
// 新增基础数据类型
export const ADD_BASIC_DATA_TYPE = BASE_PREFIX_SYS + '/BasicData/AddType'
// 修改基础数据类型
export const EDIT_BASIC_DATA_TYPE = BASE_PREFIX_SYS + '/BasicData/EditType'
// 删除基础数据类型
export const DEL_BASIC_DATA_TYPE = BASE_PREFIX_SYS + '/BasicData/DelType'
// 查询基础数据
export const GET_BASIC_DATA = BASE_PREFIX_SYS + '/BasicData/Info'
// 查询基础数据分页
// export const GET_BASIC_DATA_PAGE = BASE_PREFIX_SYS + '/BasicData/PageList'
export const GET_BASIC_DATA_PAGE = BASE_PREFIX_INIT_START_RESOUCE + '/basicData/queryBasicPageList'
// 新增基础数据
// export const ADD_BASIC_DATA = BASE_PREFIX_SYS + '/BasicData/Add'
export const ADD_BASIC_DATA = BASE_PREFIX_INIT_START_RESOUCE + '/basicData/addBasic'
// 修改基础数据
// export const EDIT_BASIC_DATA = BASE_PREFIX_SYS + '/BasicData/Edit'
export const EDIT_BASIC_DATA = BASE_PREFIX_INIT_START_RESOUCE + '/basicData/saveBasic'
// 批量修改基础数据
export const BATCHEDIT_BASIC_DATA = BASE_PREFIX_SYS + '/BasicData/BatchEdit'
// 删除基础数据
// export const DEL_BASIC_DATA = BASE_PREFIX_SYS + '/BasicData/Del'
export const DEL_BASIC_DATA = BASE_PREFIX_INIT_START_RESOUCE + '/basicData/deleteBasic'
// 获取基础数据数据下拉组件数据
// export const BASIC_DATA_SELECT = BASE_PREFIX_SYS + '/BasicData/select'
export const BASIC_DATA_SELECT = BASE_PREFIX_INIT_START_RESOUCE + '/basicData/queryBasicList'
// 设置基础数据字典启用状态
export const BASIC_DATA_SET_STATE = BASE_PREFIX_SYS + '/BasicData/SetState'

// 下载execl模板
export const BASIC_DATA_DOWN_EXCEL = BASE_PREFIX_SYS + '/BasicData/DownloadPopUp'

// 导入基础数据Execl文件
export const BASIC_DATA_IMPORT_EXCEL = BASE_PREFIX_SYS + '/BasicData/ImportPopUp'

/** ==============语种============== */
// 切换语种
export const CHANGE_LANG = BASE_PREFIX_USER + '/Authentication/ChangeLang'

/** ==============计量单位============== */
// 查询计量单位数据列表分页信息
export const BASIC_DATA_CUGET_PAGE = BASE_PREFIX_SYS + '/BasicData/CUPageList'
export const BASIC_DATA_CUGET_PAGE_BASE = BASE_PREFIX_SYS + '/BasicData/CUPageList'
// 新增计量单位数据
export const BASIC_DATA_CUSAVE = BASE_PREFIX_SYS + '/BasicData/AddCU'
export const BASIC_DATA_CUSAVE_BASE = BASE_PREFIX_SYS + '/BasicData/AddCU'
// 修改计量单位数据
export const BASIC_DATA_CUEDIT = BASE_PREFIX_SYS + '/BasicData/EditCU'
export const BASIC_DATA_CUEDIT_BASE = BASE_PREFIX_SYS + '/BasicData/EditCU'
// 删除计量单位数据
export const BASIC_DATA_CUDEL = BASE_PREFIX_SYS + '/BasicData/DelCU'
export const BASIC_DATA_CUDEL_BASE = BASE_PREFIX_SYS + '/BasicData/DelCU'
// 查询计量单位数据下拉
export const BASIC_DATA_CUSELECT = BASE_PREFIX_SYS + '/BasicData/CUSelect'
// 查询计量单位单条数据
export const BASIC_DATA_CUGET_INFO = BASE_PREFIX_SYS + '/BasicData/CUInfo'
export const BASIC_DATA_CUGET_INFO_BASE = BASE_PREFIX_SYS + '/BasicDataBase/CUInfo'
/** ==============语言相关接口====================== */
// 查询语言下拉
export const LANGUAGE_SELECT = BASE_PREFIX_SYS + '/Language/LangSelect'
// 下载模板
export const BASIC_DATA_EXPORT = BASE_PREFIX_SYS + '/BasicData/Export'
// 下载模板-BASE
export const BASIC_DATA_EXPORT_BASE = HTTP_BASE_URL_JAVACHANGE + '/excel/dictionaryTemplate'

// 上传数据
export const BASIC_DATA_IMPORT = BASE_PREFIX_SYS + '/BasicData/Import'
// 上传数据-BASE
export const BASIC_DATA_IMPORT_BASE = BASE_PREFIX_SYS + '/BasicDataBase/Import'
// 导入语种资源
export const LANGUAGE_IMPORT = BASE_PREFIX_SYS + '/Language/Import'
// 导出模板-有上级关联DI
export const BASIC_DATA_EXPORTPOPUP = BASE_PREFIX_SYS + '/BasicData/ExportPopUp'
// /excel/bizDictTemplate
// 导出execl数据
export const BASIC_DATA_EXPORT_EXCEL = BASE_PREFIX_SYS + '/BasicData/ExportPopUp'
// 导入模板-有上级关联DI
export const BASIC_DATA__IMPORTPOPUP = BASE_PREFIX_SYS + '/BasicData/ImportPopUp'
/** ==============单据编码数据接口====================== */
// 获取公司单据编码
export const GET_BILL_NO = BASE_PREFIX_SYS + '/BillCodedRule/BillNo'
// 查询编码规则
export const DOCUMENT_RULES_DETAIL = BASE_PREFIX_SYS + '/BillCodedRule/Info'
// 查询编码规则分页列表
export const DOCUMENT_RULES_PAGE = BASE_PREFIX_SYS + '/BillCodedRule/PageList'
// 新增编码规则
export const DOCUMENT_RULES_SAVE = BASE_PREFIX_SYS + '/BillCodedRule/Add'
// 修改编码规则
export const DOCUMENT_RULES_EDIT = BASE_PREFIX_SYS + '/BillCodedRule/Edit'
// 删除编码规则
export const DOCUMENT_RULES_DEL = BASE_PREFIX_SYS + '/BillCodedRule/Del'
// 获取用户系统快捷键设置
export const DOCUMENT_SHORTCUT = BASE_PREFIX_SYS + '/FunctionConfig/KeyConfigPageList'
// export const DOCUMENT_SHORTCUT = BASE_PREFIX_SYS + '/FunctionConfig/GetKeyConfigPage'
export const DOCUMENT_SAVESHORTCUT = BASE_PREFIX_SYS + '/FunctionConfig/AddKeyConfig'
// export const DOCUMENT_SAVESHORTCUT = BASE_PREFIX_SYS + '/FunctionConfig/SaveKeyConfig'
//  查询快捷键配置
export const DOCUMENT_GETKEYCONFIG = BASE_PREFIX_SYS + '/FunctionConfig/GetKeyConfig'
//  查询快捷键模板信息
export const DOCUMENT_GETKEYCONFIGTEMPINFO = BASE_PREFIX_SYS + '/FunctionConfig/KeyConfigTempInfo'
//  查询快捷键模板信息分页列表
export const DOCUMENT_GETKEYCONFIGTEMPPAGE = BASE_PREFIX_SYS + '/FunctionConfig/KeyConfigTempPageList'
// export const DOCUMENT_GETKEYCONFIGTEMPPAGE = BASE_PREFIX_SYS + '/FunctionConfig/GetKeyConfigTempPage'
//  新增快捷键模板信息
export const DOCUMENT_SAVEKEYCONFIGTEMP = BASE_PREFIX_SYS + '/FunctionConfig/AddKeyConfigTemp'
// export const DOCUMENT_SAVEKEYCONFIGTEMP = BASE_PREFIX_SYS + '/FunctionConfig/SaveKeyConfigTemp'
//  删除快捷键模板信息
export const DOCUMENT_DELKEYCONFIGTEMP = BASE_PREFIX_SYS + '/FunctionConfig/DelKeyConfigTemp'
//  修改快捷键模板信息
export const DOCUMENT_EDITKEYCONFIGTEMP = BASE_PREFIX_SYS + '/FunctionConfig/EditKeyConfigTemp'
//  修改快捷键模板按钮状态
export const DOCUMENT_SETKEYCONFIGTEMPSTATE = BASE_PREFIX_SYS + '/FunctionConfig/SetKeyConfigTempState'

/** ==============附件模板接口====================== */
// 表格筛选
export const TEMPLATE_GETPAGE = BASE_PREFIX_SYS + '/InfoTemp/PageList'
export const TEMPLATE_DEL = BASE_PREFIX_SYS + '/InfoTemp/Del'
// 新增/修改 资料模板
export const TEMPLATE_SAVE = BASE_PREFIX_SYS + '/InfoTemp/Add'
// 根据id查询资料模板
export const INFOTEMP_GETINFO = BASE_PREFIX_SYS + '/InfoTemp/Info'
// 通过模板页面编码查询模板
export const INFOTEMP_TEMPDATALIST = BASE_PREFIX_SYS + '/InfoTemp/TempDataList'
// 历史版本
export const INFOTEMP_HISTORYLIST = BASE_PREFIX_SYS + '/InfoTemp/HistoryList'
// 查询菜单资料模板列表
export const MENUINFOTEMP_DATALIST = BASE_PREFIX_SYS + '/MenuInfoTemp/DataList'

/** ==============文档管理接口====================== */
export const GET_DOCUMENT_LIST_INFO = BASE_FILE_MANAGE + '/DocManage/FileInfoList'
// 传图片的接口
export const UPLOAD_PICTURE = BASE_FILE_MANAGE + '/DocManage/UploadPicture'
// 上传文件
export const UPLOAD_FILE = BASE_FILE_MANAGE + '/DocManage/UploadFile'
// 初始化文件列表
export const SAVE_DOCS = BASE_FILE_MANAGE + '/DocManage/SaveDocs'
// 新增文件夹
export const SAVE_FOLDERS = BASE_FILE_MANAGE + '/DocManage/SaveFolders'
// 根据表单id查询文件夹
export const FOLDER_LIST = BASE_FILE_MANAGE + '/DocManage/FolderList'
// 删除文件
export const DEL_DOC = BASE_FILE_MANAGE + '/DocManage/DelDoc'
// 获取文件详情
export const DOC_INFO = BASE_FILE_MANAGE + '/DocManage/DocInfo'
// 修改文件名称和描述
export const EDIT_DOC = BASE_FILE_MANAGE + '/DocManage/EditDoc'
// 修改文件夹名称和描述
export const EDIT_FOLDER = BASE_FILE_MANAGE + '/DocManage/EditFolder'
// 删除文件夹
export const DEL_FOLDERS = BASE_FILE_MANAGE + '/DocManage/DelFolder'
// 彻底删除文件夹
export const PERMANENT_DEL_FOLDERS = BASE_FILE_MANAGE + '/DocManage/PermanentDelFolder'
// 彻底删除文件夹
export const DOC_OPT_LOGL_IST = BASE_FILE_MANAGE + '/DocManage/DocOptLogList'

/** ==============初始化模板====================== */

// 系统初始化
export const INIT_START = BASE_PREFIX_INIT_START + '/init/start'
// 查询初始化模板下拉
export const QUERY_TEMPLATE_LIST = BASE_PREFIX_INIT_START + '/template/queryTemplateList'
// 查询初始化模板
export const QUERY_TEMPLATE_PAGE_LIST = BASE_PREFIX_INIT_START + '/template/queryTemplatePageList'
// 数据库版本选择
export const SELECT_DB_VERSION = BASE_PREFIX_INIT_START + '/template/selectDbVersion'
// 新增初始化模板
export const ADD_TEMPLATE = BASE_PREFIX_INIT_START + '/template/addTemplate'
// 更新初始化模板
export const UPDATE_TEMPLATE = BASE_PREFIX_INIT_START + '/template/updateTemplate'
// 更新初始化模板
export const DELETE_TEMPLATE = BASE_PREFIX_INIT_START + '/template/deleteTemplate'
// 新增初始化检查
export const ADD_CHECK_DETAIL = BASE_PREFIX_INIT_START + '/template/addCheckDetail'
// 批量新增初始化检查
export const ADD_CHECK_DETAIL_BATCH = BASE_PREFIX_INIT_START + '/template/addCheckDetailBatch'
// 新增初始化模板步骤
export const ADD_TEMPLATE_LINE = BASE_PREFIX_INIT_START + '/template/addTemplateLine'
// 删除初始化检查
export const DELETE_CHECK_DETAIL = BASE_PREFIX_INIT_START + '/template/deleteCheckDetail'
// 删除初始化模板步骤
export const DELETE_TEMPLATE_LINE = BASE_PREFIX_INIT_START + '/template/deleteTemplateLine'
// 查询初始化模板步骤
export const QUERY_TEMPLATE_LINE_LIST = BASE_PREFIX_INIT_START + '/template/queryTemplateLineList'
// 查询初始化检查
export const QUERY_CHECK_DETAIL_LIST = BASE_PREFIX_INIT_START + '/template/queryCheckDetailList'
// 更新初始化检查
export const UPDATE_CHECK_DETAIL = BASE_PREFIX_INIT_START + '/template/updateCheckDetail'
// 更新初始化模板步骤
export const UPDATE_TEMPLATE_LINE = BASE_PREFIX_INIT_START + '/template/updateTemplateLine'
// 查询初始化检查日志
export const QUERY_INIT_CHECK_LOG = BASE_PREFIX_INIT_START + '/appLog/queryCheckLog'
// 查询初始化执行日志
export const QUERY_INIT_EXECUTE_LOG = BASE_PREFIX_INIT_START + '/appLog/queryExecuteLog'
// 检查系统初始化状态
export const CHECK_INIT_STATUS = BASE_PREFIX_INIT_START + '/update/checkInitStatus'
// 回退初始化
export const INIT_FALLBACK = BASE_PREFIX_INIT_START + '/init/fallback'

/** ==============应用版本====================== */
// 新增应用版本
export const ADD_APP_VERSION = BASE_PREFIX_INIT_START + '/appVersion/addAppVersion'
// 删除应用版本
export const DELETE_APP_VERSION = BASE_PREFIX_INIT_START + '/appVersion/deleteAppVersion'
// 查询应用版本
export const QUERY_APP_VERSION = BASE_PREFIX_INIT_START + '/appVersion/queryAppVersion'
// 应用版本选择
export const SELECT_APP_VERSION = BASE_PREFIX_INIT_START + '/appVersion/selectAppVersion'
// 分页查询应用版本
export const QUERY_APP_VERSION_LIST = BASE_PREFIX_INIT_START + '/appVersion/queryAppVersionList'
// 分页查询应用版本
export const QUERY_APP_VERSION_PAGE_LIST = BASE_PREFIX_INIT_START + '/appVersion/queryAppVersionPageList'
// 更新应用版本
export const UPDATE_APP_VERSION = BASE_PREFIX_INIT_START + '/appVersion/updateAppVersion'

/** ==============升级初始化====================== */
// 新增升级模板
export const ADD_UPGRADE_TEMPLATE = BASE_PREFIX_INIT_START + '/upgradeTemplate/addUpgradeTemplate'
// 删除升级模板
export const DELETE_UPGRADE_TEMPLATE = BASE_PREFIX_INIT_START + '/upgradeTemplate/deleteUpgradeTemplate'
// 查询升级模板
export const QUERY_UPGRADE_TEMPLATE = BASE_PREFIX_INIT_START + '/upgradeTemplate/queryUpgradeTemplate'
// 分页查询升级模板
export const QUERY_UPGRADE_TEMPLATE_LIST = BASE_PREFIX_INIT_START + '/upgradeTemplate/queryUpgradeTemplatePageList'
// 更新升级模板
export const UPDATE_UPGRADE_TEMPLATE = BASE_PREFIX_INIT_START + '/upgradeTemplate/updateUpgradeTemplate'
// 批量系统升级
export const UPGRADE_START = BASE_PREFIX_INIT_START + '/upgrade/start'
// 批量系统升级回退
export const UPGRADE_FALLBACK = BASE_PREFIX_INIT_START + '/upgrade/fallback'

/** ==============公司数据接口====================== */
// 保存公司草稿信息
export const COMPANY_SAVE_DRAFT = BASE_PREFIX_COMPANY + '/Company/SaveDraft'
// 提交公司基础信息
export const COMPANY_SUBMIT = BASE_PREFIX_COMPANY + '/Company/Submit'
// 公司列表数据
export const COMPANY_DATA = BASE_PREFIX_COMPANY + '/Company/CompanyPageList'
// 新增公司列表数据
export const COMPANY_DATA_ADD = BASE_PREFIX_COMPANY + '/Company/AddCompany'
// 修改公司列表数据
export const COMPANY_DATA_EDIT = BASE_PREFIX_COMPANY + '/Company/EditCompany'
// 删除公司列表数据
export const COMPANY_DATA_DEL = BASE_PREFIX_COMPANY + '/Company/DelCompany'
// 查询公司基础信息
export const COMPANY_DATA_DETAIL = BASE_PREFIX_COMPANY + '/Company/CompanyInfo'
// 获取公司下拉组件数据
export const COMPANY_DATA_SELECT = BASE_PREFIX_COMPANY + '/Company/SelectCompany'

// 查询公司收货地址信息
export const COMPANY_DATA_RECEIVE_DETAIL = BASE_PREFIX_COMPANY + '/Company/ReceAddrInfo'
// 查询公司收货地址分页列表
export const COMPANY_DATA_RECEIVE_ADDR = BASE_PREFIX_COMPANY + '/Company/ReceAddrPageList'
// 新增公司收货地址信息
export const COMPANY_DATA_RECEIVE_ADD = BASE_PREFIX_COMPANY + '/Company/AddReceAddr'
// 修改公司收货地址信息
export const COMPANY_DATA_RECEIVE_EDIT = BASE_PREFIX_COMPANY + '/Company/EditReceAddr'
// 删除公司收货地址信息
export const COMPANY_DATA_RECEIVE_DEL = BASE_PREFIX_COMPANY + '/Company/DelReceAddr'
// 公司收货地址设置默认状态
export const COMPANY_DATA_RECEIVE_ADDR_SET_STATE = BASE_PREFIX_COMPANY + '/Company/ReceAddrSetState'
// 下载公司收货地址导入模板
export const COMPANY_DOWNLOAD_RECEADDR_TEMP = BASE_PREFIX_JAVA_COMPANY + '/excel/company/addressTemplate'
// 导入公司收货地址
export const COMPANY_IMPORT_RECEADDR_INFO = BASE_PREFIX_COMPANY + '/Company/ImportReceAddr'
// 导出公司收货地址
export const COMPANY_EXPORT_RECEADDR_INFO = BASE_PREFIX_COMPANY + '/Company/ExportReceAddr'
// 查询公司账号信息
export const COMPANY_DATA_ACCOUNT_DETAIL = BASE_PREFIX_COMPANY + '/Company/AccountInfo'
// 查询公司账号分页列表
export const COMPANY_DATA_ACCOUNT = BASE_PREFIX_COMPANY + '/Company/AccountPageList'
// 新增公司账号信息
export const COMPANY_DATA_ACCOUNT_SAVE = BASE_PREFIX_COMPANY + '/Company/AddAccount'
// 修改公司账号信息
export const COMPANY_DATA_ACCOUNT_EDIT = BASE_PREFIX_COMPANY + '/Company/EditAccount'
// 删除公司账号
export const COMPANY_DATA_ACCOUNT_DEL = BASE_PREFIX_COMPANY + '/Company/DelAccount'
// 公司账号设置状态
export const COMPANY_DATA_ACCOUNT_SET_STATE = BASE_PREFIX_COMPANY + '/Company/AccountSetState'

// 弹窗
// 查询账号类型
export const COMPANY_ACCOUNT_TYPE_INFO = BASE_PREFIX_COMPANY + '/Company/AccountTypeInfo'
// 获取账号类型下拉组件数据
export const COMPANY_ACCOUNT_TYPE_SELECT = BASE_PREFIX_COMPANY + '/Company/SelectAccountType'
// 新增账号类型信息
export const COMPANY_ACCOUNT_TYPE_SAVE = BASE_PREFIX_COMPANY + '/Company/AddAccountType'
// 修改账号类型信息
export const COMPANY_ACCOUNT_TYPE_EDIT = BASE_PREFIX_COMPANY + '/Company/EditAccountType'
// 删除账号类型
export const COMPANY_ACCOUNT_TYPE_DEL = BASE_PREFIX_COMPANY + '/Company/DelAccountType'
// 查询公司账号类型分页列表
export const COMPANY_ACCOUNT_TYPE_CONFIG_LIST = BASE_PREFIX_COMPANY + '/Company/AccountTypePageList'

// 弹窗
// 查询硬件类型
export const COMPANY_HARDWARE_TYPE_INFO = BASE_PREFIX_COMPANY + '/Company/HardwareTypeInfo'
// 获取硬件类型下拉组件数据
export const COMPANY_HARDWARE_TYPE_SELECT = BASE_PREFIX_COMPANY + '/Company/SelectHardwareType'
// 新增硬件类型信息
export const COMPANY_HARDWARE_TYPE_SAVE = BASE_PREFIX_COMPANY + '/Company/AddHardwareType'
// 修改硬件类型信息
export const COMPANY_HARDWARE_TYPE_EDIT = BASE_PREFIX_COMPANY + '/Company/EditHardwareType'
// 删除硬件类型
export const COMPANY_HARDWARE_TYPE_DEL = BASE_PREFIX_COMPANY + '/Company/DelHardwareType'
// 查询公司硬件类型分页列表
export const COMPANY_HARDWARE_TYPE_CONFIG_LIST = BASE_PREFIX_COMPANY + '/Company/HardwareTypePageList'

// 弹窗
// 获取公司硬件名称下拉组件数据
export const COMPANY_HARDWARE_NAME_SELECT = BASE_PREFIX_COMPANY + '/Company/SelectHardwareName'
// 新增公司硬件名称信息
export const COMPANY_HARDWARE_NAME_ADD = BASE_PREFIX_COMPANY + '/Company/SaveHardwareName'
// 修改公司硬件名称信息
export const COMPANY_HARDWARE_NAME_EDIT = BASE_PREFIX_COMPANY + '/Company/EditHardwareName'
// 删除公司硬件名称信息
export const COMPANY_HARDWARE_NAME_DEL = BASE_PREFIX_COMPANY + '/Company/DelHardwareName'
// 查询公司硬件名称分页列表
export const COMPANY_HARDWARE_NAME_CONFIG_LIST = BASE_PREFIX_COMPANY + '/Company/GetHardwareNamePage'

// 查询公司硬件设备分页列表
export const COMPANY_EQUIPMENT_LIST = BASE_PREFIX_COMPANY + '/Company/EquipmentPageList'
// 查询公司硬件设备
export const COMPANY_EQUIPMENT_DETAIL = BASE_PREFIX_COMPANY + '/Company/EquipmentInfo'
// 新增公司硬件设备信息
export const COMPANY_EQUIPMENT_ADD = BASE_PREFIX_COMPANY + '/Company/AddEquipment'
// 修改公司硬件设备信息
export const COMPANY_EQUIPMENT_EDIT = BASE_PREFIX_COMPANY + '/Company/EditEquipment'
// 删除公司硬件设备信息
export const COMPANY_EQUIPMENT_DEL = BASE_PREFIX_COMPANY + '/Company/DelEquipment'

// 下载公司导入模板
export const COMPANY_DOWNLOADCOMPANY_TEMP = BASE_PREFIX_JAVA_COMPANY + '/excel/companyTemplate'
// 导入公司信息
export const COMPANY_IMPORT_COMPANY_INFO = BASE_PREFIX_COMPANY + '/Company/ImportCompany'
// 导出公司信息
export const COMPANY_EXPORT_COMPANY_INFO = BASE_PREFIX_COMPANY + '/Company/ExportCompany'

// 下载公司账号导入模板
export const COMPANY_DOWNLOAD_ACCOUNT_TEMP = BASE_PREFIX_JAVA_COMPANY + '/excel/company/accountTemplate'
// 导入公司账号信息
export const COMPANY_IMPORT_ACCOUNT_INFO = BASE_PREFIX_COMPANY + '/Company/ImportAccount'
// 导出公司账号信息
export const COMPANY_EXPORT_ACCOUNT_INFO = BASE_PREFIX_COMPANY + '/Company/ExportAccount'
// 下载公司硬件导入模板
export const COMPANY_DOWNLOAD_HARDWARE_TEMP = BASE_PREFIX_JAVA_COMPANY + '/excel/company/equipmentTemplate'
// 导入公司硬件信息
export const COMPANY_IMPORT_HARDWARE_INFO = BASE_PREFIX_COMPANY + '/Company/ImportEquipment'
// 导出公司硬件信息
export const COMPANY_EXPORT_HARDWARE_INFO = BASE_PREFIX_COMPANY + '/Company/ExportEquipment'

// 弹窗 下载公司账号类型导入模板
export const COMPANY_DOWNLOAD_ACCOUNT_TYPE_TEMP = BASE_PREFIX_COMPANY + '/Company/DownloadAccountTypeTemp'
// 导入公司账号类型信息
export const COMPANY_IMPORT_ACCOUNT_TYPE_INFO = BASE_PREFIX_COMPANY + '/Company/ImportAccountType'
// 导出公司账号类型信息
export const COMPANY_EXPORT_ACCOUNT_TYPE_INFO = BASE_PREFIX_COMPANY + '/Company/ExportAccountType'

// 弹窗 下载公司硬件类型导入模板
export const COMPANY_DOWNLOAD_HARDWARE_TYPE_TEMP = BASE_PREFIX_COMPANY + '/Company/DownloadHardwareTypeTemp'
// 导入公司硬件类型信息
export const COMPANY_IMPORT_HARDWARE_TYPE_INFO = BASE_PREFIX_COMPANY + '/Company/ImportHardwareType'
// 导出公司硬件类型信息
export const COMPANY_EXPORT_HARDWARE_TYPE_INFO = BASE_PREFIX_COMPANY + '/Company/ExportHardwareType'

// 弹窗 下载公司硬件名称导入模板
export const COMPANY_DOWNLOAD_HARDWARE_NAME_TEMP = BASE_PREFIX_COMPANY + '/Company/DownloadHardwareNameTemp'
// 导入公司硬件名称信息
export const COMPANY_IMPORT_HARDWARE_NAME_INFO = BASE_PREFIX_COMPANY + '/Company/ImportHardwareName'
// 导出公司硬件名称信息
export const COMPANY_EXPORT_HARDWARE_NAME_INFO = BASE_PREFIX_COMPANY + '/Company/ExportHardwareName'

/** ==============物料编码数据接口====================== */
// 物料编码列表数据
export const MATERIAL_RULES_DATA = BASE_PREFIX_SYS + '/MaterialRules/GetFeaturesPage'
// 物料编码列表数据
export const MATERIAL_RULES_CATEGORY = BASE_PREFIX_SYS + '/MaterialRules/GetStuffCategoryInfo'
// 查询物料规则分类分页列表
export const MATERIAL_RULES_CLASSIFY_DATA = BASE_PREFIX_SYS + '/MaterialRules/GetStuffCategoryPage'
// 新增编物料规则分类
export const MATERIAL_RULES_CLASSIFY_ADD = BASE_PREFIX_SYS + '/MaterialRules/SaveStuffCategory'
// 修改物料规则分类
export const MATERIAL_RULES_CLASSIFY_EDIT = BASE_PREFIX_SYS + '/MaterialRules/EditStuffCategory'
// 删除物料规则分类
export const MATERIAL_RULES_CLASSIFY_DEL = BASE_PREFIX_SYS + '/MaterialRules/DelStuffCategory'
// 查询物料规则特性子项分页列表
export const MATERIAL_RULES_FEATURES_ITEM = BASE_PREFIX_SYS + '/MaterialRules/GetFeaturesItemPage'
// 新增编物料规则特性子项
export const MATERIAL_RULES_FEATURES_ITEM_ADD = BASE_PREFIX_SYS + '/MaterialRules/SaveFeaturesItem'
// 修改物料规则特性子项
export const MATERIAL_RULES_FEATURES_ITEM_EDIT = BASE_PREFIX_SYS + '/MaterialRules/EditFeaturesItem'
// 删除物料规则特性子项
export const MATERIAL_RULES_FEATURES_ITEM_DEL = BASE_PREFIX_SYS + '/MaterialRules/DelFeaturesItem'
// 新增编物料规则特性
export const MATERIAL_RULES_FEATURES_ADD = BASE_PREFIX_SYS + '/MaterialRules/SaveFeatures'
// 修改物料规则特性
export const MATERIAL_RULES_FEATURES_EDIT = BASE_PREFIX_SYS + '/MaterialRules/EditFeatures'
// 删除物料规则特性
export const MATERIAL_RULES_FEATURES_DEL = BASE_PREFIX_SYS + '/MaterialRules/DelFeatures'

/** ==============部门数据接口====================== */
// 查询部门信息分页列表
export const DEPARTMENT_INFO = BASE_PREFIX_COMPANY + '/Department/pageList'
// 新增部门
export const DEPARTMENT_INFO_SUBMIT = BASE_PREFIX_COMPANY + '/Department/Submit'
// 部门草稿
export const DEPARTMENT_SAVE_DRAFT = BASE_PREFIX_COMPANY + '/Department/SaveDraft'
// 删除单个部门信息
export const DEPARTMENT_INFO_DEL = BASE_PREFIX_COMPANY + '/Department/Del'
// 修改部门信息
export const DEPARTMENT_LEADER_INFO = BASE_PREFIX_COMPANY + '/Department/LeaderInfo'
// 查询部门信息
export const DEPARTMENT_INFO_GETINFO = BASE_PREFIX_COMPANY + '/Department/Info'
// 上传多个文件
export const DEPARTMENT_INFO_PICTURE = BASE_PREFIX_COMPANY + '/FileManage/FileManage/UploadFiles'
// 此接口导入部门Execl文件信息
export const DEPARTMENT_IMPORT = BASE_PREFIX_COMPANY + '/Department/Import'
// 此接口导出部门Execl信息
export const DEPARTMENT_EXPORT = BASE_PREFIX_COMPANY + '/Department/Export'
// 下载部门导入模板
export const DEPARTMENT_DOWNLOADTEMP = HTTP_BASE_URL_JAVACHANGE + '/excel/departmentTemplate'
// // 获取部门下拉组件数据
export const DEPARTMENT_INFO_SELECT = BASE_PREFIX_COMPANY + '/Department/DeptDictionary '
// 查询部门下拉树组件数据
export const DEPARTMENT_INFO_TREE = BASE_PREFIX_COMPANY + '/Department/SelectTree'
// 部门离职汇总
export const DEPARTMENT_EMPLOYEE_STAT = BASE_PREFIX_COMPANY + '/Department/EmployeeStat'

/** ==============系统角色数据接口====================== */
// 系统角色列表
export const ROLE_SYSTEM_DATA = BASE_PREFIX_SYS + '/SysRole/PageList '
// 系统角色详情
export const ROLE_SYSTEM_INFO = BASE_PREFIX_SYS + '/SysRole/GetInfo'
// 新增系统角色
export const ROLE_SYSTEM_ADD = BASE_PREFIX_SYS + '/SysRole/Save'
// 修改系统角色
export const ROLE_SYSTEM_EDIT = BASE_PREFIX_SYS + '/SysRole/Edit'
// 删除系统角色
export const ROLE_SYSTEM_DEL = BASE_PREFIX_SYS + '/SysRole/Del'

/** ==============行政职务接口====================== */
// 职务分页
export const ADMIN_JOB_PAGE = BASE_PREFIX_COMPANY + '/Job/PageList'
// 职务分页
export const ADMIN_JOB_INFO = BASE_PREFIX_COMPANY + '/Job/Info'
// 职务添加
export const ADMIN_JOB_ADD = BASE_PREFIX_COMPANY + '/Job/Add'
// 职务修改
export const ADMIN_JOB_EDIT = BASE_PREFIX_COMPANY + '/Job/Edit'
// 职务删除
export const ADMIN_JOB_DEL = BASE_PREFIX_COMPANY + '/Job/Del'
// 职称下拉
export const ADMIN_JOB_SELECT = BASE_PREFIX_COMPANY + '/Job/Select'
// 下载职务导入模板
export const ADMIN_JOB_DOWNLOADTEMP = BASE_PREFIX_COMPANY + '/Job/DownloadTemp'
// 导入职务信息
export const ADMIN_JOB_IMPORT = BASE_PREFIX_COMPANY + '/Job/Import'
// 导出职务信息
export const ADMIN_JOB_EXPORT = BASE_PREFIX_COMPANY + '/Job/Export'

/** ==============账套数据接口====================== */
// 账套列表
export const ACCOUNTSET_DATA = BASE_PREFIX_INIT_START + '/account/queryAccountConfigList'
// 新增账套
export const ACCOUNTSET_ADD = BASE_PREFIX_INIT_START + '/account/addAccountConfig'
// 修改账套
export const ACCOUNTSET_EDIT = BASE_PREFIX_INIT_START + '/account/updateAccountConfig'
// 删除账套
export const ACCOUNTSET_DEL = BASE_PREFIX_INIT_START + '/account/deleteAccountConfig'
// 单个详情
export const QUERY_ACCOUNT_CONFIG = BASE_PREFIX_INIT_START + '/account/queryAccountConfig'
// 查询应用日志
export const QUERY_APP_LOG_LIST = BASE_PREFIX_INIT_START + '/appLog/queryAppLogList'
// 分页查询应用日志
export const QUERY_APP_LOG_PAGE_LIST = BASE_PREFIX_INIT_START + '/appLog/queryAppLogPageList'
// 锁定初始化应用版本
export const LOCK_ACCOUNT_INIT_APP_VERSION = BASE_PREFIX_INIT_START + '/account/lockAccountInitAppVersion'
// 锁定升级应用版本
export const LOCK_ACCOUNT_UPGRADE_APP_VERSION = BASE_PREFIX_INIT_START + '/account/lockAccountUpgradeAppVersion'
// 根据编码获取账套列表
export const GET_ACCOUNT_CONFIG_LIST_BY_CODE = BASE_PREFIX_INIT_START + '/account/queryAccountConfigListByCode'

/** ==============用户角色数据接口====================== */
// 系统角色列表
export const ROLE_USER_PAGE = BASE_PREFIX_COMPANY + '/AdminRole/PageList'
// 系统角色信息
export const ROLE_USER_DATA = BASE_PREFIX_COMPANY + '/AdminRole/Info'
// 新增系统角色
export const ROLE_USER_ADD = BASE_PREFIX_COMPANY + '/AdminRole/Add'
// 修改系统角色
export const ROLE_USER_EDIT = BASE_PREFIX_COMPANY + '/AdminRole/Edit'
// 删除系统角色
export const ROLE_USER_DEL = BASE_PREFIX_COMPANY + '/AdminRole/Del'
// 查询用户角色功能权限
export const GET_POWER_DATA = BASE_PREFIX_COMPANY + '/AdminRole/PowerData'
// 保存用户角色功能权限
export const SAVE_FUNC_POWER = BASE_PREFIX_COMPANY + '/AdminRole/SaveFuncPower'
// 查询角色下拉
export const ROLE_USER_ROLE = BASE_PREFIX_COMPANY + '/AdminRole/Select'

/** =================物料接口数据================ */
// 查询物料信息分页列表
export const MATERIAL_GET_PAGE = ENGINEERING + '/material/PageList'
// 新增物料信息
export const MATERIAL_SAVE = ENGINEERING + '/material/Save'
// 修改物料信息
export const MATERIAL_EDIT = ENGINEERING + '/material/Edit'
// 删除物料信息
export const MATERIAL_DEL = ENGINEERING + '/material/Del'
// 查询物料信息
export const MATERIAL_GETINFO = ENGINEERING + '/material/Info'

/** ==============员工数据接口====================== */
// 查询员工信息分页列表
export const EMPLOYEE_INFORMATION = BASE_PREFIX_COMPANY + '/Employee/PageList'
// 员工草稿信息
export const EMPLOYEE_INFORMATION_SAVE_DRAFT = BASE_PREFIX_COMPANY + '/Employee/SaveDraft'
// 保存员工信息
export const EMPLOYEE_INFORMATION_SUBMIT = BASE_PREFIX_COMPANY + '/Employee/Submit'
// 删除员工信息
export const EMPLOYEE_INFORMATION_DEL = BASE_PREFIX_COMPANY + '/Employee/Del'
// 查询员工信息
export const EMPLOYEE_INFORMATION_GETIFO = BASE_PREFIX_COMPANY + '/Employee/Info'
// 获取员工下拉组件数据
export const EMPLOYEE_INFORMATION_SELECT = BASE_PREFIX_COMPANY + '/Employee/Select'
// 导出员工信息
export const EMPLOYEE_INFORMATION_EXPORT = BASE_PREFIX_COMPANY + '/Employee/Export'
// 下载员工导入模板
export const EMPLOYEE_INFORMATION_DOWNLOADTEMP = HTTP_BASE_URL_JAVACHANGE + '/excel/employeeTemplate'
// 导入员工信息
export const EMPLOYEE_INFORMATION_IMPORT = BASE_PREFIX_COMPANY + '/Employee/Import'

/* ======新增保险====== */
// 查询员工保险信息详情
export const EMPLOYEE_GET_INSURANCE_INFO = BASE_PREFIX_COMPANY + '/Employee/InsuranceInfo'
// 查询员工保险信息分页列表
export const EMPLOYEE_GET_INSURANCE_PAGE = BASE_PREFIX_COMPANY + '/Employee/InsurancePageList'
// 新增员工保险信息
export const EMPLOYEE_SAVE_INSURANCE = BASE_PREFIX_COMPANY + '/Employee/AddInsurance'
// 修改员工保险信息
export const EMPLOYEE_EDIT_INSURANCE = BASE_PREFIX_COMPANY + '/Employee/EditInsurance'
// 删除员工保险信息
export const EMPLOYEE_DEL_INSURANCE = BASE_PREFIX_COMPANY + '/Employee/DelInsurance'
// 下载员工保险导入模板
export const EMPLOYEE_DOWNLOAD_INSURANCE_TEMP = BASE_PREFIX_COMPANY + '/Employee/DownloadInsuranceTemp'
// 导入员工保险信息
export const EMPLOYEE_IMPORT_INSURANCE = BASE_PREFIX_COMPANY + '/Employee/ImportInsurance'
// 导出员工保险信息
export const EMPLOYEE_EXPORT_INSURANCE = BASE_PREFIX_COMPANY + '/Employee/ExportInsurance'

/* ======员工紧急联系人====== */
// 查询员工紧急联系人信息
export const EMPLOYEE_GET_EMER_CONTACT_INFO = BASE_PREFIX_COMPANY + '/Employee/EmerContactInfo'
// 查询员工紧急联系人信息分
export const EMPLOYEE_GET_EMER_CONTACT_PAGE = BASE_PREFIX_COMPANY + '/Employee/EmerContactPageList'
// 新增员工紧急联系人信息
export const EMPLOYEE_SAVE_EMER_CONTACT = BASE_PREFIX_COMPANY + '/Employee/AddEmerContact'
// 修改员工紧急联系人信息
export const EMPLOYEE_EDIT_EMER_CONTACT = BASE_PREFIX_COMPANY + '/Employee/EditEmerContact'
// 删除员工紧急联系人
export const EMPLOYEE_DEL_EMER_CONTACT = BASE_PREFIX_COMPANY + '/Employee/DelEmerContact'
// 下载员工紧急联系人导入模
export const EMPLOYEE_DOWNLOAD_EMER_CONTACT_TEMP = BASE_PREFIX_COMPANY + '/Employee/DownloadEmerContactTemp'
// 导入员工紧急联系人信息
export const EMPLOYEE_IMPORT_EMER_CONTACT = BASE_PREFIX_COMPANY + '/Employee/ImportEmerContact'
// 导出员工紧急联系人信息
export const EMPLOYEE_EXPORT_EMER_CONTACT = BASE_PREFIX_COMPANY + '/Employee/ExportEmerContact'

/* ======员工工资账户信息====== */
// 查询员工工资账户信息
export const EMPLOYEE_GET_PAY_ROLL_ACC_INFO = BASE_PREFIX_COMPANY + '/Employee/PayRollAccInfo'
// 查询员工紧急联系人信息分
export const EMPLOYEE_GET_PAY_ROLL_ACC_PAGE = BASE_PREFIX_COMPANY + '/Employee/PayRollAccPageList'
// 新增员工工资账户信息
export const EMPLOYEE_SAVE_PAY_ROLL_ACC = BASE_PREFIX_COMPANY + '/Employee/AddPayRollAcc'
// 修改员工工资账户信息
export const EMPLOYEE_EDIT_PAY_ROLL_ACC = BASE_PREFIX_COMPANY + '/Employee/EditPayRollAcc'
// 员工工资账户设置状态
export const EMPLOYEE_PAY_ROLL_ACC_SET_STATE = BASE_PREFIX_COMPANY + '/Employee/PayRollAccSetState'
// 删除员工工资账户
export const EMPLOYEE_DEL_PAY_ROLL_ACC = BASE_PREFIX_COMPANY + '/Employee/DelPayRollAcc'
// 下载员工工资账户导入模板
export const EMPLOYEE_DOWNLOAD_PAY_ROLL_ACC_TEMP = BASE_PREFIX_COMPANY + '/Employee/DownloadPayRollAccTemp'
// 导入员工工资账户信息
export const EMPLOYEE_IMPORT_PAY_ROLL_ACC = BASE_PREFIX_COMPANY + '/Employee/ImportPayRollAcc'
// 导出员工紧急联系人信息
export const EMPLOYEE_EXPORT_PAY_ROLL_ACC = BASE_PREFIX_COMPANY + '/Employee/ExportPayRollAcc'
// 列表员工汇总
export const GET_LIST_SUMMARY = BASE_PREFIX_COMPANY + '/Employee/ListSummary'

/** ==============请假接口====================== */
// 查询请假单列表
export const ASK_FOR_LEAVE_LIST = BASE_PREFIX_COMPANY + '/Ask4Leave/GetPageList'
// 保存请假单列表新增编辑
export const ASK_FOR_LEAVE_SAVE = BASE_PREFIX_COMPANY + '/Ask4Leave/Save'
// 删除请假单列表
export const ASK_FOR_LEAVE_DEL = BASE_PREFIX_COMPANY + '/Ask4Leave/Del'
// 查询请假单详情
export const ASK_FOR_LEAVE_DETAILS = BASE_PREFIX_COMPANY + '/Ask4Leave/GetDetails'

/** ==============流程管理接口====================== */
// 查询流程模板
export const FLOW_TEMP_LIST = BASE_PREFIX_FLOW + '/FlowTemplete/FlowTempList'
// 查询流程模板节点
export const FLOW_TEMP_NODE_LIST = BASE_PREFIX_FLOW + '/FlowTemplete/FlowTempNodeList'
// 查询流程模板详情
export const FLOW_TEMP_DETAILS = BASE_PREFIX_FLOW + '/FlowTemplete/FlowTempDetails'
// 新增流程模板
export const ADD_FLOW_TEMP = BASE_PREFIX_FLOW + '/FlowTemplete/AddFlowTemp'
// 编辑流程模板
export const EDIT_FLOW_TEMP = BASE_PREFIX_FLOW + '/FlowTemplete/ModifyFlowTemp'
// 删除流程模板
export const DEL_FLOW_TEMP = BASE_PREFIX_FLOW + '/FlowTemplete/DelFlowTemp'
// 查询流程最新版本列表
export const FLOW_LIST = BASE_PREFIX_FLOW + '/FlowVersion/FlowList'
// 查询流程历史版本列表
export const OLD_FLOW_LIST = BASE_PREFIX_FLOW + '/FlowVersion/OldFlowList'
// 查询流程版本节点
export const FLOW_NODE_LIST = BASE_PREFIX_FLOW + '/FlowVersion/FlowNodeList'
// 查询流程版本详情
export const FLOW_DETAILS = BASE_PREFIX_FLOW + '/FlowVersion/FlowDetails'
// 申请编辑流程版本
export const APP_LY_EDIT_FLOW = BASE_PREFIX_FLOW + '/FlowVersion/ApplyEditFlow'
// 保存流程版本
export const SAVE_FLOW_VER = BASE_PREFIX_FLOW + '/FlowVersion/SaveFlowVer'
// 发布流程版本
export const PUBLISH_FLOW = BASE_PREFIX_FLOW + '/FlowVersion/PublishFlow'
// 删除流程草稿版本
export const DEL_FLOW = BASE_PREFIX_FLOW + '/FlowVersion/DelFlow'
// 启动业务流程
export const START_FLOW = BASE_PREFIX_FLOW + '/FlowBusiness/StartFlow'
// 审核业务流程
export const AUDIT_FLOW = BASE_PREFIX_FLOW + '/FlowBusiness/AuditFlow'
// 驳回业务流程
export const Reject_Flow = BASE_PREFIX_FLOW + '/FlowBusiness/RejectFlow'
// 撤销业务流程
export const Cancel_Flow = BASE_PREFIX_FLOW + '/FlowBusiness/CancelFlow'
// 流转业务流程
export const CHANGE_FLOW = BASE_PREFIX_FLOW + '/FlowBusiness/ChangeFlow'
// 根据业务单号查询流程信息
export const PROCESS_INFO = BASE_PREFIX_FLOW + '/FlowBusiness/FlowDetails'
// 我的相关流程
export const MY_FLOW_LIST = BASE_PREFIX_FLOW + '/FlowBusiness/MyFlowList'
// 查询流程任务*
export const FLOW_TASK_LIST = BASE_PREFIX_FLOW + '/FlowTask/FlowTaskList'
// 我的待办任务
export const MY_TASK_LIST = BASE_PREFIX_FLOW + '/FlowTask/MyTaskList'
// 保存动态任务*
export const SAVE_DYNAMIC_TASK = BASE_PREFIX_FLOW + '/FlowTask/SaveDynamicTask'
// 发布任务*
export const PUBLISH_TASK = BASE_PREFIX_FLOW + '/FlowTask/PublishTask'
// 删除任务
export const DEL_TASK = BASE_PREFIX_FLOW + '/FlowTask/DelTask'

/** ==============服务器信息接口====================== */
// 下拉数据接口
export const DBSERVER_SELECT = BASE_PREFIX_SYS + '/DBServer/Select'

/** ==============账号管理接口====================== */
// 账号列表
export const ACCOUNT_PAGE = BASE_PREFIX_USER + '/UserAccount/PageList'
// 账号详情
export const ACCOUNT_INFO = BASE_PREFIX_USER + '/UserAccount/Info'
// 账号下拉
export const ACCOUNT_SELECT = BASE_PREFIX_USER + '/UserAccount/Select'
// 角色下拉
export const ACCOUNT_ROLE = BASE_PREFIX_USER + '/UserAccount/GetRole'
// 绑定用户
export const BIND_EMP_LOYEE = BASE_PREFIX_USER + '/UserAccount/BinEmployee'
// 修改账号
export const ACCOUNT_EDIT = BASE_PREFIX_USER + '/UserAccount/Edit'
// 扩展账号
export const ACCOUNT_EXTEND = BASE_PREFIX_USER + '/UserAccount/Extend'
// 查看账号汇总
export const ACCOUNT_LIST_SUMMARY = BASE_PREFIX_USER + '/UserAccount/ListSummary'
// 账号休眠
export const ACCOUNT_DORMANCY = BASE_PREFIX_USER + '/UserAccount/Dormancy'
// 重置密码
export const RESET_PASSWORD = BASE_PREFIX_USER + '/UserAccount/ResetPassWord'
// 账号激活
export const ACCOUNT_ACTIVATION = BASE_PREFIX_USER + '/UserAccount/Activation'
// 账号延时
export const ACCOUNT_DELAYED = BASE_PREFIX_USER + '/UserAccount/Delayed'

// 高温补助分页
export const BASICDATA_HTSGETPAGE = BASE_PREFIX_SYS + '/BasicData/HTSPageList'
export const BASICDATA_HTSGETPAGE_BASE = BASE_PREFIX_SYS + '/BasicDataBase/HTSPageList'
// 新增高温补助
export const BASICDATA_HTSSAVE = BASE_PREFIX_SYS + '/BasicData/AddHTS'
export const BASICDATA_HTSSAVE_BASE = BASE_PREFIX_SYS + '/BasicDataBase/AddHTS'
// 修改高温补助
export const BASICDATA_HTSEDIT = BASE_PREFIX_SYS + '/BasicData/EditHTS'
export const BASICDATA_HTSEDIT_BASE = BASE_PREFIX_SYS + '/BasicDataBase/EditHTS'

// 批量新增编辑高温补贴
export const BASICDATA_SAVE_HTS = BASE_PREFIX_SYS + '/BasicData/SaveHTS'

// 删除高温补助
export const BASICDATA_HTSDEL = BASE_PREFIX_SYS + '/BasicData/DelHTS'
export const BASICDATA_HTSDEL_BASE = BASE_PREFIX_SYS + '/BasicDataBase/DelHTS'
// 查询高温补贴下拉
export const BASICDATA_HTSSELECT = BASE_PREFIX_SYS + '/BasicData/HTSSelect'
// 查询高温补贴详情
export const BASICDATA_HTSGETINFO = BASE_PREFIX_SYS + '/BasicData/HTSInfo'
export const BASICDATA_HTSGETINFO_BASE = BASE_PREFIX_SYS + '/BasicDataBase/HTSInfo'

/** 货架列表接口 */
export const WAERH_MINI_LIST = BASE_PREFIX_WAERH_SHELF + '/Wareh_List/MiniList'
export const WAERH_SHELF_LIST = BASE_PREFIX_WAERH_SHELF + '/Wareh_Shelf/List'
export const WAERH_SHELF_STATIS = BASE_PREFIX_WAERH_SHELF + '/Wareh_Shelf/Statis'
export const WAERH_SHELF_CELLLIST = BASE_PREFIX_WAERH_SHELF + '/Wareh_Shelf/CellList'
export const WAERH_SHELF_SHELF = BASE_PREFIX_WAERH_SHELF + '/Wareh_Shelf/Shelf'

export const WAERH_SHELF_TEMPHUMISIGN = BASE_PREFIX_WAERH_SHELF + '/Wareh_Shelf/TempHumiSign'
export const WAERH_SHELF_SUMMARY = BASE_PREFIX_WAERH_SHELF + '/Wareh_Shelf/Summary'
export const WAERH_SHELF_ALARMSTATIS = BASE_PREFIX_WAERH_SHELF + '/Wareh_Shelf/AlarmStatis'
export const WAERH_SHELF_ONOFFSTATIS = BASE_PREFIX_WAERH_SHELF + '/Wareh_Shelf/OnOffStatis'

// 下级库位分区信息接口
export const WAERH_PARTITIONLISTQUERY = BASE_PREFIX_WAERHOUSE + '/queryPartitionList'

/** 账号接口 */
// 获取账号下拉组件数据
export const COMPANY_EQUIPMENT_SELECTS = BASE_PREFIX_USER + '/UserAccount/Select'

/** 查询仓库列表*/
export const WARCH_LIST = BASE_PREFIX_WAERHOUSE_CHANGE + '/queryWarehList'
/** 查询仓库类别*/
export const WARCH_CATEGORY = BASE_PREFIX_WAERHOUSE_CHANGE + '/queryWarehCategory'
/** 新增仓库类别*/
export const WARCH_CATEGORY_ADD = BASE_PREFIX_WAERHOUSE_CHANGE + '/addWarehCategory'
/** 修改仓库类别*/
export const WARCH_CATEGORY_EDIT = BASE_PREFIX_WAERHOUSE_CHANGE + '/updateWarehCategory'
/** 批量删除仓库类别*/
export const WARCH_CATEGORY_DEL = BASE_PREFIX_WAERHOUSE_CHANGE + '/deleteWarehCategory'
/** 查询日志java */
export const WARCH_CATEGORY_LOG = BASE_PREFIX_WAERHOUSE_CHANGE + '/queryOperLog'

/** 新增仓库*/
export const WARCH_ADD = BASE_PREFIX_WAERHOUSE_CHANGE + '/addWareh'
/** 删除仓库*/
export const WARCH_DEL = BASE_PREFIX_WAERHOUSE_CHANGE + '/deleteWareh'
/** 批量删除仓库*/
export const WARCH_BATCH_DEL = BASE_PREFIX_WAERHOUSE_CHANGE + '/batchDeleteWareh'

/** 修改仓库 */
export const WARCH_EDIT = BASE_PREFIX_WAERHOUSE_CHANGE + '/updateWareh'
/** 查询单个仓库*/
export const WAREHOUSE_SINGLE_QUERY = BASE_PREFIX_WAERHOUSE_CHANGE + '/querySingleWareh'
/** 仓库导出excel*/
export const WAREHOUSE_EXPORT = BASE_PREFIX_WAERHOUSE_CHANGE + '/warehouseExport'
/** 仓库导入excel*/
export const WAREHOUSE_IMPORT = BASE_PREFIX_WAERHOUSE_CHANGE + '/warehouseImport'
/** 仓库下载excel模板*/
export const WAREHOUSE_TEMPLATE = BASE_PREFIX_WAERHOUSE_CHANGE + '/warehouseTemplate'

/** 查询仓库所有分区列表*/
export const PARTITION_LIST = BASE_PREFIX_WAERHOUSE_CHANGE + '/queryPartitionList'
/** 新增分区*/
export const PARTITION_ADD = BASE_PREFIX_WAERHOUSE_CHANGE + '/addPartition'
/** 删除分区*/
export const PARTITION_DEL = BASE_PREFIX_WAERHOUSE_CHANGE + '/deletePartition'
/** 批量删除分区*/
export const PARTITION_BATCH_DEL = BASE_PREFIX_WAERHOUSE_CHANGE + '/batchDeletePartition'

/** 修改分区 */
export const PARTITION_EDIT = BASE_PREFIX_WAERHOUSE_CHANGE + '/updatePartition'
/** 查询单个分区*/
export const PARTITION_SINGLE_QUERY = BASE_PREFIX_WAERHOUSE_CHANGE + '/querySinglePartition'
/** 分区导出excel*/
export const PARTITION_EXPORT = BASE_PREFIX_WAERHOUSE_CHANGE + '/partitionExport'
/** 分区导入excel*/
export const PARTITION_IMPORT = BASE_PREFIX_WAERHOUSE_CHANGE + '/partitionImport'
/** 分区下载excel模板*/
export const PARTITION_TEMPLATE = BASE_PREFIX_WAERHOUSE_CHANGE + '/partitionTemplate'
/** 查询空闲储位*/
export const QUERYIDLESTORAGESPACE = BASE_PREFIX_WAERHOUSE_CHANGE + '/queryIdleStorageSpace'

// 查询仓库库位信息接口
export const WAERH_LOCATIONLISTQUERY = BASE_PREFIX_WAERHOUSE + '/queryLocationList'
// 新增库位
export const LOCATION_ADD = BASE_PREFIX_WAERHOUSE + '/addLocation'
// 查询库位详情
export const LOCATION_DETAIL = BASE_PREFIX_WAERHOUSE + '/querySingleLocation'
// 库位更新接口
export const LOCATION_UPDATE = BASE_PREFIX_WAERHOUSE + '/updateLocation'
// 库位删除接口
export const LOCATION_DEL = BASE_PREFIX_WAERHOUSE + '/deleteLocation'

// 查询仓库库号
export const LIBRARY_QUERYLIST = BASE_PREFIX_WAERHOUSE + '/queryLibraryNoList'
// 新增库号
export const LIBRARY_ADD = BASE_PREFIX_WAERHOUSE + '/addLibraryNo'
// 查询库号详情
export const LIBRARY_QUERYID = BASE_PREFIX_WAERHOUSE + '/querySingleLibraryNo'
// 库号更新接口
export const LIBRARY_UPDATE = BASE_PREFIX_WAERHOUSE + '/updateLibraryNo'
// 库号删除接口
export const LIBRARY_DEL = BASE_PREFIX_WAERHOUSE + '/deleteLibraryNo'

// 非简写字段接口
// 库位所在分区下拉框接口
export const CHANGE_PARTITION_LIST = BASE_PREFIX_WAERHOUSE_CHANGE + '/queryPartitionList'
// 新增下级库位
export const CHANGE_LOCATION_ADD = BASE_PREFIX_WAERHOUSE_CHANGE + '/addLocation'
// 新增下级库位
export const CHANGE_LOCATION_UPDATE = BASE_PREFIX_WAERHOUSE_CHANGE + '/updateLocation'
// 查询单个库位
export const CHANGE_LOCATION_QUERYSINGLE = BASE_PREFIX_WAERHOUSE_CHANGE + '/querySingleLocation'
// 删除库位
export const CHANGE_LOCATION_DELETE = BASE_PREFIX_WAERHOUSE_CHANGE + '/deleteLocation'
// 获取库位分页数据接口
export const CHANGE_LOCATION_LIST = BASE_PREFIX_WAERHOUSE_CHANGE + '/queryLocationList'
// 仓库下级查询上级信息接口
// 查询仓库打印信息
export const CHANGE_LOCATION_QUERYDETAILSESSION = BASE_PREFIX_WAERHOUSE_CHANGE + '/queryWarehouseFullDetails'
// 库位下载excl
export const CHANGE_LOCATION_STORAGELOCATIONTEMPLATE = BASE_PREFIX_WAERHOUSE_CHANGE + '/storageLocationTemplate'
// 库号下载excl
export const CHANGE_LOCATION_LIBRARYNOTEMPLATE = BASE_PREFIX_WAERHOUSE_CHANGE + '/libraryNoTemplate'
// 库位导入excl
export const CHANGE_LOCATION_STORAGELOCATIONIMPORT = BASE_PREFIX_WAERHOUSE_CHANGE + '/storageLocationImport'
// 库号导入excl
export const CHANGE_LOCATION_LIBRARYNOIMPORT = BASE_PREFIX_WAERHOUSE_CHANGE + '/libraryNoImport'
// 库位导出excl
export const CHANGE_LOCATION_STORAGELOCATIONEXPORT = BASE_PREFIX_WAERHOUSE_CHANGE + '/storageLocationExport'
// 库号导出excl
export const CHANGE_LOCATION_LIBRARYNOEXPORT = BASE_PREFIX_WAERHOUSE_CHANGE + '/libraryNoExport'

// 库号接口
export const CHANGE_LIBRARYNO_LIST = BASE_PREFIX_WAERHOUSE_CHANGE + '/queryLibraryNoList'
// 查询库号的货品
export const CHANGE_QUERYNOTUSED = BASE_PREFIX_WAERHOUSE_CHANGE + '/library/queryNotUsed'
// 新增库号
export const CHANGE_LIBRARY_ADD = BASE_PREFIX_WAERHOUSE_CHANGE + '/addLibraryNo'
// 更新库号
export const CHANGE_LIBRARYT_UPDATE = BASE_PREFIX_WAERHOUSE_CHANGE + '/updateLibraryNo'
// 查询单个库号
export const CHANGE_LIBRARYT_QUERYSINGLE = BASE_PREFIX_WAERHOUSE_CHANGE + '/querySingleLibraryNo'
// 删除库号
export const CHANGE_LIBRARYT_DELETE = BASE_PREFIX_WAERHOUSE_CHANGE + '/deleteLibraryNo'
// 根据页面名称查询table列表数据
// export const TABLE_POST_COLUMNLIST = BASE_PREFIX_SYS + '/Table/ColumnDataList'

// 根据页面名称查询form列表数据
export const GETFORMCOLLIST_SYS = BASE_PREFIX_SYS + '/Table/GetFormColList'
// 查询资源版本
export const QUERYsYSTEMVERSION = BASE_PREFIX_INIT_START_RESOUCE + '/resource/querySystemVersion'
// 获取国际化
export const GETSOURCEBYKEY = BASE_PREFIX_SYS + '/Language/SourceByKeys'
// 根据资源id修改资源列表排序、列宽、显隐
export const RESOURCELISTUPDATE = BASE_PREFIX_INIT_START_RESOUCE + '/resourcelist/update'

// 收货管理-- 根据条件查询在途物料列表-平铺
export const QUERYINTRANSITMATERIALS = BASE_PREFIX_WAREHINTRANSIT_CHANGE + '/queryInTransitMaterials'
// 收货管理-- 根据条件查询在途物料列表-收起
export const GETINTRANSITMATERIALSPACK = BASE_PREFIX_WAREHINTRANSIT_CHANGE + '/getInTransitMaterialsPack'
// 获取formid
export const RECEIPT_GETID = BASE_PREFIX_WAREHINTRANSIT_CHANGE + '/getId'
// 在途物料导出-平铺
export const INTRANSITEXPORT = BASE_PREFIX_WAREHINTRANSIT_CHANGE + '/inTransitExport'
// 在途物料导出-收起
export const INTRANSITPACKEXPORT = BASE_PREFIX_WAREHINTRANSIT_CHANGE + '/inTransitPackExport'
// 收货记录导出--平铺明细
export const RECEIPTDETAILSEXPORT = BASE_PREFIX_WAREHINTRANSIT_CHANGE + '/receiptDetailsExport'
// 收货记录导出--收起明细
export const RECEIPT_RECEIPTTICKETEXPORT = BASE_PREFIX_WAREHINTRANSIT_CHANGE + '/receiptTicketExport'
// 查询收货单关联的源单信息
export const RECEIPTSOURCEDOC = BASE_PREFIX_WAREHINTRANSIT_CHANGE + '/receiptSourceDoc'

// 查询收货单提交页面的货品信息
export const QUERYPRODUCTINFORMATION = BASE_PREFIX_WAREHINTRANSIT_CHANGE + '/queryProductInformation'

// 收货记录删除接口 收起明细和展开明细调用的接口不一样
export const DELETERECEIPTTICKET = BASE_PREFIX_WAREHINTRANSIT_CHANGE + '/deleteReceiptTicket'
export const DELETERECEIPTTICKETROW = BASE_PREFIX_WAREHINTRANSIT_CHANGE + '/deleteReceiptTicketRow'
// 新增收货单 行
export const RECEPTADDROW = BASE_PREFIX_WAREHINTRANSIT_CHANGE + '/addRow'
// 更新收货单 行
export const RECEIPT_UPDATEROW = BASE_PREFIX_WAREHINTRANSIT_CHANGE + '/updateRow'
// 导出收货单行
export const RECEIPT_ROWEXPORT = BASE_PREFIX_WAREHINTRANSIT_CHANGE + '/rowExport'
// 导入收货单行
export const RECEIPT_ROWIMPORT = BASE_PREFIX_WAREHINTRANSIT_CHANGE + '/rowImport'
// 根据收货单编码查询收货单详情
export const RECEIPT_QUERYRECEIPTTICKET = BASE_PREFIX_WAREHINTRANSIT_CHANGE + '/queryReceiptTicket'

/** 入库 */
// 待入库记录-收起
export const PUTINSTORAGE_QUERYSTORAGELIST = BASE_PREFIX_WAREHOUSING + '/queryStorageList'
// 待入库导出-收起
export const PUTINSTORAGE_STORAGEEXPORT = BASE_PREFIX_WAREHOUSING + '/storageExport'
// 待入库记录-平铺
export const PUTINSTORAGE_QUERYWAITINGSTORAGELIST = BASE_PREFIX_WAREHOUSING + '/queryWaitingStorageList'
// 入库记录-导出-平铺
export const PUTINSTORAGE_STORAGEDETAILSEXPORT = BASE_PREFIX_WAREHOUSING + '/storageDetailsExport'
// 入库记录-导出-收起
export const PUTINSTORAGE_STOCKINEXPORT = BASE_PREFIX_WAREHOUSING + '/stockInExport'

// 待入库导出-平铺
export const PUTINSTORAGE_WAITINGSTORAGEEXPORT = BASE_PREFIX_WAREHOUSING + '/waitingStorageExport'
// 获取入库单fromId(草稿)
export const PUTINSTORAGE_GETID = BASE_PREFIX_WAREHOUSING + '/getId'
// 新增入库单行（草稿）
export const PUTINSTORAGE_ADDROW = BASE_PREFIX_WAREHOUSING + '/addRow'
// 新增入库单(提交/草稿)
export const PUTINSTORAGE_ADDSTOCKIN = BASE_PREFIX_WAREHOUSING + '/addStockIn'
// 删除入库单行
export const PUTINSTORAGE_DELETESTORAGEDETAILS = BASE_PREFIX_WAREHOUSING + '/deleteStorageDetails'
// 删除入库单
export const PUTINSTORAGE_DELETESTOCKIN = BASE_PREFIX_WAREHOUSING + '/deleteStockIn'
// 修改入库单行（草稿）
export const PUTINSTORAGE_UPDATEROW = BASE_PREFIX_WAREHOUSING + '/updateRow'
// 导出excel入库单行
export const PUTINSTORAGE_ROWEXPORT = BASE_PREFIX_WAREHOUSING + '/rowExport'
// 导入excel入库单行
export const PUTINSTORAGE_ROWIMPORT = BASE_PREFIX_WAREHOUSING + '/rowImport'
// 导出excel模板入库单行
export const PUTINSTORAGE_ROWTEMPLATE = BASE_PREFIX_WAREHOUSING + '/rowTemplate'
// 正式提交与保存草稿
export const PUTINSTORAGE_ADDBILL = BASE_PREFIX_WAREHOUSING + '/addBill'
export const PUTINSTORAGE_ADDBILLDRAFT = BASE_PREFIX_WAREHOUSING + '/addBillDraft'
// 查询入库单关联源单
export const PUTINSTORAGE_STORAGESOURCEDOCLIST = BASE_PREFIX_WAREHOUSING + '/storageSourceDocList'
// 查询源单货品
export const PUTINSTORAGE_QUERYPAGELIST = BASE_PREFIX_WAREHOUSING + '/sourceOrderList/queryPageList'
// 查询入库记录list 平铺
export const PUTINSTORAGE_QUERYSTORAGEDETAILSLIST = BASE_PREFIX_WAREHOUSING + '/queryStorageDetailsList'
// 查询入库记录list 收起
export const PUTINSTORAGE_QUERYSTOCKINLIST = BASE_PREFIX_WAREHOUSING + '/queryStockInList'
// 查询入库单货品信息
export const PUTINSTORAGE_QUERYPRODUCTINFORMATIONLIST = BASE_PREFIX_WAREHOUSING + '/queryProductInformationList'
// 查询库存列表
export const PUTINSTORAGE_QUERYINVENTORYLIST = BASE_PREFIX_WAREHOUSING + '/queryInventoryList'
// 查询库存列表
export const PUTINSTORAGE_COUNTERREVIEW = BASE_PREFIX_WAREHOUSING + '/counterReview'
// 查询库存列表
export const PUTINSTORAGE_REVOKE = BASE_PREFIX_WAREHOUSING + '/revoke'

/** 库存 */
// 查询出库记录list 平铺
export const INVENTORY_QUERYPAGELIST = BASE_PREFIX_INVENTORY + '/queryPageList'
// 查询出库记录list 收起
export const INVENTORY_QUERYPACKPAGELIST = BASE_PREFIX_INVENTORY + '/queryPackPageList'
// 查询库存列表-收起（基于批次汇总）
export const INVENTORY_QUERYBATCHPAGELIST = BASE_PREFIX_INVENTORY + '/queryBatchPageList'
// 物料库存查询(根据物料料号查询)
export const INVENTORY_QUERYINVENTORYPAGE = BASE_PREFIX_INVENTORY + '/queryInventoryPage'
// 物料库存统计信息查询(根据物料料号查询)
export const INVENTORY_QUERYINVENTORYSTATISTICS = BASE_PREFIX_INVENTORY + '/queryInventoryStatistics'
// 查询物料出库库存(根据物料料号查询)）
export const INVENTORY_QUERYMATERIALOUTBOUNDPAGE = BASE_PREFIX_INVENTORY + '/queryMaterialOutboundPage'
// 查询物料入库库存(根据物料料号查询)
export const INVENTORY_QUERYMATERIALRECEIPTPAGE = BASE_PREFIX_INVENTORY + '/queryMaterialReceiptPage'
// 查询物料出库库存统计(根据物料料号查询)
export const INVENTORY_QUERYOUTBOUNDSTATISTICS = BASE_PREFIX_INVENTORY + '/queryOutboundStatistics'
// 查询物料入库库存统计(根据物料料号查询)
export const INVENTORY_QUERYRECEIPTSTATISTICS = BASE_PREFIX_INVENTORY + '/queryReceiptStatistics'
// 物料库存导出(必传参数：物料料号)
export const INVENTORY_INVENTORYEXPORT = BASE_PREFIX_INVENTORY + '/inventoryExport'
// 物料出库库存导出(必传参数：物料料号)
export const INVENTORY_MATERIALOUTBOUNDEXPORT = BASE_PREFIX_INVENTORY + '/materialOutboundExport'
// 物料入库库存导出(必传参数：物料料号)
export const INVENTORY_MATERIALRECEIPTEXPORT = BASE_PREFIX_INVENTORY + '/materialReceiptExport'

/** 采购-供应商接口 */
// 查询供货商基础数据                                             GetSupplierlnfo
export const GETSUPPLIERINFO = BASE_PREFIX_PURCHASE_V + '/supplier/SupplierInfo'
// 查询供货商基础信息分页列表
// export const GETSUPPLIERPAGE = BASE_PREFIX_PURCHASE + '/supplier/supplierPageList'
export const GETSUPPLIERPAGE = BASE_PREFIX_PURCHASE_V + '/supplier/querySupplierPageList'
// 查询供货商基础信息列表
export const QUERY_SUPPLIER_LIST = BASE_PREFIX_PURCHASE_V + '/supplier/querySupplierList'
// 查询供应商统计详情，（根据供应商编号查询）
export const QUERY_STATISTICS = BASE_PREFIX_PURCHASE_V + '/supplier/queryStatistics'
// 保存供货商数据保存草稿
export const SUPPLIER_SAVEDRAFT = BASE_PREFIX_PURCHASE_V + '/supplier/SaveDraft'
// 修改供货商基础信息
export const EDITSUPPLIER = BASE_PREFIX_PURCHASE_V + '/supplier/EditSupplier'
// 删除供货商基础信息
export const DELSUPPLIER = BASE_PREFIX_PURCHASE_V + '/supplier/DelSupplier'
// 供应商基础信息列表
export const SUPPLIERPAGE = BASE_PREFIX_PURCHASE_V + '/supplier/SupplierPage'
/**
 * 待检物料和质检记录
 */
// 获取质检单fromId(草稿)
export const GETID = BASE_PREFIX_WAREHQUALITYCONTROL + '/getId'
// 提交质检单
export const ADDBILL = BASE_PREFIX_WAREHQUALITYCONTROL + '/addBill'
// 保存质检单草稿
export const ADDBILLDRAFT = BASE_PREFIX_WAREHQUALITYCONTROL + '/addBillDraft'
// 质检单保存草稿
export const ADDQACDRAFT = BASE_PREFIX_WAREHQUALITYCONTROL + '/addQualityAnalysisCertificateDraft'
// 新增质检单（行）-草稿
export const ADDROW = BASE_PREFIX_WAREHQUALITYCONTROL + '/addRow'
// 导出质检单行
export const ROWEXPORT = BASE_PREFIX_WAREHQUALITYCONTROL + '/rowExport'
// 导入质检单行-草稿(excel表格file，key:质检单号,value:单据start)
export const ROWIMPORT = BASE_PREFIX_WAREHQUALITYCONTROL + '/rowImport'
// 质检单行模板下载
export const ROWTEMPLATE = BASE_PREFIX_WAREHQUALITYCONTROL + '/rowTemplate'
// 修改质检单-草稿/提交
export const UPDATEBILL = BASE_PREFIX_WAREHQUALITYCONTROL + '/updateBill'
// 修改质检单行-草稿/提交
export const UPDATEROW = BASE_PREFIX_WAREHQUALITYCONTROL + '/updateRow'
// 修改质检单行-反审
export const COUNTERREVIEW = BASE_PREFIX_WAREHQUALITYCONTROL + '/counterReview'
// 修改质检单行-撤回流程
export const REVOKE = BASE_PREFIX_WAREHQUALITYCONTROL + '/revoke'

// 查询物料待检信息
export const QUERYQUALITYCONTROLAWAIT = BASE_PREFIX_WAREHQUALITYCONTROL + '/queryQualityControlAwait'
// 查询物料待检-收起明细
export const QUERYRECEIPTTICKET = BASE_PREFIX_WAREHQUALITYCONTROL + '/queryReceiptTicketList'
// 待检物料导出
export const QUALITYCONTROLAWAITEXPORT = BASE_PREFIX_WAREHQUALITYCONTROL + '/qualityControlAwaitExport'

// 查询质检记录-平铺明细
export const INSPECTIONRECORDS_LIST = BASE_PREFIX_WAREHQUALITYCONTROL + '/queryQualityInspectionRecords'
// 查询质检记录-收起明细
export const ANALYSISCERTIFICATE_LIST = BASE_PREFIX_WAREHQUALITYCONTROL + '/queryQualityAnalysisCertificate'
// 修改质检单行
// export const UPDATEQUALITYCONTROL = BASE_PREFIX_WAREHQUALITYCONTROL + '/updateQualityControl'
// 修改质检单
export const UPDATEQUALITYCONTROLAWAIT = BASE_PREFIX_WAREHQUALITYCONTROL + '/updateQualityControlAwait'
// 根据质检单号，查询质检单详情
export const QUERYQUALITYCONTROL = BASE_PREFIX_WAREHQUALITYCONTROL + '/queryQualityControl'
// 质检记录-收起-导出
export const QUALITYANALYSISCERTIFICATEEXPORT = BASE_PREFIX_WAREHQUALITYCONTROL + '/qualityAnalysisCertificateExport'
// 质检记录-平铺-导出
export const QUALITYCONTROLEXPORT = BASE_PREFIX_WAREHQUALITYCONTROL + '/qualityControlExport'
// 质检记录-批量删除质检单-收起明细
export const DELINSPECTIONRECORD = BASE_PREFIX_WAREHQUALITYCONTROL + '/deleteQualityAnalysisCertificate'
// 质检记录-批量删除质检单行-平铺明细
export const DELQUALITYCONTROL = BASE_PREFIX_WAREHQUALITYCONTROL + '/deleteQualityControl'
// 新增质检记录
export const ADDINSPECTIONRECORD = BASE_PREFIX_WAREHQUALITYCONTROL + '/addQualityAnalysisCertificate'
// 根据条件，查询质检单关联源单
export const QUALITYCONTROLSOURCEDOC = BASE_PREFIX_WAREHQUALITYCONTROL + '/qualityControlSourceDoc'
// 查询质检单货品信息
export const QUALITYPRODUCTINFORMATION = BASE_PREFIX_WAREHQUALITYCONTROL + '/qualityProductInformation'

// 根据条件查询收货记录 平铺明细
export const QUERYRECEIPTDETAILS = BASE_PREFIX_WAREHINTRANSIT_CHANGE + '/queryReceiptDetails'
// 根据条件查询收货记录 收起明细
export const QUERYRECEIPTTICKETLIST = BASE_PREFIX_WAREHINTRANSIT_CHANGE + '/queryReceiptTicketList'
// 查询源单列表（参数type:单据类型）
export const SOURCEQUERYPAGE = HTTP_BASE_URL_JAVACHANGE + '/sourceorder/queryPage'
// 查询源单详情（参数orderCode:单据编码
export const SOURCEQUERYDETAILSPAGE = HTTP_BASE_URL_JAVACHANGE + '/sourceorder/queryDetailsPage'
// /sourceorder/queryRQ 查询收货、质检单源单信息(key:单据类型编码，value:源单单号)
export const SOURCEORDERQUERYRQ = HTTP_BASE_URL_JAVACHANGE + '/sourceorder/queryRQ'
// /sourceorder/scanVerification 源单校验(key:单据类型编码，value:源单单号)
export const SOURCEORDERSCANVERIFICATION = HTTP_BASE_URL_JAVACHANGE + '/sourceorder/scanVerification'
// 源单使用校验
export const USEVALID = HTTP_BASE_URL_JAVACHANGE + '/sourceOrder/useValid'
// 新增收货单--提交
export const RECEIPTADDBILL = BASE_PREFIX_WAREHINTRANSIT_CHANGE + '/addBill'
// export const ADDRECEIPTTICKET = BASE_PREFIX_WAREHINTRANSIT_CHANGE + '/addReceiptTicket'

// 收货提交接口--草稿
export const BASE_PREFIX_ADDBILLDRAFT = BASE_PREFIX_WAREHINTRANSIT_CHANGE + '/addBillDraft'

// 收货提交接口--反审
export const BASE_PREFIX_COUNTERREVIEW = BASE_PREFIX_WAREHINTRANSIT_CHANGE + '/counterReview'

// 收货提交接口--撤回
export const BASE_PREFIX_REVOKE = BASE_PREFIX_WAREHINTRANSIT_CHANGE + '/revoke'

// 保存供货商数据保存草稿
export const SUPPLIER_SAVE_DRAFT = BASE_PREFIX_PURCHASE_V + '/supplier/SaveDraft'
// 提交供应商
export const SUPPLIER_SUBMIT = BASE_PREFIX_PURCHASE_V + '/supplier/Submit'
// =============对外投资
// 查询对外投资信息
export const OUT_BOUN_INFO = BASE_PREFIX_COMPANY + '/CustomerSupplier/OutBounInfo'
// 查询对外投资分页列表
export const OUT_BOUN_PAGE_LIST = BASE_PREFIX_COMPANY + '/CustomerSupplier/OutBounPageList'
// 新增对外投资信息
export const ADD_OUT_BOUN = BASE_PREFIX_COMPANY + '/CustomerSupplier/AddOutBoun'
// 修改对外投资信息
export const EDIT_OUT_BOUN = BASE_PREFIX_COMPANY + '/CustomerSupplier/EditOutBoun'
// 删除对外投资
export const DEL_OUT_BOUN = BASE_PREFIX_COMPANY + '/CustomerSupplier/DelOutBoun'
// 下载对外投资导入模板
export const DOWNLOAD_OUT_BOUN = BASE_PREFIX_COMPANY + '/CustomerSupplier/DownloadOutBoun'
// 导入对外投资
export const IMPORT_OUT_BOUN = BASE_PREFIX_COMPANY + '/CustomerSupplier/ImportOutBoun'
// 导出对外投资
export const EXPORT_OUT_BOUN = BASE_PREFIX_COMPANY + '/CustomerSupplier/ExportOutBoun'

// =============知识产权
// 查询知识产权信息
export const INTE_PROP_INFO = BASE_PREFIX_COMPANY + '/CustomerSupplier/IntePropInfo'
// 查询知识产权分页列表
export const INTE_PROP_PAGE_LIST = BASE_PREFIX_COMPANY + '/CustomerSupplier/IntePropPageList'
// 新增知识产权信息
export const ADD_INTE_PROP = BASE_PREFIX_COMPANY + '/CustomerSupplier/AddInteProp'
// 修改知识产权信息
export const EDIT_INTE_PROP = BASE_PREFIX_COMPANY + '/CustomerSupplier/EditInteProp'
// 删除知识产权
export const DEL_INTE_PROP = BASE_PREFIX_COMPANY + '/CustomerSupplier/DelInteProp'
// 下载知识产权导入模板
export const DOWNLOAD_INTE_PROP = BASE_PREFIX_COMPANY + '/CustomerSupplier/DownloadInteProp'
// 导入知识产权
export const IMPORT_INTE_PROP = BASE_PREFIX_COMPANY + '/CustomerSupplier/ImportInteProp'
// 导出知识产权
export const EXPORT_INTE_PROP = BASE_PREFIX_COMPANY + '/CustomerSupplier/ExportInteProp'

/* ===========================代码生成============================= */
// 新增数据源
export const DATASOURCE_ADD = BASE_PREFIX_GENERATE + '/datasource/add'
// 删除数据源
export const DATASOURCE_DELETE = BASE_PREFIX_GENERATE + '/datasource/delete'
// 数据源分页查询
export const DATASOURCE_LIST = BASE_PREFIX_GENERATE + '/datasource/queryPageList'
// 更新数据源
export const DATASOURCE_UPDATE = BASE_PREFIX_GENERATE + '/datasource/update'

// 新增模板
export const TEMPLATE_ADD = BASE_PREFIX_GENERATE + '/template/add'
// 删除模板
export const TEMPLATE_DELETE = BASE_PREFIX_GENERATE + '/template/delete'
// 模板分页查询
export const TEMPLATE_PAGE_LIST = BASE_PREFIX_GENERATE + '/template/queryPageList'
// 模板查询
export const TEMPLATE_QUERY_SINGLE = BASE_PREFIX_GENERATE + '/template/querySingle'
// 更新模板
export const TEMPLATE_UPDATE = BASE_PREFIX_GENERATE + '/template/update'
// 模板组下拉
export const TEMPLATE_QUERY_LIST = BASE_PREFIX_GENERATE + '/template/queryList'

// 新增模板组
export const TEMPLATE_ADD_GROUP = BASE_PREFIX_GENERATE + '/template/addGroup'
// 批量新增模板组
export const TEMPLATE_ADD_GROUP_BATCH = BASE_PREFIX_GENERATE + '/template/addGroupBatch'
// 删除模板组
export const TEMPLATE_DELETE_GROUP = BASE_PREFIX_GENERATE + '/template/deleteGroup'
// 模板组分页查询
export const TEMPLATE_GROUP_PAGE_LIST = BASE_PREFIX_GENERATE + '/template/queryGroupPageList'
// 模板组单个查询
export const TEMPLATE_QUERY_GROUP = BASE_PREFIX_GENERATE + '/template/queryGroup'
// 更新模板组
export const TEMPLATE_UPDATE_GROUP = BASE_PREFIX_GENERATE + '/template/updateGroup'

// 新增变量
export const VARIABLE_ADD = BASE_PREFIX_GENERATE + '/variable/add'
// 删除变量
export const VARIABLE_DELETE = BASE_PREFIX_GENERATE + '/variable/delete'
// 变量查询
export const VARIABLE_VARIABLE_LIST = BASE_PREFIX_GENERATE + '/variable/queryVariableList'
// 更新变量
export const VARIABLE_UPDATE = BASE_PREFIX_GENERATE + '/variable/update'
// 货架主板
export const SHELF_BOARD_MODEL = HTTP_BASE_URL_JAVACHANGE + '/warehousing/shelfBoardModel'

// 合并标签
export const MATERIALLABEL_CREAEBYMERGE = BASE_PREFIX_MATERIALLABEL + '/creaeByMerge'
// 根据源单创建标签
export const MATERIALLABEL_CREAEBYSOURCE = BASE_PREFIX_MATERIALLABEL + '/creaeBySource'
// 分拆标签
export const MATERIALLABEL_CREAEBYSPLIT = BASE_PREFIX_MATERIALLABEL + '/creaeBySplit'
// 批量删除
export const MATERIALLABEL_DELETELIST = BASE_PREFIX_MATERIALLABEL + '/deleteList'
// 列表查询
export const MATERIALLABEL_QUERYLIST = BASE_PREFIX_MATERIALLABEL + '/queryList'
// 分面查询
export const MATERIALLABEL_QUERYPAGELIST = BASE_PREFIX_MATERIALLABEL + '/queryPageList'
// 单个查询
export const MATERIALLABEL_QUERYSINGLE = BASE_PREFIX_MATERIALLABEL + '/querySingle'
// 查询源单已有标签数量
export const MATERIALLABEL_QUERYSOURCELABELQTY = BASE_PREFIX_MATERIALLABEL + '/querySourceLabelQty'

