/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineStore } from 'pinia'

export const bomInfoStore = defineStore({
  id: 'bomInfoId',
  state: () => {
    // 物料数据字典
    return {
      bomInfoId: '', // bom表单id
      bomApps: '', // 审批状态
      unitMeasuOptions: [], // 计量单位
      bomMatcOptions: [],
      bomMatcTableData: [],
      companyNames: [], // 所属公司
      companyDataSelect: [],
      deptOptions: [],
      matcOptions: [],
      maccOptions: []
    }
  },
  getters: {
    getUnitMeasuOptions: (state) => {
      return state.unitMeasuOptions
    },
    getCompanyNames: (state) => {
      return state.companyNames
    },
    getBomInfoId: (state) => {
      return state.bomInfoId
    },
    getBomApps: (state) => {
      return state.bomApps
    },
    getBomMatcOptions: (state) => {
      return state.bomMatcOptions
    },
    getBomMatcTableData: (state) => {
      return state.bomMatcTableData
    },
    getCompanyDataSelect: (state) => {
      return state.companyDataSelect
    },
    getDeptOptions: (state) => {
      return state.deptOptions
    },
    getMatcOptions: (state) => {
      return state.matcOptions
    },
    getMaccOptions: (state) => {
      return state.maccOptions
    }
  },
  actions: {
    setBomInfoId(data: string) {
      this.bomInfoId = data
    },
    setBomApps(data: string) {
      this.bomApps = data
    },
    setBomMatcOptions(data: []) {
      this.bomMatcOptions = data
    },
    setBomMatcTableData(data: []) {
      this.bomMatcTableData = data
    },
    setUnitMeasuOptions(list: []) {
      this.unitMeasuOptions = list
    },
    setCompanyNames(list: []) {
      this.companyNames = list
    },
    setCompanyDataSelect(list: []) {
      this.companyDataSelect = list
    },
    setDeptOptions(list: []) {
      this.deptOptions = list
    },
    setMatcOptions(list: never[]) {
      this.matcOptions = list
    },
    setMaccOptions(list: never[]) {
      this.maccOptions = list
    }
  },
  persist: {
    enabled: true, // 开启持久化
    strategies: [
      {
        storage: localStorage, // 可以为localStorage或者sessionStorage
        paths: [
          'bomInfoId', 'bomApps', 'bomMatcTableData', 'unitMeasuOptions', 'companyNames',
          'deptOptions', 'matcOptions', 'maccOptions'
        ]
      }
    ]
  }
})

export default bomInfoStore
