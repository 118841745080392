/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
// import { storeToRefs } from 'pinia'
// import { dictionarySelect } from '@/store'

// 查询基础数据类型
export const getBasicDataType = async (params: any) => {
  let data: any
  await http.post(HUrl.GET_BASIC_DATA_TYPE_BASE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询基础数据类型分页
export const getBasicDataTypePage = async (params: any) => {
  let data: any
  await http.post(HUrl.GET_BASIC_DATA_TYPE_PAGE_BASE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 新增基础数据类型
export const addBasicDataType = async (params: any) => {
  let data: any
  await http.addPost(HUrl.ADD_BASIC_DATA_TYPE_BASE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 修改基础数据类型
export const editBasicDataType = async (params: any) => {
  let data: any
  await http.editPost(HUrl.EDIT_BASIC_DATA_TYPE_BASE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 删除基础数据类型
export const delBasicDataType = async (params: any) => {
  let data: any
  await http.delPost(HUrl.DEL_BASIC_DATA_TYPE_BASE, { ids: params }).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询基础数据
export const getBasicData = async (params: any) => {
  let data: any
  await http.post(HUrl.GET_BASIC_DATA_BASE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询基础数据分页
export const getBasicDataPage = async (params: any) => {
  let data: any
  await http.post(HUrl.GET_BASIC_DATA_PAGE_BASE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 新增基础数据
export const addBasicData = async (params: any) => {
  let data: any
  await http.addPost(HUrl.ADD_BASIC_DATA_BASE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 修改基础数据
export const editBasicData = async (params: any) => {
  let data: any
  await http.editPost(HUrl.EDIT_BASIC_DATA_BASE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 修改基础数据
export const editBasicDataNoTips = async (params: any) => {
  let data: any
  await http.post(HUrl.EDIT_BASIC_DATA_BASE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 删除基础数据
export const delBasicData = async (params: any) => {
  let data: any
  await http.delPost(HUrl.DEL_BASIC_DATA_BASE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 同步数据
 */
export const dataSync = async () => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_SYS + '/BasicDataBase/DataSync').then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 通用基础数据
 * @param params
 * @returns
 */
// export const basicDataSelect = async (params: {
//   bdtc: string,
//   text?: string
// }, updateCache = false) => {
//   const refDictionarySelect = storeToRefs(dictionarySelect())
//   const bdtc = params.bdtc

//   // 有缓存取缓存里面的数据
//   if ((!updateCache) && bdtc && refDictionarySelect.data.value[bdtc] && refDictionarySelect.data.value[bdtc].code === 1) {
//     return refDictionarySelect.data.value[bdtc]
//   } else {
//     // 没有缓存 发起请求
//     let data: any
//     await http.post(HUrl.BASIC_DATA_SELECT_BASE, params).then((result: any) => {
//       data = result
//     }).catch((err: any) => {
//       return http.catch(err)
//     })
//     // 把数据缓存
//     refDictionarySelect.data.value[bdtc] = data
//     return data
//   }
// }
// 设置基础数据字典启用状态
export const basicDataSetState = async (params: any) => {
  let data: any
  await http.post(HUrl.BASIC_DATA_SET_STATE_BASE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 计量单位分页
export const basicDataCUGetPage = async (params: any) => {
  let data: any
  await http.post(HUrl.BASIC_DATA_CUGET_PAGE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 新增计量单位
export const basicDataCUSave = async (params: any) => {
  let data: any
  await http.post(HUrl.BASIC_DATA_CUSAVE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 修改计量单位数据
export const basicDataCuedit = async (params: any) => {
  let data: any
  await http.post(HUrl.BASIC_DATA_CUEDIT, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除计量单位数据
export const basicDataCudel = async (params: any) => {
  let data: any
  await http.delPost(HUrl.BASIC_DATA_CUDEL, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询计量单位数据下拉
// export const basicDataCuselect = async (params: any) => {
//   let data: any
//   await http.post(HUrl.BASIC_DATA_CUSELECT, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }
// 查询计量单位单条数据
export const basicDataCugetInfo = async (params: any) => {
  let data: any
  await http.get(HUrl.BASIC_DATA_CUGET_INFO, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 计量单位下拉
// export const basicDataCUSelect = async (params: any) => {
//   let data: any
//   await http.post(HUrl.BASIC_DATA_CUSELECT, params).then((result: any) => {
//     data = result
//   }).catch((err: any) => {
//     return http.catch(err)
//   })
//   return data
// }

/**
 * 下载模板
 */

// 下载字典模板
export const basicDataExport = async (params? : any) => {
  let data: any
  await http.getFilesExt(HUrl.BASIC_DATA_EXPORT_BASE, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 下载字典模板-新
export const bizDictTemplate = async (params? : any) => {
  let data: any
  // await http.getFilesExt(HUrl.HTTP_BASE_URL + '/smartshelf/excel/bizDictTemplate', params).then((result : any) => {
  await http.getFilesExt(HUrl.BASE_PREFIX_INIT_START_RESOUCE + '/basicData/bizDictTemplate', params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
export const basicDataExportPopUp = async (params? : any) => {
  let data: any
  await http.postFiles(HUrl.BASIC_DATA_EXPORTPOPUP, {}, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
export const bizDictExport = async (params? : any) => {
  let data: any
  // await http.getFilesExt(HUrl.HTTP_BASE_URL + '/smartshelf/excel/bizDictExport', params).then((result : any) => {
    await http.getFilesExt(HUrl.BASE_PREFIX_INIT_START_RESOUCE + '/basicData/bizDictExport', params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
export const bizDictImport = async (params? : any) => {
  let data: any
  // await http.postFiles(HUrl.HTTP_BASE_URL + '/smartshelf/excel/bizDictImport', {}, params).then((result : any) => {
    await http.postFiles(HUrl.BASE_PREFIX_INIT_START_RESOUCE + '/basicData/bizDictImport', {}, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

export const basicDataImportPopUp = async (params : any) => {
  let data: any
  await http.postFiles(HUrl.BASIC_DATA__IMPORTPOPUP, {}, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 导入模板信息
 */
export const basicDataImport = async (params : any) => {
  let data: any
  await http.postFiles(HUrl.BASIC_DATA_IMPORT_BASE, {}, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 导入语种资源
 */
export const languageImport = async (params : any) => {
  let data: any
  await http.postFiles(HUrl.LANGUAGE_IMPORT, {}, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 级联数据字典导出excel
export const cascadExport = async (params? : any) => {
  let data: any
  await http.getFilesExt(HUrl.BASE_PREFIX_INIT_START_RESOUCE + '/basicData/cascadExport', params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 级联数据字典下载excel模板
export const cascadTemplate = async (params? : any) => {
  let data: any
  await http.getFilesExt(HUrl.BASE_PREFIX_INIT_START_RESOUCE + '/basicData/cascadTemplate', params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// /basicData/cascadImport
// 级联数据字典导入excel
export const cascadImport = async (params? : any) => {
  let data: any
  await http.postFiles(HUrl.BASE_PREFIX_INIT_START_RESOUCE + '/basicData/cascadImport', {}, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
