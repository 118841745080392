import { pullDownData } from '@/store' // 引入setting permission的store
import { storeToRefs } from 'pinia'
import { SCompany, SJob, SEmployee, SDivisional } from '@/service'
import { querySupplierList } from '@/service/modules/purchase/supplier'
import { CustomerPageList } from '@/service/modules/sales/salesOrder'

import { apiType } from 'types/commonresource'

const initStore = () => {
  const settStore = pullDownData()
  // 设置为响应数据
  const refSettStore = storeToRefs(pullDownData())
  return { settStore, refSettStore }
}
// 获取公司
export const getCompany = () => {
  const { getCompanyList } = initStore().refSettStore
  return getCompanyList.value
}

// 获取公司txt
export const getCompanyTxt = (key: string | number) => {
  const { getCompanyList } = initStore().refSettStore
  const res = getCompanyList.value.filter(item => item.key === key)
  if (res && res.length > 0) {
    return res[0].txt
  } else {
    return key
  }
}

// 获取部门
export const getDept = () => {
  const { getDeptList } = initStore().refSettStore
  return getDeptList.value
}

// 获取部门txt
export const getDeptTxt = (key: string | number) => {
  const { getDeptList } = initStore().refSettStore
  const res = getDeptList.value.filter(item => item.deid + '' === key + '')
  if (res && res.length > 0) {
    return res[0].depn
  } else {
    return key
  }
}

// 获取职位
export const getJob = () => {
  const { getJobList } = initStore().refSettStore
  return getJobList.value
}

// 获取职位txt
export const getJobTxt = (key: string | number) => {
  const { getJobList } = initStore().refSettStore
  const res = getJobList.value.filter(item => item.val === key)
  if (res && res.length > 0) {
    return res[0].txt
  } else {
    return key
  }
}

// 获取员工
export const getEmployee = () => {
  const { getEmployeeList } = initStore().refSettStore
  return getEmployeeList.value
}

// 获取员工txt
export const getEmployeeTxt = (key: string | number | string[], labelKey = 'txt') => {
  if (Array.isArray(key)) {
    if (key.length > 0) {
      const { getEmployeeList } = initStore().refSettStore
      const newArr = key.map(item => {
        const res = getEmployeeList.value.filter(val => String(val.val) === String(item))
        if (res && res.length > 0) {
          return res[0][labelKey]
        } else {
          return key
        }
      })
      return newArr
    } else {
      return ''
    }
  } else {
    const { getEmployeeList } = initStore().refSettStore

    const res = getEmployeeList.value.filter(item => String(item.val) === String(key))
    if (res && res.length > 0) {
      return res[0][labelKey]
    } else {
      return key
    }
  }
}

// 是否是员工
export const isEmployee = (key: string) => {
  const { getEmployeeList } = initStore().refSettStore
  const res = getEmployeeList.value.filter(item => item.val === key)
  if (res && res.length > 0) {
    return true
  } else {
    return false
  }
}

// 获取供应商
export const getSupplier = () => {
  const { getSupplierList } = initStore().refSettStore
  return getSupplierList.value
}

// 获取供应商txt
export const getSupplierTxt = (key: string | number, val = 'supn') => {
  const { getSupplierList } = initStore().refSettStore
  const res = getSupplierList.value.filter(item => {
    return item.suid === key
  })
  if (res && res.length > 0) {
    return res[0][val]
  } else {
    return key
  }
}

// 获取客户
export const getCust = () => {
  const { getCustList } = initStore().refSettStore
  return getCustList.value
}

// 获取客户txt
export const getCustTxt = (key: string | number, val = 'nama') => {
  const { getCustList } = initStore().refSettStore
  const res = getCustList.value.filter(item => item.csid === key)
  if (res && res.length > 0) {
    return res[0][val]
  } else {
    return key
  }
}

// 统一获取 公司 部门 职位 员工 数据
export const setData = (judge?: boolean) => {
  const { getState } = initStore().refSettStore
  const { setState } = initStore().settStore
  if (!getState.value || judge) {
    setCompanyList()
    setDeptList()
    setJobList()
    setEmployeeList()
    setSupplierList()
    setCustList()
    setState(true)
  }
}

// 接口 获取 全部 公司数据
export const setCompanyList = () => {
  const { setState } = initStore().settStore
  SCompany.getCompanyDataSelect().then((result: apiType) => {
    const { setCompanyList } = initStore().settStore
    if (result.code) {
      setCompanyList(result.data)
    }
  }).catch(()=>{
    setState(false)
  })
}

// 接口 获取 全部 部门数据
export const setDeptList = () => {
  const { setState } = initStore().settStore
  SDivisional.getDeptData().then((result: apiType) => {
    const { setDeptList } = initStore().settStore
    if (result.code) {
      setDeptList(result.data)
    }
  }).catch(()=>{
    setState(false)
  })
}

// 接口 获取 全部 职位数据
export const setJobList = () => {
  const { setState } = initStore().settStore
  SJob.jobSelect().then((result: apiType) => {
    const { setJobList } = initStore().settStore
    if (result.code) {
      setJobList(result.data)
    }
  }).catch(()=>{
    setState(false)
  })
}

// 接口 获取 全部 员工数据
export const setEmployeeList = () => {
  const { setState } = initStore().settStore
  SEmployee.employeeDictionary().then((result: apiType) => {
    const { setEmployeeList } = initStore().settStore
    if (result.code) {
      setEmployeeList(result.data)
    }
  }).catch(()=>{
    setState(false)
  })
}

// 接口 获取 全部 供应商
export const setSupplierList = () => {
  const { setState } = initStore().settStore
  querySupplierList().then((result: apiType) => {
    const { setSupplierList } = initStore().settStore
    if (result.code) {
      setSupplierList(result.data)
    }
  }).catch(()=>{
    setState(false)
  })
}

// 接口 获取 全部 客户
export const setCustList = () => {
  const { setState } = initStore().settStore
  CustomerPageList().then((result: apiType) => {
    const { setCustList } = initStore().settStore
    if (result.code) {
      setCustList(result.data.items)
    }
  }).catch(()=>{
    setState(false)
  })
}

