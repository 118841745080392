import { defineStore } from 'pinia'

const useCompanyStore = defineStore({
  id: 'tagsview',
  state: () => ({
    breadcrumb: [] as any[],
    itemHighlight: ''
  }),
  getters: {
    getBreadcrumb: (state) => state.breadcrumb
  },
  actions: {
    setBreadcrumb(breadcrumb: any) {
      this.breadcrumb = breadcrumb
    },
    addBreadcrumb(breadcrumb: any) {
      this.breadcrumb = [...this.breadcrumb, ...breadcrumb]
    }
  }
})

export default useCompanyStore

