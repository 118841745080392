// 引入文件
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

type resType = {
  code: number,
  data: any
  message: string
}

// 代码下载
export const codeDownload = async (params?: any) => {
  let data: any
  await http.javaDl(HUrl.BASE_PREFIX_GENERATE + '/code/download', params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data as resType
}

// 代码分页查询
export const codePageQuery = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_GENERATE + '/code/queryPageList', params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data as resType
}

// 表信息分页查询
export const tableinfoQuery = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_GENERATE + '/table/queryPageList', params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data as resType
}

// DB字段查询
export const queryColumnList = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_GENERATE + '/table/queryColumnList', params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data as resType
}

// 代码生成
export const codeGenerate = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_GENERATE + '/code/generate', params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data as resType
}

// 删除生成的代码
export const deleteCode = async (params?: any) => {
  let data: any
  await http.delPost(HUrl.BASE_PREFIX_GENERATE + '/code/delete', params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data as resType
}
// 代码列表查询
export const codeQueryList = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_GENERATE + '/code/queryList', params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data as resType
}

