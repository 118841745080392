/* eslint-disable @typescript-eslint/no-explicit-any */
// 物料分类接口
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
import { attrList, categoryList } from '@/views/business/engineer/materialcode/components/materialCategory/interface/levManage'
import { Page, Result } from '@/views/business/engineer/materialcode/components/materialCategory/interface/base'
import { Feature, FeatureFolderQuery, Folder } from '@/views/business/engineer/materialcode/components/materialCategory/interface/feature'
import { Product } from '@/views/business/engineer/materialcode/components/materialCategory/interface/product'
import { FieldForm } from '@/views/business/engineer/materialcode/components/materialCategory/interface/field'
import { apiType } from 'types/commonresource'

export const getMaterialCategory = async (
  parentId: string | number,
  keyword: string
) => {
  let data: any
  await http
    .get(HUrl.MATERIAL_LIST_DATA, {
      parentId: parentId,
      keyword: keyword
    })
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 获取全部树状结构
export const SecTreeByParents = async (
  parentId: string | number,
  keyword: string
) => {
  let data: any
  await http
    .post(HUrl.SEC_TREE_BY_PARENTS, {
      parentId: parentId,
      keyword: keyword
    })
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 分类物料接口
export const materialCategory = async (params: object) => {
  if (!params['gocc']) {
    params['gocc'] = undefined
  }
  let data = {} as apiType
  await http.post(HUrl.HTTP_BASE_URL + '/engineer/material/category', params).then((result: apiType) => {
    data = result
  })
  return data
}

// 分类物料分页接口
export const materialCategoryPage = async (params: object) => {
  let data = {} as apiType
  await http.post(HUrl.HTTP_BASE_URL + '/engineer/material/categoryPage', params).then((result: apiType) => {
    data = result
  })
  return data
}

// 分类分层 当前全局属性
export const getAttribute = async () => {
  let data: any
  await http
    .get(HUrl.MATERIAL_ATTRIBUTE_DATA)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 分类分层 删除数据
export const levManageDelete = async (id: any) => {
  let data: any
  await http
    .get(HUrl.MATERIAL_DELCATEGORY_DATA, { id: id })
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 属性列表 获取数据
export const getLikeCategory = async (keyword: any) => {
  let data: any
  await http
    .get(HUrl.MATERIAL_LIKECATEGORY_DATA, { keyword: keyword })
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 分类分层 全局属性列表
export const getAttrSelectList = async () => {
  let data: any
  await http
    .get(HUrl.MATERIAL_ATTRIBUTENOW_DATA)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 分类分层 保存更新
export const levManageSaveUp = async (datacate: any) => {
  let data: any
  await http
    .post(HUrl.MATERIAL_SAVEUP_DATA, datacate)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 分类分层 保存全局配置
export const attributeSaveUp = async (datasave: attrList) => {
  let data: any
  await http
    .post(HUrl.MATERIAL_ATTRIBUTESAVEUP_DATA, datasave)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 分类分层 批量新增
export const addSomeSaveBatch = async (params: any) => {
  let data: any
  await http
    .post(HUrl.MATERIAL_SAVEBATCH_DATA, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 分类分层 检查名称和编号
export const CheckaddCategory = async (
  name: string,
  code: string,
  notes: string
) => {
  let data: any
  await http
    .post(HUrl.MATERIAL_CHECKNAMECODE_DATA, {
      name: name,
      code: code,
      notes: notes
    })
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 物料分类导入
export const categoryReadFile = async (params: any = {}) => {
  let data: any
  await http
    .postFiles(HUrl.MATERIAL_READFILE_DATA, {}, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 物料分类导入
export const threeLevelCategoryReadFile = async (params: any = {}) => {
  let data: any
  await http
    .postFiles(HUrl.MATERIAL_CATEGORY_READ_FILE_DATA, {}, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
/**
 *物料分类 下载模板  二级分类下三级分类导入模板
 * */
export const categoryDownloadSec: any = async (code: string | number) => {
  let data: any
  await http
    .getFiles(HUrl.MATERIAL_THREELEVELTEMPLATE, { code: code })
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

export const exportAllCategory = async (params: any) => {
  let data: any
  await http.getFilesExt(HUrl.EXPORT_ALL_MATERIAL_CATEGORY, params)
    .then((result: any) => {
      data = result
    }).catch((err: any) => {
      return http.catch(err)
    })
  return data
}

export const exportThreeCategory = async (params: any) => {
  let data: any
  await http.getFiles(HUrl.EXPORT_ALL_MATERIAL_THREE_CATEGORY, params)
    .then((result: any) => {
      data = result
    }).catch((err: any) => {
      return http.catch(err)
    })
  return data
}
//  物料分类三级   克隆
export const categoryClone = async (params: {
  fromCategoryId: number;
  newName: string;
  newCode: string;
}) => {
  let data: any
  await http
    .post(HUrl.MATERIAL_CLONECATEGORY_DATA, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 属性列表  获取编码长度
export const getGlobalCodeLength = async () => {
  let data: any
  await http
    .get(HUrl.MATERIAL_CODELENGTH_DATA)
    .then((result: any) => {
      data = result.data
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

//  属性列表  添加属性
export const addToCategory = async (features: Feature[]) => {
  let data: any
  await http
    .post(HUrl.MATERIAL_ADDTOCATEGORY_DATA, features)

    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
//  属性列表  添加之所有子类属性
export const flushToCategory = async (features: Feature[]) => {
  let data: any
  await http
    .post(HUrl.MATERIAL_FLUSHTOCATEGORY_DATA, features)

    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 属性列表  属性ID获取特性信息
export const getFeatureById = async (categoryId: number) => {
  let data: any
  await http
    .get(HUrl.MATERIAL_FEATUREBYID_DATA, { categoryId: categoryId })
    .then((result: any) => {
      data = result.data
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

//  属性列表  获取物料分页信息
export const productListMaterial = async (
  productPage: Page<Product>,
  categoryCode: string,
  keyword: string
) => {
  let data: any
  await http
    .post(HUrl.MATERIAL_PRODUCTLIST_DATA, {
      orders: productPage.orders,
      keyword: keyword,
      pageNo: productPage.pageNo,
      pageSize: productPage.pageSize,
      param: {
        categoryCode: categoryCode
      }
    })

    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

//  属性列表  查询分类下所有特性
export const getListSonCategory = async (
  productPage: Page<Product>,
  parentId: string,
  keyword: string
) => {
  let data: any
  await http
    .post(HUrl.MATERIAL_LISTSONCATEGORY_DATA, {
      keyword: keyword,
      pageNo: productPage.pageNo,
      pageSize: productPage.pageSize,
      param: {
        parentId: parentId
      }
    })

    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

//  属性列表  查询分类特性列表
export const fieldList = async (fieldPage: Page<FieldForm>) => {
  let data: any
  await http
    .post(HUrl.MATERIAL_FIELDLIST_DATA, fieldPage)

    .then((result: any) => {
      result.records.forEach((e:any) => {
        e.oldValue = e.value
      });
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

//  属性列表  特性列表导入
export const fieldReadFile = async (params: FormData) => {
  let data: any
  await http
    .upload(HUrl.MATERIAL_FIELDREADFILE_DATA, { data: params })
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

//  属性列表  特性列表导入
export const fieldDownLoadFile = async (params: any) => {
  let data: any
  await http.javaDl(HUrl.MATERIAL_FIELD_DOWNLOAD_FILE_DATA, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

//  属性列表  特性列表导入模板
export const fieldDownTemplateFile = async (params: any) => {
  let data: any
  await http.javaDl(HUrl.MATERIAL_FIELD_TEMPLATE, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

//  属性列表  分类特性列表更新保存
export const fieldSaveOrUpdate = async (params: any): Promise<Result> => {
  let data: any
  await http
    .post(HUrl.MATERIAL_FIELDSAVEORUPDATE_DATA, params)

    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

//  属性列表  分类特性列表编辑保存
export const updateBatch = async (params: any): Promise<Result> => {
  let data: any
  await http
    .post(HUrl.MATERIAL_FIELDUPDATE_BATCH, params)

    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
//  属性列表  分类特性列表编码名称查重
export const checkField = async (field: FieldForm) => {
  let data: any
  await http
    .post(HUrl.MATERIAL_FIELDCHECK_DATA, field)

    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

//  属性列表  分类特性列表删除数据
export const fieldBatchDelete = async (params:any) => {
  let data: any
  await http
    .post(HUrl.MATERIAL_FIELDDELETE_DATA, params)

    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

//  全局特性列表  信息
export const featureFolderCascader = async (query: FeatureFolderQuery) => {
  let data: any
  await http
    .post(HUrl.MATERIAL_FOLDERCASCADER_DATA, query)

    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
//  全局特性列表  继承父类属性
export const copyParentProductFeature = async (query: { folderId: string }) => {
  let data: any
  await http
    .post(HUrl.MATERIAL_COPYPARENT_DATA, query)

    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

//  全局特性列表  导入--
export const featureReadFile = async (params: any = {}) => {
  let data: any
  await http
    .postFiles(HUrl.MATERIAL_FOLDERREADFILE_DATA, {}, params)

    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

//  全局特性列表  获取信息
export const getFeatureFolder = async (parentId: string) => {
  let data: any
  await http
    .get(HUrl.MATERIAL_GETFOLDER_DATA, { parentId: parentId })

    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}
//  全局特性列表  保存更新属性
export const saveOrUpdateFeature = async (feature: Feature) => {
  let data: any
  await http
    .post(HUrl.MATERIAL_FEATURESAVEORUPDATE_DATA, feature)

    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

//  全局特性列表  新增属性
export const saveOrAddFeature = async (feature: Feature) => {
  let data: any
  await http
    .post(HUrl.MATERIAL_FEATUREADD_DATA, feature)

    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}


//  全局特性列表  保存更新folder
export const saveOrUpdateFolder = async (folder: Folder) => {
  let data: any
  await http.post(HUrl.MATERIAL_FOLDERSAVEORUPDATE_DATA, folder).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  if (data.code === 200) {
    return data
  } else {
    throw data
  }
}

// 全局特性列表  删除文件夹
export const deleteFeatureFolder = async (id: string, type: number, categoryLevel:any, confirm:boolean) => {
  let data: any
  await http
    .get(HUrl.MATERIAL_FOLDERDELETE_DATA, { id: id, type: type, categoryLevel,confirm })
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 全局特性列表  删除特性
export const deleteFeature = async (id: string, type: number, categoryLevel:any, confirm:boolean) => {
  let data: any
  await http
    .get(HUrl.MATERIAL_DELETE_DATA, { id: id, type: type, categoryLevel,confirm })
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 全局特性列表
export const listByLevelAndParent = async (
  parentId: number,
  level: number | null
) => {
  let data: any
  await http
    .get(HUrl.MATERIAL_LISTBYLEVELANDPARENT_DATA, {
      level: level,
      parentId: parentId
    })
    .then((result: any) => {
      data = result.data
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 图示
export const matchImageUrlByName = async (name: string) => {
  let data: any
  await http
    .get(HUrl.MATERIAL_MATCHUIMG_DATA, { brandName: name })
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 获取物料详情   LIST
export const getProductLists = async (materialId: any) => {
  let data: any
  await http
    .get(HUrl.MATERIAL_GETPROLIST_DATA, { id: materialId })
    .then((result: any) => {
      data = result.data.data
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 获取物料详情   单项LIST数据
export const getAttrProLists = async (dictionaryCode: string) => {
  let data: any
  await http
    .get(HUrl.MATERIAL_GETATTRPROLIST_DATA, { dictionaryCode: dictionaryCode })
    .then((result: any) => {
      data = result.data.data
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}

// 物料衍生任务 列表查询
export const deriveQueryList = async (params: any) => {
  const result = await http.post(HUrl.ENGINEERING + '/derive/queryList', params)
  return result
}
export const getOriginCodeTrans = async (params: any) => {
  let data: any
  await http.post(HUrl.ENGINEERING + '/material/originCodeTrans', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

export const addOriginCodeRelation = async (params: any) => {
  let data: any
  await http.post(HUrl.ENGINEERING + '/material/addOriginCodeRelation', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 根据原厂名称获取logo地址
 */
export const originInfoBrandImage = async (params: any) => {
  let data: any
  await http.post('api/engineer/originInfo/brandImage', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
/**
 * 根据封装查询尺寸
 */
export const originInfoPackageInfo = async (params: any) => {
  let data: any
  await http.post('api/engineer/originInfo/packageInfo', {}, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 更新子类的基础属性和排序
 */
export const updateSubFeatureBase = async (params: any) => {
  let data: any
  await http.post(HUrl.MATERIAL_FEATUREUPDATESUBFEATUREBASE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

/**
 * 查询关联数据
 */
export const queryAssociated= async (params: any): Promise<Result> => {
  let data: any
  await http
    .post(HUrl.MATERIAL_FIELDSAVEORUPDATE_DATA, params)
    .then((result: any) => {
      data = result
    })
    .catch((err: any) => {
      return http.catch(err)
    })
  return data
}