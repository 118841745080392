import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

// 新增变量
export const variableAdd = async (params?: any) => {
  let data: any
  await http.addPost(HUrl.VARIABLE_ADD, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除变量
export const variableDelete = async (params?: any) => {
  let data: any
  await http.delPost(HUrl.VARIABLE_DELETE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 变量查询
export const variableVariableList = async (params?: any) => {
  let data: any
  await http.post(HUrl.VARIABLE_VARIABLE_LIST, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 更新变量
export const variableUpdate = async (params?: any) => {
  let data: any
  await http.editPost(HUrl.VARIABLE_UPDATE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
