import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

// 模块分页查询
export const queryPageList = async (params: object) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_GENERATE + '/module/queryPageList', params).then((result) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 新增模块
export const moduleAdd = async (params?: object) => {
  let data: any
  await http.addPost(HUrl.BASE_PREFIX_GENERATE + '/module/add', params).then((result) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 删除模块
export const moduleDelete = async (params?: object) => {
  let data: any
  await http.delPost(HUrl.BASE_PREFIX_GENERATE + '/module/delete', params).then((result) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 模块列表查询
export const moduleQueryList = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_GENERATE + '/module/queryList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 更新模块
export const moduleUpdate = async (params?: any) => {
  let data: any
  await http.editPost(HUrl.BASE_PREFIX_GENERATE + '/module/update', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 同步数据
export const moduleSync = async (params?: any) => {
  let data: any
  await http.editPost(HUrl.BASE_PREFIX_GENERATE + '/module/sync', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
