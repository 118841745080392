import { computed, defineAsyncComponent } from 'vue'
import type { ColumnType } from 'types/table'

import basicInfoHooksIndex from '@/views/business/sales/priceMaintenance/hooks/basicInfo'
import PriceTableHooksIndex from '@/views/business/sales/priceMaintenance/hooks/priceTableFormJson'

import { getInfo, getTablePageList } from '@/service/modules/sales/salesPriceMaintenance'

const formJson = () => {
  const basicInfoHooks = basicInfoHooksIndex({}, false)
  const priceTableHooks = PriceTableHooksIndex()

  // 设置部分字段的配置
  const nameType = ['物料名称']
  priceTableHooks.columnList = priceTableHooks.columnList.map((item: ColumnType) => {
    if (nameType.includes(item.label)) {
      item.formatter = ({ fullNameOfMaterial }: {fullNameOfMaterial: string}) => {
        return fullNameOfMaterial
      }
    }
    return item
  })

  const res = computed(() => {
    return { ...basicInfoHooks.formJson }
  })
  return [
    {
      title: { lank: 'sales.basicInfo', default: '基本信息' },
      type: 'form',
      json: res.value,
      getInfo: getInfo,
      slot: {}
    },
    {
      title: { lank: 'sales.productPrice', default: '产品价格' },
      type: 'table',
      formid: 'maintenanceId',
      id: 'id',
      columnList: priceTableHooks.columnList,
      getInfo: getTablePageList,
      slot: {
        priceList: defineAsyncComponent(() => import('./componentsSlot/priceList.vue')),
        unit: defineAsyncComponent(() => import('./componentsSlot/unit.vue'))
      }
    }
  ]
}

export default formJson
