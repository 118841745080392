/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

// 高温补助分页
export const htsGetPage = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASICDATA_HTSGETPAGE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 新增高温补助
export const htsSave = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASICDATA_HTSSAVE, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 修改高温补助
export const htsEdit = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASICDATA_HTSEDIT, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 删除高温补助
export const htsDel = async (params?: any) => {
  let data: any
  await http.delPost(HUrl.BASICDATA_HTSDEL, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 批量新增编辑高温补贴
export const saveHTS = async (params?: any) => {
  let data: any
  await http.savePost(HUrl.BASICDATA_SAVE_HTS, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 高温补贴下拉
export const htsSelect = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASICDATA_HTSSELECT, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询高温补贴详情
export const htsGetInfo = async (params?: any) => {
  let data: any
  await http.post(HUrl.BASICDATA_HTSGETINFO, params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
