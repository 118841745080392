/* eslint-disable @typescript-eslint/no-explicit-any */
import { SGeneralData } from '@/service'
// 仓库类别
export const warehouseCate = 'warehouseCate'
// 仓库类型
export const warehouseType = 'warehouseType'
// 仓库状态
export const warehouseStatus = 'warehouseStatus'
// 物料归属
export const materialOwner = 'materialOwner'

// 物料类型，
export const materialType = 'materialType'
// 物料属性，
export const materAttri = 'materAttri'
// 物料分类，
export const materialClass = 'materialClass'
// 物料封装，
export const materialPack = 'materialPack'
// 包装方式，
export const packagingMode = 'packagingMode'
// 物料标签，
export const materialLabel = 'materialLabel'
// 后段工艺，
export const subquentPro = 'subquentPro'
// 商品类别，
export const commCate = 'commCate'
// 解决方案-入库至使用最长，
export const solution = 'solution'
// 解决方案-有效期管理，
export const overdueValidity = 'overdueValidity'
// 解决方案-盘点周期，
export const overdueInventory = 'overdueInventory'
// 送料器类型，
export const feederType = 'feederType'
// 检验方法，
export const inspectionMethod = 'inspectionMethod'
// 检验标准，
export const inspecStan = 'inspecStan'
// 检验工具，
export const inspectionTool = 'inspectionTool'
// 基本品质检查方案
export const basicQuality = 'basicQuality'
// 计量单位
export const unitMeasu = 'unitMeasu'
// 币种
export const currencysType = 'currencysType'
// 存货分类
export const invenClass = 'invenClass'
// 数量精度
export const quantityAccuracy = 'quantityAccuracy'
// 时间单位
export const timeUnit = 'timeUnit'
// 最小数量
export const minQuantity = 'minQuantity'
// 产地
export const producAddress = 'producAddress'
// 入库至使用最长
export const maxUseTime = 'maxUseTime'
// 有效期
export const termValidity = 'termValidity'
// 盘点周期管理
export const countingCycle = 'countingCycle'
// 使用默认损耗
export const useDefaultLoss = 'useDefaultLoss'
// 后端加工点数
export const backPoints = 'backPoints'
// smt加工点数
export const smtPoints = 'smtPoints'
// 原厂名称
export const origFactName = 'origFactName'
// 采购时效(天) PURT
export const purtDict = 'purtDict'
// 采购税率(%) PUTR
export const putrDict = 'putrDict'
// 销售时效(天) SALT
export const saltDict = 'saltDict'
// 销售税率(%) SATR
export const satrDict = 'satrDict'
// 湿敏等级
export const humSenGrade = 'humSenGrade'
// 烘烤温度
export const bakingTemp = 'bakingTemp'
// 烘烤时间
export const bakingTime = 'bakingTime'
// 新物料首次下单限制数量
export const folc = 'folc'
// 间隔不小于（天）
export const lmit = 'lmit'
// 存储温度
export const temp = 'temp'
// 存储湿度
export const humidity = 'humidity'

// 获取基础数据字典下拉
const getBasicDataSelect = async (item: any) => {
  let params:any = {
    bdtc: item.bdtc,
    enab: 1,
    text: ''
  }
  if (item.cdid) {
    params.cdid = item.cdid
  }
  return SGeneralData.basicDataSelect(params, true).then(res => {
    if (res.code == 1) {
      item.dict = res.data
      return item
    }
  })
}
// 查询计量单位下拉
const getBasicDataCUSelect = async (item: { bdtc: any; dict: any }) => {
  let params = {
    cutc: '',
    unin: ''
  }
  return SGeneralData.basicDataCUSelect(params).then(res => {
    if (res.code == 1) {
      item.dict = res.data
      return item
    }
  })
}
const dataDict = [
  { dict: [], bdtc: materAttri, text: '物料属性', API: getBasicDataSelect },
  { dict: [], bdtc: packagingMode, cdid: '', text: '包装方式', API: getBasicDataSelect },
  { dict: [], bdtc: materialLabel, text: '物料标签', API: getBasicDataSelect },
  { dict: [], bdtc: subquentPro, text: '后段工艺', API: getBasicDataSelect },
  { dict: [], bdtc: commCate, text: '商品类别', API: getBasicDataSelect },
  { dict: [], bdtc: solution, text: '解决方案', API: getBasicDataSelect },
  { dict: [], bdtc: feederType, text: '送料器类型', API: getBasicDataSelect },
  { dict: [], bdtc: inspectionMethod, text: '检验方法', API: getBasicDataSelect },
  { dict: [], bdtc: inspecStan, text: '检验标准', API: getBasicDataSelect },
  { dict: [], bdtc: inspectionTool, text: '检验工具', API: getBasicDataSelect },
  { dict: [], bdtc: basicQuality, text: '基本品质检查方案', API: getBasicDataSelect },
  { dict: [], bdtc: unitMeasu, text: '计量单位', API: getBasicDataCUSelect },
  { dict: [], bdtc: currencysType, text: '币种', API: getBasicDataSelect },
  { dict: [], bdtc: invenClass, text: '存货分类', API: getBasicDataSelect },
  { dict: [], bdtc: quantityAccuracy, text: '数量精度', API: getBasicDataSelect },
  { dict: [], bdtc: timeUnit, text: '时间单位', API: getBasicDataSelect },
  { dict: [], bdtc: minQuantity, text: '最小数量', API: getBasicDataSelect },
  { dict: [], bdtc: producAddress, text: '产地', API: getBasicDataSelect },
  { dict: [], bdtc: maxUseTime, text: '入库至使用最长', API: getBasicDataSelect },
  { dict: [], bdtc: termValidity, text: '有效期', API: getBasicDataSelect },
  { dict: [], bdtc: countingCycle, text: '盘点周期管理', API: getBasicDataSelect },
  { dict: [], bdtc: useDefaultLoss, text: '使用默认损耗', API: getBasicDataSelect },
  { dict: [], bdtc: backPoints, text: '后端加工点数', API: getBasicDataSelect },
  { dict: [], bdtc: smtPoints, text: 'SMT加工点数', API: getBasicDataSelect },
  { dict: [], bdtc: overdueValidity, text: '解决方案', API: getBasicDataSelect },
  { dict: [], bdtc: overdueInventory, text: '解决方案', API: getBasicDataSelect },
  { dict: [], bdtc: origFactName, text: '原厂名称', API: getBasicDataSelect },
  { dict: [], bdtc: purtDict, text: '采购时效(天)', API: getBasicDataSelect },
  { dict: [], bdtc: putrDict, text: '采购税率(%)', API: getBasicDataSelect },
  { dict: [], bdtc: saltDict, text: '销售时效(天)', API: getBasicDataSelect },
  { dict: [], bdtc: satrDict, text: '销售税率(%)', API: getBasicDataSelect },

  { dict: [], bdtc: humSenGrade, text: '采购税率(%)', API: getBasicDataSelect },
  { dict: [], bdtc: bakingTemp, text: '销售时效(天)', API: getBasicDataSelect },
  { dict: [], bdtc: bakingTime, text: '采购税率(%)', API: getBasicDataSelect },
  { dict: [], bdtc: folc, text: '销售时效(天)', API: getBasicDataSelect },
  { dict: [], bdtc: lmit, text: '销售税率(%)', API: getBasicDataSelect }
]
export const getDataDicts = async (key?: string, cdid?:string) => {
  let promiseAll = []
  if (!key) {
    dataDict.forEach((item: any) => {
      if (item.API) {
        promiseAll.push(item.API(item))
      }
    })
  } else {
    let item: any = dataDict.find(dict => dict.bdtc === key)
    if (item) {
      item.cdid = cdid
      promiseAll.push(item.API(item))
    }
  }
  return Promise.all(promiseAll)
}
