/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineStore } from 'pinia'

interface companyType {
  comc: string // "HYD"
  key: string // "011ec4b3265345959d837b8612b55c17"
  txt: string // "深圳市宏英达科技有限公司"
  val: string // "011ec4b3265345959d837b8612b55c17"
  value: string // "深圳市宏英达科技有限公司"
}
interface deptType {
  deid: number //  74
  depn: string //  "总经办"
}
interface jobType {
  comn: string // "深圳市德比网络设备有限公司"
  depn: string // "测试部"
  key: string // "027e870e906047ed82b79256da436952"
  txt: string // "web测试"
  val: string // "027e870e906047ed82b79256da436952"
  value: string // "web测试"

}
interface employeeType {
  deid: number //  79
  depn: string //  "研发部"
  empc: string //  "P00000013"
  text: string //  "莫军(P00000013)"
  txt: string //  "莫军(P00000013)"
  val: string //  "5e31d12e8a634e81a99cc16f54eb9400"
}
interface supplierType {
  suid: string
  supa: string
  suen: string
}

interface custType {
  csid: string // 客户id
  abbr: string // 客户 简称
  nama: string // 客户 名称
  cusc: string // 客户 编码
}

export default defineStore({
  id: 'pullDownData',
  state: () => ({
    companyList: [] as companyType[], // 公司
    deptList: [] as deptType[], // 部门
    jobList: [] as jobType[], // 职位
    employeeList: [] as employeeType[], // 员工
    supplierList: [] as supplierType[], // 供应商
    custList: [] as custType[], // 客户
    apiState: false
  }),
  getters: {
    getCompanyList: (state) => state.companyList,
    getDeptList: (state) => state.deptList,
    getJobList: (state) => state.jobList,
    getEmployeeList: (state) => state.employeeList,
    getSupplierList: (state) => state.supplierList,
    getCustList: (state) => state.custList,
    getState: (state) => state.apiState
  },
  actions: {
    setCompanyList(val: any[]) {
      this.companyList = val
    },
    setDeptList(val: any[]) {
      this.deptList = val
    },
    setJobList(val: any[]) {
      this.jobList = val
    },
    setEmployeeList(val: any[]) {
      this.employeeList = val
    },
    setSupplierList(val: any[]) {
      this.supplierList = val
    },
    setCustList(val: any[]) {
      this.custList = val
    },
    setState(val: boolean) {
      this.apiState = val
    }

  }
})

