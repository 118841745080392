/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

const url = HUrl.BASE_PREFIX_SHELF_PLATE_MODEL
const wsUrl = HUrl.SHELF_BOARD_MODEL
// 获取表格分页数据
// 货架料盘型号
export const queryPageList = async (params?: any) => {
  let data: any
  await http.post(url + '/queryPageList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

export const save = async (params?: any) => {
  let data: any
  await http.addPost(url + '/save', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

export const deleteList = async (params?: any) => {
  let data: any
  await http.delPost(url + '/deleteList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出数据excel
export const exportFiles = async (params?: any) => {
  let data: any
  await http.getFilesExt(url + '/export', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 下载模板
export const exportFilesTmpl = async (params?: any) => {
  let data: any
  await http.getFiles(url + '/template', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导入数据
export const importFiles = async (params: any) => {
  let data: any
  await http.postFiles(url + '/import', {}, params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 下拉查询

export const queryList = async (params: any) => {
  let data: any
  await http.post(url + '/queryList', {}, params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
export const saveShelfBoardModel = async (params?: any) => {
  let data: any
  await http.addPost(wsUrl + '/save', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
export const queryShelfBoardModelPageList = async (params?: any) => {
  let data: any
  await http.post(wsUrl + '/queryPageList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
export const deleteWsList = async (params?: any) => {
  let data: any
  await http.delPost(wsUrl + '/deleteList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 货架主板-select
export const wsQueryList = async (params?: any) => {
  let data: any
  await http.post(wsUrl + '/queryList', params).then((result: any) => {
    console.log('货架主板list', result)
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 货架主板-单个查询
export const querySingle = async (params?: any) => {
  let data: any
  await http.post(wsUrl + '/querySingle', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 货架主板-下载模板
export const wsTemplate = async (params?: any) => {
  let data: any
  await http.getFiles(wsUrl + '/template', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出数据excel
export const wsExport = async (params?: any) => {
  let data: any
  await http.getFilesExt(wsUrl + '/export', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导入数据
export const wsImport = async (params: any) => {
  let data: any
  await http.postFiles(url + '/import', {}, params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
