/* eslint-disable @typescript-eslint/no-explicit-any */
// 引入文件
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址
const url = HUrl.HTTP_BASE_URL_JAVACHANGE // 路径前缀 + 微服务
/**
 * 货架分页-统计
 */
export const pageInfo = async (params : any = {}) => {
  let data: any
  await http.post(url + '/warehousing/shelf/pageInfo', params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
export const page = async (params : any = {}) => {
  let data: any
  await http.post(url + '/warehousing/shelf/page', params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
