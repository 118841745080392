/* eslint-disable @typescript-eslint/no-explicit-any */
import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

// 执行器分页查询
export const jobgroupPagelist = async (params: any) => {
  let data: any
  await http.post(HUrl.BASE_PREFIX_SCHEDULER + '/jobgroup/pageList', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 执行器新增
export const jobgroupSave = async (params: any) => {
  let data: any
  await http.addPost(HUrl.BASE_PREFIX_SCHEDULER + '/jobgroup/save', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 执行器更新
export const jobgroupUpdate = async (params: any) => {
  let data: any
  await http.editPost(HUrl.BASE_PREFIX_SCHEDULER + '/jobgroup/update', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 执行器删除
export const jobgroupRemove = async (params: any) => {
  let data: any
  await http.delPost(HUrl.BASE_PREFIX_SCHEDULER + '/jobgroup/remove', params).then((result: any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
