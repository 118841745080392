import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

const url = HUrl.BASE_PREFIX_SALE // 路径前缀 + 微服务
// 获取表格分页数据
export const getPageList = async (params: any) => {
  let data: any
  await http.post(url + '/saleOrderCollectionTask/queryPageList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 保存
export const save = async (params: any) => {
  let data: any
  await http.post(url + '/saleOrderCollectionTask/save', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 删除
export const del = async (params: any) => {
  let data: any
  await http.delPost(url + '/saleOrderCollectionTask/deleteList', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导入
export const importFiles = async (params: any) => {
  let data: any
  await http.postFiles(url + '/saleOrderCollectionTask/import', {}, params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
// 导出
export const exportFiles = async (params?: any) => {
  let data: any
  await http.getFilesExt(url + '/saleOrderCollectionTask/export', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}

// 导出模板
export const exportFilesTmpl = async (params?: any) => {
  let data: any
  await http.getFiles(url + '/saleOrderCollectionTask/template', params).then((result: any) => {
    data = result
  }).catch((err) => {
    return http.catch(err)
  })
  return data
}
