import { http } from '@/service' // 引入网络请求http
import { HUrl } from '@/config' // 引入http请求URL地址

// 新增应用版本
export const addAppVersion = async (params : any) => {
  let data: any
  await http.addPost(HUrl.ADD_APP_VERSION, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 删除应用版本
export const deleteAppVersion = async (params : any) => {
  let data: any
  await http.delPost(HUrl.DELETE_APP_VERSION, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 查询应用版本
export const queryAppVersion = async (params : any) => {
  let data: any
  await http.post(HUrl.QUERY_APP_VERSION, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 应用版本选择
export const selectAppVersion = async (params : any) => {
  let data: any
  await http.post(HUrl.SELECT_APP_VERSION, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 查询应用版本未分页
export const queryAppVersionDown = async (params?: any) => {
  let data: any
  await http.post(HUrl.QUERY_APP_VERSION_LIST, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}

// 分页查询应用版本
export const queryAppVersionList = async (params : any) => {
  let data: any
  await http.post(HUrl.QUERY_APP_VERSION_PAGE_LIST, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
// 更新应用版本
export const updateAppVersion = async (params : any) => {
  let data: any
  await http.editPost(HUrl.UPDATE_APP_VERSION, params).then((result : any) => {
    data = result
  }).catch((err: any) => {
    return http.catch(err)
  })
  return data
}
