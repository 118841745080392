/**
 * 定义基于pinia的store存储
 * 设置相关的store数据（token、菜单、标签等相关）
 * @author jihua.huang
 * @since 2022-03-15
 */
import { defineStore } from 'pinia'
import i18n from '@/i18n'
import { setting } from '@/utils'
import { langType } from 'types/commonresource'
import { SLanguage } from '@/service'
const { locale } = i18n.global

// 创建store，defineStore调用后返回函数，调用该函数获得Store实体
const settingStore = defineStore({
  // 必传的参数，id值，在store中唯一
  id: 'settingStore',
  // 定义数据，返回对象和属性
  state: () => ({
    token: '', // 请求token
    userInfo: {
      userId: '',
      account: '',
      emplId: '',
      companyId: '',
      userName: '',
      avatar_url: ''
    }, // 用户基本信息
    prefer: {
      lang: 'zh-cn', // 语言
      theme: 'theme-default', // 主题
      collapse: true, // 菜单收缩按钮是否显示
      refresh: true, // 是否展示头部工具栏的刷新图标
      username: true, // 是否展示头部我的账号
      avatar: true, // 是否展示头部我的头像
      filter: 0, // 置灰程度百分比
      draweMaskLayer: true, // 设置面板遮罩层
      fontSize: 14, // 字体大小
      rowSpacing: 6, // 行间距
      format: '1' // 版式
    }, // 设置偏好，包括：主题皮肤等 // 写类目自定义样式
    collapse: 2, // 左边菜单折叠
    modelDecide: 0, // 菜单模式
    twoCollapse: false, // 二级菜单折叠
    drawer: false, // 右边抽屉工具页面
    dropDown: false, // 账号的下拉菜单折叠
    defaultActive: '', // 默认激活的菜单path -key
    secondaryMenu: '', // 二级菜单激活项
    defaultMenu: '', // 激活的一级菜单
    amplification: false, // 表单放大标识
    menuList: [] as any[], // 菜单列表
    renovate: true, // 刷新按钮
    globalRefresh: true, // 全局刷新
    headerShow: true, // 顶部操作栏是否显示
    secondaryMenuShow: false, // 二级菜单是否显示
    cacheKeepAlive: [], // 页面缓存
    loginPopWindow: false,
    oldPath: '/', // 上一个页面路径
    languageList: [] as langType[], // 国际化下拉数据
    refreshRoute: false, // 是否重新构建路由
    isReLogin: true // 是否重新登陆
  }),
  // 获取store模块的属性
  getters: {
    getHeaderShow: (state) => state.headerShow,
    getIsReLogin: (state) => state.isReLogin,
    getCacheKeepAlive: (state) => state.cacheKeepAlive,
    getLanguageList: (state) => state.languageList,
    getToken: (state) => state.token,
    getUserInfo: (state) => state.userInfo,
    getPrefer: (state) => state.prefer,
    getCollapse: (state) => state.collapse,
    gettWoCollapse: (state) => state.twoCollapse,
    getDrawer: (state) => state.drawer,
    getDropDown: (state) => state.dropDown,
    getDefaultActive: (state) => state.defaultActive,
    getSecondaryMenu: (state) => state.secondaryMenu,
    getDefaultMenu: (state) => state.defaultMenu,
    getAmplification: (state) => state.amplification,
    getMenuList: (state) => state.menuList,
    getModelDecide: (state) => state.modelDecide,
    getRenovate: (state) => state.renovate,
    getGlobalRefresh: (state) => state.globalRefresh,
    getSecondaryMenuShow: (state) => state.secondaryMenuShow,
    getLoginPopWindow: (state) => state.loginPopWindow,
    getOldPath: (state) => state.oldPath
  },
  // 设置store模块的属性
  actions: {
    setIsReLogin(isReLogin: boolean) {
      this.isReLogin = isReLogin
    },
    // 修改语言
    setLang(lang: string) {
      return new Promise((resolve: (value: boolean) => void, reject) => {
        if (lang === this.prefer.lang) {
          resolve(false)
          return
        }
        // 获取全部语种
        setting.getLanguageList().then((result) => {
          this.languageList = result
          const arr = result.map(item => {
            return item.lafn === lang ? item : ''
          })
          const res = arr.filter(item => item)
          console.log(res)
          if (!res[0]) {
            resolve(false)
            return
          }
          SLanguage.changeLang({ lang: res[0].key }).then(result => {
            // console.log(result)
            if (result.code === 1) {
              // 切换语种
              locale.value = lang
              this.prefer.lang = lang
              // 切换成功
              resolve(true)
            } else {
              resolve(false)
            }
          })
        })
      })
    },
    // 头部是否显示
    setHeaderShow(headerShow: any) {
      this.headerShow = headerShow
    },
    // 国际化下拉数据
    setLanguageList(languageList: any) {
      this.languageList = languageList
    },
    // 保存路径
    setOldPath(oldPath: any) {
      this.oldPath = oldPath
    },
    // 登录弹窗状态
    setLoginPopWindow(loginPopWindow: any) {
      this.loginPopWindow = loginPopWindow
    },
    // 修改底部状态
    setCacheKeepAlive(cacheKeepAlive: any) {
      this.cacheKeepAlive = cacheKeepAlive
    },
    // 模式修改
    setSecondaryMenuShow(secondaryMenuShow: any) {
      this.secondaryMenuShow = secondaryMenuShow
    },
    // 局部刷新
    setRenovate(renovate: any) {
      this.renovate = renovate
    },
    // 全局刷新
    setGlobalRefresh(globalRefresh: any) {
      this.globalRefresh = globalRefresh
    },
    // 模式修改
    setModelDecide(modelDecide: any) {
      this.modelDecide = modelDecide
    },
    // 获取菜单列表数据
    setMenuList(menuList: any) {
      this.menuList = menuList
    },
    // 保存token
    setToken(token: string) {
      this.token = token
    },
    // 修改表单放大标识
    setAmplification(amplification: boolean) {
      console.log('amplification', amplification)
      this.amplification = amplification
    },
    // 保存用户基本信息
    setUserInfo(userInfo: any) {
      console.log('userInfo', userInfo)
      this.userInfo = userInfo
    },
    // 二级菜单标识
    setSecondaryMenu(secondaryMenu: any) {
      this.secondaryMenu = secondaryMenu
    },
    // 设置偏好，包括：主题皮肤等
    setPrefer(prefer: any) {
      this.prefer = prefer
    },
    // 保存左边menu折叠状态
    setCollapse(collapse: number) {
      this.collapse = collapse
    },
    // 保存二级menu折叠状态
    setTwoCollapse(twoCollapse: boolean) {
      this.twoCollapse = twoCollapse
    },
    // 设置右边抽屉工具页面打开关闭状态
    setDrawer(drawer: boolean) {
      this.drawer = drawer
    },
    // 保存账号的下拉menu折叠状态
    setDropDown(dropDown: boolean) {
      this.dropDown = dropDown
    },
    // 设置默认激活的二级菜单path -key
    setDefaultActive(defaultActive: string) {
      this.defaultActive = defaultActive
    },
    // 设置默认激活的二级菜单path -key
    setDefaultMenu(defaultMenu: string) {
      this.defaultMenu = defaultMenu
    }
  },

  // 开启数据缓存  -- 需要在store/index.js中import piniaPluginPersist 插件
  persist: {
    enabled: true, // 开启持久化
    strategies: [
      {
        // key: 'token', //自定义Key，也可以去掉，使用默认值为参数id的值
        storage: localStorage, // 可以为localStorage或者sessionStorage
        // 可以通过paths指定需要持久化的值，其他没有指定的则不会持久化
        paths: [
          'token', 'collapse', 'twoCollapse', 'headerShow', 'userInfo', 'dropDown', 'languageList', 'defaultActive', 'drawer', 'prefer', 'secondaryMenu', 'defaultMenu', 'modelDecide'
        ]
      }
    ]
  }
})

export default settingStore
