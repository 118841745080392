import { ElMessage, ElMessageBox } from 'element-plus'
import i18n from '@/i18n'
import { ref, reactive } from 'vue'
import type { Ref } from 'vue'
import { PublicField } from '@/config'
import { SGeneralData } from '@/service'
import * as math from 'mathjs' // mathjs
import { queryExchangeRate } from '@/service/modules/sales/salesOrder'
import { Tany, apiType } from 'types/commonresource'
import { IitemEmit } from 'types/form'
import { ColumnType, objAny } from 'types/table'
import { cache } from '@/utils'
import moment from 'moment'
import { Materia, SGeneralDataBase } from '@/service'
import { basicDataCUGetPage, basicDataSelect } from '@/service/modules/sysmanage/generalData'
// import { useRouter } from 'vue-router'
// const router = useRouter()

const data = reactive({
  unit: []
})
/** 路由跳转 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const routeJump = (p: { type: string, pathOrName: string, params: Tany }) => {
  // const router = useRouter()
  // const paramObj = {
  //   query: {
  //     name: p.pathOrName,
  //     query: p.params || undefined
  //   },
  //   path: {
  //     path: p.pathOrName,
  //     state: p.params || undefined
  //   },
  //   viewName: p.pathOrName
  // }
  // router.push(paramObj[p.type])
}

// 删除方法
export const del = (selectData: Tany[] | string, func: () => void) => {
  const t = i18n.global.t
  if (!selectData || !selectData.length) {
    ElMessage.warning(t('commonresource.leastReminder.key', '最少勾选一条数据'))
  } else {
    let message = t('commonresource.delPrompt.key', '确定删除此项？')
    if (typeof selectData === 'string') {
      message = t('commonresource.deleteThisItem.key', '确定删除此项吗？')
    }
    ElMessageBox.confirm(
      message,
      t('commonresource.prompt.key', '提示'),
      {
        confirmButtonText: t('commonresource.determine.key', '确认'),
        cancelButtonText: t('commonresource.cancel.key', '取消'),
        type: 'error'
      }
    )
      .then(() => {
        func()
      })
      .catch(() => {
        console.log('back')
      })
  }
}
export const goEditPage = (selectData: Tany[] | string, callback: () => void) => {
  const t = i18n.global.t
  if (selectData.length === 1) {
    let apps = selectData[0].apps
    if (apps === '0' || apps === '1') {
      ElMessage.warning(t(`commonresource.appsIs${apps}.key`, '不可编辑'))
    } else {
      callback()
    }
  } else {
    noSelect()
  }
}
// 没有选中数据的错误提示
export const noSelect = () => {
  const t = i18n.global.t
  ElMessage.warning(t('commonresource.warning2.key', '请勾选一条数据'))
}

type TanyObj = {
  [x: string]: Tany
}
type TPageSaveParams = {
  fn: (params?: TanyObj) => void
  params?: TanyObj
  errFn?: (params?: TanyObj) => void
  errParams?: TanyObj
  message?: string
}
/**
 *
 * @param obj
 * 弹窗提示执行相应方法
 * fn 弹窗提示点击确认执行的方法
 * params fn的参数
 * message 弹窗的提示语信息
 * errFn 取消按钮执行的方法
 * errParams 取消的方法参数
 *
 */
export const pageSavePoint = (obj: TPageSaveParams) => {
  const t = i18n.global.t
  let mes =
    obj.message ||
    t('commonresource.saveTitle.key', '页面有未保存的数据,是否一起提交')
  ElMessageBox.confirm(mes, t('commonresource.prompt.key', '提示'), {
    confirmButtonText: t('commonresource.determine.key', '确认'),
    cancelButtonText: t('commonresource.cancel.key', '取消'),
    type: 'error'
  })
    .then(() => {
      obj.fn(obj.params)
    })
    .catch(() => {
      if (obj.errFn) obj.errFn(obj.errParams)
    })
}
// 循环将字典转为对象
export const arrToJson = (arr: { key: Tany; value: string }[]) => {
  let obj = {}
  arr.forEach((ele: { key: Tany; value: string }) => {
    obj[ele.key] = ele
  })
  return obj
}

// 四舍五入 传入数字和需要保留几位小数
/**
 *
 * @param num 数字字符串
 * @param digit 传入需要保留几位小数
 * @returns
 */
export const halfAdjust = (num: string, digit: number) => {
  if (!num || !+num) return '0'
  num = String(num)
  if (num && num.includes('.')) {
    const arr = num.split('.')
    let d = ''
    if (arr[1]) {
      d = String(Number('1.' + arr[1]).toFixed(digit + 1)).slice(2, 2 + digit)
      // console.log(`num:${arr[0]}.${d}`)
      return `${arr[0]}.${d}`
    }
    console.log(`num2:${parseFloat(num).toFixed(digit)}`)
    return parseFloat(num).toFixed(digit)
  } else {
    return num
  }
}
// 百分比数值转为小数
/**
 *
 * @param num string 传入百分比数值可带百分比符号
 * @returns
 */
export const numToDec: (num: string) => string = (num: string) => {
  if (num) {
    if (num.includes('%')) {
      num = num.split('%')[0]
    }
    return math.format(math.divide(math.bignumber(num), math.bignumber('100')))
  } else {
    return ''
  }
}

export type TAnyObj = {
  [x: string]: Tany
}

type TParams = {
  paramsData: TAnyObj // 需要格式化的参数
  numArr: string[] // 需要格式化成number的字段集合
  numZero?: boolean, // 数字的字符串如果为空就设置为 '0' 默认是删除
  type?: 'number' | 'string' // 转成 0 的类型 默认 '0'
}
// 格式化参数的方法 paramsData可以是对象数组 或者是单独的对象
export const paramsFormat = (obj: TParams) => {
  let paramsData = obj.paramsData
  if (Array.isArray(paramsData)) {
    paramsData.forEach((element: TanyObj) => {
      let formatData = {
        paramsData: element,
        numArr: obj.numArr,
        type: obj?.type
      }
      if (obj.numZero) {
        element = formatObjNum(formatData)
      } else {
        element = formatObj(formatData)
      }
    })
    return paramsData
  } else {
    if (obj.numZero) {
      return formatObjNum(obj)
    } else {
      return formatObj(obj)
    }
  }
}
// 将参数中的指定字段格式化为数字如果内容为空 则从字段中删除
const formatObj = (obj: TParams) => {
  let numberParams = obj.numArr
  let paramsData = obj.paramsData
  if (!numberParams.length) return paramsData
  for (let i = 0; i < numberParams.length; i++) {
    let item = numberParams[i]
    if (!paramsData[item] && paramsData[item] !== 0) {
      delete paramsData[item]
    } else if (paramsData[item]) {
      paramsData[item] = Number(paramsData[item])
    }
  }
  return paramsData
}
// 参数为数字的话 如果没有这个值 那么就将这个值设置为0
const formatObjNum = (obj: TParams) => {
  let numberParams = obj.numArr
  let paramsData = obj.paramsData
  if (!numberParams.length) return paramsData
  for (let i = 0; i < numberParams.length; i++) {
    let item = numberParams[i]
    if (!paramsData[item] && paramsData[item] !== 0) {
      paramsData[item] = (obj.type && obj.type) === 'number' ? 0 : '0'
    } else if (paramsData[item]) {
      paramsData[item] = Number(paramsData[item])
    }
  }
  return paramsData
}

// 没有选中数据的错误提示
export const delSuccess = () => {
  const t = i18n.global.t
  ElMessage.success(t('commonresource.delSucceeded.key', '删除成功'))
}

// 没有选中数据的错误提示
export const saveSuccess = () => {
  const t = i18n.global.t
  ElMessage.success(t('commonresource.savingSucceeded.key', '保存成功'))
}

// 选中多条的错误提示
export const cannotSelectMultiple = () => {
  const t = i18n.global.t
  ElMessage.error(t('commonresource.editErr.key', '不能选中多条'))
}
/**
 * list页面校验是否选中 并且只能选中一项
 * isDraft 如果需要校验草稿 就传入这个字段 string类型 这个字段就是草稿的字段key 默认state
 * 传true 默认 state 传字符串 就是用字符串字段作为key
 * @param list select选中数据
 * @returns boolean true校验成功 可以跳转
 */

export const inspectSelect = (list: TAnyObj[], isDraft?: boolean | string) => {
  const t = i18n.global.t
  if (!list || !list.length) {
    noSelect()
    return false
  } else if (list.length > 1) {
    // cannotSelectMultiple()
    noSelect()
    return false
  } else {
    let draftStr = ''
    if (!isDraft) {
      return true
    } else {
      if (isDraft === true) {
        draftStr = 'state'
      } else {
        draftStr = isDraft
      }
    }
    const draft = (list[0] && list[0][draftStr]) ? String(list[0][draftStr]) : ''
    if (draft && ['4', '草稿', '2', '审批不通过'].includes(String(draft))) {
      return true
    } else {
      ElMessage.error(t('commonresource.editApprovalFailedPrompt.key', '只有草稿和审批不通过状态才能编辑'))
      return false
    }
  }
}
/**
 *
 * @param data 请求后返回的数据 数组
 * @param dictData 字段数据加对应的字典key  object {itemVal:dict||function()}
 * dictData 的val如果是函數 则是自定义格式化函数 该函数需要有返回值 返回值为格式化完成的数据
 */
type TFilterF = (key: string) => string
type TfilterObj = { [x: string]: string | TFilterF }
export const keyToVal = (data: Tany[], dictObj: TfilterObj) => {
  // 字典的key和value指定的key是什么 value为中文名
  let keyStr = 'key'
  let valStr = 'value'
  return new Promise((resolve: Tany) => {
    const backData = JSON.parse(JSON.stringify(data))
    // 先发送请求获取字典数据
    let promiseArr: Tany[] = []
    for (const key in dictObj) {
      let dict = dictObj[key] as string
      if (typeof dict === 'string') {
        // 如果传入的是字典key的字符串 就使用该字符串获取字典数据
        let bdtc = PublicField[dict]
        promiseArr.push(SGeneralData.basicDataSelect({ bdtc }, true))
      }
    }
    // 将字段key作为键值 对应字典数据作为其value(对象) 本来是字典数组 这里直接将数组转为对象
    type TdictData = {
      [x: string]: {
        [x: string]: {
          value: string
          key: string
        }
      }
    }
    let dictData: TdictData = {}
    Promise.all(promiseArr).then((res: Tany) => {
      // 组装字典数据
      let i = 0
      // 循环传进来的字典key 将对应的字典数据赋值给key
      for (const key in dictObj) {
        let dict = dictObj[key]
        if (typeof dict === 'string') {
          let dictArr = res[i] || []
          if (dictArr && dictArr.data && dictArr.data.length) {
            let obj = {}
            // 循环字典数据 将数组转为对象
            dictArr.data.forEach((dictCur: { key: Tany; value: string }) => {
              obj[dictCur[keyStr]] = dictCur
            })
            let dictKey = dict as string
            dictData[dictKey] = obj
          }
          i++
        }
      }
      // 循环data设置value
      // if (filterFunc) dictObj = Object.assign(dictObj, filterFunc)
      (backData || []).forEach((dataItem: object) => {
        for (const key in dictObj) {
          if (dataItem[key] !== undefined) {
            if (typeof dictObj[key] === 'function') {
              // 如果类型是function 就调用函数 函数需要一个参数 并且会返回格式化好的字符串
              let func = dictObj[key] as TFilterF
              dataItem[key] = func(dataItem[key])
            } else {
              // dataItem.key 拿到指定字典key的value
              let backData = ''
              let dataKey = dictObj[key] as string
              if (
                dictData[dataKey] &&
                dictData[dataKey][dataItem[key]] &&
                dictData[dataKey][dataItem[key]][valStr]
              ) {
                backData = dictData[dataKey][dataItem[key]][valStr]
              }
              dataItem[key] = !backData ? dataItem[key] : backData
            }
          }
        }
      })
      resolve(backData)
    })
  })
}

/**
 * 循环 保证 selectData中的某些字段一样   [{x:Tany,x:Tany}]
 * @param identicalData //需要校验的字段 对象格式 {x:{label:string}} x为指定的字段名 label为当前字段的中文名
 * @param selectData
 * @param fn //校验成功后的执行函数
 */
interface IObjAny {
  [x: string]: {
    [x: string]: string
  }
}
export const filterData = (
  identicalData: IObjAny,
  selectData: Tany[],
  fn?: () => void
) => {
  const t = i18n.global.t
  // const identicalData = {
  //   sourceBillCode: {
  //     label: '源单单号'
  //   }
  // }
  // 将需要对比的值通过对象保存起来
  let saveSameData = {}
  const identicalKeyArr = Object.keys(identicalData)
  let errArr = []
  for (let i = 0; i < selectData.length; i++) {
    const element = selectData[i]
    if (i === 0) {
      // 第一次循环进行赋值
      for (let o = 0; o < identicalKeyArr.length; o++) {
        const identicalKey = identicalKeyArr[o]
        if (element[identicalKey]) {
          saveSameData[identicalKey] = element[identicalKey]
        }
      }
    } else {
      // 后面的循环用于对比
      for (let o = 0; o < identicalKeyArr.length; o++) {
        const identicalKey = identicalKeyArr[o]
        if (
          saveSameData[identicalKey] &&
          saveSameData[identicalKey] !== element[identicalKey]
        ) {
          errArr.push(identicalData[identicalKey].label)
        }
      }
    }
  }
  if (errArr.length) {
    let errStr =
      t('warehouse.selectErr.key', '选中的') +
      ' ' +
      errArr.join('/') +
      ' ' +
      t('warehouse.selectErrEnd.key', '必须一样')
    ElMessage.error(errStr)
    return false
  } else {
    if (fn) fn()
    return true
  }
}

type TCountType = '+' | '-' | '*' | '/'
/**
 * 计算方法
 * @param start 计算开始值
 * @param end 计算结束值
 * @param type 计算方式  + - * /
 * @param numLength 格式化小数位 number
 * @returns string 计算结果
 */
export const countFn = (
  start: string | number,
  end: string | number,
  type: TCountType = '+',
  numLength = 6
) => {
  let back = ''
  if (!start) {
    start = 0
  }
  if (!end) {
    end = 0
  }
  let startStr = String(start)
  let endStr = String(end)
  let fn: Tany = null
  if (type === '+') {
    fn = math.add
  } else if (type === '-') {
    fn = math.subtract
  } else if (type === '*') {
    fn = math.multiply
  } else if (type === '/') {
    fn = math.divide
  }
  try {
    if (fn) {
      let backCount: string = math.format(
        fn(math.bignumber(startStr), math.bignumber(endStr))
      )
      back = backCount
    } else {
      back = startStr || endStr || '0'
    }
    if (typeof +back === 'number') {
      back = (+back).toFixed(numLength)
    }
    back = halfAdjust(back, numLength)
    // parseFloat 方法去除小数点后面无用的 0
  } catch (err) {
    // 捕获异常并处理
    console.log(err, '错误')
  }
  return String(parseFloat(back))
}

/**
 * 数字求和
 * @param Numbers (string | number)[] 求和的数据
 */
export const sumOfNumbers = (Numbers: (string | number)[]) => {
  const res: string = Numbers.reduce((callbackfn: string, item) => {
    return countFn(callbackfn, item, '+')
  }, '0')
  return res
}

/**
 * 计算汇率
 * @param start 计算开始值
 * @param end 计算结束值
 * @returns string 汇率
 */
export const getexchangeRate = async (
  start: string | number,
  end: string | number
) => {
  if (start === end) {
    return '1'
  } else {
    let exchangeRate = ''
    await queryExchangeRate([start, end]).then((result: apiType) => {
      // console.log(result)
      if (result.code) {
        if (result.data.length >= 2) {
          exchangeRate = countFn(
            result.data[1].exchangeRate,
            result.data[0].exchangeRate,
            '/'
          )
        } else {
          ElMessage.warning('接口数据未返回')
        }
      }
    })
    return halfAdjust(exchangeRate, 6)
  }
}

/**
 * 保存草稿提示
 * @param arr 需要提示项数据的数组
 */
export const unsavedPrompt = (arr: string[]) => {
  const t = i18n.global.t
  let str = t(
    'commonresource.unsavedPrompt.key',
    '以下数据未保存, 请保存之后再操作'
  )
  arr.forEach((item, index) => {
    str += `<br/>${index + 1}. ${item}`
  })
  ElMessage({
    dangerouslyUseHTMLString: true,
    message: str,
    type: 'error'
  })
}
// 获取计量单位数据
const getUnitData = async () => {
  if (!data.unit || !data.unit.length) {
    let params = {
      bdtc: '',
      pageIndex: 1,
      pageSize: 999
    }
    await SGeneralDataBase.basicDataCUGetPage(params).then(res => {
      if (res.code == 1) {
        data.unit = res.data.items
      }
    })
  }
}
interface dataListItem {
  code: string
}
// 导出val的类型
export type TVal = {
  dataListItem: dataListItem
  emitName?: string
  type?: string
  data?: { [x: string]: Tany }

  //  字典select的数据
  value: string
  val: string
  gocc: string
  maid: string
  mata: string
  matn: string
  spec: string
  minp: string // 最小包装量
  unid: string // 计量单位

  waid: string // 仓库相关id
  wpid: string
  wlid: string
  wnid: string
  unin: string
  macc: number
  esnc: boolean
  vald: number
}
export type TFormType = IitemEmit
type rowType = {
  materialCode: string
  materialName: string
  specification: string
  materialNo: string
  materialId: string
}
type TMaterType = {
  prop: string
  scope: {
    row: TAnyObj
  }
  val: TVal
  formatterComponent?: {
    storageSelect: string
  }// 有哪些组件的参数需要格式化-对应的key就是其对应得字段 storage 仓库组件
}
/**
 * 引入方法
 * import { commonFunc } from '@/utils'
 *
 * 保存物料导出的两个参数
 * const materFn = commonFunc.backDefMaterNoComponents()
*
* columnList的使用方法 ...materFn.materColumn

 * tableForm itemChange的 方法的参数 itemChange(val:commonFunc.TVal, scope:{row:commonFunc.TAnyObj}, from:commonFunc.TFormType, prop:string)
 * change赋值方法  materFn.materChange({ val, scope, prop })
 *
 *
 * @returns  {
 *  materChange, //物料料号的change执行的事件 需传入参数 TMaterType
 * materColumn:{},//对象格式 使用 对象展开运算符 展开内容到原有物料输入栏
 * }
 */

export const getUnit = async () => {
  let resType
  const res = await basicDataSelect({ bdtc: 'unitMeasure' }, true)
  res.code = res.code
  res.data = res.data.map((item: any) => {
    item.unin = item.txt
    item.unic = item.val
    return item
  })
  resType = res
  return resType
}
export const getUnitOptions = () => {
  return { API: getUnit(), optionsLabel: { label: 'unin', value: 'unic' } }
}
export const backDefMaterNoComponents = (unitOptions: object = {}, changeParams?: objAny) => {
  getUnitData()
  const productLineVal = ref()
  // const sendRequest = ref()
  let materColumn: ColumnType[] = [
    {
      label: '物料分类',
      prop: 'materialClassification',
      title: 'warehouse.materialClassification',
      options: materClassFormatter(),
      // sifting: {
      //   name: 'select',
      //   optionsSetting: {
      //     getOptionsFunc: getMaterClass,
      //     // params: { level: 0 },
      //     optionsLabel: { label: 'name', value: 'id' }
      //   }
      // },
      width: 120,
      form: {
        required: true,
        name: 'select',
        optionsSetting: {
          getOptionsFunc: getMaterClass,
          optionsLabel: { label: 'name', value: 'id' }
        }
      }
    },
    {
      label: '产品线',
      prop: 'productLine',
      title: 'purchase.productLine',
      options: {
        formatter: 'productLine'
      },
      form: {
        name: 'basicDictSelect',
        optionsSetting: {
          dict: 'productLine'
        }
      },
      minWidth: 155,
      sortable: true
    },
    {
      label: '物料名称', // materialName
      prop: 'materialId',
      title: 'purchase.MaterialName',
      form: {
        name: 'materialNumber',
        optionsSetting: {
          optionsLabel: {
            label: 'mata',
            value: 'maid'
          }
        }
      },
      formatter: (row: rowType) => {
        return row.materialName
      },
      minWidth: 220
    },
    {
      label: '物料料号', // materialNo
      prop: 'materialNo',
      title: 'purchase.MaterialPartNumber',
      form: {
        validator: 'required',
        name: 'materialNumber',
        optionsSetting: {
          params: {
            gocc: productLineVal
          },
          optionsLabel: {
            label: 'matn',
            value: 'maid'
          }
        }
      },
      minWidth: 220,
      formatter: (row: rowType) => {
        return row.materialNo || row.materialCode
      },
      sortable: true
    },
    {
      label: '规格型号', // specification
      prop: 'specification',
      title: 'purchase.SpecificationsAndModels',
      form: {
        name: 'materialNumber',
        optionsSetting: {
          params: {
            gocc: productLineVal
          },
          optionsLabel: {
            label: 'spec',
            value: 'maid'
          }
        }
      },
      formatter: (row: rowType) => {
        return row.specification
      },
      showOverflowTooltip: true,
      minWidth: 375,
      // width: 180,
      sortable: true
    },
    {
      label: '计量单位',
      title: 'warehouse.unitOfMeasurement',
      prop: 'unitOfMeasurement',
      options: getUnitOptions(),
      ...unitOptions,
      value: 0
    }
  ]
  const materChange = (materParams: TMaterType, refName: Ref) => {
    // console.log('materParams',materParams);
    let materProp = materParams.prop
    if (materParams.prop == 'materialNo' || materParams.prop == 'specification') {
      materProp = 'materialId'
    }
    const prop = materProp
    const value = materParams.val
    const scope = materParams.scope
    if (!value.data && (value?.type === 'optionsClick' && prop === 'materialClassification') || prop === 'productLine') { // 产品线、物料分类
      // scope.row.materialId = '' // 产品线的值更改 清空已选择物料
      // productLineVal.value = value.val || ''
      scope.row.materialCode = '' // 物料编码
      scope.row.materialName = '' // 物料名称
      scope.row.specification = '' // 规格型号
      scope.row.materialId = ''
      if (prop === 'productLine') {
        productLineVal.value = value.data?.tableRow.productLine
      }
      // sendRequest.value = !sendRequest.value
      refName.value.refreshRowItemData({ rowId: scope.row.rowId, prop: 'materialId' })
    } else if (prop === 'materialId') { // 物料料号,物料名称,规格型号
      if (scope.row.productLine !== value.gocc) {
        scope.row.productLine = value.gocc
        productLineVal.value = value.gocc
        scope.row.unit = value.unid // 单位
        // sendRequest.value = !sendRequest.value // 产品线修改重新刷新下拉数据
        refName.value.refreshRowItemData({ rowId: scope.row.rowId, prop: 'materialId' })
      } // 修改产品线的值
      scope.row.scanControl = Boolean(value.esnc)
      scope.row.unit = value.unid // 修改单位
      scope.row.materialNo = value.matn || ''
      scope.row.materialId = value.maid || ''
      scope.row.materialCode = value.matn || '' // 物料编码
      scope.row.materialName = value.mata || '' // 物料名称
      scope.row.specification = value.spec || '' // 规格型号
      // scope.row.productLine = value.maid || ''
      // scope.row.unitOfMeasurement = value.unin || '' // 计量单位
      // 指定组件参数的格式化
      if (materParams.formatterComponent) {
        const storageKey = materParams.formatterComponent.storageSelect
        // 仓库储位组件
        if (storageKey) {
          console.log('storageKey', storageKey)

          // 入库储位参数格式化
          scope.row[storageKey] = JSON.stringify({
            warehouseId: value.waid, // 仓库id
            partitionId: value.wpid, // 分区id
            storageLocationId: value.wlid, // 库位id
            libraryNoId: value.wnid // 库号id
          })
        }
      }
      // 最小包装量
      scope.row.minimumPackaging = value.minp || ''
      scope.row.materialClassification = String(value.macc || '')
      scope.row.unitOfMeasurement = value.unid
      scope.row.validityDays = value.vald
      // 赋值计量单位
      // if (value.unid) {
      //   if (data.unit && data.unit.length) {
      //     for (let i = 0; i < data.unit.length; i++) {
      //       const element: { unic: string, unin: string } = data.unit[i]
      //       if (element.unic == value.unid) {
      //         scope.row.unin = element.unin
      //         scope.row.unitOfMeasurement = element.unin
      //         return element.unin
      //       }
      //     }
      //   }
      // }
    }
  }

  // 替换数据
  if (changeParams) {
    materColumn = materColumn.map((item: ColumnType) => {
      const prop = item.prop
      if (changeParams[prop]) {
        item = { ...item, ...changeParams[prop] }
      }
      return item
    })
  }

  return {
    materColumn,
    materChange
  }
}
// 物料类型请求封装 -- id类型转换 code与常用code不符
export const getMaterType = async () => {
  let resType
  const res = await Materia.listByLevelAndParent({ level: 0, parentId: 0 })
  res.code = res.code === 200 ? 1 : res.code
  resType = res
  return resType
}
// 物料分类请求封装 -- id类型转换 code与常用code不符
export const getMaterClass = async () => {
  let resType
  const res = await Materia.listByLevelAndParent({ level: 1 })
  res.code = res.code === 200 ? 1 : res.code
  // 将所有数字id转为字符串id
  res.data = res.data.map((item: objAny) => ({ ...item, id: String(item.id) }))
  resType = res
  return resType
}
// 物料分类的fomatter
/**
 * if (item.coln === 'materialClassification') {
      obj.options = commonFunc.materClassFormatter()
    }
 * @returns
 */
export const materClassFormatter = () => {
  return {
    API: getMaterClass(),
    optionsLabel: { label: 'name', value: 'id' }
  }
}

// 导出当前用户的登录信息和当前时间---赋值新增页面的默认内容
export const defData = {
  userSes: cache.getLocalStorage('userInfo') ? JSON.parse(cache.getLocalStorage('userInfo')) : {},
  currentTime: moment().format('YYYY-MM-DD')
}
